import React from 'react'
import landingpage from '../ASSETS/landingpage/images/landingpage_barniere.png'
import logo1 from '../ASSETS/tourisme/Image/LOGO.png'
import { Link } from 'react-router-dom'
import immCA from '../ASSETS/landingpage/icones/passport (1).png'
import immRE from '../ASSETS/landingpage/icones/immigration (1).png'
import tourisme from '../ASSETS/landingpage/icones/vacation (1).png'
import logocall from'../ASSETS/landingpage/icones/appel.png'
import logomail from '../ASSETS/landingpage/icones/email.png'
import logotiktok from '../ASSETS/landingpage/icones/tiktok.png'
import logofacebook from '../ASSETS/landingpage/icones/facebook.png'
import { useState, useEffect } from 'react';
import Loading from '../TOURISME/COMPONENTS/Loading/Loading'
import logoinstagram from '../ASSETS/landingpage/icones/instagram.png'
import { ToastContainer, toast } from "react-toastify"

function LandingPage() {
  const platformEmail = 'info@monbonsejour.com';
  const phoneNumber = '+2250702322868';
  const instagramUsername = 'monbonsejour';
  const tiktokUsername = 'monbonsejour.ci';
  const facebookUsername = 'profile.php?id=61551029507292&mibextid=LQQJ4d';

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 1000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <div className=" bg-cover bg-center bg-no-repeat xs:h-screen s:h-full md:h-full w-screen s:pb-10 " style={{backgroundImage:`url(${landingpage})`}}>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
      <Link to='/'>
        <div style={{borderBottomRightRadius:"100px"}} className=' relative sm:pl-5 s:pl-2 bg-white w-40 pb-4 pr-4  '>
          <img src={logo1} className='h-20  s:pl-0 pt-6  ' alt='logo' />
        </div>
      </Link>

      <div>
                            <ToastContainer />
                        </div>

      <div className='text_home text-white s:pt-8  flex justify-center  '>
        <p className='xs:w-[700px] sm:w-80 s:w-64 text-center s:text-base xs:text-lg'>Avec <span className='font-semibold'>Mon Bon Séjour</span>, voyagez et intégrez vous facilement, paisiblement et confortablement partout dans le monde.</p>
      </div>

      <div className='text_home sm:text-2xl s:text-xl md:text-5xl font-bold text-white pl-12 md:pt-5 s:pt-10 s:pr-10 sm:pr-0 text-center'>
        Quel service desirez-vous ?
      </div>

      {/* option */}
      <div className='navbar_animation s:mt-8 xs:mt-16 grid md:grid-cols-3 s:grid-cols-1 s:gap-10 xs:grid-cols-2 md:pt-4  md:gap-2 place-items-center'>
       
        {/* Assistance & accompagnement voyages */}
       
        <div className=' bg-slate-700 hover:bg-white text-white hover:text-slate-700  w-64 h-40 rounded-md flex flex-col justify-center items-center cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
          <Link to='/hometourisme'>
          <div className='text-center px-24' ><img src={tourisme} className='w-14 pb-3' alt='immigration' /></div>
          <p className=' px-4 text-center font-semibold text-lg '>Voyages, Loisirs & Locations</p>
          </Link>
        </div>

        {/* Conseil en immigration ou retour en afrique */}
        <div className='bg-white text-slate-700 hover:bg-slate-700 hover:text-white w-64 h-40 rounded-md flex flex-col justify-center items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
          <Link to='/accueil_afrique_immigration'>
          <div className='text-center px-24' ><img src={immRE} className='w-14 pb-3' alt='immigration' /></div>
          <p className='px-4 text-center font-semibold text-lg'>Installation & Intégration en Afrique</p>
          </Link>
        </div>

         {/* Conseil en immigration canadienne */}
         <div className=' bg-slate-700 hover:bg-white  text-white  hover:text-slate-700 w-64 h-40 rounded-md flex flex-col justify-center items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer'>
          <Link to="/homeCaimmigration">
          <p className='text-center px-24'><img src={immCA} className='w-14 pb-3' alt='immigration'/></p>
          <p className='px-4 text-center font-semibold text-lg'>Immigration Canadienne & Autres Pays</p>
          </Link>
        </div>


       

      </div>

      <Link to='/PrendreRendezVous'>
        <div className='flex justify-center mt-6'>
          <button className='bg-[#fe7f2d] h-10 w-[130px] rounded text-white'>Contactez-Nous</button>
        </div>
      </Link>

      

      <div className='text_home grid md:grid-cols-5 xs:grid-cols-3 xs:text-sm s:grid-cols-1 xs:space-x-4 s:space-y-4 xs:space-y-0 sm:px-16 s:px-10 xs:px-3 place-items-center place-content-center  mt-7'>
         {/* phone */}
          <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
        <div className='flex flex-row  items-center space-x-4 hover:underline decoration-white cursor-pointer'>
          <img src={logocall} alt='call' className='w-7'/>
          <p className='text-white w-full '>+225 05 45 32 37 56 </p>
        </div>
        </a>

         {/* mail */}
        <a href={`mailto:${platformEmail}`}>
        <div className='flex flex-row items-center space-x-4  hover:underline decoration-white cursor-pointer'>
          <img src={logomail} alt='mail' className='w-7' />
          <p className='text-white'>info@monbonsejour.com </p>
        </div>
        </a>
        
         {/* facebook */}
        <a href={`https://www.facebook.com/${facebookUsername}`} target="_blank" rel="noopener noreferrer">
        <div className='flex flex-row  items-center space-x-4 hover:underline decoration-white cursor-pointer'>
          <img src={logofacebook} alt='call' className='w-7'/>
          <p className='text-white w-full '>Mon Bon Sejour </p>
        </div>
        </a>

        {/* Tiktok */}
        <a href={`https://www.tiktok.com/@${tiktokUsername}`} target="_blank" rel="noopener noreferrer"  >
        <div className='flex flex-row items-center space-x-4  hover:underline decoration-white cursor-pointer'>
          <img src={logotiktok} alt='mail' className='w-6' />
          <p className='text-white'>Mon Bon Sejour </p>
        </div>
        </a>

        {/* instagram */}
        <a href={`https://www.instagram.com/${instagramUsername}`} target="_blank" rel="noopener noreferrer">
        <div className='flex flex-row items-center space-x-4  hover:underline decoration-white cursor-pointer'>
          <img src={logoinstagram} alt='mail' className='w-6' />
          <p className='text-white'>Mon Bon Sejour </p>
        </div>
        </a>

      </div>
  
      </>
      )}
    </div>
  )
}

export default LandingPage