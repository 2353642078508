import React from 'react'
import photo from '../../../ASSETS/tourisme/Image/camera.png'
import camping from '../../../ASSETS/tourisme/Image/fire.png'
import visite from '../../../ASSETS/tourisme/Image/public-transport.png'
import back2 from '../../../ASSETS/tourisme/Image/back_2.png'
import randonne from '../../../ASSETS/tourisme/Image/randone.png'

function BarniereCD() {
  return (
        <div className='img_culture_C2 s:py-10  bg-cover bg-center bg-no-repeat s:space-y-10 flex flex-col justify-center items-center  lg:h-screen lg:w-screen mb-10 text-white ' style={{ backgroundImage:`url(${back2})` }}>
            {/* title */}
            <div className='flex flex-col justify-center items-center space-y-3'>
              <p className='md:text-lg'>Activité A Faire Pendant</p>
              <p className='md:text-5xl s:text-xl font-semibold'>Le Tourisme</p>
              <p className='md:w-[800px] s:w-[260px] sm:w-[320px] xs:w-[700px] text-center md:text-base s:text-sm '>
                Découvrez la Côte d'Ivoire, joyau de l'Afrique de l'Ouest ! Plages paradisiaques, nature exubérante, 
                et patrimoine culturel unique vous attendent. Plongez dans une expérience gastronomique inoubliable 
                et laissez-vous séduire par l'hospitalité chaleureuse des Ivoiriens. Explorez un pays aux mille facettes,
                où chaque instant est une invitation à l'émerveillement. Bienvenue en Côte d'Ivoire, la destination qui 
                éveillera vos sens et laissera des souvenirs impérissables.
              </p>
        
            </div>

            {/* option */}
            <div className='grid gap-20 md:grid-cols-4 xs:grid-cols-2 sm:grid-cols-1 '>
              {/* randonnée */}
              <div className='bg-white w-44 h-44 rounded-full flex flex-col space-y-4 items-center justify-center'>
              <img src={randonne} alt=''/>
              <p className=' text-slate-800 font-bold'> Randonnée</p>
              </div>

              {/* photo */}
              <div className='bg-white  w-44 h-44 rounded-full flex-col space-y-4  flex items-center justify-center'>
              <img src={photo} alt=''/>
              <p className=' text-slate-800 font-bold'> Photographie</p>
              </div>

              {/* camping */}
              <div className='bg-white  w-44 h-44 rounded-full flex-col space-y-4  flex items-center justify-center'>
              <img src={camping} alt=''/>
              <p className=' text-slate-800 font-bold'> Camping</p>
              </div>

              {/* photo */}
              <div className='bg-white  w-44 h-44 rounded-full flex-col   flex items-center justify-center'>
              <img src={visite} alt='' className='w-20'/>
              <p className=' text-slate-800 font-bold'> Visite guidé</p>
              </div>
            </div>
            </div>
  )
}

export default BarniereCD