import React from 'react'
import sucess from '../../../ASSETS/tourisme/icone/tick-mark.png'

function Sucess() {
  return (
    <div className='bg-[#f7f7f7] h-screen flex justify-center items-center'>
      <div className='bg-white w-[350px] h-[350px]  flex flex-col items-center  space-y-4 shadow '>
        <img src={sucess} alt='sucess'  className='w-[150px] '/>
        <p className='text-xl text-[#4e4e4e] font-semibold '>Success!</p>
        <p className='w-[260px] text-center'>Excepteur sint occaecat cupidatat non proident, sunt in culpa</p>
        <button className='border-[#88C941] border-2 rounded-2xl text-[#88C941] h-8 w-[140px]'>Continuer</button>
      </div>
    </div>
  )
}

export default Sucess