import React from 'react'
import Header from '../../COMPONNENTS/Header/Header'
import accueil from '../../../ASSETS/CaImmigration/images/Ca_barniere.png'
import etude from '../../../ASSETS/CaImmigration/images/visa_etudiant.png'
import travail from '../../../ASSETS/CaImmigration/images/visa_travail.png'
import touriste from '../../../ASSETS/CaImmigration/images/visa_touriste.png'
import famille from '../../../ASSETS/CaImmigration/images/visa_familiale.png'
import residence from '../../../ASSETS/CaImmigration/images/vue-port-vancouver-immeubles-appartements-urbains-bateau-baie-au-canada.png'
import citoyen from '../../../ASSETS/CaImmigration/images/citoyen.png'
import immobilier from '../../../ASSETS/CaImmigration/images/bienimmobilier.png'
import rdv from '../../../ASSETS/CaImmigration/images/rdv_barniere.png'
import Footer from '../../COMPONNENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import {Link} from 'react-router-dom'
import FAQ from '../FAQ/Faq'
import temoignage from '../../../ASSETS/CaImmigration/images/photo_temoignage.jpg'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visa from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard'
import { ToastContainer, toast } from "react-toastify"



function HomeCa() {

  localStorage.setItem('url', JSON.stringify('/homeCaimmigration'));

  let [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 1000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{backgroundColor:"#f7f7f7"}}>

      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
      
      <Header/>

      <div>
        <ToastContainer />
      </div>
      
      {/* back image */}
      <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${accueil})` }}>
    
      {/* Navbar */}
      <div className='navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0  bg-transparent flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
      <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center xs:space-x-[600px]    justify-between'>

        <Link to='/'>
          <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
            <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
          </div>
        </Link>
        <div onClick={() => setOpen(!open)} className=' lg:hidden text-end relative   s:text-3xl pr-4 s:text-white cursor-pointer'>
          <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
        </div>
      </div>
      {/* element_menu */}
      <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

        <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>
          
            {/* Accueil */}
            <Link to='/homeCaimmigration'>
              <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-yellow-400 '>
                <div>
                  <img className='w-6' src={home} alt='' />
                </div>
                <div> Accueil</div>
              </div>
            </Link>

          {/* Visa */}
          
            <div style={{ fontSize: "16px" }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
              <div className='relative bottom-1'>
                <img className='w-4' src={visa} alt='' />
              </div>
              <div className='navbar1 '>
                <ul>
                <li> <Link to='/visa'><p className='flex space-x-2 items-center'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p></Link>
                <ul className='z-10  '>
                    <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                </ul>
                
                </li>
                </ul>
              </div>
            </div>
          

          {/* Résidence permanente */}
          <Link to='/ResidencePermante'>
          <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
            <div>
              <img className='w-6' src={Rpermanent} alt='' />
            </div>
            <div>Résidence permanente</div>
          </div>
          </Link>

          {/*Citoyenneté Canadienne */}
          <Link to='/CitoyenneteCa'>
          <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
            <div>
              <img className='w-6' src={CitoyenCA} alt='' />
            </div>
            <div> Citoyenneté Canadienne</div>
          </div>
          </Link>

          {/* Achat de biens immobilers */}
          <Link to='/BienImmobilier'>
            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
              <div>
                <img className='w-6' src={immobilierN} alt='' />
              </div>
              <div>Achat de biens immobilers</div>
            </div>
          </Link>
        </div>
      </div>
        </div>

        <div className='text_home flex flex-col  space-y-12 md:pl-10 s:pl-5  s:pt-10 md:pt-0 s:mt-10 xs:mt-18 lg:mt-44'>
          <div className=' flex flex-col space-y-3 text-white lg:text-6xl xs:text-4xl sm:text-xl  '>
            <p>RENCONTREZ NOS CONSEILLERS</p>
            <p>EN IMMIGRATION CANADIENNE ;</p>
            <p>ACHATS DES BIEN IMMOBILIERS</p>
            <p>AU CANADA</p>
          </div>
         <div className='flex xs:flex-row s:flex-col s:space-x-0 s:space-y-8 xs:space-y-0  xs:space-x-10'>
          <Link to="/AboutUs">
            <button style={{backgroundColor:"#fe7f2d"}} className=' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700  w-48 h-16 rounded text-white text-lg font-semibold'>
            En savoir plus
          </button>
          </Link>
          <Link to="/PrendreRendezVous">
          <button style={{backgroundColor:"white", color:"#fe7f2d"}} className=' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700  w-48 h-16 rounded text-lg font-semibold'>
            Prendre rendez-vous
          </button>
          </Link>
         </div>
        </div>

      </div>

      {/* option */}
      <OptionCard/>
    

      {/* en tete */}
      <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
        <div style={{ color:"##4e4e4e"}} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
        <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
      </div>
        
        
      {/* visa */}
      <div className='bg-white py-20 '>
        {/* titre */}
        <div className='titre_visa flex  xs:flex-row xs:px-5 md:px-0 md:pl-0 flex-col justify-center items-center xs:space-x-40 xl:space-x-[700px] lg:space-x-[300px] s:space-y-10'>
          <div>
            <p className=' text-sm font-semibold'><span style={{ color:"#FE7F2D"}} className=' font-bold sm:text-xl s:text-lg'>/</span>QUE PROPOSONS-NOUS </p>
            <p style={{ color: "##4e4e4e" }} className=' sm:text-2xl s:text-xl font-bold w-[250px]'>Conseils en Services de visa d'immigration exceptionnels.</p>
          </div>

          <div style={{ color: "##4e4e4e" }} className='xs:w-[650px] md:w-[800px] sm:w-[300px] s:w-[200px] sm:text-lg s:text-base text-justify '>
          À la recherche d'une expertise exceptionnelle en matière d'immigration au Canada ? 
          Nous sommes là pour vous accompagner tout au long de votre parcours. Nos consultants 
          chevronnés vous offrent des conseils personnalisés et des services de visa d'immigration 
          de haute qualité. Avec notre équipe dévouée, nous vous guiderons à chaque étape de votre 
          processus d'immigration, vous aidant à réaliser votre rêve canadien en toute simplicité. 
          Faites confiance à notre expérience pour une immigration réussie et sans stress.</div>
        </div>
        {/* bloc */}
        <div className='grid md:grid-cols-4 s:grid-cols-1 xs:grid-cols-2 mt-20 gap-3 place-items-center'>
          {/* visa etude */}
          <div className='opacity1'>
            <Link to='/visa/visaEtude'>
            <img src={etude} alt='permisetudes' className='w-[250px]'/>
            <div className='font-semibold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{backgroundColor: "#233D4D",color:"#ffffff" }}>
              <p>Visa et permis d'etudes</p>
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                <g id="right" transform="translate(-24.596 -147.982)">
                  <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff"/>
                </g>
              </svg>
              </div>
            </div>
            </Link>
          </div>
          {/* visa travail */}
          <div className='opacity1'>
             <Link to='/visa/visaTravail'>
            <img src={travail} alt='permisetudes' className='w-[250px]' />
            <div className=' text-white font-bold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{ backgroundColor: "#233D4D",color:"#ffffff"  }}>
              <p>Visa et permis de travail</p>
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                <g id="right" transform="translate(-24.596 -147.982)">
                  <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff"/>
                </g>
              </svg>
              </div>
              </div>
              </Link>
          </div>
          {/* visa touriste*/}
          <div className='opacity1'>
             <Link to='/visa/visaTouriste'>
            <img src={touriste} alt='permisetudes' className='w-[250px]' />
            <div className=' text-white font-bold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{ backgroundColor: "#233D4D",color:"#ffffff"  }}>
              <p>Visa touriste</p>
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                <g id="right" transform="translate(-24.596 -147.982)">
                  <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff"/>
                </g>
              </svg>
              </div>
              </div>
              </Link>
          </div>
          {/* visa visiteur*/}
          <div className='opacity1'>
             <Link to='/visa/visaFamiliale'>
            <img src={famille} alt='permisetudes' className='w-[250px]' />
            <div className=' font-semibold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{backgroundColor: "#233D4D",color:"#ffffff"  }}>
              <p>Visa familiale</p>
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                <g id="right" transform="translate(-24.596 -147.982)">
                  <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff"/>
                </g>
              </svg>
              </div>
              </div>
              </Link>
          </div>
        </div>

      </div>
      {/* residence  */}
      <div className=' w-screen s:h-screen xs:h-full  bg-no-repeat bg-center flex flex-col xs:items-end s:items-center px-10 py-4' style={{backgroundImage:`url(${residence})`,}}>
        <div className='xs:w-[550px] sm:w-[300px] s:w-[250px]  px-6  pb-6 py-8 space-y-6 ' style={{ backgroundColor:"#FE7F2D"}}>
          <p className='text-white font-bold xs:text-2xl s:text-lg'>Résidence permanente</p>
          <p className='text-justify xs:w-[480px] sm:w-[250px] s:w-[200px] xs:text-lg sm:text-base s:text-sm text-white'>
            La résidence permanente au Canada est le premier pas vers une nouvelle vie riche en opportunités. 
            Notre expertise en matière d'obtention de la résidence permanente est votre meilleur allié dans ce 
            voyage. Nous vous guiderons à travers les programmes de résidence permanente les plus adaptés à 
            votre situation, vous fournissant des conseils avisés et une assistance de haut niveau pour que 
            vous puissiez réaliser votre rêve de vivre de manière permanente au Canada. Faites confiance à 
            notre équipe expérimentée pour une démarche de résidence permanente fluide et couronnée de succès.
          </p>
          <Link to="/ResidencePermante"><button className=' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150  border-2 border-white text-white xs:w-36 xs:h-12 s:w-28 s:h-10 s:text-sm xs:text-base font-medium hover:bg-white hover:text-orange-400 mt-10'>En savoir plus</button></Link>
        </div>
      </div>
      <div className='flex justify-center'>
      <div className='border-b border-gray-400  pt-14 w-[600px] '></div>
      </div>
     
      {/* citoyeneté */}
      <div className='md:mt-14 s:mt-0 flex md:flex-row justify-center s:items-center md:items-start s:flex-col  pl-10 s:pl-0' style={{ backgroundColor:"#4E4E4E"}}>
        <div className='space-y-8 py-8 md:pl-10 '>
          <p className='text-white font-bold xs:text-2xl s:text-xl'>Citoyenneté Canadienne</p>
          <p className='text-justify  md:w-[580px] xs:w-[600px] s:w-[220px] sm:w-[300px] s:text-sm sm:text-lg text-white'>
            La citoyenneté canadienne est le couronnement de votre parcours au Canada, marquant l'aboutissement de votre 
            engagement envers ce magnifique pays. Nous sommes là pour vous guider dans le processus d'obtention de la 
            citoyenneté canadienne. Notre équipe d'experts en immigration vous accompagnera étape par étape, vous aidant
            à comprendre les exigences, à rassembler les documents nécessaires et à préparer votre demande avec succès.
            Laissez-nous vous soutenir dans votre démarche vers la citoyenneté canadienne, et ensemble, réalisons votre
            rêve de devenir un citoyen fier et engagé du Canada. </p>
          <Link to="/CitoyenneteCa"><button className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150 border-2 border-white text-white w-36 h-12 font-medium hover:bg-white hover:text-orange-400 mt-10'>En savoir plus</button></Link>
        </div>
        <div className='relative md:top-[60px]  s:top-0 s:right-10 sm:right-0 sm:w-[400px] s:w-[300px] xs:w-[400px] md:w-[700px]'>
          <img  src={citoyen} alt='citoyeneté'/>
        </div>
      </div>

      {/* bien immobilier */}
      <div className='py-20  bg-white flex flex-col xs:flex-row justify-center s:items-center xs:items-start s:space-y-8 s:flex-col md:space-x-28 xs:space-x-10'>

        <div className='space-y-8 pl-10'>
          <p style={{ color: "#4e4e4e" }} className=' font-bold xs:text-2xl s:text-xl'>Achat d'un bien immobilier</p>
          <p style={{color:"#4e4e4e"}} className='text-justify  md:w-[600px] xs:w-[400px] s:w-[220px] sm:w-[300px] s:text-sm sm:text-lg'>
            Explorez notre gamme de biens immobiliers disponibles à la location dès maintenant. Notre sélection de propriétés de qualité vous 
            offre une opportunité exceptionnelle de vivre dans le confort ou d'investir dans des espaces qui répondent à vos besoins. Découvrez 
            nos offres de location de biens immobiliers et laissez-nous vous accompagner dans la recherche de la propriété parfaite pour vous. </p>
          <div className='flex items-center space-x-5'>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="62.165" height="64.138" viewBox="0 0 92.165 94.138">
              <g id="immobi" transform="translate(-9.373 -10)">
                <g id="Groupe_1" data-name="Groupe 1" transform="translate(9.373 10)">
                  <path id="Tracé_2" data-name="Tracé 2" d="M21.008,88.508a1.733,1.733,0,0,1,2.45,0l3.466,3.466a1.733,1.733,0,1,1-2.45,2.45l-3.465-3.466a1.733,1.733,0,0,1,0-2.45Z" transform="translate(-10.859 -20.42)" fill="#233d4d"/>
                  <path id="Tracé_3" data-name="Tracé 3" d="M64.859,10.508a1.733,1.733,0,0,0-2.45,0L50.449,22.467,50.2,17.282a1.733,1.733,0,1,0-3.462.165l.4,8.331-4.609,4.61a1.735,1.735,0,0,0-1.393,1.392l-8.184,8.185a1.733,1.733,0,1,0,2.45,2.45l5.706-5.706V59.425a15.6,15.6,0,0,0-9.5,4.128l-7.388,6.859-1.036-1.055a3.466,3.466,0,0,0-4.871-.075L10.44,76.826a3.466,3.466,0,0,0-.031,4.974l21.733,21.345a3.466,3.466,0,0,0,4.932-.077l7.157-7.481a3.466,3.466,0,0,0-.032-4.823L43.04,89.582a5.193,5.193,0,0,1,2.671-.739H69.383a14.984,14.984,0,0,0,10.056-3.8,125.8,125.8,0,0,0,11.521-11.56,142.442,142.442,0,0,0,10.078-14.736,3.439,3.439,0,0,0-1.245-4.774l-.886-.507a10.4,10.4,0,0,0-13.519,2.847l-.093.126v-20.6l6.573,6.573a1.733,1.733,0,1,0,2.45-2.45Zm16.969,21.87L63.634,14.183,44.573,33.244V59.385h4.89a8.664,8.664,0,0,1,5.074,1.641V45.09a1.733,1.733,0,0,1,1.733-1.733H68.832a1.733,1.733,0,0,1,1.733,1.733V63.371a6.7,6.7,0,0,1,3.947,2.18,1.726,1.726,0,0,1,.273-.231l7.06-4.83a1.75,1.75,0,0,1-.016-.237ZM40.571,87.067,26.647,72.887l7.318-6.794a12.13,12.13,0,0,1,8.253-3.241h7.244a5.2,5.2,0,0,1,3.293,1.175l.935.765a8.664,8.664,0,0,0,5.486,1.958H69.482a3.249,3.249,0,1,1,0,6.5H53.67a1.733,1.733,0,0,0,0,3.466H69.482a6.715,6.715,0,0,0,6.539-8.25,1.714,1.714,0,0,0,.721-.283l7.99-5.467a3.465,3.465,0,0,0,.83-.8l2.612-3.535a6.931,6.931,0,0,1,9.013-1.9l.872.5a138.905,138.905,0,0,1-9.766,14.3,122.488,122.488,0,0,1-11.174,11.2,11.516,11.516,0,0,1-7.735,2.905H45.711a8.664,8.664,0,0,0-5.141,1.69ZM57.31,62.938a5.206,5.206,0,0,0,1.867.347H67.1V46.822H58V61.551a1.73,1.73,0,0,1-.693,1.386Zm-36.6,8.846-7.871,7.544,21.733,21.344,7.156-7.481Z" transform="translate(-9.373 -10)" fill="#233d4d" fillRule="evenodd"/>
                </g>
              </g>
            </svg>
            </div>
            <p style={{ color:"#233D4D"}} className='text-xl s:text-base sm:text-lg font-bold'>+1000 Bien en ventes </p>
          </div>
                <Link to="/BienImmobilier" className=''><button style={{ backgroundColor: "#FCCA46" }} className='mt-10 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150 text-white w-36 h-12 font-medium '>Acheter un bien</button></Link>
        </div>
        <div className='md:w-[400px] xs:w-[350px] s:w-[250px] sm:w-[350px] s:pl-10 xs:pl-0'>
          <img src={immobilier} alt=''/>
        </div>
        
      
      </div>

      

      {/* banniere */}
      <div className='mb-0 w-screen  h-[400px] flex md:justify-end s:justify-center items-center bg-no-repeat bg-center pr-3' style={{backgroundImage:`url(${rdv})`}}>
       <div className='flex flex-col space-y-8 items-center'>
       <div className='xs:text-3xl lg:text-5xl s:text-xl font-semibold text-white text-center s:w-[200px] sm:w-[350px] xs:w-[600px] lg:w-[800px]'>
          Votre demarche d'immigration vers le Canada est assuré avec nous
        </div>
        <Link to="/PrendreRendezVous">
        <button style={{ backgroundColor:"#FCCA46"}} className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150 text-white w-36 h-12 font-medium '>Prendre RDV</button>
        </Link>
       </div>
      </div>

      {/* temoignages */}
      <div className='bg-white py-10'>
        <p className='text-3xl font-semibold text-[#4e4e4e] text-center'>Temoignages</p>
        <p className='text-lg text-center pt-4'>Nos clients Temoignent de la bonne qualité de nos services et nous remercient. </p>

        <div className='grid md:grid-cols-3 xs:grid-cols-2 s:grid-cols-1 gap-4 place-items-center pt-10'>

            {/* temoignages1 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] h-[250px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
                <p className='sm:w-[300px] s:w-[230px] text-sm'>“ Grâce à leur expertise et à leur soutien continu, j'ai pu réaliser mon rêve de poursuivre mes études au Canada. L'équipe de mon bon sejour m'a guidé à travers tout le processus, depuis la demande de permis d'études jusqu'à mon installation au Canada.
                    Merci a mon bon sejour”</p>
                <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
             {/* temoignages2 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] h-[250px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
                  <p className='sm:w-[300px] s:w-[230px] text-sm'>“ Grâce à leur expertise et à leur soutien continu, j'ai pu réaliser mon rêve de poursuivre mes études au Canada. L'équipe de mon bon sejour m'a guidé à travers tout le processus, depuis la demande de permis d'études jusqu'à mon installation au Canada.
                    Merci a mon bon sejour”</p>
                <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
              {/* temoignages3 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] h-[250px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
               <p className='sm:w-[300px] s:w-[230px] text-sm'>“ Grâce à leur expertise et à leur soutien continu, j'ai pu réaliser mon rêve de poursuivre mes études au Canada. L'équipe de mon bon sejour m'a guidé à travers tout le processus, depuis la demande de permis d'études jusqu'à mon installation au Canada.
                    Merci a mon bon sejour”</p>
               <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div className='py-10  bg-white'>
        <FAQ/>
      </div>
      
      <Footer/>
        </>
      )}
    </div>
    
  )
  
}
document.body.style.overflowX = "hidden"
document.body.style.overflowY = "auto"

export default HomeCa