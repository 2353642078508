// import {
//     Card,
//     CardBody,
//     CardHeader,
//     Typography,
//   } from "@material-tailwind/react";
//   import Chart from "react-apexcharts";

   
//   // If you're using Next.js please use the dynamic import for react-apexcharts and remove the import from the top for the react-apexcharts
//   // import dynamic from "next/dynamic";
//   // const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });
   
//   const chartConfig = {
//     type: "bar",
//     height: 240,
//     series: [
//       {
//         name: "Sales",
//         data: [50, 40, 300, 320, 500, 350, 200, 230, 500,100, 10,399],
//       },
//     ],
//     options: {
//       chart: {
//         toolbar: {
//           show: false,
//         },
//       },
//       title: {
//         show: "",
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       colors: ["#020617"],
//       plotOptions: {
//         bar: {
//           columnWidth: "40%",
//           borderRadius: 2,
//         },
//       },
//       xaxis: {
//         axisTicks: {
//           show: false,
//         },
//         axisBorder: {
//           show: false,
//         },
//         labels: {
//           style: {
//             colors: "#616161",
//             fontSize: "12px",
//             fontFamily: "inherit",
//             fontWeight: 400,
//           },
//         },
//         categories: [
//           "jan",
//           "fev",
//           "mar",
//           "Avr",
//           "Mai",
//           "Juin",
//           "Juil",
//           "Aou",
//           "Sep",
//           "Oct",
//           "Nov",
//           "Dec",
//         ],
//       },
//       yaxis: {
//         labels: {
//           style: {
//             colors: "#616161",
//             fontSize: "12px",
//             fontFamily: "inherit",
//             fontWeight: 400,
//           },
//         },
//       },
//       grid: {
//         show: true,
//         borderColor: "#dddddd",
//         strokeDashArray: 5,
//         xaxis: {
//           lines: {
//             show: true,
//           },
//         },
//         padding: {
//           top: 5,
//           right: 20,
//         },
//       },
//       fill: {
//         opacity: 0.8,
//       },
//       tooltip: {
//         theme: "dark",
//       },
//     },
//   };
   
//   export default function Example() {
//     return (
//       <Card className="w-[500px]">
//         <CardHeader
//           floated={false}
//           shadow={false}
//           color="transparent"
//           className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
//         >
//           <div className="flex flex-col space-y-4">
//             <Typography variant="h5" className=" text-[#fe7f2d]">
//               Statistiques de réservation totale
//             </Typography>
//             <Typography
//               variant="small"
//               color="gray"
//               className="max-w-sm font-normal flex items-center space-x-3"
//             >
//               <div className='bg-black w-4 h-4 '></div>
//               <p>Nombre totale de reservations dans l'année</p>
//             </Typography>
//           </div>
//         </CardHeader>
//         <CardBody className="px-2 pb-0">
//           <Chart {...chartConfig} />
//         </CardBody>
//       </Card>
//     );
//   }

import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const chartSetting = {
  
  
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};
const dataset = [
  {
    Residence: 59,
    Vehicule: 57,
    Restaurant: 86,
    Tourisme: 21,
    RendezVous: 120,
    month: 'Jan',
  },
  {
    Residence: 50,
    Vehicule: 52,
    Restaurant: 78,
    Tourisme: 28,
    RendezVous: 200,
    month: 'Fev',
  },
  {
    Residence: 47,
    Vehicule: 53,
    Restaurant: 106,
    Tourisme: 41,
    RendezVous: 40,
    month: 'Mar',
  },
  {
    Residence: 54,
    Vehicule: 56,
    Restaurant: 92,
    Tourisme: 73,
    RendezVous: 320,
    month: 'Apr',
  },
  {
    Residence: 57,
    Vehicule: 69,
    Restaurant: 92,
    Tourisme: 99,
    RendezVous: 340,
    month: 'May',
  },
  {
    Residence: 60,
    Vehicule: 63,
    Restaurant: 103,
    Tourisme: 144,
    RendezVous: 240,
    month: 'June',
  },
  {
    Residence: 59,
    Vehicule: 60,
    Restaurant: 105,
    Tourisme: 319,
    RendezVous: 400,
    month: 'July',
  },
  {
    Residence: 65,
    Vehicule: 60,
    Restaurant: 106,
    Tourisme: 249,
    RendezVous: 90,
    month: 'Aug',
  },
  {
    Residence: 51,
    Vehicule: 51,
    Restaurant: 95,
    Tourisme: 131,
    RendezVous: 300,
    month: 'Sept',
  },
  {
    Residence: 60,
    Vehicule: 65,
    Restaurant: 97,
    Tourisme: 55,
    RendezVous: 40,
    month: 'Oct',
  },
  {
    Residence: 67,
    Vehicule: 64,
    Restaurant: 76,
    Tourisme: 48,
    RendezVous: 40,
    month: 'Nov',
  },
  {
    Residence: 61,
    Vehicule: 70,
    Restaurant: 103,
    Tourisme: 25,
    RendezVous: 40,
    month: 'Dec',
  },
];

const valueFormatter = (value: number) => `${value} Réservations`;

export default function BarsDataset() {
  return (
    <div className='bg-white shadow-md p-4 rounded-lg xs:w-[700px] s:w-[340px] '>

      <div  className=" text-[#233D4D] text-xl font-semibold mb-4">
        Statistiques de réservation de l'année {new Date().getFullYear()}
      </div>

      <BarChart className='s:w-[300px] xs:w-[680px]'
        dataset={dataset}
        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
        series={[
          { dataKey: 'Residence', label: 'Residence', valueFormatter },
          { dataKey: 'Vehicule', label: 'Vehicule', valueFormatter },
          { dataKey: 'Restaurant', label: 'Restaurant', valueFormatter },
          { dataKey: 'Tourisme', label: 'Tourisme', valueFormatter },
          { dataKey: 'RendezVous', label: 'Immigration', valueFormatter },
        ]}
        {...chartSetting}
      />
    </div>
  );
}