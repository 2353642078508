import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function GestionCookies() {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
  return (
     <div>
        <button onClick={handleClickOpen('paper')}>Gestion des cookies</button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">

              <DialogTitle id="scroll-dialog-title" className='text-xl font-semibold text-orange-500' >Gestion des cookies</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                                                
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}>
                        {[...new Array(50)]
                        .map(
                        () => `Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                        Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                        )
                        .join('\n')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='transform active:scale-75 transition-transform' style={{backgroundColor:"#233D4D", color:"white"}} onClick={handleClose}>Retour</Button>
                    <Button className='transform active:scale-75 transition-transform' style={{border:"2px solid #233D4D", color:"#233D4D"}} onClick={handleClose}>Accepter</Button>
                </DialogActions>
            </Dialog>
    </div>
  )
}

export default GestionCookies