import React from 'react'
import Footer from '../../COMPONNENTS/Footer/Footer'
import { useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENTS/Header/Header';
import { useState } from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import { Link } from 'react-router-dom'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visa from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilier from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import CV from '../../../ASSETS/CaImmigration/images/Group 5.png'
import teach from '../../../ASSETS/CaImmigration/images/undraw_teacher_re_sico 1.png'

 

function Cv() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    let [open, setOpen] = useState(false);

  return (
    <div>
         {
        loading ? (
          // Afficher le spinner tant que loading est true
          <Loading />
        ) : (
          <>
        <Header/>
        {/* navabar */}
        <div  className=' bg-[#233D4D] s:relative s:bottom-2  sm:bottom-5 md:bottom-0 flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
        <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center justify-between'>

            <Link to='/'>
            <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
            </div>
            </Link>
            <div onClick={() => setOpen(!open)} className=' lg:hidden text-end s:text-3xl pr-4 s:text-white cursor-pointer'>
            <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
            </div>
        </div>
        {/* element_menu */}
        <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5 s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

            <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>

            {/* Accueil */}
            <Link to='/homeCaimmigration'>
                <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
                    <img className='w-6' src={home} alt='logo_home' />
                <div> Accueil</div>
                </div>
            </Link>

            {/* Visa */}

            <div style={{ fontSize: "16px" }} className='relative top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
                <div className='relative bottom-1'>
                <img className='w-4' src={visa} alt='icone_visa' />
                </div>
                <div className='navbar1 '>
                <ul>
                    <li> <Link to='/visa'> Visa & Permis
                    <ul className='z-10  '>
                        <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                    </ul>
                    </Link>
                    </li>
                </ul>
                </div>
            </div>


            {/* Résidence permanente */}
            <Link to='/ResidencePermante'>
                <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                <div>
                    <img className='w-6' src={Rpermanent} alt='' />
                </div>
                <div>Résidence permanente</div>
                </div>
            </Link>

            {/*Citoyenneté Canadienne */}
            <Link to='/CitoyenneteCa'>
                <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                <div>
                    <img className='w-6' src={CitoyenCA} alt='' />
                </div>
                <div> Citoyenneté Canadienne</div>
                </div>
            </Link>

            {/* Achat de biens immobilers */}
            <Link to='/BienImmobilier'>
                <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
                <div>
                    <img className='w-6' src={immobilier} alt='' />
                </div>
                <div>Achat de biens immobilers</div>
                </div>
            </Link>
            </div>
        </div>
        </div>

        <div className='flex md:flex-row s:flex-col-reverse md:space-x-14  justify-center s:items-center md:items-start my-20'>
            <div className='text_home flex flex-col space-y-4 items-center'>
                <p className='text-xl font-semibold text-[#4e4e4e]  xs:w-[500px] s:w-[260px] sm:w-[300px]'>Pourquoi as-tu besoin de faire un CV au format canadien ?</p>
                <p className='xs:w-[500px] s:w-[260px] sm:w-[300px]'>Il est recommandé de créer un CV au format canadien lorsque vous postulez pour des emplois au Canada pour 
                plusieurs raisons. Le format canadien de curriculum vitae (CV) diffère légèrement de celui utilisé dans d'autres 
                pays, et il est important de suivre ces conventions pour maximiser vos chances de succès. Voici quelques raisons 
                pour lesquelles vous pourriez avoir besoin d'un CV au format canadien :</p>
                <p className=' xs:w-[500px] s:w-[260px] sm:w-[300px]'><b>- Normes locales :</b> Les employeurs canadiens sont familiers avec le format canadien 
                de CV, et l'utilisation de ce format montre que vous comprenez les normes locales en matière de recherche d'emploi.
                et vous passerez le filtre des logiciels (ATS) utilisés par de nombreux employeurs canadiens</p>
            </div>
            <div>
                <img src={CV} alt='cv' className='navbar_animation xs:w-[400px]  md:w-[600px] s:w-[260px] sm:w-[300px]'/>
            </div>
        </div> 

        <div className="bg-[#F2F2F2] my-20 py-6 flex xs:flex-row s:flex-col xs:space-x-14 justify-center s:items-center xs:items-start s:space-y-4 xs:space-y-0 ">
            <div>
                <img src={teach} className='w-[300px]' alt="teach"/>
            </div>
            <div >
                <p className='md:w-[500px] xs:w-[400px] s:w-[260px] sm:w-[300px] text-3xl font-semibold'>Comment faire un bon Cv et une lettre de motivation en format Canadien</p>
                <p className='md:w-[500px] xs:w-[400px] s:w-[260px] sm:w-[300px] relative top-6'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <Link to="/PrendreRendezVous"><button className=' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150 text-lg w-40 bg-[#FE7F2D] text-white h-12 mt-16'>Contactez-nous</button></Link>
            </div>
        </div>
       <Footer/>
          </>
        )}
    </div>
  )
}

export default Cv