import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import { FaRegBuilding } from "react-icons/fa";
import SidebarTop from '../SidebarTop';
import { CiMapPin } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { IoLogoTiktok } from "react-icons/io5";
import { TbWorldWww } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { CiText } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify"
import Utilisateur from '../../../API/profil/index'
import { CustomerProps} from "../../../props";
// import { CountryProps } from '../../../props';
import axios from 'axios';




function EditProfil() {

  const [data, setData] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
  });

  interface Item {
    id: number;
    code: string;
    fr_Name: string;	
   
    // Autres propriétés si nécessaire
  }
  interface ItemCities {
    id: number;	
    code_city: number;	
    Name: string;	
    fr_Name: string;	
    code_province: string;	
    Commentaire: string;
  }

  interface ItemProvince {
    id: number;	
    code_province: string;		
    Name: string;	
    fr_Name: string;	
    code_country: string;	
    // Autres propriétés si nécessaire
  }
  const [formData, setFormData] = useState({});

  const [nom, setNom] = useState("")
  const [adresse, setAdresse] = useState("")
  const [tel, setTel] = useState("")
  const [mail, setMail] = useState("")
  const [description, setDescription] = useState("")

  const [selectedCountry, setSelectedCountry] = useState("");
  //const [countrie, setCountry] = useState("");
  const [countries, setCountries] = useState<Item[]>([]);

  const [selectedProvince, setSelectedProvince] = useState("");
  //const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState<ItemProvince[]>([]);

  const [city, setCity] = useState("");
  const [cities, setCities] = useState<ItemCities[]>([]);

  const [customer, setCustomer] = useState<CustomerProps>();

  const [loading, setLoading] = useState(true);

  const getInformation = () => {
    Utilisateur.getUserInfo(data.azId)
    .then((res:any) => {
        if (res.data.status === 201) {
            setCustomer(res.data.data)
            console.log(res.data.data)
        }
    })
    .catch((err:any) => {
        console.log(err);
    })
    .finally(() => {
        setLoading(false);
    })
  }

  const navigate = useNavigate();

  useEffect(() => {
    //country
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/country/list`)
    .then(response => {
      setCountries(response.data.data)
      //console.log(response)
    })
    //.then(response => console.log(response.data.data))
    .catch(err => console.log(err))

    if (!data) {
      navigate("/Connexion");
    }

    getInformation();
    
    //const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      //setLoading(false);
    //}, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    //return () => clearTimeout(timer);
  }, []);

  const handleCountryChange=(event : any) => {
        const { name, value } = event.target;
        setSelectedCountry(event.target.value);
        console.log(value)
        //setFormData({ ...formData, [name]: value });
        //province
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/province/list/${value}`)
        .then(response => {
        setProvinces(response.data.data)
        //console.log(response.data.data)
        })
  };

  const handleProvinceChange = (event: any) => {
    const { name, value } = event.target;
    setSelectedProvince(event.target.value);
    console.log(value)
    //city
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/city/list/${value}`)
    .then(response => {
        setCities(response.data.data)
        //console.log(value)
    })
    .catch(err => console.log(err))

};


  function submit(e: FormEvent) {
    e.preventDefault()
    var templateParams = {
      id : customer?.Customers_Numbers,
      nom: nom !== "" ? nom : customer?.Names,
      adresse: adresse !== "" ? adresse : customer?.Adresses,
      tel: tel !== "" ? tel : customer?.Phones,
      mail: mail !== "" ? mail : customer?.['E-mails'],
      country: selectedCountry !=="" ? selectedCountry : customer?.Country,//nameselect_ou_input + nameuseState + nameuseState
      province: selectedProvince !== "" ? selectedProvince : customer?.Province,
      city: city !== "" ? city : customer?.City,
      description: description !== "" ? description : customer?.Description,
    };

    

    //console.log(templateParams)

    setLoading(true)
    Utilisateur.submitForm(templateParams)
        .then((res: any) => {
          console.log(res)
          if(res.data.status === 201){
            toast.success(res.data.message);
            navigate("/Dashboard/Profil");
            console.log(res);
          }else{
            toast.error(res.data.message);
            console.log(res);
          }
        })
        .catch((err: any) => {
            toast.error('Erreur merci de reessayer !');
            console.log(err);
        })
        .finally(() => {
          setLoading(false);
        })

  }


  return (
    <div className='flex flex-row bg-[#eeee]'>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>

          <div>
            <ToastContainer />
          </div>

          <div className='w-screen  barre_scroll h-screen'>
            {/* sidebar top  */}
            <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs >
                <Link to="" className="opacity-60">
                Mon compte
                </Link>
                <Link to="" className="opacity-60">
                 Profil
                </Link>
                <Link to="" className="">
                 Modifier
                </Link>
              </Breadcrumbs>
            </div>
            {/* container formulaire */}
            <div className='flex flex-row space-x-10 justify-center xs:p-10 sm:p-6 s:p-2 '>
            
              <div className='bg-white p-8 xs:w-[700px] s:w-[260px] sm:w-[340px] rounded-md shadow border-t-8 border-[#fe7f2d]'>
                <div className='flex flex-row space-x-8  text-lg'>
                    <Link to="/Dashboard/Profil">
                    <p className='hover:border-b-2 border-[#FCCA46] cursor-pointer '>Profile</p>
                    </Link>
                  <p className='border-b-2 border-[#FCCA46] cursor-pointer hover:border-none'>Modifier</p>
                </div>
                <hr className='my-8'/>
                <form action="" onSubmit={submit} className=' flex flex-col space-y-5'>
                  {/* Nom de l'entreprise */}
                  <div>
                    <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                        <div className='flex flex-row items-center space-x-2 w-[200px]'>
                        <FaRegBuilding className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                        <p className=' font-medium '>Nom de la structure :</p>
                        </div>
                        <input type='text' defaultValue={customer?.Names} onChange={(e) => setNom(e.currentTarget.value)} className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                    </div>
                    <small className=' font-thin italic'> Précisez le nom de votre structure</small>
                  </div>
                  {/* Adresse de la structure */}
                  <div>
                    <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                        <div className='flex flex-row items-center space-x-2 w-[200px]'>
                            <CiMapPin className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                            <p className=' font-medium '>Adresse de la structure :</p>
                        </div>
                        <input defaultValue={customer?.Adresses} onChange={(e) => setAdresse(e.currentTarget.value)} type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                    </div>
                    <small className=' font-thin italic'>Précisez l'adresse de votre structure</small>
                  </div>
                  {/* Telephone*/}
                  <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <FaPhoneAlt className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Telephone :</p>
                            </div>
                        <input defaultValue={customer?.Phones} onChange={(e) => setTel(e.currentTarget.value)} type='tel'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className='italic'>Précisez le numéro de téléphone la structure</small>
                    </div>
                    {/* mail*/}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <MdOutlineEmail className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Adresse mail :</p>
                            </div>
                        <input type='email' readOnly defaultValue={customer?.['E-mails']} onChange={(e) => setMail(e.currentTarget.value)} className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className='   italic'>Précisez l'e-mail de la la structure</small>
                    </div>
                    {/* country*/}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <CiMapPin className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Pays :</p>
                            </div>
                            <select  required onChange={handleCountryChange} name='country' value={selectedCountry} className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md pl-3'>
                                        {/* <option value={customer?.code} selected> {customer?.Country} </option> */}
                                                            {countries.map((option, index) => (
                                            <option key={index} value={option.code}>{option.fr_Name}</option>
                                            ))}                                             
                            </select>
                        {/* <input type='text' readOnly defaultValue={customer?.Country} onChange={(e) => setCountry(e.currentTarget.value)} className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  /> */}
                      </div>
                        <small className='   italic'>Précisez le pays de résidence</small>
                    </div>

                    {/* Province*/}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <CiMapPin className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Province :</p>
                            </div>
                            <select  required name='province' onChange={handleProvinceChange} value={selectedProvince} className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md pl-3'>
                                        {/* <option value="" >Sélectionner une province</option> */}
                                                            {provinces.map((option, index) => (
                                            <option key={index} value={option.code_province}>{option.fr_Name}</option>
                                            ))}                                             
                            </select>
                        {/* <input type='text' readOnly defaultValue={customer?.Country} onChange={(e) => setCountry(e.currentTarget.value)} className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  /> */}
                      </div>
                        <small className='   italic'>Précisez la province</small>
                    </div>

                    {/* City*/}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <CiMapPin className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Ville :</p>
                            </div>
                            <select  required onChange={(e) => setCity(e.currentTarget.value)} name='city'  className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md pl-3'>
                                        {/* <option value="" >Sélectionner une ville</option> */}
                          
                                                            {cities.map((option, index) => (
                                            <option key={index} value={option.code_city}>{option.fr_Name}</option>
                                            ))}                                             
                            </select>
                        {/* <input type='text' readOnly defaultValue={customer?.Country} onChange={(e) => setCountry(e.currentTarget.value)} className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  /> */}
                      </div>
                        <small className='   italic'>Précisez la ville</small>
                    </div>
                  {/* Facebook */}
                  {/* <div>
                    <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                        <div className='flex flex-row items-center space-x-2 w-[200px]'>
                        <FaFacebookF className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                        <p className=' font-medium '>Facebook :</p>
                        </div>
                        <input type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                    </div>
                    <small className=' font-thin italic'>Spécifiez le nom de votre page Facebook</small>
                  </div> */}
                  {/* Instagram */}
                    {/* <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                            <SlSocialInstagram className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                            <p className=' font-medium '>Instagram :</p>
                            </div>
                            <input type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className=' italic'> Spécifiez le nom de votre page Instagram</small>
                    </div> */}
                  {/* Tiktok*/}
                    {/* <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                            <IoLogoTiktok className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                            <p className=' font-medium '>Titok :</p>
                            </div>
                            <input type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className='  italic'>Spécifiez le nom de votre page Tiktok</small>
                    </div> */}
                   {/* Site web*/}
                    {/* <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                            <TbWorldWww className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                            <p className=' font-medium '>Site web :</p>
                            </div>
                            <input type="url" className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className=' italic'> Spécifiez l'URL de votre site web</small>
                    </div> */}
                    
                     {/* Description*/}
                    <div>
                        <div className=' flex xs:flex-row s:flex-col items-start xs:space-x-2 s:space-y-3 xs:space-y-0'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <CiText className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Description :</p>
                            </div>
                            <textarea defaultValue={customer?.Description} onChange={(e) => setDescription(e.currentTarget.value)} className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-[200px] rounded-md p-4'></textarea>
                        </div>
                        <small className='italic'>Faite la description de votre entreprise</small>
                    </div>

                    <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Mettre a jour</button>

                </form>
              </div>
            </div>
            
          </div>
        </>
      )}
    </div>
  )
}

export default EditProfil