import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENT/Header/Header';
import backimage from '../../../ASSETS/AfImmigration/imgDestination.png'
import {Link} from 'react-router-dom'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import BoutonLogin from '../../../TOURISME/COMPONENTS/BoutonLogin/BtnLogin'
import Footer from '../../COMPONNENT/Footer/Footer';
import benin from '../../../ASSETS/AfImmigration/000_32JN7YL.png'
import drapeauBenin from '../../../ASSETS/AfImmigration/drapeau-du-benin.png'
import CI from '../../../ASSETS/AfImmigration/participation-sitho.png'
import drapeauCI from '../../../ASSETS/AfImmigration/drapeau-cote-ivoire.png'
import zambie from '../../../ASSETS/AfImmigration/b39df0e9-299f-4a28-ae24-0323c2adbc35fcfff1ad1cd191d150_IMG_0239_Fotor.png'
import drapeauzambie from '../../../ASSETS/AfImmigration/drapeau-zambie.png'
import nigeria from '../../../ASSETS/AfImmigration/VIDEO-Lagos-State-unveils-Abiola-statue-to-mark-June-12-1.png'
import drapeaunigeria from '../../../ASSETS/AfImmigration/drapeau-du-nigeria.png'
import drapeauCapVert from '../../../ASSETS/AfImmigration/drapeau-national-du-cap-vert-arriere-plan-drapeau-du-cap-vert_659987-41075.png'
import CapVert from '../../../ASSETS/AfImmigration/cap vert.png'
import Cameroun from '../../../ASSETS/AfImmigration/cameroun.png'
import drapeauCameroun from '../../../ASSETS/AfImmigration/drapeau-du-cameroun.png'
import Senegal from '../../../ASSETS/AfImmigration/senegal.png'
import drapeauSenegal from '../../../ASSETS/AfImmigration/drapeau-du-senegal.png'
import SierraLeone from '../../../ASSETS/AfImmigration/sierra leone.png'
import drapeauSierraLeone from '../../../ASSETS/AfImmigration/drapeau-sierra-leone.png'
import Togo from '../../../ASSETS/AfImmigration/togo.png'
import drapeauTogo from '../../../ASSETS/AfImmigration/drapeau-du-togo.png'
import { IoPin } from "react-icons/io5";


function Destination() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div className='bg-[#F7F7F7]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading/>
          ) : (
        <>
        <Header/>
        <div style={{ backgroundImage:`url(${backimage})` }} className='h-screen w-screen bg-cover bg-center bg-no-repeat'>
            <div className='flex s:flex-col sm:flex-row sm:justify-between'>
                <Link to='/accueil_afrique_immigration'>
                    <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                        <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                    </div>
                </Link>
                <BoutonLogin/>
            </div>
          <div className='relative top-[200px] xs:left-20 s:left-6'>
            <div className=' xs:text-6xl uppercase  s:text-xl sm:text-4xl font-semibold text-white xs:w-[600px]'>
             Destination
            </div>
            <div className='h-2 w-20 bg-[#FCCA46] mt-4'></div>
          </div>

        </div>

        <div className='py-14 flex flex-col space-y-4 items-center'>
            <div className='flex flex-row items-center space-x-2 '>
                <IoPin className='text-2xl text-[#fe7f2d]' />
                <div className='text-2xl font-semibold text-[#233D4D]'>Votre destination</div>
            </div>
            <div className='text-lg xs:w-[700px] text-center px-2'>
                Vivamus pretium turpis eget elit condimentum tincidunt. 
                Cras aliquam tincidunt est, in varius lorem laoreet in. 
                Sed quis ex rutrum, vehicula enim vitae, auctor urna. 
            </div>
            <div className='pt-10 place-content-center place-items-center grid md:grid-cols-4 gap-4 s:grid-cols-1 xs:grid-cols-2'>
                {/* BENIN */}
                <div className='example flex flex-col items-center'>
                    <img src={benin} alt='benin' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">BENIN</div>
                    </div>
                    <img src={drapeauBenin} alt='drapeaubenin' className='w-[60px] relative bottom-7'/>
                </div>
                {/* COTE D IVOIRE */}
                <Link to='/accueil_afrique_immigration/Destination/VoirPlus'>
                    <div className='example flex flex-col items-center'>
                        <img src={CI} alt='CI' className='sm:w-[290px] s:w-[260px]'/>
                        <div className="fadedbox">
                            <div className="title text">Côte d'ivoire</div>
                        </div>
                        <img src={drapeauCI} alt='drapeauCI' className='w-[60px] relative bottom-7'/>
                    </div>
                </Link>
                {/* ZAMBIE */}
                <div className='example flex flex-col items-center'>
                    <img src={zambie} alt='zambie' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">ZAMBIE</div>
                    </div>
                    <img src={drapeauzambie} alt='drapeauzambie' className='w-[60px] relative bottom-7'/>
                </div>
                {/* NIGERIA */}
                <div className='example flex flex-col items-center'>
                    <img src={nigeria} alt='benin' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">NIGERIA</div>
                    </div>
                    <img src={drapeaunigeria} alt='drapeaubenin' className='w-[60px] relative bottom-7'/>
                </div>
                {/* CAP VERT */}
                <div className='example flex flex-col items-center'>
                    <img src={CapVert} alt='CapVert' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">CAP VERT</div>
                    </div>
                    <img src={drapeauCapVert} alt='drapeauCapVert' className='w-[60px] relative bottom-7'/>
                </div>
                {/* CAMEROUN */}
                <div className='example flex flex-col items-center'>
                    <img src={Cameroun} alt='Cameroun' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">CAMEROUN</div>
                    </div>
                    <img src={drapeauCameroun} alt='drapeauCameroun' className='w-[60px] relative bottom-7'/>
                </div>
                {/* Togo */}
                <div className='example flex flex-col items-center'>
                    <img src={Togo} alt='Togo' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">TOGO</div>
                    </div>
                    <img src={drapeauTogo} alt='drapeauTogo' className='w-[60px] relative bottom-7'/>
                </div>
                {/* Sierra leone */}
                <div className='example flex flex-col items-center'>
                    <img src={SierraLeone} alt='SierraLeone' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">SIERRA LEONNE</div>
                    </div>
                    <img src={drapeauSierraLeone} alt='drapeauSierraLeone' className='w-[60px] relative bottom-7'/>
                </div>
                {/* Senegal*/}
                <div className='example flex flex-col items-center'>
                    <img src={Senegal} alt='Senegal' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">SENEGAL</div>
                    </div>
                    <img src={drapeauSenegal} alt='drapeauSenegal' className='w-[60px] relative bottom-7'/>
                </div>
            </div>
        </div>

       
        <Footer/>
        </>
          )}
    </div>
  )
}

export default Destination