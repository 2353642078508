import React from 'react'
import axios from 'axios';
import { Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { IoIosEyeOff } from "react-icons/io";
import { IoIosLaptop } from 'react-icons/io';
import {usePagination, useMemo} from '@mui/material/usePagination/usePagination';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,

} from "@material-tailwind/react";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { IoAddSharp } from "react-icons/io5";
import {
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { FiEdit } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import {
  Card,
  CardHeader,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { IoIosEye } from "react-icons/io";
import SidebarTop from '../../SidebarTop';
import { ToastContainer, toast } from "react-toastify"

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { GiMoneyStack } from 'react-icons/gi';
import { CiCalendarDate } from 'react-icons/ci';
import Hebergement from '../../../../API/tourisme/hebergement';

const TABLE_HEAD = ["Nom de la salle", "chambres", "Type de lit", "Périmètre","État", "Statut", "Action",];

const TABLE_ROWS = [
  {
    img: "https://images.pexels.com/photos/271618/pexels-photo-271618.jpeg?auto=compress&cs=tinysrgb&w=600",
    name: "Villa, piscine privée",
    tarif: "70.000",
    chambre: "2",
    TypeLit: "Lit double",
    perimetre: "100",
    etat: "Activé",
    statut: "Disponible",
  },
  {
    img: "https://images.pexels.com/photos/1743231/pexels-photo-1743231.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Residence studio",
    tarif: "30.000",
    TypeLit: "Lit doube",
    perimetre: "30",
    etat: "Desactivé",
    statut: "Réservé",
  },
  {
    img: "https://images.pexels.com/photos/338504/pexels-photo-338504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Duplexe",
    tarif: "55.000",
    TypeLit: "Lit simple",
    perimetre: "40",
    etat: "Activé",
    statut: "Réservé",
  },
  {
    img: "https://images.pexels.com/photos/635041/pexels-photo-635041.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "studio",
    tarif: "20.000",
    TypeLit: "Lit simple",
    perimetre: "30",
    etat: "Desactivé",
    statut: "Disponible",
  },
  {
    img: "https://images.pexels.com/photos/2507007/pexels-photo-2507007.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Appartement meublé",
    tarif: "50.000",
    TypeLit: "Lit double",
    perimetre: "90",
    etat: "Activé",
    statut: "Disponible",
  },
];

function VoirHebergement() {

  localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Hebergement/Voir_des_hebergements'));

  const [records, setRecords] = useState([]);

  const [data, setData] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
  });
const dateexpired = new Date();
const dateexpired1=dateexpired.toISOString().split('T')[0];
// console.log(dateexpired1);
const [open, setOpen] = React.useState(false);
var [listUpDateHeber, setlistUpDateHebeL] = React.useState([]);
//const [identifiantHeber, setIdentifiantHeber] = React.useState();
    const handleClickOpen = (id) => {
      //setIdentifiantHeber(id);
        setOpen(true);
        async function listOfReafficherHerb(){
          try{
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/hebergement/list/update/pub`, {id: id})
            //console.log(res)
            setlistUpDateHebeL(res.data.data)
          }catch (err) {
            console.log(err);
          }
        }
        listOfReafficherHerb()
    };
//console.log(identifiantHeber)
    const handleClose = () => {
        setOpen(false);
    };

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        //setMontantTotal(montantTotal);
    };

    //console.log("identifiant", formData.get('identifiant'))
  
  useEffect(() => {
    if (!data) {
      navigate("/Connexion");
    }

    async function listHerb(){
      try{
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/list/${data.userId}`)
          setRecords(response.data[0].data)
      }catch (err) {
        console.log(err);
      }
    }
    listHerb();
        // .then(response => {})
        // //.then(response => console.log(response.data.data))
        // .catch(err => console.log(err))

     //console.log(records);

    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);

  ///////////////////// début pagination ////////////////////
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3); //le nombre d'éléments à afficher par page
    // Calcul du nombre total de pages
    const totalPages = Math.ceil(records.length / itemsPerPage);

      // Récupération des enregistrements pour la page actuelle
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentItems = records.slice(startIndex, endIndex);

      // Fonctions pour naviguer entre les pages
  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  ///////////////////// fin pagination ////////////////////
  
  const changeStatut = (id) => {
    async function ChangeStatutHerb(){
      try{
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/hebergement/change/statut`, {id: id})
        window.location.reload();
        if (res.data.status === 201) {
          toast.success(res.data.message);
          //console.log(res);
      } else {
        toast.error(res.data.message);
        console.log(res);
    }
      }catch (err) {
        console.log(err);
      }
    }
    ChangeStatutHerb()
    
    //  .then(res => {
    //   window.location.reload();
    //   if (res.data.status === 201) {
    //     toast.success(res.data.message);
    //     //console.log(res);
    //   } else {
    //       toast.error(res.data.message);
    //       console.log(res);
    //   }
    // })
    //  .catch(err =>
    //   {
    //     toast.error("Erreur Merci de ressayer");
    //    console.log(err)
    //   })

  }
  const handleSubmit = (event) => {
    event.preventDefault();
    let fd = new FormData();
    fd.append("identifiant", formData.identifiant ?? listUpDateHeber.id_announce);
    fd.append("prix", formData.prix ?? listUpDateHeber.price_announce);
    fd.append("date_debut", formData.date_debut ?? listUpDateHeber.date_debut);
    fd.append("date_fin", formData.date_fin ?? listUpDateHeber.date_fin);

    if ((formData.date_debut ?? listUpDateHeber.date_debut) && (formData.prix ?? listUpDateHeber.price_announce) && (formData.date_fin ?? listUpDateHeber.date_fin)) {
        fd.append("montant", parseFloat(formData.prix ?? listUpDateHeber.price_announce) * 
        Math.ceil((new Date(formData.date_fin ?? listUpDateHeber.date_fin) - 
        new Date(formData.date_debut ?? listUpDateHeber.date_debut)) / (1000 * 60 * 60 * 24)));
    }
    // console.log("identifiant", formData.identifiant ?? listUpDateHeber.id_announce)
    // console.log("prix", formData.prix ?? listUpDateHeber.price_announce)
    // console.log("date_debut", formData.date_debut ?? listUpDateHeber.date_debut)
    // console.log("date_fin", formData.date_fin ?? listUpDateHeber.date_fin)
    setLoading(true);
    Hebergement.submitFormUp(fd)
        .then((res) => {
            console.log(res);
            if (res.data.status === 201) {
                handleClose()
                toast.success(res.data.message);
                //console.log(res);
            } else {
                toast.error(res.data.message);
                //console.log(res);
            }
        })
        .catch((err) => {
            toast.error('Erreur merci de reessayer !');
            //console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
  };

  return (
    <div className='flex flex-row bg-[#eeee]'>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
          <div className='w-screen h-screen barre_scroll'>
            {/* sidebar top  */}
            <SidebarTop/>
            <div>
                <ToastContainer />
            </div>
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs>
                <Link to="" className="opacity-60 md:text-base s:text-xs">
                  Mes publications
                </Link>
                <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements" className="opacity-60 md:text-base s:text-xs">
                  Hebergements
                </Link>
                <Link to="" className='md:text-base s:text-xs'>
                  Voir
                </Link>
              </Breadcrumbs>
            </div>

            {/* boutton ajouter une residence */}
            <div className='p-6  w-[230px]' >
              <Link to="/Dashboard/Publications/Hebergement/Ajouter_des_hebergements">
                <button className='flex flex-row items-center justify-center h-10  space-x-2 bg-[#FCCA46] text-white md:text-base s:text-sm md:w-[230px] s:w-[190px] transform active:scale-75 transition-transform'>
                  <IoAddSharp />
                  <p>Ajouter un hebergement</p>
                </button>
              </Link>
            </div>
            {/* container formulaire */}
            <div className='flex justify-center md:p-10 s:p-4'>

              <Card className="h-full w-full border-t-8 border-[#fe7f2d]">
                <CardHeader floated={false} shadow={false} className="rounded-none">
                  <Typography className='font-semibold md:text-xl s:text-lg' color="blue-gray">
                    Liste des chambres publiées
                  </Typography>
                </CardHeader>
                <CardBody className="px-0 overflow-scroll">
                  <table className="w-full mt-4 overflow-scroll text-left table-auto min-w-max">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head, index) => (
                          <th
                            key={head}
                            className="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                            >
                              {head}{" "}
                              {index !== TABLE_HEAD.length - 1 && (
                                <ChevronUpDownIcon strokeWidth={2} className="w-4 h-4" />
                              )}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                      {records.length === 0 && (
                      <tbody>
                    <Typography variant="small" color="blue-gray" className="flex items-center font-normal">
                    Pas de donnees
                  </Typography>
                  </tbody> 
                  )}
                  {records.length > 0 && (
                    <tbody>
                      {currentItems.map((list, index )=> {
                          const isLast = index === records.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";

                          return(
                            <tr key={index} className='hover:bg-slate-100'>

                              <td className={classes}>
                                <div className="flex items-center space-x-2 ">
                                  <Avatar src={`data:image/png;base64,${list[0]}`} alt={list.title_announce} className='w-[180px] h-[100px]' />
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {list.title_announce}
                                    </Typography>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal opacity-70"
                                    >
                                      {list.price_announce} XOF / Nuitée
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {list.realstate_number_pieces} chambre (s)
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {list.realstate_bedtype}
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes} >
                                <div className="w-max" >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"

                                  >
                                    {list.realstate_size} m
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes} >
                                <div className="w-max" >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"

                                  >
                                    {list.status} 
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {list.status === "enabled" && (
                                    "Actif"
                                    )}
                                    {list.status === "disabled" && (
                                    "Inactif"
                                    )}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Tooltip content="Action sur la publication">
                                  <IconButton variant="text">
                                    <Menu>
                                      <MenuHandler>
                                        <IconButton>
                                          <RxDotsVertical />
                                        </IconButton>
                                      </MenuHandler>
                                      <MenuList className="flex flex-col gap-2">

                                        {/* modifier */}
                                         <Link 
                                         to="/Dashboard/Publications/Hebergement/Voir_des_hebergements/Modifier" 
                                         state={ 
                                           list.id_announce 
                                        }
                                          >
                                          <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <FiEdit className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                              <Typography variant="small" color="gray" className="font-semibold">
                                                Modifier
                                              </Typography>
                                            </div>
                                          </MenuItem>
                                        </Link>
                                        {/* Voir */}
                                         <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements/Voir_Plus"
                                         state={ 
                                          list.id_announce 
                                       }
                                         >
                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                              <IoIosEye className='text-lg' />
                                              <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                  Voir
                                                </Typography>
                                              </div>
                                            </MenuItem>
                                        </Link> 
                                        {/* Desactiver / Activer */}
                                        
                                         
                                         {list.status === "enabled" && (
                                            <MenuItem onClick={() => changeStatut(list.id_announce)} className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                              <IoIosEyeOff className='text-lg' />
                                              <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                  Désactiver
                                                </Typography>
                                              </div>
                                            </MenuItem>
                                          )}
                                          {list.status === "disabled" && (
                                            <MenuItem onClick={() => changeStatut(list.id_announce)} className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                <IoIosEye className='text-lg' />
                                                <div className="flex flex-col gap-1">
                                                  <Typography variant="small" color="gray" className="font-semibold">
                                                    Activer
                                                  </Typography>
                                                </div>
                                            </MenuItem>
                                          )}
                                          {dateexpired1 > list.date_fin ? (
                                            <MenuItem onClick={() => handleClickOpen(list.id_announce)} className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                              <IoIosLaptop className='text-lg' />
                                              <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                  Reafficher
                                                </Typography>
                                              </div>
                                            </MenuItem>
                                          ) : "" }
                                      </MenuList>
                                    </Menu>
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                            );
                        },
                      )}
                      </tbody> 
                    )}
                  </table>
                </CardBody>
                <CardFooter className="flex items-center justify-between p-4 border-t border-blue-gray-50">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                  Page {currentPage} of {totalPages}
                  </Typography>
                  <div className="flex gap-2">
                    <Button variant="outlined" onClick={prevPage} size="sm" disabled={currentPage === 1}>
                      Previous
                    </Button>
                    <Button variant="outlined" onClick={nextPage} size="sm" disabled={currentPage === totalPages}>
                      Next
                    </Button>
                  </div>
                </CardFooter>
              </Card>

            </div>
          </div>
        </>
      )}
              <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md" // Try 'xs', 'sm', 'md', 'lg', 'xl'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">

                <DialogTitle id="scroll-dialog-title" className='text-xl font-semibold text-orange-500' >Modifier</DialogTitle>
                <DialogContent>
                                                
                  <form onSubmit={handleSubmit} className='flex flex-col items-center w-full max-w-2xl mx-auto space-y-6'>
                      <div className='flex flex-col w-full space-y-4 bg-white border-b-8 rounded-md shadow s:p-6 xs:p-10'>
                          {/* Tarif */}
                          <div className='flex flex-col space-y-3'>
                              <div className='flex flex-row items-center space-x-2'>
                                  <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                                  <label>Tarif</label>
                              </div>
                              <input id="prix" defaultValue={listUpDateHeber?.price_announce} onChange={handleInputChange} type='number' name='prix' required min={1} className='w-full h-10 rounded' />
                              <input hidden id="identifiant" defaultValue={listUpDateHeber?.id_announce} onChange={handleInputChange} type='number' name='identifiant' className='w-full h-10 rounded' />
                              <small>Le tarif doit être par nuitée</small>
                          </div>
                      </div>

                      <div className='flex flex-col w-full space-y-4 bg-white border-b-8 rounded-md shadow s:p-6 xs:p-10'>
                          {/* date debut */}
                          <div className='flex flex-col space-y-3'>
                              <div className='flex flex-row items-center space-x-2'>
                                  <CiCalendarDate className='text-[#fe7f2d]' />
                                  <label>Date de debut affichage</label>
                              </div>
                              <input id="date_debut" defaultValue={listUpDateHeber?.date_debut} name='date_debut' onChange={handleInputChange} type='date' className='w-full h-10 rounded' required />
                          </div>

                          {/* date fin */}
                          <div className='flex flex-col space-y-3'>
                              <div className='flex flex-row items-center space-x-2'>
                                  <CiCalendarDate className='text-[#fe7f2d]' />
                                  <label>Date de fin affichage</label>
                              </div>
                              <input id="date_fin" defaultValue={listUpDateHeber?.date_fin} name='date_fin' onChange={handleInputChange} type='date' className='w-full h-10 rounded' required />
                          </div>

                          {(formData.date_debut ?? listUpDateHeber.date_debut) && (formData.prix ?? listUpDateHeber.price_announce) && (formData.date_fin ?? listUpDateHeber.date_fin) ? (
                              <div className='flex flex-col space-y-3'>
                                  <div className='flex flex-row items-center space-x-2'>
                                      <GiMoneyStack className='text-xl text-[#1f2937]' />
                                      <label className='text-[#1f2937] font-bold'>
                                          Montant total: {(parseFloat(formData.prix ?? listUpDateHeber.price_announce) * Math.ceil((new Date(formData.date_fin ?? listUpDateHeber.date_fin) - new Date(formData.date_debut ?? listUpDateHeber.date_debut)) / (1000 * 60 * 60 * 24)))} F CFA
                                      </label>
                                      <input hidden id="montant" onChange={handleInputChange} value={(parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut)) / (1000 * 60 * 60 * 24)))} name='montant' type='number' />
                                  </div>
                              </div>
                          ) : ""}
                      </div>

                      {(formData.date_debut ?? listUpDateHeber.date_debut) && (formData.prix ?? listUpDateHeber.price_announce) && (formData.date_fin ?? listUpDateHeber.date_fin) ? (
                          <button className='bg-[#FCCA46] w-[250px] h-10 rounded text-white'>
                              Procéder au paiement
                          </button>
                      ) : (
                          <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>
                              Reafficher
                          </button>
                      )}

                              {/* {showPopup && (
                              <PaymentPopup
                                  open={showPopup}
                                  montant={parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut)) / (1000 * 60 * 60 * 24))}
                                  onSuccess={handlePopupSuccess}
                                  userNom={data.userLastname}
                                  userPrenom={data.userName}
                                  email={data.userMail}
                                  formData={formData}
                                  urlpay={urlbackpay}
                                  onCancel={handlePopupCancel}
                              />
                              )} */}
                  </form>
                </DialogContent>
                <DialogActions>
                    <Button className='transition-transform transform active:scale-75' style={{backgroundColor:"#233D4D", color:"white"}} onClick={handleClose}>Retour</Button>
                    {/* <Button className='transition-transform transform active:scale-75' style={{border:"2px solid #233D4D", color:"#233D4D"}} onClick={handleClose}>Accepter</Button> */}
                </DialogActions>
              </Dialog>
    </div>


  )
}

export default VoirHebergement