import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

export default function BasicDateCalendar() {
  return (
    <div className='bg-white shadow-md p-4 rounded-lg xs:w-[400px] s:w-[340px]'>
        <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DateCalendar className='s:w-[300px]'   />
        </LocalizationProvider>
    </div>
  );
}