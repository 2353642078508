import React from 'react'
import { useState } from 'react'
import { FormEvent } from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../COMPONNENTS/Header/Header';
import Footer from '../../COMPONNENTS/Footer/Footer';
import { useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import login from '../../../ASSETS/CaImmigration/images/img_login.png'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import left from "../../../ASSETS/CaImmigration/icones/back.png"
import { ToastContainer, toast } from "react-toastify"
import ResetPassword from "../../../API/password/index"

function MotPasseOublie() {


    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
      });

      const [mail, setMail] = useState("")

    useEffect(() => {
        //console.log(user);
        if (data) {
            navigate("/");
           }
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    // Dialog
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function submit(e: FormEvent) {
      e.preventDefault()

      var templateParams = {
          mail: mail
      };

      setLoading(true)
      ResetPassword.submitForm(templateParams)
          .then((res: any) => {
            if(res.data.status === 201){
              toast.success(res.data.message);
              navigate("/Connexion");
            }else{
              toast.error(res.data.message);
            }
          })
          .catch((err: any) => {
              toast.error("Erreur merci de ressayer");
              console.log(err);
          })
          .finally(() => {
            setLoading(false);
          })
       
  }

  return (
     <div>
          {
        loading ? (
          // Afficher le spinner tant que loading est true
          <Loading />
        ) : (
          <>
          <Header/>

          <div>
        <ToastContainer />
      </div>

           <Link to='/homeCaimmigration'>
            <div className='flex items-center space-x-2 ml-6 relative top-8'>
              <img src={left} className='w-4' alt='arrow_left'/>
              <p className='text-lg text-[#233D4D] hover:text-[#fe7f2d]'>Retour à la page d'accueil</p>
            </div>
          </Link>

        <div className='my-20 flex xs:flex-row s:flex-col-reverse justify-center items-center '>
            <div className='bg-white shadow-3xl xs:w-[500px] s:w-[310px] h-[400px]'>
                {/* logo */}
                <Link to='/'>
                <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                    <img src={logo1} className='h-16  s:pl-0 pt-6 ' alt='logo' />
                </div>
                </Link>
                <p className='text-2xl font-bold text-[#233D4D] text-center relative s:bottom-4 xs:bottom-7'>Renitialiser le mot de passe</p>

                <p className='text-center font-thin'>Vous avez oublié votre mot de passe ? Renitialiser le.</p>

                <div className='flex flex-col items-center pt-6 space-y-4'>

                    {/* Mail */}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-12 flex pl-3 space-x-2 items-center'>
                            {/* icone mail */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.25" height="15.188" viewBox="0 0 20.25 15.188">
                            <g id="mail" transform="translate(0 -64)">
                                <path id="Tracé_218" data-name="Tracé 218" d="M18.563,64H1.688A1.689,1.689,0,0,0,0,65.688V77.5a1.689,1.689,0,0,0,1.688,1.688H18.563A1.689,1.689,0,0,0,20.25,77.5V65.688A1.689,1.689,0,0,0,18.563,64Zm-16.875.844H18.563a.8.8,0,0,1,.175.035c-1.461,1.337-6.305,5.769-8,7.3a.846.846,0,0,1-1.225,0c-1.7-1.527-6.54-5.959-8-7.3A.806.806,0,0,1,1.688,64.844ZM.844,77.5V65.688a.815.815,0,0,1,.047-.233c1.118,1.023,4.5,4.116,6.69,6.109C5.4,73.44,2.015,76.645.888,77.72a.813.813,0,0,1-.045-.22Zm17.719.844H1.688a.807.807,0,0,1-.19-.039c1.165-1.11,4.567-4.334,6.714-6.17l.737.667a1.685,1.685,0,0,0,2.355,0l.737-.667c2.146,1.836,5.549,5.06,6.714,6.17A.806.806,0,0,1,18.563,78.344Zm.844-.844a.815.815,0,0,1-.045.22c-1.128-1.075-4.508-4.281-6.693-6.157,2.192-1.993,5.572-5.085,6.69-6.109a.815.815,0,0,1,.047.233Z" fill="#4e4e4e"/>
                            </g>
                            </svg>

                            <input name='mail' value={mail} required onChange={(e) => setMail(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' type='text' placeholder='Votre adresse mail'/>
                        </div>
                </div>
              

                <button className=' '></button>
                <div className='ml-16'>
                    <Button style={{backgroundColor:"white", color:"#233D4D", border:"solid 2px #233D4D"}} className='border-solid border-2  mt-4 px-10 h-10 text-lg rounded transform active:scale-75 transition-transform '  onClick={handleClickOpen}>
                       Comfirmer
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {" Avertissement !"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Voulez-vous rénitialiser votre mot de passe ?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button onClick={submit} autoFocus>
                           Confirmer
                        </Button>
                        </DialogActions>
                    </Dialog>
                    </div>
            </div>


            {/* image */}
            <div className='bg-white shadow-3xl ' style={{backgroundImage:`url(${login})`, height:400, width:310}}>
              <p className='text-3xl font-bold text-white text-center pt-24'>Hello !</p>
              <div className='flex justify-center pt-10'>
                <p className='w-[250px] text-center text-white'>Vous n'avez pas encore créer
                un compte ? inscrivrez vous en entrant
                vos informations
                </p>
              </div>
              <Link to='/Inscription'>
                <div className='flex justify-center pt-12'>
                    <button className='bg-[#FCCA46] text-white px-10 h-10 text-lg rounded transform active:scale-75 transition-transform'>S'inscrire</button>
                </div>
              </Link>
            </div>
        </div>




        <Footer/>
         </>
        )}
    </div>
  )
}

export default MotPasseOublie