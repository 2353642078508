import React from 'react'
import axios from 'axios';
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { MdOutlineHomeWork } from "react-icons/md";
import { IoMdPin, IoIosMail } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { MdOutlinePayment } from "react-icons/md";
import Loisirs from "../../../../API/tourisme/loisir/index"
import { ToastContainer, toast } from "react-toastify"
import { FaPhoneAlt } from 'react-icons/fa';
import { CiCalendarDate } from "react-icons/ci";
import { GiMoneyStack } from "react-icons/gi";
import { styled } from '@mui/material/styles';
import { FedaCheckoutButton, FedaCheckoutContainer } from 'fedapay-reactjs';
import {Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function PaymentPopup({ open,tel, user_Id, montant, email, onSuccess, userPrenom,userNom, formData, onClose }) {
    const [isDialogOpen, setIsDialogOpen] = useState(open);

    useEffect(() => {
        setIsDialogOpen(open);
    }, [open]);
    //console.log(email)
    const handleOpenAZ = () => {
        //alert("AZPay")
        // axios.get('/api/payment/create', {
        //     params: {
        //         provider: 'AZPay',
        //         item_id: 'item_id',
        //         item_name: 'item',
        //         amount: montant, // Assurez-vous de convertir les devises correctement
        //         currency: 'XOF',
        //         email: email,   
        //         description: 'Publicité sur Mon Bon Sejour', 
        //         phone: tel,
        //         email: email,
        //         last_name: userNom,  // Remplacez par les informations du client
        //         first_name: userPrenom,  
        //         success_url: 'successUrl',
        //         customer_number: userId
        //     }
        // }).then(response => {
        //     if (response.data.fail) {
        //         if (response.data.type_error === "balance_insufficient") {
        //             //setError("Votre solde est insuffisant pour effectuer cette transaction.");
        //         }
        //     } else if (response.data.success_url) {
        //         window.location.href = response.data.success_url;
        //     } else {
        //         //setError("Une erreur s’est produite, veuillez réessayer !");
        //     }
        // }).catch(error => {
        //     console.error("Erreur lors de la requête AZPay :", error);
        // });
    };


    const handleClose = () => {
        setIsDialogOpen(false);
        if (onClose) onClose();  
    };


    const handlePayCancel = () => {
        //console.log('Paiement annulé');
        handleClose();
    };
    const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
    const checkoutButtonOptions = {
        public_key: PUBLIC_KEY,
        transaction: {
          amount: montant,
          description: 'Publicité sur Mon Bon Sejour',
          callback_url: 'Dashboard/Publications/Hebergement/Ajouter_des_hebergements',
          custom_metadata: {
                user_id: user_Id,
                //additional_info: 'Some additional info'
            }
        },
        currency: {
          iso: 'XOF'
        },
        customer: {
          lastname: userNom,
          firstname: userPrenom,
          email: email
        },
        button: {
          class: 'btn btn-primary',
          text: 'Payer ' + montant + ' FCFA'
        },
        onComplete(resp) {
          const FedaPay = window['FedaPay'];
          if (resp.reason === FedaPay.DIALOG_DISMISSED) {
            //window.location.reload();
            toast.success('Vous avez fermé la boite de dialogue');
          } else {
            setIsDialogOpen(false);
            onSuccess();
    
            // Extraire les données de la transaction
            const transactionData = {
              transaction_id: resp.transaction.id,
              reference: resp.transaction.reference,
              amount: resp.transaction.amount,
              description: resp.transaction.description,
              status: resp.transaction.status,
              approved_at: resp.transaction.approved_at,
              customer: {
                firstname: resp.transaction.customer.firstname,
                lastname: resp.transaction.customer.lastname,
                email: resp.transaction.customer.email,
              },
              transaction_key: resp.transaction.transaction_key,
              fees: resp.transaction.fees,
              mode: resp.transaction.mode,
              amount_debited: resp.transaction.amount_debited,
              custom_metadata:resp.transaction.custom_metadata.user_id,
            };
            console.log('Transaction Data: ',transactionData);
            console.log(checkoutButtonOptions);
            // Envoyer les données de la transaction au backend
            fetch(`${process.env.REACT_APP_BASE_URL}/api/fedapay/callback`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify(transactionData),
            })
            .then(response => {
              if (!response.ok) {
                return response.json().then(errorData => {
                  throw new Error(errorData.message || 'Something went wrong');
                });
              }
              return response.json();
            })
            .then(data => {
              console.log('Transaction saved:', data);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
          }
    
          console.log(resp.transaction);
          
        },
        onCancel: handlePayCancel
      };
    
      // Lancer le paiement FedaPay
      const handlePayment = () => {
        const FedaPay = window['FedaPay'];
        FedaPay.init(checkoutButtonOptions).open();
      };
    

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isDialogOpen}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='flex items-center text-[#FE7F2D] space-x-3'>
                <p>Veuillez choisir le mode de paiement</p>
                <IconButton aria-label="close" onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Pour les paiements avec votre compte Az, choisissez l'option payer avec mon compte Az-Pay. Et pour les paiements par carte bancaire ou mobile money, choisissez l'option payer par carte ou mobile money
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOpenAZ} style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}>
                    Az-Pay : {montant} F CFA
                </Button>
                {/* <Button id="fedapay_btn_" onClick={handleOpenCard} style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}>
                    Carte bancaire / Mobile money : {price} F CFA
                </Button> */}

                {/* <FedaCheckoutButton 
                    style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}
                    options={checkoutButtonOptions}
                /> */}

                <button onClick={handlePayment} className={checkoutButtonOptions.button.class} 
                    style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}>
                    {checkoutButtonOptions.button.text}
                </button>

            </DialogActions>

        </BootstrapDialog>
    );
}





function AjoutLoisir() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Loisirs_activites/Voir_des_activites'));

    const navigate = useNavigate();

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

    const [formData, setFormData] = useState({});

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [address, setAddress] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCountryChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //province
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/province/list/${value}`)
     .then(response => {setProvinces(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const handleProvinceChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //city
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/city/list/${value}`)
     .then(response => {setCities(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }

        //country
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/country/list`)
     .then(response => {setCountries(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

     //adresse
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/utilisateur/adresse/${data.azId}`)
     .then(response => {
       setAddress(response.data.data)
   })
     .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    // États pour gérer le formulaire et le popup
    const [showPopup, setShowPopup] = useState(false);

    const submitForm = (event) => {
        //event.preventDefault();

        let fd = new FormData();

        fd.append("nom", formData.nom);
        fd.append("user", data.userId);
        fd.append("desc", formData.msg);
        fd.append("prix", formData.tarif);

        fd.append("date_debut", formData.date_debut );
        fd.append("date_fin", formData.date_fin);

        fd.append("adresse", formData.lieu);
        fd.append("mail", formData.mail ? formData.mail : data.userMail);
        fd.append("tel", formData.phone ? formData.phone : data.userTel);
        fd.append("pays", formData.country);
        fd.append("ville", formData.ville);
        fd.append("province", formData.province);

        if (formData.date_debut && formData.tarif && formData.date_fin) {
            fd.append("montant", parseFloat(formData.tarif) * 
            Math.ceil((new Date(formData.date_fin) - 
            new Date(formData.date_debut)) / (1000 * 60 * 60 * 24)));
        }

        for(let i = 0; i < document.getElementById("photo")?.files.length; i++){
            fd.append("photo[]", document.getElementById("photo")?.files[i]);
        }
        setLoading(true)
        Loisirs.submitForm(fd)
            .then((res) => {
                if (res.data.status === 201) {
                    toast.success(res.data.message);
                    //console.log(res);
                } else {
                    toast.error(res.data.message);
                    //console.log(res);
                }
            })
            .catch((err) => {
                toast.error('Erreur merci de reessayer !');
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };

     // Fonction de gestion de la soumission du formulaire
    const handleSubmit = (event) => {
        event.preventDefault();
        // Afficher le popup de paiement
        setShowPopup(true);
    };

    // Callback de succès du popup de paiement
    const handlePopupSuccess = () => {
        setShowPopup(false);
        submitForm();
    };

    // Callback d'annulation du popup de paiement
    const handlePopupCancel = () => {
        setShowPopup(false);
    };


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    {/* sidebar left */}

                    <div className='w-screen h-screen barre_scroll'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs >
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                   Loisirs et Activités
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Ajouter 
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <div>
                            <ToastContainer />
                        </div>

                        <Link to="/Dashboard/Publications/Loisirs_activites/Voir_des_activites">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précedente</p>
                            </div>
                        </Link>
                        {/* container formulaire */}
                        <div className='flex justify-center py-20 '>
                            <form onSubmit={handleSubmit} className='flex flex-col space-y-3 '>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                    {/* Ajout des images */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                                            <label> Ajouter des images </label>
                                        </div>
                                        <input type="file" accept="image/*" onChange={handleInputChange} required multiple id="photo" name="photo" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 ' />
                                    </div>
                                    {/* Nom du site */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
                                            <label>Nom du site </label>
                                        </div>
                                        <input type='text' onChange={handleInputChange} required name='nom' placeholder='Ex : Les cascade de Man' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>

                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlinePayment className='text-xl text-[#fe7f2d]' />
                                            <label>Tarif du billet </label>
                                        </div>
                                        <input onChange={handleInputChange} required name='tarif' type='number' placeholder='Ex : 10000' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    
                                  {/* Description */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                                            <label>Description </label>
                                        </div>
                                        <textarea onChange={handleInputChange} name='msg' required className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                                    </div>
                                   
                                   
                                </div>

                                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                    {/* Tel */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaPhoneAlt className=' text-[#fe7f2d]' />
                                            <label>Telephone</label>
                                        </div>
                                        <input type='tel' defaultValue={data.userTel} name='phone' required onChange={handleInputChange} placeholder="Ex : +225 xxx xxx xxxx " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Mail */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <IoIosMail className=' text-[#fe7f2d]' />
                                            <label>E-mail</label>
                                        </div>
                                        <input defaultValue={data.userMail} type='email' name='mail' required onChange={handleInputChange} placeholder="Ex : info@monbonsejour.com " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Country */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <IoMdPin className=' text-[#fe7f2d]' />
                                            <label>Pays</label>
                                        </div>
                                        <select onChange={handleCountryChange} required name='country' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                        <option value={address.code}>{address.fr_Name}</option>
                                                            {countries.map((option, index) => (
                                            <option key={index} value={option.code}>{option.fr_Name}</option>
                                            ))}
                                                
                                        </select>
                                    </div>
                                    {/* Province */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <IoMdPin className=' text-[#fe7f2d]' />
                                            <label>Province</label>
                                        </div>
                                        <select onChange={handleProvinceChange} required name='province' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                        <option value={address.code_province}>{address.pname}</option>
                                                            {provinces.map((option, index) => (
                                            <option key={index} value={option.code_province}>{option.fr_Name}</option>
                                            ))}
                                                                                
                                        </select>
                                    </div>
                                    {/* Ville */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <IoMdPin className=' text-[#fe7f2d]' />
                                            <label>Ville</label>
                                        </div>
                                        <select onChange={handleInputChange} required name='ville' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                        <option value={address.code_city}>{address.cname}</option>
                                                            {cities.map((option, index) => (
                                            <option key={index} value={option.code_city}>{option.fr_Name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* Lieu */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <IoMdPin className=' text-[#fe7f2d]' />
                                            <label>Lieu</label>
                                        </div>
                                        <input defaultValue={address.Adresses} type='text' name='lieu' onChange={handleInputChange} placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                </div>

                                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                {/* date debut */}
                                <div className='flex flex-col space-y-3'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <CiCalendarDate className=' text-[#fe7f2d]' />
                                        <label>Date de debut affichage</label>
                                    </div>
                                    <input name='date_debut' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
                                </div>

                                {/* date fin */}
                                <div className='flex flex-col space-y-3'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <CiCalendarDate className=' text-[#fe7f2d]' />
                                        <label>Date de fin affichage</label>
                                    </div>
                                    <input name='date_fin' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
                                </div>

                                {formData.date_debut && formData.tarif && formData.date_fin ? 
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2 '>
                                            <GiMoneyStack className='text-xl text-[#1f2937]' />
                                            <label className='text-[#1f2937] text-bold'>Montant total: {(parseFloat(formData.tarif) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut))/ (1000 * 60 * 60 * 24)))} F CFA</label>
                                            <input hidden id="montant" onChange={handleInputChange} value={(parseFloat(formData.tarif) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut))/ (1000 * 60 * 60 * 24)))} name='montant' type='number'/>
                                                            
                                        </div>
                                    </div>
                                            
                                    : ""
                                }
                            
                            </div>
                            {formData.date_debut && formData.tarif && formData.date_fin ?
                                        <button className='bg-[#FCCA46] w-[250px] h-10 rounded
                                        text-white'>Procéder au paiement</button>
                                    
                                        :
                                        <button className='bg-[#FCCA46] w-[150px] h-10 rounded
                                        text-white'>Ajouter</button>
                                    }

                                    {showPopup && (
                                        <PaymentPopup
                                            open={showPopup}
                                            montant={parseFloat(formData.tarif) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut)) / (1000 * 60 * 60 * 24))}
                                            onSuccess={handlePopupSuccess}
                                            userNom={data.userLastname}
                                            userPrenom={data.userName                    }
                                            email={data.userMail}
                                            tel={data.userTel}
                                            user_Id={data.userId}
                                            formData={formData}
                                            onCancel={handlePopupCancel}
                                        />
                                    )}



                                {/* <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Ajouter</button> */}
                            </form>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default AjoutLoisir