import React from 'react'
import axios from 'axios';
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { MdOutlineHomeWork } from "react-icons/md";
import { IoMdPin, IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from 'react-icons/fa';
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { MdOutlinePayment } from "react-icons/md";
import Loisirs from "../../../../API/tourisme/loisir/index"
import { ToastContainer, toast } from "react-toastify"
import {useLocation} from 'react-router-dom';
import { CiCalendarDate } from "react-icons/ci";


function EditLoisirs() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Loisirs_activites/Voir_des_activites'));

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({});

    const navigate = useNavigate();

    const [records, setRecords] = useState([]);
    const [tmprecords, setTmpecords] = useState([]);

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [address, setAddress] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const location = useLocation();
    const id = location.state

    const handleCountryChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //province
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/province/list/${value}`)
     .then(response => {setProvinces(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const handleProvinceChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //city
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/city/list/${value}`)
     .then(response => {setCities(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/loisir/show/${id}`)
     .then(response => {
        setRecords(response.data.data)
        setTmpecords(response.data.data)
    })
     //.then(response => console.log(response))
     .catch(err => console.log(err))

      //country
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/country/list`)
      .then(response => {setCountries(response.data.data)})
      //.then(response => console.log(response.data.data))
      .catch(err => console.log(err))

      //adresse
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/annonce/info/${id}`)
      .then(response => {
        setAddress(response.data.data)
    })
      .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        let fd = new FormData();

        fd.append("id", id);
        fd.append("nom", formData.nom ?? records.title_announce);
        fd.append("adresse", formData.lieu ?? records.contact_address);
        fd.append("desc", formData.msg ?? records.description_announce);
        fd.append("prix", formData.prix ?? records.price_announce);
        fd.append("date_debut", formData.date_debut ?? records.date_debut);
        fd.append("date_fin", formData.date_fin ?? records.date_fin);

        fd.append("mail", formData.mail ?? records.contact_email);
        fd.append("tel", formData.phone ?? records.contact_phone);
        fd.append("pays", formData.country ?? records.contact_country);
        fd.append("ville", formData.ville ?? records.contact_city);
        fd.append("province", formData.province ?? records.contact_province);

        for(let i = 0; i < document.getElementById("photo")?.files.length; i++){
        fd.append("photo[]", document.getElementById("photo")?.files[i]);
    }
        setLoading(true)
        Loisirs.submitEdit(fd)
            .then((res) => {
                if (res.data.status === 201) {
                    toast.success(res.data.message);
                    console.log(res);
                } else {
                    toast.error(res.data.message);
                    console.log(res);
                }
                navigate("/Dashboard/Publications/Loisirs_activites/Voir_des_activites");
            })
            .catch((err) => {
                toast.error('Erreur merci de reessayer !');
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    {/* sidebar left */}

                    <div className='w-screen  barre_scroll h-screen'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs >
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                   Loisirs et Activités
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Ajouter 
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <Link to="/Dashboard/Publications/Loisirs_activites/Voir_des_activites">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précedente</p>
                            </div>
                        </Link>
                        {/* container formulaire */}
                        <div className='flex justify-center py-20 '>
                            <form onSubmit={handleSubmit} className='flex flex-col space-y-3   '>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                    {/* Ajout des images */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                                            <label> Ajouter des images </label>
                                        </div>
                                        <input type="file" accept="image/*" id='photo' multiple name="photo" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 ' />
                                    </div>
                                    {/* Nom du site */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
                                            <label>Nom du site </label>
                                        </div>
                                        <input type='text' defaultValue={tmprecords.title_announce} onChange={handleInputChange} name='nom' required placeholder='Ex : Les cascade de Man' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlinePayment className='text-xl text-[#fe7f2d]' />
                                            <label>Tarif du billet </label>
                                        </div>
                                        <input type='number' defaultValue={tmprecords.price_announce} onChange={handleInputChange} name='prix' required placeholder='Ex : 10000' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    
                                  {/* Description */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                                            <label>Description </label>
                                        </div>
                                        <textarea defaultValue={tmprecords.description_announce} onChange={handleInputChange} name="msg" required className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                                    </div>
                                    
                                    
                                   
                                   
                                </div>

                                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                    {/* Tel */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <FaPhoneAlt className=' text-[#fe7f2d]' />
                            <label>Telephone</label>
                        </div>
                        <input type='tel' defaultValue={tmprecords.contact_phone} name='phone' onChange={handleInputChange} required placeholder="Ex : +225 xxx xxx xxxx " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Mail */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoIosMail className=' text-[#fe7f2d]' />
                            <label>E-mail</label>
                        </div>
                        <input type='email' defaultValue={tmprecords.contact_email} name='mail' onChange={handleInputChange} placeholder="Ex : info@monbonsejour.com " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Country */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Pays</label>
                        </div>
                        <select onChange={handleCountryChange} name='country' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                            <option value={address.code}>{address.fr_Name}</option>
                                            {countries.map((option, index) => (
          <option key={index} value={option.code}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Province */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Province</label>
                        </div>
                        <select onChange={handleProvinceChange} name='province' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_province}>{address.pname}</option>
                                            {provinces.map((option, index) => (
          <option key={index} value={option.code_province}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Ville */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Ville</label>
                        </div>
                        <select onChange={handleInputChange}  name='ville' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_city}>{address.cname}</option>
                                            {cities.map((option, index) => (
          <option key={index} value={option.code_city}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Lieu */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Lieu</label>
                        </div>
                        <input type='text' defaultValue={tmprecords.contact_address} name='lieu' onChange={handleInputChange} placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                </div>

                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                {/* date debut */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de debut affichage</label>
        </div>
        <input defaultValue={tmprecords.date_debut} name='date_debut' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>

    {/* date fin */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de fin affichage</label>
        </div>
        <input defaultValue={tmprecords.date_fin} name='date_fin' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>
    
</div>

                                <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Modifier</button>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default EditLoisirs