import React from 'react'
import { useState } from 'react';
import DropdownLogin from '../DropdownLogin/DropdownLogin';


function Header() {

    const platformEmail = 'info@monbonsejour.com';
    const phoneNumber = '+2250702322868';
    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
      });
  return (
      <div style={{ backgroundColor: "#233D4D" }} className='h-12 w-screen flex flex-row space-x-4 justify-end items-center pr-6'>


{data ? <DropdownLogin/> : null}
        
          {/* phone */}
          <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
          <div className='flex items-center md:space-x-2 s:space-x-0'>
              <div className=''>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.185" viewBox="0 0 19.182 19.185">
                      <path id="Tracé_13" data-name="Tracé 13" d="M12.216,12.483,9.735,14.964c-.522.522-2.174-.281-3.689-1.8S3.734,10.007,4.256,9.485L6.737,7,2.614,2.88.557,4.937c-1.514,1.514.327,5.817,4.118,9.6s8.088,5.633,9.6,4.113L16.335,16.6ZM9.617,0V1.918a7.683,7.683,0,0,1,7.674,7.674H19.21A9.592,9.592,0,0,0,9.617,0Z" transform="translate(-0.027)" fill="#fff" />
                  </svg>
              </div>
              <div className='text-white text-xs hover:text-orange-400 cursor-pointer s:w-3 sm:w-5 md:w-full s:invisible md:visible'>
                  +225 07 02 32 28 68
              </div>
          </div>
          </a>

          {/* mail */}
          <a href={`mailto:${platformEmail}`}>
          <div className='flex items-center md:space-x-2 s:space-x-0  '>
              <div className=''>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 25 15">
                      <g id="gmail" transform="translate(-11 -31.3)">
                          <path id="Tracé_7" data-name="Tracé 7" d="M27.577,38.909,36,43.551V34.065ZM11,34.065v9.486l8.423-4.642ZM34.442,31.3H12.558a1.5,1.5,0,0,0-1.519,1.156L23.5,39.613l12.462-7.156A1.511,1.511,0,0,0,34.442,31.3Zm-8.308,8.43L23.923,41a.934.934,0,0,1-.423.117A.722.722,0,0,1,23.077,41L20.865,39.73l-9.808,5.413A1.512,1.512,0,0,0,12.577,46.3H34.462a1.48,1.48,0,0,0,1.519-1.156Z" transform="translate(0)" fill="#fff" />
                      </g>
                  </svg>
              </div>
              <div className='text-white text-xs  sm:w-5 s:w-3 md:w-full  s:invisible md:visible'>
                  info@monbonsejour.com
              </div>
          </div>
          </a>
          

    </div>
  )
}

export default Header