import React from 'react'
import Header from '../../COMPONNENTS/Header/Header'

import visa from '../../../ASSETS/CaImmigration/images/Visa_barniere.png'
import etude from '../../../ASSETS/CaImmigration/images/visa_etudiant.png'
import travail from '../../../ASSETS/CaImmigration/images/visa_travail.png'
import touriste from '../../../ASSETS/CaImmigration/images/visa_touriste.png'
import transit from '../../../ASSETS/CaImmigration/images/visa_transit.png'
import familiale from '../../../ASSETS/CaImmigration/images/visa_familiale.png'
import Footer from '../../COMPONNENTS/Footer/Footer'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import FAQ from '../FAQ/Faq'
import Navbar from './Navbar'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard'


function Visa() {
    
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
      <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          {/* back image */}
          <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${visa})` }}>
             
             {/* Navbar */}

             <Navbar/>
               
              <div className='text_home flex justify-end md:pl-10 s:pl-5  s:pt-20 md:pt-0 s:mt-20 xs:mt-40 lg:mt-44'>
                  <div className='flex pt-20  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg    font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>SERVICES DE VISA CANADIEN</p>
                  </div>
              </div>

          </div>

          {/* option */}
        <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
              <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/* visa */}
          <div className='bg-white py-20'>

             {/* titre */}
                <div className='flex  xs:flex-row xs:px-5 md:px-0 md:pl-0 flex-col justify-center items-center xs:space-x-40 xl:space-x-[700px] lg:space-x-[300px] s:space-y-10'>
                <div>
                    <p className=' text-sm font-semibold'><span style={{ color:"#FE7F2D"}} className=' font-bold sm:text-xl s:text-lg'>/</span>QUE PROPOSONS-NOUS </p>
                    <p style={{ color: "##4e4e4e" }} className=' sm:text-2xl s:text-xl font-bold w-[250px]'>Conseils en Services de visa d'immigration exceptionnels.</p>
                </div>

                <div style={{ color: "##4e4e4e" }} className='xs:w-[650px] md:w-[800px] sm:w-[300px] s:w-[200px] sm:text-lg s:text-base text-justify '>
                À la recherche d'une expertise exceptionnelle en matière d'immigration au Canada ? 
                Nous sommes là pour vous accompagner tout au long de votre parcours. Nos consultants 
                chevronnés vous offrent des conseils personnalisés et des services de visa d'immigration 
                de haute qualité. Avec notre équipe dévouée, nous vous guiderons à chaque étape de votre 
                processus d'immigration, vous aidant à réaliser votre rêve canadien en toute simplicité. 
                Faites confiance à notre expérience pour une immigration réussie et sans stress.</div>
                </div>
              {/* bloc */}
              <div className='grid md:grid-cols-4 s:grid-cols-1 xs:grid-cols-2 mt-20 gap-3 place-items-center place-justify-center'>
                  {/* visa etude */}
                  <Link to='/visa/visaEtude'>
                  <div className='opacity1'>
                        <img src={etude} alt='permisetudes' className='w-[250px] hover:transition delay-150 duration-300 ease-in-out' />
                        <div className=' text-white font-semibold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{ backgroundColor: "#FCCA46" }}>
                            <p>Visa et permis d'etudes</p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                                    <g id="right" transform="translate(-24.596 -147.982)">
                                        <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff" />
                                    </g>
                                </svg>
                            </p>
                        </div>
                        <div className='w-[250px] text-sm relative bottom-4'>
                           Un visa étudiant ou un permis d'études est un document officiel délivré par un gouvernement permettant à un étudiant étranger de séjourner dans un pays pour poursuivre ses études.
                        </div>
                  </div>
                  </Link>
                  {/* visa travail */}
                  <Link to='/visa/visaTravail'>
                  <div className='opacity1'>
                      <img src={travail} alt='permisetudes' className='w-[250px]' />
                      <div className=' text-white font-semibold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{ backgroundColor: "#FCCA46" }}>
                          <p>Visa et permis de travail</p>
                          <p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                                  <g id="right" transform="translate(-24.596 -147.982)">
                                      <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff" />
                                  </g>
                              </svg>
                          </p>
                      </div>
                      <div className='w-[250px] text-sm relative bottom-4'>
                        Un visa de travail de travail est un document officiel délivré par un pays qui autorise un ressortissant étranger à travailler légalement sur le territoire de ce pays pour une période déterminée.
                      </div>
                  </div>
                   </Link>
                  {/* visa touriste*/}
                  <Link to='/visa/visaTouriste'>
                  <div className='opacity1'>
                      <img src={touriste} alt='permisetudes' className='w-[250px]' />
                      <div className=' text-white font-semibold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{ backgroundColor: "#FCCA46" }}>
                          <p>Visa touriste</p>
                          <p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                                  <g id="right" transform="translate(-24.596 -147.982)">
                                      <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff" />
                                  </g>
                              </svg>
                          </p>
                      </div>
                      <div className='w-[250px] text-sm relative bottom-4'>
                          Un visa touristique canadien, également connu sous le nom de "visa de visiteur", est un document officiel délivré par le gouvernement du Canada qui permet à des ressortissants étrangers...
                      </div>
                  </div>
                  </Link>
                  {/* visa transit*/}
                  <Link to='/visa/visaTransit'>
                  <div className='opacity1'>
                      <img src={transit} alt='permisetudes' className='w-[250px]' />
                      <div className=' text-white font-semibold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{ backgroundColor: "#FCCA46" }}>
                          <p>Visa transit</p>
                          <p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                                  <g id="right" transform="translate(-24.596 -147.982)">
                                      <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff" />
                                  </g>
                              </svg>
                          </p>
                      </div>
                      <div className='w-[250px] text-sm relative bottom-4'>
                            est un document officiel délivré par le gouvernement du Canada qui permet aux voyageurs étrangers de transiter par le Canada en route vers leur destination finale. Ce type de visa est...
                      </div>
                  </div>
                  </Link>
                  {/* visa familiale*/}
                  <Link to='/visa/visaFamiliale'>
                  <div className='opacity1'>
                      <img src={familiale} alt='permisetudes' className='w-[250px]' />
                      <div className=' text-white font-semibold w-56 h-10 flex flex-row space-x-4 items-center pl-2 relative bottom-16 text-sm' style={{ backgroundColor: "#FCCA46" }}>
                          <p>Visa familiale</p>
                          <p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18.578" height="8.256" viewBox="0 0 18.578 8.256">
                                  <g id="right" transform="translate(-24.596 -147.982)">
                                      <path id="Tracé_1" data-name="Tracé 1" d="M18.47,140.9l-4.128-3.763a.345.345,0,0,0-.376-.054.312.312,0,0,0-.2.312V139.8H.344a.331.331,0,0,0-.344.344v2.064a.331.331,0,0,0,.344.344H13.761v2.408a.324.324,0,0,0,.2.312.335.335,0,0,0,.376-.064l4.128-3.806a.351.351,0,0,0,.107-.258.34.34,0,0,0-.108-.247Z" transform="translate(24.596 10.931)" fill="#fff" />
                                  </g>
                              </svg>
                          </p>
                      </div>
                      <div className='w-[250px] text-sm relative bottom-4'>
                         Un visa familial offre à vos proches la possibilité de vivre, d'étudier et de travailler au Canada tout en attendant le traitement de leur demande.
                        </div>
                  </div>
                  </Link>
              </div>

               <FAQ />
          </div>

         
        
          <Footer />
              </>
          )}
      </div>
  )
} document.body.style.overflowX = "hidden"
document.body.style.overflowY = "auto"

export default Visa