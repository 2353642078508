import { useState, useEffect } from 'react';
import Loading from '../../../../COMPONENTS/Loading/Loading'
import Footer from '../../../../COMPONENTS/Footer/Footer'
import Carousel from "../Carousel";
import img1 from "../../..//../../ASSETS/tourisme/Image/image_banco/1671801383OKOKOKBANCO2-23122022.png";
import img2 from "../../../../../ASSETS/tourisme/Image/image_banco/images.png";
import img3 from "../../..//../../ASSETS/tourisme/Image/image_banco/img2.png";
import BackImage from '../../BackImage';
import BarniereCD from '../../BarniereCD';
import { IoIosArrowRoundForward } from "react-icons/io";
import Suggestion from '../Suggestion'

function ForetBanco() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const slides = [img1, img2, img3];
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    return (
        <div >
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

             {/* Back image */}
             <BackImage/>

             {/* barre bleu */}
            <div className='border-t-4 border-slate-900 py-10 w-2/3'></div>

            {/* container1 */}
            <div className='grid md:gap-8 md:grid-cols-2 place-items-center md:px-10 s:gap-10  xs:grid-cols-2 sm:grid-cols-1 pb-10'>
                <div className='space-y-6 md:w-full s:w-[260px] sm:w-[340px]'>
                    <p className='md:text-xl font-medium text-orange-400 '>La forêt du Banco</p>
                    <div className=' md:text-base sm:text-sm flex-col space-y-3'>

                        <p>
                            Le Parc national du Banco est un parc national de la Côte d'Ivoire 
                            situé en plein cœur d'Abidjan. C'est la seule forêt tropicale dense 
                            primaire située au cœur d'une agglomération avec le parc national de 
                            la Tijuca à Rio de Janeiro au Brésil1.
                            Il a une superficie de 3 438 hectares (soit 34,38 km2)
                        </p>

                        <p>
                            En plein cœur de la ville d’Abidjan se situe le parc national du Banco, 
                            à la jonction des communes d’Abobo, Yopougon, Adjamé et Attécoubé. 
                            Il s’inscrit dans un carré de 7,8 km de côté et couvre une superficie 
                            de 3473,55 ha avec un périmètre de 25,58 km et s’étend sur l’ancien 
                            domaine coutumier des villages attié d’Andokoi et ébrié d’Anonkoua-Kouté 
                            et Agba.
                        </p>
                        
                         {isShowMore && (
                        <p>
                            Le Banco est le deuxième plus grand parc urbain après celui de Rio de Janeiro.
                            Cette forêt est le poumon vert de la ville d’Abidjan et son réservoir 
                            hydraulique et abrite en son centre une forêt primaire de 600 hectares, 
                            avec des espèces de bois devenues rares (acajou, avodirés…).
                        </p>
                        )}

                        <button className='text-[#FE7F2D] flex flex-row space-x-2 items-center' onClick={toggleReadMoreLess}>
                        {isShowMore ? "Read Less" : "Read More"}
                        <IoIosArrowRoundForward className="text-lg " />
                        </button>
                    </div>

                    {/* Lieu */}
                    <div className='flex items-center space-x-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.161" height="32.741" viewBox="0 0 35.161 52.741">
                            <g id="localisation" transform="translate(-85.333)">
                                <path id="Tracé_149" data-name="Tracé 149" d="M102.913,0a17.582,17.582,0,0,0-15.47,25.935l14.508,26.24a1.1,1.1,0,0,0,1.923,0l14.514-26.248A17.583,17.583,0,0,0,102.913,0Zm0,26.37a8.79,8.79,0,1,1,8.79-8.79A8.8,8.8,0,0,1,102.913,26.37Z" fill="#233d4d" />
                            </g>
                        </svg>
                        <p className='text-slate-800 font-medium'>Lieu : <span className=' font-light'>Dabou, Côte d'ivoire</span></p>
                    </div>

                </div>

                {/* Caroussel */}
                <div className="relative md:w-[400px]  s:w-[260px] sm:w-[340px] ">
                    <div className="max-w-lg">
                        <Carousel slides={slides} />
                    </div>
                </div>

            </div>

            <div className='border-t-4 border-slate-900 py-10 w-2/3 grid place-items-end'></div>

            {/* suggestions */}
            <div className='space-y-4 flex flex-col items-center pb-14'>
                <p className='text-xl font-semibold'>Suggestions</p>
                <p className=' md:w-[900px] xs:w-[700px] s:w-[260px] sm:w-[340px] text-center md:text-base s:text-sm'>Découvrez d'autre sites touristique de la côte d'ivoire ut galisum provident At voluptate galisum non eveniet similique aut impedit consectetur qui quia quia. Ut ipsam accusamus ut odio quos et reiciendis placeat ea accusantium numquam ulpa ut officia consequatur. Id ducimu</p>
            </div>
            <Suggestion/>

           {/* Banniere culture et decouverte */}
            <BarniereCD/>
            
            <Footer />
                </>
            )}

        </div>
    )
}

export default ForetBanco