import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { MdOutlineHomeWork, MdOutlinePayment } from "react-icons/md";
import { MdOutlinePool } from "react-icons/md";
import { MdOutlineWifi } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { LiaCocktailSolid } from "react-icons/lia"
import { WiSnowflakeCold } from "react-icons/wi";
import { LiaUmbrellaBeachSolid } from "react-icons/lia";
import { IoMdPin, IoIosMail } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify"
import Restau from "../../../../API/tourisme/restaurant/index"
import { FaPhoneAlt } from 'react-icons/fa';


function EditRestaurant() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Restaurant/Voir_des_restaurants'));

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

    const navigate = useNavigate();

    const [records, setRecords] = useState([]);
    const [tmprecords, setTmpecords] = useState([]);

    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({});

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [address, setAddress] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const location = useLocation();
    const id = location.state

    const handleCountryChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //province
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/province/list/${value}`)
     .then(response => {setProvinces(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const handleProvinceChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //city
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/city/list/${value}`)
     .then(response => {setCities(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }
        
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/restaurant/show/${id}`)
     .then(response => {
        setRecords(response.data.data)
        setTmpecords(response.data.data)
    })
     //.then(response => console.log(response))
     .catch(err => console.log(err))

      //country
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/country/list`)
      .then(response => {setCountries(response.data.data)})
      //.then(response => console.log(response.data.data))
      .catch(err => console.log(err))

      //adresse
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/annonce/info/${id}`)
      .then(response => {
        setAddress(response.data.data)
    })
      .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        let fd = new FormData();

        fd.append("id", id);
        fd.append("nom", formData.nom ?? records.title_announce);
        fd.append("methode", formData.methode ?? records.payment_method);
        fd.append("adresse", formData.lieu ?? records.contact_address);
        fd.append("balcon", formData.terasse ?? records.realstate_balcony);
        fd.append("camera", formData.TV ?? records.realstate_security);
        fd.append("desc", formData.msg ?? records.description_announce);
        fd.append("piscine", formData.piscine ?? records.realstate_swimming_pool);
        fd.append("prix", formData.prix ?? records.price_announce);
        fd.append("wifi", formData.wifi ?? records.realstate_wifi);
        fd.append("date_debut", formData.date_debut ?? records.date_debut);
        fd.append("date_fin", formData.date_fin ?? records.date_fin);

        fd.append("mail", formData.mail ?? records.contact_email);
        fd.append("tel", formData.phone ?? records.contact_phone);
        fd.append("pays", formData.country ?? records.contact_country);
        fd.append("ville", formData.ville ?? records.contact_city);
        fd.append("province", formData.province ?? records.contact_province);

        for(let i = 0; i < document.getElementById("photo")?.files.length; i++){
        fd.append("photo[]", document.getElementById("photo")?.files[i]);
    }
        setLoading(true)
        Restau.submitEdit(fd)
            .then((res) => {
                if (res.data.status === 201) {
                    toast.success(res.data.message);
                    console.log(res);
                } else {
                    toast.error(res.data.message);
                    console.log(res);
                }
                navigate("/Dashboard/Publications/Restaurant/Voir_des_restaurants");
            })
            .catch((err) => {
                toast.error('Erreur merci de reessayer !');
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

                    <div className='w-screen  barre_scroll h-screen'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants" className="opacity-60 md:text-base s:text-xs">
                                    Restaurant
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Modifier
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <div>
                <ToastContainer />
            </div>

                        <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précdente</p>
                            </div>
                        </Link>

                        {/* container formulaire */}
                        <div className='flex justify-center py-20'>

                            <form onSubmit={handleSubmit} className='flex flex-col space-y-3'>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                    {/* Ajout des images */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                                            <label> Ajouter des images du restaurant</label>
                                        </div>
                                        <input type="file" accept="image/*" multiple name="photo" id='photo' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 ' />
                                    </div>
                                    {/* Nom du restaurant */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
                                            <label>Nom du restaurant</label>
                                        </div>
                                        <input required type='text' defaultValue={tmprecords.title_announce} onChange={handleInputChange} name='nom' placeholder='Ex : Lorenzo' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>

                                    {/* Type de paiement */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <MdOutlinePayment className='text-xl text-[#fe7f2d]' />
            <label>Méthode de paiement</label>
        </div>
        <select onChange={handleInputChange} name="methode" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4' required>
        <option value=""></option>
        {tmprecords.payment_method === 1 
        ? <option value="1"  selected>Paiement en ligne et sur place</option>
        : <option value="1" >Paiement en ligne et sur place</option>
        }

{tmprecords.payment_method === 2 
        ? <option value="2" selected>Paiement en ligne</option>
        : <option value="2">Paiement en ligne</option>
        }

{tmprecords.payment_method === 3 
        ? <option value="3" selected>Paiement sur place</option>
        : <option value="3">Paiement sur place</option>
        }
        
        </select>
    </div>

                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    {/* Service du restaurant */}
                                    <p className='font-semibold text-lg'>Service du restaurant</p>
                                    <div className='grid sm:grid-cols-3 s:grid-cols-2 xs:grid-cols-4 gap-y-8'>
                                        {/* piscine */}
        <div className=' flex flex-col space-y-2'>
            <div className='flex flex-row items-center space-x-2'>
                <MdOutlinePool className='text-xl text-[#fe7f2d]' />
                <label>Piscine</label>
            </div>
               
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_swimming_pool === 1
        ? <input onChange={handleInputChange} type='radio' name='piscine' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='piscine' value="1" />
        }
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_swimming_pool === 0
        ? <input onChange={handleInputChange} type='radio' name='piscine' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='piscine' value="0" />
        }
                
                <label>Non</label>
            </div>
        </div>
        {/* Wifi  */}
        <div className=' flex flex-col space-y-2'>
            <div className='flex flex-row items-center space-x-2'>
                <MdOutlineWifi className='text-xl text-[#fe7f2d]' />
                <label>Wifi </label>
            </div>
             
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_wifi === 1
        ? <input onChange={handleInputChange} type='radio' name='wifi' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='wifi' value="1" />
        } 
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_wifi === 0
        ? <input onChange={handleInputChange} type='radio' name='wifi' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='wifi' value="0" />
        } 
                
                <label>Non</label>
            </div>
        </div>
        {/* TV */}
        <div className=' flex flex-col space-y-2'>
            <div className='flex flex-row items-center space-x-2'>
                <SlScreenDesktop className='text-xl text-[#fe7f2d]' />
                <label>TV</label>
            </div>
              
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_security === 1
        ? <input onChange={handleInputChange} type='radio' name='TV' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='TV' value="1" />
        } 
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_security === 0
        ? <input onChange={handleInputChange} type='radio' name='TV' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='TV' value="0"  />
        } 
                
                <label>Non</label>
            </div>
        </div>
        {/* Pétit déjeuner */}
        <div className=' flex flex-col space-y-2'>
            <div className='flex flex-row items-center space-x-2'>
                <LiaUmbrellaBeachSolid className='text-xl text-[#fe7f2d]' />
                <label>Terasse</label>
            </div>
              
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_balcony === 1
        ? <input onChange={handleInputChange} type='radio' name='terasse' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='terasse' value="1" />
        } 
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_balcony === 0
        ? <input onChange={handleInputChange} type='radio' name='terasse' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='terasse' value="0" />
        } 
                
                <label>Non</label>
            </div>
        </div>
                                        
                                    </div>
                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    
                                    {/* Tarif */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                                            <label>Tarif minimun</label>
                                        </div>
                                        <input defaultValue={tmprecords.price_announce} onChange={handleInputChange} type='number' name='prix' required placeholder='Ex : 10000 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />

                                    </div>
                                    {/* Description */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                                            <label>Description du restaurant</label>
                                        </div>
                                        <textarea defaultValue={tmprecords.description_announce} onChange={handleInputChange} name="msg" className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                                    </div>
                                </div>

                                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                    {/* Tel */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <FaPhoneAlt className=' text-[#fe7f2d]' />
                            <label>Telephone</label>
                        </div>
                        <input type='tel' defaultValue={tmprecords.contact_phone} name='phone' onChange={handleInputChange} required placeholder="Ex : +225 xxx xxx xxxx " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Mail */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoIosMail className=' text-[#fe7f2d]' />
                            <label>E-mail</label>
                        </div>
                        <input type='email' defaultValue={tmprecords.contact_email} name='mail' onChange={handleInputChange} placeholder="Ex : info@monbonsejour.com " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Country */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Pays</label>
                        </div>
                        <select onChange={handleCountryChange} name='country' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code}>{address.fr_Name}</option>
                                            {countries.map((option, index) => (
          <option key={index} value={option.code}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Province */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Province</label>
                        </div>
                        <select onChange={handleProvinceChange} name='province' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_province}>{address.pname}</option>
                                            {provinces.map((option, index) => (
          <option key={index} value={option.code_province}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Ville */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Ville</label>
                        </div>
                        <select onChange={handleInputChange}  name='ville' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_city}>{address.cname}</option>
                                            {cities.map((option, index) => (
          <option key={index} value={option.code_city}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Lieu */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Lieu</label>
                        </div>
                        <input type='text' defaultValue={tmprecords.contact_address} name='lieu' onChange={handleInputChange} placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                </div>

                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
    {/* date debut */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de debut affichage</label>
        </div>
        <input defaultValue={tmprecords.date_debut} name='date_debut' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>

    {/* date fin */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de fin affichage</label>
        </div>
        <input defaultValue={tmprecords.date_fin} name='date_fin' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>
    
</div>

                                <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Modifier</button>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default EditRestaurant