import React from 'react'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";

import fr from '../../../ASSETS/tourisme/icone/france.png'
import en from '../../../ASSETS/tourisme/icone/united-states-of-america.png'


function Header() {

    const platformEmail = 'info@monbonsejour.com';
    const instagramUsername = 'monbonsejour';
    const facebookUsername = 'profile.php?id=61551029507292&mibextid=LQQJ4d';
    const phoneNumber = '+2250702322868';

const [open, setOpen] = React.useState(false);
 
  const handleOpen = () => setOpen(!open);

  return (
    <nav className=' '>
      
        <div className='header  s:h-10 md:h-8 w-screen flex md:felx-row justify-between  items-center py-4  sm:px-5 s:space-x-2 ' >

        <div className='E1 flex  md:flex-row md:space-x-5 s:space-x-0   '>
               {/* phone */}
            <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                <div className='flex items-center md:space-x-2 s:space-x-0'>
                    <div className=''>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.185" viewBox="0 0 19.182 19.185">
                        <path id="Tracé_13" data-name="Tracé 13" d="M12.216,12.483,9.735,14.964c-.522.522-2.174-.281-3.689-1.8S3.734,10.007,4.256,9.485L6.737,7,2.614,2.88.557,4.937c-1.514,1.514.327,5.817,4.118,9.6s8.088,5.633,9.6,4.113L16.335,16.6ZM9.617,0V1.918a7.683,7.683,0,0,1,7.674,7.674H19.21A9.592,9.592,0,0,0,9.617,0Z" transform="translate(-0.027)" fill="#fff"/>
                        </svg>
                    </div>
                <div className='text-white text-xs hover:text-orange-400 cursor-pointer s:w-3 sm:w-5 md:w-full s:invisible md:visible'>
                +225 05 855 421 02
                </div>
                </div>
            </a>

            {/* mail */}
            <a href={`mailto:${platformEmail}`}>
                <div className='flex items-center md:space-x-2 s:space-x-0 '>
                    <div className=''>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 25 15">
                    <g id="gmail" transform="translate(-11 -31.3)">
                    <path id="Tracé_7" data-name="Tracé 7" d="M27.577,38.909,36,43.551V34.065ZM11,34.065v9.486l8.423-4.642ZM34.442,31.3H12.558a1.5,1.5,0,0,0-1.519,1.156L23.5,39.613l12.462-7.156A1.511,1.511,0,0,0,34.442,31.3Zm-8.308,8.43L23.923,41a.934.934,0,0,1-.423.117A.722.722,0,0,1,23.077,41L20.865,39.73l-9.808,5.413A1.512,1.512,0,0,0,12.577,46.3H34.462a1.48,1.48,0,0,0,1.519-1.156Z" transform="translate(0)" fill="#fff"/>
                    </g>
                    </svg>
                    </div>
                <div className='text-white text-xs hover:text-orange-400 sm:w-5 s:w-3 md:w-full cursor-pointer s:invisible md:visible'>
                monbonsejour@gmail.com
                </div>
                </div>
            </a>

            {/* icone reseaux sociaux */}
            <div className='flex items-center sm:space-x-5 s:space-x-2 '>
                {/* icone whatsapp */}
                <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                    <div className=' hover:border-orange-400 hover:border-b-2 cursor-pointer'>
                        <svg  xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
                        <g id="what" transform="translate(-761.071 -902.758)">
                            <path id="Tracé_6" data-name="Tracé 6" d="M17.336,2.907a9.968,9.968,0,0,0-17,7,9.856,9.856,0,0,0,1.329,4.955L.25,20l5.279-1.378a9.985,9.985,0,0,0,4.759,1.206h0a9.949,9.949,0,0,0,9.958-9.91,9.826,9.826,0,0,0-2.914-7.011ZM10.292,18.154h0a8.3,8.3,0,0,1-4.213-1.148l-.3-.179-3.133.818.836-3.04-.2-.312A8.234,8.234,0,0,1,10.3,1.674a8.24,8.24,0,1,1,0,16.481Zm4.54-6.169c-.249-.124-1.472-.723-1.7-.806s-.394-.124-.56.124-.643.806-.788.971-.29.186-.539.062a6.8,6.8,0,0,1-2-1.229A7.475,7.475,0,0,1,7.86,9.392c-.145-.248,0-.369.109-.505a7.008,7.008,0,0,0,.622-.847.454.454,0,0,0-.021-.434c-.062-.124-.56-1.343-.767-1.839s-.407-.418-.56-.425-.311-.009-.477-.009a.916.916,0,0,0-.664.31,2.772,2.772,0,0,0-.871,2.066A4.805,4.805,0,0,0,6.248,10.27,11.07,11.07,0,0,0,10.5,14.01a14.28,14.28,0,0,0,1.419.522,3.427,3.427,0,0,0,1.567.1,2.563,2.563,0,0,0,1.68-1.178,2.059,2.059,0,0,0,.145-1.178c-.062-.1-.228-.165-.477-.289Zm0,0" transform="translate(760.821 902.758)" fill="#fff" fillRule="evenodd"/>
                        </g>
                        </svg>
                    </div>
                </a>

                {/* icone facebook */}
                <a href={`https://www.facebook.com/${facebookUsername}`} target="_blank" rel="noopener noreferrer">
                    <div className=' hover:border-orange-400 hover:border-b-2 cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 20">
                    <g id="facebok" transform="translate(-6.187)">
                        <path id="Tracé_12" data-name="Tracé 12" d="M14.361,3.321h1.826V.141A23.573,23.573,0,0,0,13.527,0C10.895,0,9.092,1.656,9.092,4.7V7.5h-2.9v3.555h2.9V20h3.561V11.056H15.44L15.883,7.5H12.652V5.052C12.653,4.024,12.93,3.321,14.361,3.321Z" transform="translate(0)" fill="#fff"/>
                    </g>
                    </svg>
                    </div>
                </a>

                {/* icone instagram */}
                <a href={`https://www.instagram.com/${instagramUsername}`} target="_blank" rel="noopener noreferrer">
                    <div className=' hover:border-orange-400 hover:border-b-2 cursor-pointer'>
                    <svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
                    <path id="Tracé_9" data-name="Tracé 9" d="M218.406,214.7a3.7,3.7,0,1,1-3.7-3.7A3.7,3.7,0,0,1,218.406,214.7Zm0,0" transform="translate(-204.703 -204.703)" fill="#fff"/>
                    <path id="Tracé_10" data-name="Tracé 10" d="M120.2,120h-.14a.055.055,0,0,0-.055.055v.14a.055.055,0,0,0,.055.055h.14a.055.055,0,0,0,.055-.055v-.14A.055.055,0,0,0,120.2,120Zm-.07.195a.069.069,0,1,1,.069-.069A.069.069,0,0,1,120.125,120.195Zm.079-.135a.014.014,0,1,1,.014-.014A.014.014,0,0,1,120.2,120.06Zm0,0" transform="translate(-110.125 -110.125)" fill="#fff"/>
                    <path id="Tracé_11" data-name="Tracé 11" d="M14.727,0H5.273A5.279,5.279,0,0,0,0,5.273v9.453A5.279,5.279,0,0,0,5.273,20h9.453A5.279,5.279,0,0,0,20,14.727V5.273A5.279,5.279,0,0,0,14.727,0Zm1.758,12.969a3.52,3.52,0,0,1-3.516,3.516H7.031a3.52,3.52,0,0,1-3.516-3.516V7.031A3.52,3.52,0,0,1,7.031,3.516h5.938a3.52,3.52,0,0,1,3.516,3.516Zm0,0" fill="#fff"/>
                    </svg>

                    </div>
                </a>

            </div>
       </div>

           <div className='E2 flex md:flex-row items-center  md:justify-end  md:space-x-5  s:space-x-0 '>
                {/* panier */}
                
                    <div className='s:pr-3'>
                    <svg id="panier" xmlns="http://www.w3.org/2000/svg" width="18.99" height="19" viewBox="0 0 23.99 24">
                    <path id="Tracé_40" data-name="Tracé 40" d="M3.69,7H0V5H3.45ZM0,13v2H4.63l-.24-2ZM3.92,9H0v2H4.16Zm17.67,6H8.65l.13,1.12a1,1,0,0,0,.99.88H20.99v2H9.78A3,3,0,0,1,6.8,16.35L5.21,2.88A1,1,0,0,0,4.22,2H2V0H4.22A3,3,0,0,1,7.2,2.65L7.24,3H23.99ZM21.55,5H7.48l.94,8H19.95ZM9,20a2,2,0,1,0,2,2A2.006,2.006,0,0,0,9,20Zm9,0a2,2,0,1,0,2,2A2.006,2.006,0,0,0,18,20Z" fill="#fff"/>
                    </svg>
                    </div>
                        
                {/* langue */}
                <div className=''>
               

                 {/* Langage */}
                 <>
                    <Button onClick={handleOpen} variant="gradient">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34.496" height="19" viewBox="0 0 41.496 24">
                            <g id="langue" transform="translate(-1840 -12)">
                                <g id="lng" transform="translate(1840.311 12.312)">
                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="12" cy="12" r="12" transform="translate(-0.311 -0.312)" fill="#f0f0f0"/>
                                <path id="Tracé_41" data-name="Tracé 41" d="M352.843,27.14a11.965,11.965,0,0,0-7.8-11.217V38.356A11.965,11.965,0,0,0,352.843,27.14Z" transform="translate(-329.155 -15.412)" fill="#d80027"/>
                                <path id="Tracé_42" data-name="Tracé 42" d="M0,27.14A11.965,11.965,0,0,0,7.8,38.356V15.923A11.965,11.965,0,0,0,0,27.14Z" transform="translate(-0.233 -15.412)" fill="#0052b4"/>
                                </g>
                                <g id="down" transform="translate(1868.674 -112.346)">
                                <g id="Groupe_6" data-name="Groupe 6" transform="translate(0 136.346)">
                                    <path id="Tracé_43" data-name="Tracé 43" d="M6.9,143.25a.687.687,0,0,1-.969,0L.2,137.515a.685.685,0,0,1,.969-.969L6.9,142.281A.687.687,0,0,1,6.9,143.25Z" transform="translate(0 -136.346)" fill="#fff" fillRule="evenodd"/>
                                    <path id="Tracé_44" data-name="Tracé 44" d="M273.043,143.4a.687.687,0,0,1,0-.969l5.735-5.735a.685.685,0,1,1,.969.969l-5.735,5.735A.687.687,0,0,1,273.043,143.4Z" transform="translate(-267.125 -136.494)" fill="#fff" fillRule="evenodd"/>
                                </g>
                                </g>
                            </g>
                    </svg>
                    </Button>
                    <Dialog open={open} handler={handleOpen} className='w-[350px] h-[250px]' >
                        <DialogHeader style={{fontSize:'20px'}}>Veuillez selectionner une langue</DialogHeader>
                        <DialogBody>

                        <div className='flex flex-row items-center space-x-3 hover:bg-slate-50 p-4 rounded-md cursor-pointer'>
                            <img src={fr} alt='french' className='w-10'/>
                            <div className='text-lg font-medium'>Français</div>
                        </div>

                        <div className='flex flex-row items-center space-x-3 hover:bg-slate-50 p-4 rounded-md cursor-pointer'>
                            <img src={en} alt='anglais' className='w-10'/>
                            <div className='text-lg font-medium'>Anglais</div>
                        </div>
                        </DialogBody>
                    </Dialog>
                    </>
                </div>
           
          </div>
        </div>

        
    </nav>
  )
}

export default Header
