import React from 'react'
import Footer from '../../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import BackImage from '../BackImage';
import Carousel from './Carousel';
import { MdOutlinePool } from "react-icons/md";
import { MdOutlineWifi } from "react-icons/md";
import { IoMdPin } from "react-icons/io";
import img1 from '../../../../ASSETS/tourisme/Image/mario.png'
import img2 from '../../../../ASSETS/tourisme/Image/havana.png'
import img3 from '../../../../ASSETS/tourisme/Image/laBrise.png'
import { SlScreenDesktop } from "react-icons/sl";
import { FaFacebookF } from "react-icons/fa6";
import { SlSocialInstagram } from "react-icons/sl";
import { CiMail } from "react-icons/ci";
import { BsWhatsapp } from "react-icons/bs";
import Commentaire from './Commentaire';
import Suggestion from "../Suggestion"
import { LiaCocktailSolid } from "react-icons/lia"
import { WiSnowflakeCold } from "react-icons/wi";
import { LiaUmbrellaBeachSolid } from "react-icons/lia";
import Barniere from '../Barniere';
import {Link} from 'react-router-dom'
import Restaurant from '../../../../API/tourisme/restaurant/index'
import { AnnoncesProps, ImagesProps} from "../../../../props";
import {useLocation} from 'react-router-dom';


function VoirPlus() {

  const location = useLocation();
    const id = location.state

    const [restau, setRestau] = useState<AnnoncesProps>();

    const [image, setImage] = useState([]);

    const slides = [img1, img2, img3];

    const [loading, setLoading] = useState(true);

    const getRestaurant = () => {
      Restaurant.getOne(id)
      .then((res:any) => {
          if (res.data.status === 201) {
            setRestau(res.data.data)
          }
          console.log(res);
      })
      .catch((err:any) => {
          console.log(err);
      })
      .finally(() => {
          setLoading(false);
      })
  }

  const getImages = () => {
    Restaurant.getImages(id)
      .then((res:any) => {
          if (res.data.status === 201) {
            setImage(res.data.data)
          }
          console.log(res);
      })
      .catch((err:any) => {
          console.log(err);
      })
      .finally(() => {
          setLoading(false);
      })
  }

    useEffect(() => {

        getRestaurant();
        getImages();

        //const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            //setLoading(false);
        //}, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        //return () => clearTimeout(timer);
    }, []);

  return (
    <div>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>

                 <BackImage />

                  {/* title */}
                    <div className=' flex flex-col md:mt-40 s:mt-80  justify-center items-center space-y-5 my-10'>
                        <p className='text-center font-semibold xs:text-2xl s:text-xl'> RESTAURATIONS</p>
                        <p className='text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                            Explorez la liberté de voyager à votre rythme avec notre
                            service de location de véhicules. Des options variées, des
                            tarifs compétitifs et un service client dévoué font de chaque
                            trajet une aventure sans souci.
                        </p>
                    </div>

                   <div className='flex md:flex-row s:flex-col md:justify-center s:items-center md:items-start md:space-x-14 s:space-x-0'>
                      <div className="md:w-[700px] xs:w-[500px] sm:w-[300px] s:w-[260px] mt-8  ">
                          <Carousel slides={image} />
                      </div>
                   
                      <div className='my-8 flex flex-col  space-y-6'>
                        <div className=' flex flex-col space-y-3'>
                          <p className='uppercase text-[#233d4d] font-semibold xs:text-xl s:text-base'>{restau?.title_announce}</p>
                          <div className='flex items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d] ' />
                            <p className=' text-[#fe7f2d]'>{restau?.contact_address}</p>
                          </div>
                        </div>
                        <p className='uppercase text-[#233d4d] xs:w-full s:w-[260px] sm:w-[300px] font-semibold xs:text-xl s:text-base '>Service et équipement du restaurant</p>
                        <div className='grid s:grid-cols-3 md:grid-cols-4 gap-y-6 text-[#4e4e4e]'>
                        {restau?.realstate_swimming_pool === 1 && (
                        <MdOutlinePool className='text-3xl text-[#fe7f2d]' />
                        )}
                        {restau?.realstate_wifi === 1 && (
                        <MdOutlineWifi className='text-3xl text-[#fe7f2d]' />
                        )}
                        {restau?.realstate_security === 1 && (
                        <SlScreenDesktop className='text-3xl text-[#fe7f2d]' />
                        )}
                        {/* <LiaCocktailSolid className='text-3xl text-[#fe7f2d]' /> */}
                        {restau?.realstate_balcony === 1 && (
                        <LiaUmbrellaBeachSolid className='text-3xl text-[#fe7f2d]' />
                        )}
                        {/* <WiSnowflakeCold className='text-3xl text-[#fe7f2d]' /> */}
                        </div>

                        <div className='flex xs:flex-row  xs:space-x-10 s:flex-col s:space-y-8 xs:space-y-0'>
                            <div className='flex flex-col space-y-3'>
                                {/* <p className='text-[#FCCA46] font-semibold text-lg'>Horaire d'ouverture</p>
                                <p>Lundi : 11h - 22h</p>
                                <p>Mardi : 11h - 22h</p>
                                <p>Mercredi : 11h - 22h</p>
                                <p>Jeudi : 11h - 22h</p>
                                <p>vendredi: 11h - 22h</p>
                                <p>Samedi: 10h - 22h</p>
                                <p>Dimanche : 14h - 20h</p> */}
                            </div>
                            <div>
                             <p className='text-[#FCCA46] font-semibold'>Coordonnée</p>
                             <div className='grid grid-cols-1 gap-4 text-lg pt-4 text-[#233d4d]'>
                                {/* <div className='flex space-x-2 items-center'>
                                  <FaFacebookF className='text-xl' />
                                  <p>: La rose noire</p>
                                </div>
                                <div className='flex space-x-2 items-center'>
                                  <SlSocialInstagram  className='text-xl' />
                                  <p>: La rose noire</p>
                                </div> */}
                                <div className='flex space-x-2 items-center'>
                                  <CiMail  className='text-xl' />
                                  <p>: {restau?.contact_email}</p>
                                </div>
                                <div className='flex space-x-2 items-center'>
                                  <BsWhatsapp  className='text-xl' />
                                  <p>: {restau?.contact_phone}</p>
                                </div>
                             </div>
                            </div>
                        </div>
                      </div>

                   </div>


                   <div className='xs:ml-12 s:mx-2 sm:mx-4 flex flex-col space-y-6 mb-14'>
                      <p className='text-[#4e4e4e] text-xl pt-4 font-semibold '>Description</p>
                        <p className='md:w-[900px] xs:w-[700px] s:w-[260px] sm:w-[300px] pt-2 text-justify'>
                          {restau?.description_announce}
                        </p>

                        {restau?.status === "enabled" && (
                            <Link to='/Reservation_Restaurant'
                            state={ 
                              restau?.id_announce 
                             }  
                            >
                          <button className='w-[170px] h-10 bg-[#FE7F2D] text-white text-lg rounded transition 
                          ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white
                          hover:text-[#FE7F2D] '>Réserver</button>
                        </Link>
                        )}
                        <Commentaire/>
                   </div>
                      
                    <Suggestion/>
                    <Barniere/>

                <Footer />
                  </>
            )}
    </div>
  )
}

export default VoirPlus