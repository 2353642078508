import React from 'react'
import Navbar from './Navbar'
import BarresearchP from '../../COMPONENTS/BarreSearchP/BarreSearchP'
import accueil from '../../../ASSETS/tourisme/Image/accueilCulture.png'

function BackImage() {
  return (
    <div>
         {/* navbar */}
            <Navbar />
            {/* back image */}
            <div className='bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative flex justify-center items-center' style={{ backgroundImage: `url(${accueil})` }}>
                <div className='navbar_animation flex items-center justify-center sm:pt-40 md:pt-0'>

                    <div className=' text-white md:text-7xl sm:text-5xl font-semibold uppercase '>
                        culuture & decouverte
                    </div>
                </div>

            </div>

            {/* barre de recherche principale */}
          <div className=' absolute inset-x-0 md:-bottom-20 xs:-bottom-40 s:-bottom-64 flex justify-center  '>
                <BarresearchP />
            </div>

            {/* title */}
            <div className='relative md:mt-40 s:mt-80  px-10 pb-20'>

                <div className=' space-y-4'>
                    <p className='md:text-2xl s:text-lg  font-medium text-orange-400'>Culture & Decouverte</p>
                    <p className='md:text-3xl s:text-xl font-semibold text-zinc-800'>Vivez Votre Vie À Travers Les Voyages</p>
                    <p className='md:text-lg s:text-sm'>Découvrez les différents sites touristiques à visités et diverses activités sur la plateforme Tempor Incididunt Ut Labore Et..</p>
                </div>
            </div>
    </div>
  )
}

export default BackImage