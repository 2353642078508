import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENTS/Header/Header'
import Navbar from './Navbar'
import Footer from '../../COMPONNENTS/Footer/Footer'
import visa from '../../../ASSETS/CaImmigration/images/VisaTravail_barniere.png'
import visatravail1 from '../../../ASSETS/CaImmigration/images/AdobeStock_490505395.jpeg'
import travail2 from '../../../ASSETS/CaImmigration/images/industrial-designers-working-3d-model.jpg'
import travail3 from '../../../ASSETS/CaImmigration/images/high-angle-view-male-accountant-checking-financial-document.jpg'
import { Link } from 'react-router-dom'
import work from '../../../ASSETS/CaImmigration/icones/real-estate.png'
import TestEligibilite from '../../COMPONNENTS/BoutonTestEligibilite/TestEligibilite';
import BarniereRdvVisa from '../../COMPONNENTS/BarniereRdvVisa/BarniereRdvVisa';
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';


function VisaEtude() {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
      <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
                  <Header />

                  {/* back image */}
                  <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${visa})` }}>
                      <Navbar />
                      <div className='text_home flex  justify-end md:pl-10 s:pl-3  s:pt-20 md:pt-0 mt-20'>
                              <div className='flex space-y-4 pt-20 flex-col text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg    font-semibold xs:w-[470px] md:w-[770px] '>
                              <p>SERVICES DE VISA CANADIEN</p>
                              <p className=' md:text-3xl sm:text-lg s:text-sm'>VISA ET PERMIS DE TRAVAIL</p>
                          </div>

                      </div>

                  </div>

                  {/* option */}
                 <OptionCard/>

                  {/* en tete */}
                  <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
                      <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
                      <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
                  </div>

                <div className='bg-white py-20'>
                   {/* titre */}
                    <div className='flex  xs:flex-row xs:px-5 md:px-0 md:pl-0 flex-col justify-center items-center xs:space-x-40 xl:space-x-[700px] lg:space-x-[300px] s:space-y-10'>
                    <div>
                        <p className=' text-sm font-semibold'><span style={{ color:"#FE7F2D"}} className=' font-bold sm:text-xl s:text-lg'>/</span>QUE PROPOSONS-NOUS </p>
                        <p style={{ color: "##4e4e4e" }} className=' sm:text-2xl s:text-xl font-bold w-[250px]'>Conseils en Services de visa d'immigration exceptionnels.</p>
                    </div>

                    <div style={{ color: "##4e4e4e" }} className='xs:w-[650px] md:w-[800px] sm:w-[300px] s:w-[200px] sm:text-lg s:text-base text-justify '>
                    "À la recherche d'une expertise exceptionnelle en matière d'immigration au Canada ? 
                    Nous sommes là pour vous accompagner tout au long de votre parcours. Nos consultants 
                    chevronnés vous offrent des conseils personnalisés et des services de visa d'immigration 
                    de haute qualité. Avec notre équipe dévouée, nous vous guiderons à chaque étape de votre 
                    processus d'immigration, vous aidant à réaliser votre rêve canadien en toute simplicité. 
                    Faites confiance à notre expérience pour une immigration réussie et sans stress."</div>
                    </div>
                    {/* container1 */}
                    <div className='flex xs:flex-row s:flex-col xs:space-x-40 s:space-y-20 s:justify-center items-center s:py-20 xs:py-0 xs:pb-20'>
                        {/* filtre */}
                        <div className='sm:w-[300px] s:w-[260px] h-[400px] flex flex-col justify-center items-center space-y-5  ' style={{backgroundColor:"#f7f7f7"}}>

                            {/* permis d'etudes */}
                            <Link to='/visa/visaEtude'>
                            <div style={{  backgroundColor: 'white', color:"#FE7F2D" }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base  text-white rounded-xl shadow-lg font-semibold justify-center items-center space-x-5'>
                            <p>Visa et Permis d'etudes</p>
                              <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                           
                            </div>
                            </Link>
                            {/* visa travail */}
                            <Link to='/visa/visaTravail'>
                            <div style={{ backgroundColor: '#FE7F2D', color:'white' }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-5'>
                                    <p>Visa et Permis de travail</p>
                                    
                                     <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                <g id="rigth" transform="translate(-1.974 -1.315)">
                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fff" />
                                </g>
                                </svg>
                                </p>
                            </div>
                            </Link>
                            {/* visa touriste */}
                            <Link to='/visa/visaTouriste'>
                            <div style={{ backgroundColor: 'white', color:"#FE7F2D" }} className='transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa touriste</p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                            </div>
                            </Link>
                            {/* visa transit */}
                            <Link to="/visa/visaTransit">
                            <div style={{ backgroundColor: 'white', color:"#FE7F2D" }} className='transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa transit</p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                            </div>
                            </Link>
                            {/* visa familiale*/}
                            <Link to="/visa/visaFamiliale">
                            <div style={{ backgroundColor: 'white', color:"#FE7F2D" }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa familiale</p>
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                            </div>
                            </Link>
                        </div>

                        {/* texte1 */}
                        <div>
                            <img className='md:w-[700px] sm:w-[300px] s:w-[210px]' src={visatravail1} alt='etudiant'/>
                            <p className='text-xl font-semibold pt-6' style={{color:"#FCCA46"}}>Visa et permis de travail</p>
                            <p className='md:w-[680px] sm:w-[300px] s:w-[210px] pt-6'>
                                Un visa de travail ou un permis de travail est un document officiel délivré par un pays qui autorise un ressortissant étranger à travailler légalement sur le territoire de ce pays pour une période déterminée. Les visas de travail et les permis de travail sont généralement assortis de conditions spécifiques, telles que le type d'emploi, la durée de validité, l'employeur ou le secteur d'activité dans lequel le titulaire du permis peut travailler.
                                Les visas de travail sont essentiels pour les travailleurs étrangers qui souhaitent travailler à l'étranger légalement.
                            </p>
                        </div>
                    </div>

                    {/* container2 */}
                    <div className='flex md:flex-row s:flex-col justify-center s:items-center xs:items-start md:space-x-32 s:space-x-0 s:space-y-16 xs:space-y-0 xs:mr-24 s:mr-0'>

                        <div className=' flex md:flex-col xs:flex-row s:flex-col s:space-y-10 xs:space-y-0 xs:space-x-10 md:space-x-0 md:space-y-10'>
                            <div><img src={travail2} className='md:w-[400px] sm:w-[300px] s:w-[210px]' alt='etudiant1'/></div>
                            <div><img src={travail3} className='md:w-[400px] sm:w-[300px] s:w-[210px]' alt='etudiant1'/></div>
                        </div>

                        <div className='flex flex-col items-center space-y-10' >
                            {/* avantages */}
                            <div>
                            <p className='text-2xl font-semibold xs:w-[500px] sm:w-[300px] s:w-[210px]' style={{color:"#233D4D"}}>Avantage de travailler au canada</p>
                            <p className='md:w-[500px] xs:w-[600px] sm:w-[300px] s:w-[210px] pt-6'>
                            <b>- Stabilité économique :</b> Le Canada est connu pour sa stabilité économique et son système financier solide, ce qui crée un environnement favorable pour les opportunités professionnelles.<br/><br/>
                            <b>- Opportunités de carrière :</b> Le Canada offre un large éventail d'opportunités de carrière dans différents secteurs, y compris la technologie, la santé, l'ingénierie, l'éducation, l'industrie, et bien d'autres.<br/><br/>
                            <b>- Conditions de travail équitables :</b> Le Canada a des lois du travail strictes qui protègent les droits des travailleurs, y compris des heures de travail raisonnables, des congés payés et la possibilité de syndicalisation.<br/><br/>
                            <b>- Salaires compétitifs :</b> Les salaires au Canada sont compétitifs sur le plan international, ce qui permet aux travailleurs de gagner un revenu décent.<br/><br/>
                            <b>- Possibilité de résidence permanente : </b>Travailler au Canada peut ouvrir la voie à la résidence permanente, ce qui permet de bénéficier de la citoyenneté canadienne et de tous les avantages qui en découlent.
                            </p>
                            </div>
                            
                        </div>
                    </div>

                          {/* guide */}
                          <div className='flex flex-col items-center'>
                              <p className='text-2xl font-semibold md:w-[800px] xs:w-[500px] sm:w-[300px] s:w-[210px] mt-10' style={{ color: "#233D4D" }}>Nous vous guidons dans le choix de votre metier pour le Canada</p>
                              <p className=' text-center md:w-[800px] xs:w-[600px] sm:w-[300px] s:w-[210px] pt-6'>
                                  Votre avenir professionnel au Canada commence ici. Mon Bon Séjour vous guide avec expertise dans le choix de votre métier, ouvrant les portes vers une carrière réussie. Fiez-vous à notre accompagnement pour un choix de carrière éclairé au Canada
                              </p>
                          </div>

                          <div className='flex xs:flex-row justify-center s:items-center mt-10 s:flex-col s:space-x-0 xs:space-x-4'>
                              {/* travailler */}
                              <div className='flex flex-row items-center text-xl font-bold s:mr-8 md:mr-0 space-x-6' style={{ color: "#233D4D" }}>
                                  {/* icones */}
                                  <p>
                                      <svg id="emploi" xmlns="http://www.w3.org/2000/svg" width="50" height="42.734" viewBox="0 0 60 52.734">
                                          <path id="Tracé_42" data-name="Tracé 42" d="M58.253,7.032H42.3V5.273A5.279,5.279,0,0,0,37.031,0H22.969A5.279,5.279,0,0,0,17.7,5.273V7.031H1.758A1.762,1.762,0,0,0,0,8.789V47.461a5.279,5.279,0,0,0,5.273,5.273H54.727A5.28,5.28,0,0,0,60,47.461V8.819a1.706,1.706,0,0,0-1.747-1.788ZM21.211,5.273a1.76,1.76,0,0,1,1.758-1.758H37.031a1.76,1.76,0,0,1,1.758,1.758V7.031H21.211ZM55.8,10.547,50.344,26.923a1.755,1.755,0,0,1-1.667,1.2H38.789V26.367a1.758,1.758,0,0,0-1.758-1.758H22.969a1.758,1.758,0,0,0-1.758,1.758v1.758H11.323a1.755,1.755,0,0,1-1.667-1.2L4.2,10.547ZM35.273,28.125v3.516H24.727V28.125ZM56.484,47.461a1.76,1.76,0,0,1-1.758,1.758H5.273a1.76,1.76,0,0,1-1.758-1.758V19.621l2.8,8.414a5.266,5.266,0,0,0,5,3.606h9.888V33.4a1.758,1.758,0,0,0,1.758,1.758H37.031A1.758,1.758,0,0,0,38.789,33.4V31.641h9.888a5.266,5.266,0,0,0,5-3.606l2.8-8.414Zm0,0" fill="#fe7f2d" />
                                      </svg>
                                  </p>
                                  <p>Travailler au Canada</p>
                              </div>
                              {/* Habiter */}
                              <div className='flex flex-row items-center text-xl font-bold s:mr-8 md:mr-0 space-x-6' style={{ color: "#233D4D" }}>
                                  {/* icones */}
                                  <p>
                                      <img src={work} className='w-14' alt='icone' />
                                  </p>
                                  <p>Vivre au Canada</p>
                              </div>
                          </div>

                   {/* eligibilité */}

                    <TestEligibilite />
                  

                    {/* banniere */}
                    <BarniereRdvVisa/>
                    
                </div>

                  <Footer />
              </>
          )}
      </div>
  )
}

export default VisaEtude