import axios from 'axios';

let Lieu : any = {};

Lieu.submitForm = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/culture/add`, data)
}

Lieu.submitFormPopUp = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/decouverte/culture/check/pub/update/popup`, data)
}

Lieu.getHomeData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/culture/home`)
}

Lieu.getAllData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/culture/all`)
}

Lieu.submitReservation = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/culture/send/reservation`, data)
}

Lieu.submitEdit = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/culture/edit`, data)
}

Lieu.getOne = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/culture/show/${id}`)
}

Lieu.getImages = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/culture/images/${id}`)
}


export default Lieu;