import React from 'react'
import { Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { FcCancel } from "react-icons/fc";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import {
    ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { RxDotsVertical } from "react-icons/rx";
import {
    Card,
    CardHeader,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import SidebarTop from '../SidebarTop';
import { GrMoney } from "react-icons/gr";
import { IoAddSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Transaction from '../../../API/tourisme/transaction/index'
import { TransactionProps} from "../../../props";





const TABLE_HEAD = ["Categorie","Client", "Titre & Section", "Date & lieu", "Montant",  "Action",];

const TABLE_ROWS = [
    {
        name: "Hotel Lorenzo",
        email: "info@gmail.com",
        portable:"+225 01 00 00 00 00",
        titre:"Achat de congelateur",
        date: "22/10/2024",
        lieu: "Abidjan, Cote d'ivoire",
        montant: "100000",
        section: "Restaurant",
        categorie:"Depense",
    },
    {
        name: "Adjagbe christia",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        titre:"Location de vehicule",
        date: "29/05/2024",
        lieu: "Abidjan, Cote d'ivoire",
        montant: "30000",
        section: "Vehicule",
        categorie:"Vente",
    },
    {
        name: "Africa digitalizer",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        titre:"Achat de ticket touristique",
        date: "06/09/2024",
        lieu: "Abidjan, Cote d'ivoire",
        montant: "400000",
        section: "Tourisme",
        categorie:"Depense",
    },
    {
        name: "Murphy micheal",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        titre:"Location d'hebergement",
        date: "03/01/2024",
        lieu: "Abidjan, Cote d'ivoire",
        montant: "30000",
        section: "Hebergement",
        categorie:"vente",
    },
    {
        name: "Mon bon sejour",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        titre:"Achat de ticket de cinema ",
        date: "22/10/2024",
        lieu: "Abidjan, Cote d'ivoire",
        montant: "9000000",
        section: "loisirs",
        categorie:"Depense",
    },
    {
        name: "vavoua eric",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        titre:"Restauration ",
        date: "22/10/2024",
        lieu: "Abidjan, Cote d'ivoire",
        montant: "9000000",
        section: "Restaurant",
        categorie:"vente",
    },
];


function Liste() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Transation'));

    const [records, setRecords] = useState<TransactionProps[]>([]);

  const [data, setData] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
});

    const [loading, setLoading] = useState(true);

    const getAllDepense = () => {
        Transaction.getVentes()
        .then((res:any) => {
            if (res.data.status === 200) {
                setRecords(res.data.data)
                console.log(res);
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {

        getAllDepense();


        //const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            //setLoading(false);
        //}, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        //return () => clearTimeout(timer);
    }, []);

    const handleDepense = () => {

        Transaction.getDepenses()
        .then((res:any) => {
            if (res.data.status === 200) {
                setRecords(res.data.data)
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })

    };

    const handleVente = () => {

        Transaction.getVentes()
        .then((res:any) => {
            if (res.data.status === 200) {
                setRecords(res.data.data)
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })

    };


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* sidebar top  */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                   Transaction
                                </Link>
                                <Link to="/Dashboard/Transation/Revenu" className="md:text-base s:text-xs">
                                   Vente & Depense
                                </Link>
                            </Breadcrumbs>
                        </div>

                        {/* container formulaire */}
                        <div className='flex flex-col space-y-8 justify-center md:p-10 s:p-4'>

                            <div className='flex md:flex-row s:flex-col  md:justify-between  '>
                                {/* Somme total */}
                                <div className='flex flex-row items-center justify-center space-x-2 s:text-base xs:text-xl bg-[#233D4D] xs:w-[450px] s:w-[260px] sm:w-[320px]  text-white h-20 rounded-md shadown-lg'>
                                    <GrMoney />     
                                    <div className='flex xs:flex-row s:flex-col'>
                                    <p>Chiffre d'affaire : </p><p className='font-bold pl-3'>800.000.000.000 XOF</p>
                                    </div>
                                </div>

                                {/* boutton ajouter achats */}
                                <div className='p-6  w-[230px]' >
                                <Link to="/Dashboard/Transation/Ajouter">
                                    <button className='flex flex-row items-center justify-center h-10  space-x-2 bg-[#FCCA46] text-white md:text-base s:text-sm md:w-[200px] s:w-[190px] transform active:scale-75 transition-transform'>
                                    <IoAddSharp />
                                    <p>Ajouter une transaction</p>
                                    </button>
                                </Link>
                                </div>
                            </div>

                           <div className='flex md:flex-row s:flex-col  md:justify-between '>
                                <div className='flex items-center space-x-4'> 
                                    <p className='text-lg'>Filtrer par date : </p>
                                    <input type='date' className='border-none'/>
                                </div>

                                <div className='flex items-center space-x-4'> 
                                    <p className='text-lg'>Afficher : </p>
                                    <button onClick={() => handleDepense()} className='bg-[#4E4E4E] text-white h-10 shadown-lg w-[130px] rounded transform active:scale-75 transition-transform'>Depense</button>
                                    <button onClick={() => handleVente()} className='bg-white text-[#4E4E4E] h-10 shadown-lg w-[130px] rounded transform active:scale-75 transition-transform'>Vente</button>
                                </div>
                                
                           </div>

                            <Card className="h-full w-full border-t-8 border-[#fe7f2d] ">
                                <CardHeader floated={false} shadow={false} className="rounded-none">
                                    <Typography className='md:text-xl s:text-lg font-semibold' color="blue-gray">
                                        Liste 
                                    </Typography>
                                </CardHeader>
                                <CardBody className="overflow-scroll px-0">
                                    <table className="mt-4 w-full min-w-max overflow-scroll  table-auto text-left">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head, index) => (
                                                    <th
                                                        key={head}
                                                        className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                                    >
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                                        >
                                                            {head}{" "}
                                                            {index !== TABLE_HEAD.length - 1 && (
                                                                <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                                            )}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        {records.length === 0 && (
                      <tbody>
                    <Typography variant="small" color="blue-gray" className="font-normal flex items-center">
                    Pas de donnees
                  </Typography>
                  </tbody> 
)}
{records.length > 0 && (
                                        <tbody>
                                            {records.map(
                                                (list, index) => {
                                                    const isLast = index === records.length - 1;
                                                    const classes = isLast
                                                        ? "p-4"
                                                        : "p-4 border-b border-blue-gray-50";

                                                    return (
                                                        <tr key={index} className='hover:bg-slate-100'>

                                                            <td className={classes}>
                                                                <div className="flex items-center space-x-2 ">
                                                                    <div className="flex flex-col">
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal"
                                                                        >
                                                                            {list?.Names}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal opacity-70"
                                                                        >
                                                                            {list?.['E-mails']}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal opacity-70"
                                                                        >
                                                                            {list?.Phones}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            
                                                            <td className={classes}>
                                                                <div className="flex flex-col">
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {list?.title_announce}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {list?.fr_title}
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <div className="flex flex-col">
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {list?.created_date}
                                                                    </Typography>
                                                                    {/* <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {lieu}
                                                                    </Typography> */}
                                                                </div>
                                                            </td>
                                                            <td className={classes} >
                                                                <div className="w-max" >
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"

                                                                    >
                                                                        {list?.price_announce} XOF
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <Tooltip content="Action sur la publication">
                                                                    <IconButton variant="text">
                                                                        <Menu>
                                                                            <MenuHandler>
                                                                                <IconButton>
                                                                                    <RxDotsVertical />
                                                                                </IconButton>
                                                                            </MenuHandler>
                                                                            <MenuList className="flex flex-col gap-2">

                                                                                 {/* modifier */}
                                                                                <Link to="/Dashboard/Transation/Revenu/Modifier">
                                                                                <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                                    <FiEdit className='text-lg' />
                                                                                    <div className="flex flex-col gap-1">
                                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                                        Modifier
                                                                                    </Typography>
                                                                                    </div>
                                                                                </MenuItem>
                                                                                </Link>

                                                                                {/* supprimer */}
                                                                                <Link to="">
                                                                                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                                        <FcCancel className='text-lg' />
                                                                                        <div className="flex flex-col gap-1">
                                                                                            <Typography variant="small" color="gray" className="font-semibold">
                                                                                              supprimer
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                </Link>
                                                                            </MenuList>
                                                                        </Menu>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                        )}
                                    </table>
                                </CardBody>
                                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        Page 1 of 10
                                    </Typography>
                                    <div className="flex gap-2">
                                        <Button variant="outlined" size="sm">
                                            Previous
                                        </Button>
                                        <Button variant="outlined" size="sm">
                                            Next
                                        </Button>
                                    </div>
                                </CardFooter>
                            </Card>

                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Liste