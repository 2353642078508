import React from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import paradis from '../../../ASSETS/tourisme/Image/accueilHebergement.png'
import paradis2 from '../../../ASSETS/tourisme/Image/living-room-mid-century-style-with-warm-colors-ai-generative.jpg'
import paradis3 from '../../../ASSETS/tourisme/Image/luxury-bedroom-hotel.jpg'
import paradis4 from '../../../ASSETS/tourisme/Image/3d-rendering-white-wood-living-room-near-bedroom-upstair.jpg'
import "pure-react-carousel/dist/react-carousel.es.css";
import {Link} from 'react-router-dom'

/* Install pure-react-carousel using -> npm i pure-react-carousel */

export default function Index() {

  

  return (
    <div className=" mx-auto bg-[#233D4D] mb-20">
      <div className="flex items-center justify-center w-full h-full py-24 sm:py-8 px-4">
        {/* Carousel for desktop and large size devices */}
        <CarouselProvider className="md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={4} step={1} infinite={true}>
          <div className="w-full relative flex items-center justify-center">
            <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
              <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </ButtonBack>
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
              <Slider>
                <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                  <Slide index={0}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 1</h2>
                        <Link to="">
                          <div className="flex h-full items-end pb-6 ">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Basilique de Yamoussokro</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={1}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La cascade de Man</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={2}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 3</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La mosque de kouto</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={3}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 4</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La forêt du banco</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={4}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 5</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Cathédrale Saint Paul</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={5}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 6</h2>
                        <Link>
                        <div className="flex h-full items-end pb-6">
                          <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Le fort de Dabou</h3>
                        </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={0}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 1</h2>
                        <Link to="">
                          <div className="flex h-full items-end pb-6 ">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Basilique de Yamoussokro</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={1}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La cascade de Man</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={2}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 3</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La mosque de kouto</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={3}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 4</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La forêt du banco</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={4}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 5</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Cathédrale Saint Paul</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={5}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 6</h2>
                        <Link>
                        <div className="flex h-full items-end pb-6">
                          <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Le fort de Dabou</h3>
                        </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                 
                  
                </div>
              </Slider>
            </div>
            <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
              <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>

        {/* Carousel for tablet and medium size devices */}
        <CarouselProvider className="md:hidden xs:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
          <div className="w-full relative flex items-center justify-center">
            <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
              <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </ButtonBack>
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
              <Slider>
              <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                  <Slide index={0}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 1</h2>
                        <Link to="">
                          <div className="flex h-full items-end pb-6 ">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Basilique de Yamoussokro</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={1}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La cascade de Man</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={2}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 3</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La mosque de kouto</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={3}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 4</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La forêt du banco</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={4}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 5</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Cathédrale Saint Paul</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={5}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 6</h2>
                        <Link>
                        <div className="flex h-full items-end pb-6">
                          <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Le fort de Dabou</h3>
                        </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={0}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 1</h2>
                        <Link to="">
                          <div className="flex h-full items-end pb-6 ">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Basilique de Yamoussokro</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={1}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La cascade de Man</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={2}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 3</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La mosque de kouto</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={3}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 4</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La forêt du banco</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={4}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 5</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Cathédrale Saint Paul</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={5}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 6</h2>
                        <Link>
                        <div className="flex h-full items-end pb-6">
                          <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Le fort de Dabou</h3>
                        </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                 
                  
                </div>
              </Slider>
            </div>
            <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
              <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>

        {/* Carousel for mobile and Small size Devices */}
        <CarouselProvider className="block xs:hidden " naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={1} step={1} infinite={true}>
          <div className="w-full relative flex items-center justify-center">
            <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
              <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </ButtonBack>
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
              <Slider>
                <div id="slider" className="h-full w-full flex lg:gap-8 md:gap-6 items-center justify-start transition ease-out duration-700">
                 
                  <Slide index={0}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 1</h2>
                        <Link to="">
                          <div className="flex h-full items-end pb-6 ">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Basilique de Yamoussokro</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={1}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La cascade de Man</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={2}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 3</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La mosque de kouto</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={3}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 4</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La forêt du banco</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={4}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 5</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Cathédrale Saint Paul</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={5}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 6</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Le fort de Dabou</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>

                  <Slide index={0}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 1</h2>
                        <Link to="">
                          <div className="flex h-full items-end pb-6 ">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Basilique de Yamoussokro</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={1}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 2</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La cascade de Man</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={2}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 3</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La mosque de kouto</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={3}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis2} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 4</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La forêt du banco</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={4}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis3} alt="black chair and white table" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 5</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">La Cathédrale Saint Paul</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={5}>
                    <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img src={paradis4} alt="sitting area" className="object-cover object-center w-full" />
                      <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                        <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white">Catalog 6</h2>
                        <Link>
                          <div className="flex h-full items-end pb-6">
                            <h3 className="text-lg lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">Le fort de Dabou</h3>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Slide>
                  
                </div>
              </Slider>
            </div>
            <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
              <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>
      </div>

      

    </div>
  );
}
