import React from 'react'
import { useState, FormEvent } from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../COMPONNENTS/Header/Header';
import Africa from '../../../ASSETS/tourisme/Image/A.D 2.png'
import { useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import sigin from '../../../ASSETS/CaImmigration/images/img_sigin.png'
import { ToastContainer, toast } from "react-toastify"
import Register from "../../../API/inscription/Inscription"
import left from "../../../ASSETS/CaImmigration/icones/back.png"

// Importation des pop ups
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//importation des button du pop up
import Button from '@mui/material/Button';
function Inscription() {

  //localStorage.setItem('url', JSON.stringify('/'));

  const navigate = useNavigate();

  const [data, setData] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
  });

  const [url, setUrl] = useState(() => {
    // getting stored value
    const link = localStorage.getItem("url");
    const value = link !== null ? JSON.parse(link) : null;
    return value;
  });

  const [nom, setNom] = useState("")
  const [pnom, setPnom] = useState("")
  const [tel, setTel] = useState("")
  const [mail, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const [password, setPassword] = useState("")
  const [mentionLegal, setMentionLegal] = useState(false)
  const [acceptNewsLetter, setAcceptNewsLetter] = useState(false)

  // Creer la varible déclencheuse du pop up
  const [openPolitique, setOpenPolitique] = React.useState(false);
  const [openLegal, setOpenLegal] = React.useState(false);
  const [acceptPolitique, setAcceptPolitique] = React.useState(false);
  const [acceptLegal, setAcceptLegal] = React.useState(false);

  // Pour le scroll dans le pop up
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  // fonction pour éteindre le pop up
  const handleClosePolitique = () => {
    setOpenPolitique(false);
  };
  const handleCloseLegal = () => {
    setOpenLegal(false);
  };

  // const handleClickOpenDialog = (e : any, scrollType: DialogProps['scroll']) => () => {
  //   setOpenPolitique(true);
  //   setScroll(scrollType);
  //   console.log(e.currentTarget.value)
  // };

    const [loading, setLoading] = useState(true);
    useEffect(() => {

      const user = localStorage.getItem('userNumber');
        //console.log(user);
        if (data) {
            navigate("/");
           }

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    function submit(e: FormEvent) {
      e.preventDefault()
      var templateParams = {
        nom: nom,
        pnom: pnom,
        tel: tel,
        mail: mail,
        password: password,
        mentionLegal: mentionLegal,
        acceptNewsLetter: acceptNewsLetter,
      };
      console.log(templateParams)
      setLoading(true)
      Register.submitForm(templateParams)
          .then((res: any) => {
            if(res.data.status === 201){
              localStorage.setItem('data', JSON.stringify(res.data.data));
              toast.success(res.data.message);
              navigate(url);
              url !== null ? navigate(url)  : navigate('/');
              //console.log(navigate(url));
            }else{
              toast.error(res.data.message);
              console.log(res);
            }
          })
          .catch((err: any) => {
              toast.error('Erreur merci de reessayer !');
              console.log(err);
          })
          .finally(() => {
            setLoading(false);
          })

  }

  const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (openPolitique) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openPolitique]);

  return (
    <div>
          {
        loading ? (
          // Afficher le spinner tant que loading est true
          <Loading />
        ) : (
          <>
          <Header/>
          
          <div>
            <ToastContainer />
          </div>
          <Link to='/homeCaimmigration'>
            <div className='flex items-center space-x-2 ml-6 relative top-8'>
              <img src={left} className='w-4' alt='arrow_left'/>
              <p className='text-lg text-[#233D4D] hover:text-[#fe7f2d]'>Retour à la page d'accueil</p>
            </div>
          </Link>

        <div className='my-20 flex xs:flex-row s:flex-col-reverse justify-center items-center '>

              {/* image */}
            <div className='bg-white shadow-3xl ' style={{backgroundImage:`url(${sigin})`, height:570, width:462}}>
                  <p className='text-3xl font-bold text-white text-center pt-24'>Bienvenue !</p>
              <div className='flex justify-center pt-10'>
                <p className='w-[250px] text-center text-white'>Vous avez déjà créer un compte ?
                  connectez-vous a partir de vos 
                  identifiants
                </p>
              </div>
              <Link to='/Connexion'>
              <div className='flex justify-center pt-12'>
                <button className='bg-[#fe7f2d] text-white px-10 h-10 text-lg rounded transform active:scale-75 transition-transform'>Connectez-vous</button>
              </div>
              </Link>
            </div>

            <div className='bg-white shadow-3xl xs:w-[500px] s:w-[310px] h-[570px]'>
                {/* logo */}
                <Link to='/'>
                <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                    <img src={logo1} className='h-16  s:pl-0 pt-6 ' alt='logo' />
                </div>
                </Link>
                <p className='text-2xl font-bold text-[#fe7f2d] text-center relative s:bottom-5 xs:bottom-14'>Créer un compte </p>

                <p className='text-center font-thin relative xs:bottom-10 s:bottom-5'>Pas encore de compte ? inscrivez-vous</p>
                <form action="" onSubmit={submit}>
                <div className='flex flex-col items-center pt-6 space-y-4 relative bottom-10'>

                        {/*Nom */}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-9 flex pl-3 space-x-2 items-center'>
                            {/* icone mail */}
                            <svg id="user1" xmlns="http://www.w3.org/2000/svg" width="20.655" height="25.699" viewBox="0 0 20.655 25.699">
                            <path id="Tracé_217" data-name="Tracé 217" d="M10.106,12.828A6.414,6.414,0,1,0,3.692,6.414,6.414,6.414,0,0,0,10.106,12.828Zm0-11.741A5.327,5.327,0,1,1,4.779,6.414,5.327,5.327,0,0,1,10.106,1.087Zm0,13.344A10.123,10.123,0,0,0,2.8,17.48,10.863,10.863,0,0,0-.221,25.155a.545.545,0,0,0,.544.543H19.89a.545.545,0,0,0,.543-.543,10.851,10.851,0,0,0-3.022-7.669,10.129,10.129,0,0,0-7.305-3.055ZM.877,24.612a9.708,9.708,0,0,1,2.7-6.365A9.056,9.056,0,0,1,10.1,15.524a9.055,9.055,0,0,1,6.528,2.723,9.681,9.681,0,0,1,2.7,6.365Zm0,0" transform="translate(0.221)" fill="#4e4e4e"/>
                            </svg>


                            <input name='nom' required value={nom} onChange={(e) => setNom(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' type='text' placeholder='Nom'/>
                        </div>
                         {/*prenom*/}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-9 flex pl-3 space-x-2 items-center'>
                            {/* icone mail */}
                            <svg id="user1" xmlns="http://www.w3.org/2000/svg" width="20.655" height="25.699" viewBox="0 0 20.655 25.699">
                            <path id="Tracé_217" data-name="Tracé 217" d="M10.106,12.828A6.414,6.414,0,1,0,3.692,6.414,6.414,6.414,0,0,0,10.106,12.828Zm0-11.741A5.327,5.327,0,1,1,4.779,6.414,5.327,5.327,0,0,1,10.106,1.087Zm0,13.344A10.123,10.123,0,0,0,2.8,17.48,10.863,10.863,0,0,0-.221,25.155a.545.545,0,0,0,.544.543H19.89a.545.545,0,0,0,.543-.543,10.851,10.851,0,0,0-3.022-7.669,10.129,10.129,0,0,0-7.305-3.055ZM.877,24.612a9.708,9.708,0,0,1,2.7-6.365A9.056,9.056,0,0,1,10.1,15.524a9.055,9.055,0,0,1,6.528,2.723,9.681,9.681,0,0,1,2.7,6.365Zm0,0" transform="translate(0.221)" fill="#4e4e4e"/>
                            </svg>

                            <input name='firstname' required value={pnom} onChange={(e) => setPnom(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' type='text' placeholder='Prénoms'/>
                        </div>
                        {/* portable */}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-9 flex pl-3 space-x-2 items-center'>
                            {/* icone mail */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.182" height="19.185" viewBox="0 0 19.182 19.185">
                              <g id="phone" transform="translate(-0.027)">
                                <path id="Tracé_13" data-name="Tracé 13" d="M12.216,12.483,9.735,14.964c-.522.522-2.174-.281-3.689-1.8S3.734,10.007,4.256,9.485L6.737,7,2.614,2.88.557,4.937c-1.514,1.514.327,5.817,4.118,9.6s8.088,5.633,9.6,4.113L16.335,16.6ZM9.617,0V1.918a7.683,7.683,0,0,1,7.674,7.674H19.21A9.592,9.592,0,0,0,9.617,0Z" transform="translate(0)" fill="#4e4e4e" />
                                <path id="Tracé_14" data-name="Tracé 14" d="M187.485,75v1.918a3.838,3.838,0,0,1,3.837,3.837h1.918A5.764,5.764,0,0,0,187.485,75Z" transform="translate(-177.868 -71.163)" fill="#4e4e4e" />
                              </g>
                            </svg>

                            <input required value={tel} onChange={(e) => setTel(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' type='tel' placeholder='Numero de téléphone'/>
                        </div>
                        {/* Mail */}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-9 flex pl-3 space-x-2 items-center'>
                            {/* icone mail */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.25" height="15.188" viewBox="0 0 20.25 15.188">
                            <g id="mail" transform="translate(0 -64)">
                                <path id="Tracé_218" data-name="Tracé 218" d="M18.563,64H1.688A1.689,1.689,0,0,0,0,65.688V77.5a1.689,1.689,0,0,0,1.688,1.688H18.563A1.689,1.689,0,0,0,20.25,77.5V65.688A1.689,1.689,0,0,0,18.563,64Zm-16.875.844H18.563a.8.8,0,0,1,.175.035c-1.461,1.337-6.305,5.769-8,7.3a.846.846,0,0,1-1.225,0c-1.7-1.527-6.54-5.959-8-7.3A.806.806,0,0,1,1.688,64.844ZM.844,77.5V65.688a.815.815,0,0,1,.047-.233c1.118,1.023,4.5,4.116,6.69,6.109C5.4,73.44,2.015,76.645.888,77.72a.813.813,0,0,1-.045-.22Zm17.719.844H1.688a.807.807,0,0,1-.19-.039c1.165-1.11,4.567-4.334,6.714-6.17l.737.667a1.685,1.685,0,0,0,2.355,0l.737-.667c2.146,1.836,5.549,5.06,6.714,6.17A.806.806,0,0,1,18.563,78.344Zm.844-.844a.815.815,0,0,1-.045.22c-1.128-1.075-4.508-4.281-6.693-6.157,2.192-1.993,5.572-5.085,6.69-6.109a.815.815,0,0,1,.047.233Z" fill="#4e4e4e"/>
                            </g>
                            </svg>

                            <input required value={mail} onChange={(e) => setEmail(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' type='email' placeholder='Adresse mail'/>
                        </div>

                        {/*password */}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-9 flex pl-3 space-x-2 items-center'>
                            {/* icone password */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.478" height="19.597" viewBox="0 0 15.478 19.597">
                              <g id="Groupe_36" data-name="Groupe 36" transform="translate(-985 -805.403)">
                                  <path id="Tracé_219" data-name="Tracé 219" d="M696.116,868.536a2.255,2.255,0,0,0-1.154,4.193v1.838h2.307v-1.838a2.255,2.255,0,0,0-1.154-4.193Zm.663,3.674-.2.093v1.574h-.93V872.3l-.2-.093a1.566,1.566,0,1,1,1.327,0Z" transform="translate(296.623 -53.679)" fill="#4e4e4e"/>
                                  <path id="Tracé_220" data-name="Tracé 220" d="M204.753,11.052V8.706a4.45,4.45,0,0,0-8.9,0V9.938h.689V8.713a3.762,3.762,0,0,1,7.523,0v2.342h-11.5v5.187a7.74,7.74,0,0,0,15.478,0V11.052Zm2.6,5.18a7.051,7.051,0,0,1-14.1,0V11.74h14.1v4.491Z" transform="translate(792.436 801.102)" fill="#4e4e4e"/>
                              </g>
                            </svg>
                            <input required value={password} onChange={(e) => setPassword(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' type='password' placeholder='Mot de passe'/>
                        </div>

                        {/*password */}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-9 flex pl-3 space-x-2 items-center'>
                            {/* icone password */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="15.478" height="19.597" viewBox="0 0 15.478 19.597">
                            <g id="Groupe_36" data-name="Groupe 36" transform="translate(-985 -805.403)">
                                <path id="Tracé_219" data-name="Tracé 219" d="M696.116,868.536a2.255,2.255,0,0,0-1.154,4.193v1.838h2.307v-1.838a2.255,2.255,0,0,0-1.154-4.193Zm.663,3.674-.2.093v1.574h-.93V872.3l-.2-.093a1.566,1.566,0,1,1,1.327,0Z" transform="translate(296.623 -53.679)" fill="#4e4e4e"/>
                                <path id="Tracé_220" data-name="Tracé 220" d="M204.753,11.052V8.706a4.45,4.45,0,0,0-8.9,0V9.938h.689V8.713a3.762,3.762,0,0,1,7.523,0v2.342h-11.5v5.187a7.74,7.74,0,0,0,15.478,0V11.052Zm2.6,5.18a7.051,7.051,0,0,1-14.1,0V11.74h14.1v4.491Z" transform="translate(792.436 801.102)" fill="#4e4e4e"/>
                            </g>
                          </svg>
                            <input value={pass} onChange={(e) => setPass(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' type='password' placeholder='Confirmer Mot de passe'/>
                        </div>

                        {/*Checkbox-conditions */}
                        <div className='flex w-[280px]  h-5 xs:w-[360px] pl-3'>
                            <div className=''>
                                    <input required  className='form-check-input w-[12px] h-[12px] text-sm me-2 focus:outline-none' checked={mentionLegal} 
                                      onClick={(e)=>{
                                        if(e.currentTarget.checked)
                                        {
                                          setOpenPolitique(true)
                                          setOpenLegal(true)
                                        }else{
                                          setMentionLegal(false)
                                        }
                                      }}
                                      type='checkbox'
                                    />
                                </div>
                                <div className=''>
                                    <label className='text-start text-sm font-thin flex items-center' >
                                      J'accepte les conditions d'utilisation.
                                    </label>
                                </div>              
                        </div>

                        {/*Checkbox-news-letter */}
                        <div className='flex pt-2 w-[280px] h-5 xs:w-[360px] pl-3'>
                            <div className=''>
                                <input className='form-check-input w-[12px] h-[12px] text-sm me-2 focus:outline-none' checked={acceptNewsLetter} onChange={(e) => setAcceptNewsLetter(e.currentTarget.checked)} type='checkbox'/>
                            </div>
                            <div className=''>
                                <label className='text-start text-sm font-thin'>S'inscrire à notre news letter.</label>
                            </div>
                        </div>

                        <span id="msgerror" className="text-danger"> </span>
                </div>

                <button className='border-[#fe7f2d] border-solid border-2 text-[#fe7f2d] ml-16 mt-4 relative bottom-12 px-10 h-10 text-lg rounded transform active:scale-75 transition-transform'>S'inscrire</button>
                </form>
            </div>
        </div>




        <div className='bg-black w-screen text-white text-sm h-10 flex justify-center items-center font-light'>
            @ Design by <img className='w-12 px-2' src={Africa} alt='logoAfrica'/> Africa Digitalizer
        </div>
         </>
        )}
        {/* Le pop up de politique */}
        <Dialog
                open={openPolitique}
                onClose={handleClosePolitique}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">

                <DialogTitle id="scroll-dialog-title" className='text-xl font-semibold text-orange-500' >Politique de confidentialité</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                                                
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}>
                        {[...new Array(50)]
                        .map(
                        () => `Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                        Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                        )
                        .join('\n')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='transform active:scale-75 transition-transform' style={{backgroundColor:"#233D4D", color:"white"}} onClick={handleClosePolitique}>Retour</Button>
                    <Button className='transform active:scale-75 transition-transform' style={{border:"2px solid #233D4D", color:"#233D4D"}} 
                    onClick={()=>{
                      setAcceptPolitique(true);
                      setOpenPolitique(false);
                      
                      if(acceptLegal)
                      {
                        setMentionLegal(true)
                      }
                    }}>Accepter</Button>
                </DialogActions>
        </Dialog>
            {/* Le pop up de legal */}
            <Dialog
                open={openLegal}
                onClose={handleCloseLegal}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">

                <DialogTitle id="scroll-dialog-title" className='text-xl font-semibold text-orange-500' >Legal de confidentialité</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                                                
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}>
                        {[...new Array(50)]
                        .map(
                        () => `Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                        Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                        )
                        .join('\n')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='transform active:scale-75 transition-transform' style={{backgroundColor:"#233D4D", color:"white"}} onClick={handleCloseLegal}>Retour</Button>
                    <Button className='transform active:scale-75 transition-transform' style={{border:"2px solid #233D4D", color:"#233D4D"}} 
                    onClick={()=>{
                      // mettre la variable accepteLegal à true
                      setAcceptLegal(true);

                      //fermer le pop up legal
                      setOpenLegal(false);
                    }}>Accepter</Button>
                </DialogActions>
            </Dialog>
    </div>
  )
}

export default Inscription