import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import SidebarTop from '../../SidebarTop';
import OM from '../../../../ASSETS/tourisme/icone/orange-money-logo-8F2AED308D-seeklogo.com.png'
import MOMO from '../../../../ASSETS/tourisme/icone/1678624190074.png'
import WAVE from '../../../../ASSETS/tourisme/icone/0PZcR8OO_400x400.jpg'
import MOOV from '../../../../ASSETS/tourisme/icone/unnamed.png'
import Visa from '../../../../ASSETS/tourisme/icone/Visa-logo.jpg'
import MasterCard from '../../../../ASSETS/tourisme/icone/MasterCard-Logo-1-1024x768.jpg'
import paiement from '../../../../ASSETS/tourisme/Image/65Z_2201.w012.n001.25B.p12.25-removebg-preview.png'

function Recharge() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee] '>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>

          <div className='w-screen  barre_scroll h-screen'>
            {/* sidebar top  */}
            <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs >
                <Link to="" className="opacity-60">
                  Achat de credit
                </Link>
                <Link to="" className="opacity-60">
                  Recharge
                </Link>
                
              </Breadcrumbs>
            </div>
            {/* container formulaire */}
            <div className='  h-screen md:p-10 s:p-4 space-y-5  '>
              <div className='flex s:flex-col s:space-y-10 md:space-y-0 md:flex-row md:space-x-28'>
                <div className='flex flex-col space-y-5'>
                    <div className='md:text-4xl s:text-3xl font-semibold text-[#4e4e4e] '>
                      Rechargement de la carte Credit AZ
                    </div>
                    <div className='md:w-[800px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing 
                        elit. Ut consectetur orci massa, in posuere felis 
                        condimentum sagittis. Cras posuere, dui eu feugiat 
                        dignissim, dolor turpis sollicitudin nulla, sed interdum 
                        diam neque a sem. Aenean viverra lacus vitae imperdiet laoreet. 
                        Praesent ac eros at risus condimentum semper.
                    </div>
                    <hr className='md:w-[800px]'/>
                    <div className='text-3xl font-semibold text-[#4e4e4e]'>Option de recharge</div>
                    <div className='md:w-[800px]'>
                        Vestibulum viverra leo ut purus interdum faucibus. Morbi a vulputate justo. 
                        Praesent viverra convallis nunc, id fermentum tellus efficitur eget.
                    </div>
                </div>
                <img src={paiement} alt='paiement' className='w-[400px]'/>
              </div>

             <div className='flex s:flex-col s:space-y-10 md:space-y-0 md:flex-row md:space-x-10'>
                {/* PAR CARTE BANCAIRE */}
                <form className='flex flex-col space-y-5'>
                  <div className='flex flex-row items-center space-x-2'>
                    <input type='radio' name='Option_paiement' value='bancaire'/>
                    <div>Par Carte bancaire</div>
                  <div className='flex flex-row items-center space-x-3'>
                    <img src={Visa} alt='Visa' className='w-14'/>
                    <img src={MasterCard} alt='MasterCard' className='w-14'/>
                  </div>
                  </div>
                <div className='flex flex-col space-y-2'>
                  <label className='text-lg'>Nom du propriétaire de la carte</label>
                  <input type='text' name='numero' className='xs:w-[400px] '/>
                </div>
                <div className='flex flex-col space-y-2'>
                  <label className='text-lg'>Numero de carte</label>
                  <input type='text' name='numero' className='xs:w-[400px] '/>
                </div>
                <div className='flex s:flex-col s:space-y-6 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-6'>
                  <div className='flex flex-col space-y-2'>
                    <label className='text-lg'>Date d'expiration</label>
                    <input type='month' name='numero' className='w-[150px] '/>
                  </div>
                  <div className='flex flex-col space-y-2'>
                    <label className='text-lg'>CVC</label>
                    <input type='number' name='numero' className='w-[150px] '/>
                  </div>
                </div>
                <button className='bg-[#FE7F2D] w-[150px] text-white text-lg h-10 rounded '>Recharger</button>
                </form>
                {/* PAR MOBILE MONEY */}
                <form className='flex flex-col space-y-5'>
                  <div className='flex flex-row xs:items-center space-x-2'>
                    <input type='radio' name='Option_paiement' value='bancaire'/>
                    <div>Par Mobile Money</div>
                  <div className='grid xs:grid-cols-4 s:grid-cols-2 place-content-center place-items-center gap-3'>
                    <img src={OM} alt='OM' className='w-14'/>
                    <img src={MOMO} alt='MOMO' className='w-14'/>
                    <img src={WAVE} alt='WAVE' className='w-14'/>
                    <img src={MOOV} alt='MOOV' className='w-14'/>
                  </div>
                  </div>
                <div className='flex flex-col space-y-2'>
                  <label className='text-lg'>Numero a debiter</label>
                  <input type='tel' name='numero' className='xs:w-[400px] '/>
                </div>
                <button className='bg-[#FE7F2D] w-[150px] text-white text-lg h-10 rounded '>Recharger</button>
                </form>
             </div>
              
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Recharge