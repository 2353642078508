import React from 'react'
import { IoIosAddCircleOutline } from "react-icons/io";
import {Link} from 'react-router-dom'
import img_restau from '../../../ASSETS/tourisme/Image/image_restaurants/vue-dessus-restauration-rapide-dans-assiette-poulet-au-citron-aux-herbes-dans-assiette-cote-bols-sauces-au-poivre-noir-frites-cote-gauche-ta.png'

function Barniere() {
  return (
    <div>
        {/* barre bleu */}
        <div className='flex justify-end'>
            <div className='border-t-4 border-slate-900 py-10 w-2/3'></div>
        </div>

        <div style={{ backgroundImage:`url(${img_restau})` }} className='bg-cover bg-center bg-no-repeat w-screen h-[400px] mb-10  flex justify-end items-center xs:pr-6 '>
            <div className='flex flex-col space-y-6 items-center'>
                <p className='sm:text-3xl s:text-xl font-medium s:w-[260px] sm:w-[400px] text-white text-center'>Avec Mon Bon Séjour vous pouvez enregistrer votre restaurant</p>
                <Link to="/Dashboard/Publications/Restaurant/Ajouter_des_restaurants">
                    <button className=' text-white bg-[#233D4D] hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 px-4 h-10 flex items-center space-x-2'>
                        <IoIosAddCircleOutline className='text-lg' />
                        <p>Ajouter un restaurant</p>
                    </button>
                </Link>
            </div>

        </div>
    </div>
  )
}

export default Barniere