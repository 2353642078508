import React from 'react'
import {Link} from 'react-router-dom'

function TestEligibilite() {
  return (
    <div>
      <Link to="/TestEligibilite">
              <div className='transform active:scale-75 s:px-4 md:px-0 transition-transform flex flex-row items-center justify-center md:ml-28 sm:ml-10 s:ml-6 md:mt-28 s:mt-14 shadow-lg xs:w-[500px] sm:w-[280px] s:w-[230px] space-x-10 md:h-16 s:h-14 rounded-xl ' style={{ backgroundColor: "#F3F3F3" }}>
                  <div className='md:text-lg sm:text-sm s:text-xs font-semibold' style={{ color: "#233D4D" }}>Tester votre éligibilité au visa ou permis</div>

                  <div><svg xmlns="http://www.w3.org/2000/svg" width="19.173" height="25.244" viewBox="0 0 29.173 45.244">
                      <g id="left" transform="translate(-1.974 -1.315)">
                          <path id="Tracé_40" data-name="Tracé 40" d="M5.427,1.316a3.223,3.223,0,0,0-2.116,5.7L23,23.876,3.3,40.732a3.223,3.223,0,1,0,4.2,4.865L30.018,26.344a3.223,3.223,0,0,0,0-4.9L7.494,2.13a3.223,3.223,0,0,0-2.068-.815Z" transform="translate(0 0)" fill="#fe7f2d" />
                      </g>
                  </svg>
                  </div>
              </div>
          </Link>
    </div>
  )
}

export default TestEligibilite