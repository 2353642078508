import React from 'react'
import Footer from '../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import BackImage from './BackImage';
import botanique from '../../../ASSETS/tourisme/Image/image_loisirs/botanique.png'
import galerie from '../../../ASSETS/tourisme/Image/image_loisirs/galerieCecile.png'
import mall from '../../../ASSETS/tourisme/Image/image_loisirs/Mall.png'
import cosmos from '../../../ASSETS/tourisme/Image/image_loisirs/cosmos.png'
import sofitel from '../../../ASSETS/tourisme/Image/image_loisirs/sofitel-abidjan-hotel.png'
import muse from '../../../ASSETS/tourisme/Image/image_loisirs/Musée_des_civilisations1.png'
import { GiMoneyStack } from "react-icons/gi";
import { IoMdPin } from "react-icons/io";
import Barniere from './Barniere';
import {Link} from 'react-router-dom'
import Culture from '../../../API/tourisme/loisir/index'
import { AnnoncesProps} from "../../../props";

function Loisirs() {

    function truncate (str : any) {
        return str.length > 200 ? str.substring(0, 200) + "..." : str;
    }

    const [loisir, setLoisir] = useState<AnnoncesProps[]>([]);

    const [loading, setLoading] = useState(true);

    const getAllLoisir = () => {
        Culture.getAllData()
          .then((res:any) => {
              if (res.data.status === 200) {
                setLoisir(res.data.data)
              }
          })
          .catch((err:any) => {
              console.log(err);
          })
          .finally(() => {
              setLoading(false);
          })
      }

    useEffect(() => {

        getAllLoisir();

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div>
          {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
          
          <BackImage/>

          {/* filtre */}
            <div className='flex s:flex-col xs:flex-row xs:space-x-4 s:space-y-4 xs:space-y-0  pl-10 pt-8'>
              <button className='w-[200px] h-10 bg-[#233D4D] text-lg text-white  hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Tout</button>
              <button className='w-[200px] h-10 border-[#233D4D] border-2 text-lg text-[#233D4D]  hover:bg-[#233D4D] hover:text-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Les plus populaires</button>
              <button className='w-[200px] h-10 border-[#233D4D] border-2 text-lg text-[#233D4D]  hover:bg-[#233D4D] hover:text-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Recommandée</button>
            </div>

            {/* Les activitées */}
            <div className='my-10 grid md:grid-cols-3 xs:grid-cols-2 s:grid-cols-1 gap-y-14  place-items-center'>
            {loisir.map((list, index )=> (
                <div className='xs:w-[360px] sm:w-[320px] s:w-[260px]'>
                    <img className='w-[360px] rounded' src={`data:image/png;base64,${list[0]}`} alt={list.title_announce} />
                    {/* description */}
                    <p className='text-lg text-[#fe7f2d] font-semibold py-3'>{list.title_announce}</p>
                    <p className='xs:w-[350px] sm:w-[320px] s:w-[270px]'>{truncate(list.description_announce)}</p>

                    {/* lieu et prix */}
                    <div className='flex xs:flex-row s:flex-col xs:items-center xs:space-x-3 s:space-y-3 xs:space-y-0 py-4'>
                        <div className='font-thin flex flex-row items-center  space-x-2 '>
                        <IoMdPin className='text-xl text-[#fe7f2d]' />
                        <p>{list.contact_address}</p>
                        </div>
                        <div className=' font-thin flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-green-600' />
                            <p > {list.price_announce} FCFA</p>
                        </div>
                    </div>

                   <div className='flex justify-between'>
                         {/* Acheter un tiket */}
                        <Link to='/Reservation_Loisirs'
                        state={ 
                            list.id_announce 
                           }
                        >
                            <div className='grid place-content-end'>
                                <button className='hover:bg-[white] w-[150px] h-10 rounded bg-[#fe7f2d]  text-[white] hover:text-[#fe7f2d] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Acheter un ticket</button>
                            </div>
                        </Link>
                        {/* decouvrir */}
                        <Link to="/Loisirs_Activite/Voir_Plus"
                        state={ 
                            list.id_announce 
                           }
                        >
                        <div className='grid place-content-end'>
                            <button className='bg-[#233D4D] w-[100px] h-10 text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Decouvrir</button>
                        </div>
                        </Link>
                   </div>
                </div>
                )
                )}
            </div>

           <Barniere/>

          <Footer/>
        </>
      )}
    </div>
  )
}

export default Loisirs