import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../../COMPONENTS/Loading/Loading'
import Footer from '../../../../COMPONENTS/Footer/Footer'
import Carousel from "../Carousel";
import img1 from "../../..//../../ASSETS/tourisme/Image/BasiliqueYakro/94d61968-yamoussoukro_basilique.png";
import img2 from "../../../../../ASSETS/tourisme/Image/BasiliqueYakro/depositphotos_356398402-stock-photo-ivory-coast-basilica-of-our.png";
import img3 from "../../..//../../ASSETS/tourisme/Image/BasiliqueYakro/basilica-of-our-lady.png";
import img4 from "../../../../../ASSETS/tourisme/Image/BasiliqueYakro/Basilique-Notre-Dame-de-la-Paix.png";
import BackImage from '../../BackImage';
import BarniereCD from '../../BarniereCD';
import { IoIosArrowRoundForward } from "react-icons/io";
import Suggestion from '../Suggestion'
import { MdOutlinePayment } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import {Link} from 'react-router-dom'



function BasiliqueYakro() {

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const slides = [img1, img2, img3, img4];
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>

                  {/* Back image */}
                  <BackImage />

                  {/* barre bleu */}
                  <div className='border-t-4 border-slate-900 py-10 w-2/3'></div>

                  {/* container1 */}
            <div className='grid md:gap-8 md:grid-cols-2 place-items-center md:px-10 s:gap-10  xs:grid-cols-2 sm:grid-cols-1 pb-10'>
                <div className='space-y-6 md:w-full s:w-[260px] sm:w-[340px]'>
                    <p className='md:text-xl font-medium text-orange-400 '>La Basilique de yamossokro</p>

                    <div className="card md:text-base sm:text-sm flex-col space-y-3">
                        <p>
                            La basilique Notre-Dame-de-la-Paix, située à Yamoussoukro, capitale politique 
                            de la Côte d'Ivoire⁣⁣⁣, est l'une des plus grandes églises catholiques du monde 
                            et le deuxième plus haut édifice chrétien du monde derrière l'église principale
                            de Ulm. Elle est construite à l'initiative du premier président du pays, 
                            Félix Houphouët-Boigny, en 1983. Son apparence rappelle celle de la basilique 
                            Saint-Pierre à Rome. Le recteur de la basilique est Franck Allatin.
                        </p>

                        <p>La basilique Notre-Dame-de-la-Paix est construite à Yamoussoukro, capitale 
                            politique et administrative de la Côte d'Ivoire, sur les plans de l'architecte 
                            ivoirien Pierre Fakhoury, choisi par le président Félix Houphouët-Boigny, le 4
                            février 1986, à l'issue d'un concours organisé entre 1984 et 1986.
                        </p>

                        {isShowMore && (
                        <p>
                            La basilique est réalisée par vingt-quatre entreprises ivoiriennes et 
                            étrangères entre 1986 et 1989. C'est la société Eiffel Construction 
                            Métallique qui réalise le dôme, le plus grand jamais construit. Le 
                            chantier est placé sous le contrôle d'Antoine Cesareo, directeur des 
                            grands travaux de la République de Côte d'Ivoire...
                        </p>
                        )}

                        <button className='text-[#FE7F2D] flex flex-row space-x-2 items-center' onClick={toggleReadMoreLess}>
                        {isShowMore ? "Read Less" : "Read More"}
                        <IoIosArrowRoundForward className="text-lg " />
                        </button>
                    </div>
                              


                    {/* Lieu */}
                    <div className='flex items-center space-x-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.161" height="32.741" viewBox="0 0 35.161 52.741">
                            <g id="localisation" transform="translate(-85.333)">
                                <path id="Tracé_149" data-name="Tracé 149" d="M102.913,0a17.582,17.582,0,0,0-15.47,25.935l14.508,26.24a1.1,1.1,0,0,0,1.923,0l14.514-26.248A17.583,17.583,0,0,0,102.913,0Zm0,26.37a8.79,8.79,0,1,1,8.79-8.79A8.8,8.8,0,0,1,102.913,26.37Z" fill="#233d4d" />
                            </g>
                        </svg>
                        <p className='text-slate-800 font-medium'>Lieu : <span className=' font-light'>Yamoussokro, Côte d'ivoire</span></p>
                    </div>

                    <div className='flex items-center space-x-2'>
                        <MdOutlinePayment className='text-lg text-[#FE7F2D]' />
                        <p><span className=' font-semibold'>Tarif du guide :</span> 10.000 XOF / Heure</p>
                    </div>
                    <Link to='/Reservation_Tourisme' className='pt-3'>
                        <button className='transform active:scale-75 transition-transform text-sm flex items-center space-x-2 font-bold bg-orange-400 px-2 h-8 rounded  text-white '>
                            <p>Reserver un guide</p>
                            <MdPerson className='text-lg' />
                        </button>
                    </Link>
                </div>

                {/* Caroussel */}
                <div className="relative md:w-[400px]  s:w-[260px] sm:w-[340px] ">
                    <div className="max-w-lg">
                        <Carousel slides={slides} />
                    </div>
                </div>

            </div>

                    <div className='border-t-4 border-slate-900 py-10 w-2/3 grid place-items-end'></div>


                  {/* suggestions */}
                  <div className='space-y-4 flex flex-col items-center pb-14'>
                      <p className='text-xl font-semibold'>Suggestions</p>
                      <p className=' md:w-[900px] xs:w-[700px] s:w-[260px] sm:w-[340px] text-center md:text-base s:text-sm'>Découvrez d'autre sites touristique de la côte d'ivoire ut galisum provident At voluptate galisum non eveniet similique aut impedit consectetur qui quia quia. Ut ipsam accusamus ut odio quos et reiciendis placeat ea accusantium numquam ulpa ut officia consequatur. Id ducimu</p>
                  </div>
                  <Suggestion />

                  {/* Banniere culture et decouverte */}
                  <BarniereCD />

                  <Footer />
              </>
          )}

    </div>
  )
}

export default BasiliqueYakro