
import { useState } from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import { Link } from 'react-router-dom'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visa from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilier from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Footer from '../../COMPONNENTS/Footer/Footer'
import { useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENTS/Header/Header';




const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },

}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#fe7f2d' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#fe7f2d',
  fontSize: '12rem',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function FaqPage() {

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 1000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);


  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  let [open, setOpen] = useState(false);
  return (

   

    <div >
      {
        loading ? (
          // Afficher le spinner tant que loading est true
          <Loading />
        ) : (
          <>
      <Header/>
      {/* navabar */}
      <div  className=' bg-[#233D4D] s:relative s:bottom-2  sm:bottom-5 md:bottom-0 flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
      <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center justify-between'>

        <Link to='/'>
          <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
            <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
          </div>
        </Link>
        <div onClick={() => setOpen(!open)} className=' lg:hidden text-end s:text-3xl pr-4 s:text-white cursor-pointer'>
          <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
        </div>
      </div>
      {/* element_menu */}
      <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5 s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

        <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>

          {/* Accueil */}
          <Link to='/homeCaimmigration'>
            <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
              <div>
                <img className='w-6' src={home} alt='' />
              </div>
              <div> Accueil</div>
            </div>
          </Link>

          {/* Visa */}

          <div style={{ fontSize: "16px" }} className='relative top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
            <div className='relative bottom-1'>
              <img className='w-4' src={visa} alt='' />
            </div>
            <div className='navbar1 '>
              <ul>
                <li> <Link to='/visa'> Visa & Permis
                  <ul className='z-10  '>
                    <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaVisiteur'> Visa visiteur </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                  </ul>
                </Link>
                </li>
              </ul>
            </div>
          </div>


          {/* Résidence permanente */}
          <Link to='/ResidencePermante'>
            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
              <div>
                <img className='w-6' src={Rpermanent} alt='' />
              </div>
              <div>Résidence permanente</div>
            </div>
          </Link>

          {/*Citoyenneté Canadienne */}
          <Link to='/CitoyenneteCa'>
            <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
              <div>
                <img className='w-6' src={CitoyenCA} alt='' />
              </div>
              <div> Citoyenneté Canadienne</div>
            </div>
          </Link>

          {/* Achat de biens immobilers */}
          <Link to='/BienImmobilier'>
            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
              <div>
                <img className='w-6' src={immobilier} alt='' />
              </div>
              <div>Achat de biens immobilers</div>
            </div>
          </Link>
        </div>
      </div>
      </div> 

      

      <div className='md:px-12 s:px-5 my-10'>
          <div style={{ color: '#4e4e4e' }} className='uppercase text-2xl font-semibold pb-8'>questions fréquentes</div>

    
         <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography >Quel est le moyen le plus facile d'immigrer au Canada ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                     Immigrer au Canada peut être un processus complexe, et il n'y a pas de moyen
                     unique et facile pour tout le monde, car les exigences d'immigration varient 
                     en fonction de votre situation personnelle, de votre expérience et de votre 
                     objectif d'immigration. Cependant, voici quelques-unes des voies les plus 
                     courantes pour immigrer au Canada :<br/><br/>
                     <p>
                      1- <b>Programme d'immigration économique :</b> Le Canada propose plusieurs programmes
                       d'immigration économique, notamment le Programme des travailleurs qualifiés, 
                       le Programme des travailleurs de métiers spécialisés, le Programme de l'expérience 
                       canadienne, le Programme des candidats des provinces, et d'autres. Ces programmes 
                       sont conçus pour attirer des travailleurs qualifiés, des entrepreneurs et des investisseurs.
                     </p><br/>
                     <p>
                      2- <b>Regroupement familial :</b> Si vous avez des membres de votre famille au 
                      Canada qui sont citoyens canadiens ou résidents permanents, vous 
                      pourriez être admissible à immigrer au Canada grâce à une demande de 
                      parrainage familial.
                     </p><br/>
                     <p>
                      3- <b>Programme de réfugiés :</b> Si vous craignez des persécutions dans votre pays
                      d'origine, vous pourriez être admissible à demander l'asile au Canada.
                     </p><br/>
                     <p>
                      4- <b>Études :</b> Vous pouvez obtenir un permis d'études pour étudier au Canada, et après 
                      l'obtention de votre diplôme, vous pourriez être admissible à un permis de travail
                       postdiplôme ou à un programme de résidence permanente.
                     </p><br/>
                     <p>
                      5- <b>Investissement :</b> Si vous êtes un entrepreneur ou un investisseur, 
                      certaines provinces canadiennes offrent des programmes spécifiques 
                      pour les entrepreneurs et les investisseurs qui souhaitent créer ou investir 
                      dans des entreprises au Canada.
                     </p><br/>
                     <p>
                      6- <b>Programme des travailleurs agricoles temporaires :</b> Si vous avez de l'expérience 
                      dans l'agriculture, vous pourriez être admissible au Programme des travailleurs 
                      agricoles temporaires, qui permet de travailler au Canada temporairement.
                     </p><br/>
                     <p>
                      Il est important de noter que chaque programme d'immigration a ses propres critères 
                      d'admissibilité, et le processus peut prendre du temps. Il est recommandé de consulter
                      le site Web officiel d'Immigration, Réfugiés et Citoyenneté Canada (IRCC) pour obtenir 
                      des informations à jour sur les programmes d'immigration et pour déterminer celui qui 
                      correspond le mieux à votre situation. Il est également conseillé de consulter un avocat 
                      en immigration ou un consultant en immigration agréé pour obtenir une orientation spécifique 
                      à votre cas.
                     </p>
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>Quel est le délai de traitement d'un visa canadien ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                    Le délai de traitement d'un visa canadien varie en fonction du type de 
                    visa que vous demandez, de votre pays de résidence et du volume de demandes 
                    que le gouvernement canadien reçoit à un moment donné. Voici quelques exemples 
                    de délais approximatifs pour certains types de visas :<br/><br/>
                    <p>
                      1- <b>Visa de visiteur (tourisme, affaires, famille) :</b> Les délais de traitement 
                      pour les visas de visiteur varient généralement de quelques semaines à 
                      quelques mois, en fonction de votre pays de résidence.
                    </p><br/>
                    <p>
                      2- <b>Permis d'études :</b> Les délais de traitement pour les permis d'études dépendent 
                      du programme d'études, de la durée du programme et d'autres facteurs. En général,
                      le traitement d'une demande de permis d'études peut prendre de quelques semaines 
                      à plusieurs mois.
                    </p><br/>
                    <p>
                      3- <b>Permis de travail :</b> Les délais de traitement des permis de travail varient 
                      en fonction du type de permis. Les permis de travail temporaires peuvent prendre 
                      quelques semaines, tandis que les permis de travail postdiplôme pour les étudiants
                       diplômés au Canada sont généralement traités plus rapidement.
                    </p><br/>
                    <p>
                      4- <b>Programme de résidence permanente :</b> Les délais de traitement pour 
                      les programmes de résidence permanente, tels que le Programme des travailleurs 
                      qualifiés ou le Programme des candidats des provinces, varient considérablement 
                      en fonction du programme et de votre situation. Certains programmes peuvent avoir 
                      des délais de traitement plus longs en raison de la demande élevée.
                    </p>
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>Quelle somme d'argent pour entrer au Canada ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Pour avoir une estimation approximative, voici quelques éléments à prendre en compte pour un voyageur :<br/><br/>
                      <p>
                        1- <b>Billets d'avion :</b> Les prix des billets d'avion varient en fonction de la saison, de la destination et de
                        la compagnie aérienne. Il est recommandé de faire des recherches pour trouver les meilleures offres, mais prévoyez 
                        un budget pour les billets aller-retour.
                      </p><br/>
                      <p>
                        2- <b>Hébergement :</b> Les coûts d'hébergement dépendent du type d'endroit où vous séjournez
                        (hôtel, auberge de jeunesse, location de logement, etc.). Les grandes villes canadiennes comme 
                        Toronto, Vancouver et Montréal ont tendance à avoir des tarifs d'hébergement plus élevés que les 
                        régions moins urbanisées.
                      </p><br/>
                      <p>
                        3- <b>Nourriture :</b> Les dépenses alimentaires varient en fonction de vos préférences alimentaires, 
                        de l'endroit où vous mangez (restaurants, restauration rapide, cuisine maison), et de la fréquence de 
                        vos repas à l'extérieur.
                      </p><br/>
                      <p>
                        4- <b>Transport :</b> Les coûts de transport local dépendent de la ville où vous séjournez et de la
                         manière dont vous vous déplacez (transports en commun, location de voiture, taxis, etc.).
                      </p><br/>
                      <p>
                        5- <b>Activités et loisirs :</b> Les coûts liés aux activités touristiques, aux visites de musées, 
                        aux excursions, aux divertissements, etc., dépendent de vos préférences et de ce que vous souhaitez 
                        faire pendant votre séjour.
                      </p><br/>
                      <p>
                        5- <b>Assurance voyage :</b> Il est recommandé de souscrire une assurance voyage pour couvrir d'éventuels frais médicaux, d'annulation de voyage ou de perte de bagages.
                      </p><br/><br/>

                      <p>Le budget global peut varier de quelques centaines à quelques milliers de dollars canadiens par semaine, en fonction de ces facteurs. Il est recommandé de planifier soigneusement votre voyage, de rechercher les coûts locaux et de créer un budget en fonction de vos préférences et de vos besoins.
                      </p>
                  </Typography>
              </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography >Quel est le délai de traitement d'un CAQ</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <Typography>Quel université ou Cegep choisir pour avoir le visa d'etude ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography>Qui peut être garant pour un étudiant Canada ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                  </Typography>
              </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
              <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
            <Typography >Quelle est la meilleure procédure pour immigrer au Canada ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
              <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
            <Typography>Comment immigrer au Canada depuis la Côte d'Ivoire ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
              <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
            <Typography>Quels sont les papiers à fournir pour avoir un visa canadien ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                  </Typography>
              </AccordionDetails>
          </Accordion>
      </div>

      <Footer/>
          </>
        )}
    </div>

  )
}

export default FaqPage
