import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import Footer from '../../../COMPONENTS/Footer/Footer'
import img1 from "../../../../ASSETS/tourisme/Image/image_loisirs/botanique.png";
import img2 from "../../../../ASSETS/tourisme/Image/image_loisirs/botanique.png";
import img3 from "../../../../ASSETS/tourisme/Image/image_loisirs/botanique.png";
import { IoIosArrowRoundForward } from "react-icons/io";
import Carousel from './Carousel';
import BackImage from '../BackImage';
import Barniere from '../Barniere';
import { GiMoneyStack } from "react-icons/gi";
import { IoMdPin } from "react-icons/io";
import Suggestion from './Suggestion';
import Culture from '../../../../API/tourisme/loisir/index'
import { AnnoncesProps, ImagesProps} from "../../../../props";
import {useLocation} from 'react-router-dom';

function VoirPlus() {

    const location = useLocation();
    const id = location.state
    const [loisir, setLoisir] = useState<AnnoncesProps>();
    const [image, setImage] = useState([]);

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const getLoisir = () => {
        Culture.getOne(id)
        .then((res:any) => {
            if (res.data.status === 201) {
                setLoisir(res.data.data)
            }
            console.log(res);
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }
  
    const getImages = () => {
        Culture.getImages(id)
      .then((res:any) => {
          if (res.data.status === 201) {
            setImage(res.data.data)
          }
          console.log(res);
      })
      .catch((err:any) => {
          console.log(err);
      })
      .finally(() => {
          setLoading(false);
      })
    }

    const slides = [img1, img2, img3];
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        getImages();
        getLoisir();

        //const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            //setLoading(false);
        //}, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        //return () => clearTimeout(timer);
    }, []);

    return (
        <div >
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

             {/* Back image */}
             <BackImage/>

             {/* barre bleu */}
            <div className='border-t-4 border-slate-900 py-10 w-2/3'></div>

            {/* container1 */}
            <div className='grid md:gap-8 md:grid-cols-2 place-items-center md:px-10 s:gap-10  xs:grid-cols-2 sm:grid-cols-1 pb-10'>
                <div className='space-y-6 md:w-full s:w-[260px] sm:w-[340px]'>
                    <p className='md:text-xl font-medium text-orange-400 '>{loisir?.title_announce}</p>
                    <div className=' md:text-base sm:text-sm flex-col space-y-3'>

                    {loisir?.description_announce}
                        
                    </div>
                    {/* lieu et prix */}
                    <div className='flex md:flex-row s:flex-col lg:items-center md:space-x-3 s:space-y-3 md:space-y-0 py-4'>
                        <div className='font-thin flex flex-row items-center  space-x-2 '>
                        <IoMdPin className='text-xl text-[#fe7f2d]' />
                        <p>{loisir?.contact_address}</p>
                        </div>
                        <div className=' font-thin flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-green-600' />
                            <p > {loisir?.price_announce} FCFA</p>
                        </div>
                    </div>

                </div>

                {/* Caroussel */}
                <div className="relative md:w-[400px]  s:w-[260px] sm:w-[340px] ">
                    <div className="max-w-lg">
                        <Carousel slides={image} />
                    </div>
                </div>

            </div>

            <div className='border-t-4 border-slate-900 py-10 w-2/3 grid place-items-end'></div>

            {/* suggestions */}
            <div className='space-y-4 flex flex-col items-center pb-14'>
                <p className='text-xl font-semibold'>Suggestions</p>
                <p className=' md:w-[900px] xs:w-[700px] s:w-[260px] sm:w-[340px] text-center md:text-base s:text-sm'>Découvrez d'autre sites touristique de la côte d'ivoire </p>
            </div>

            <Suggestion/>
           

           {/* Banniere culture et decouverte */}
            <Barniere/>
            
            <Footer />
                </>
            )}

        </div>
    )
}

export default VoirPlus