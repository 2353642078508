import axios from 'axios';

let Restaurant : any = {};

Restaurant.submitForm = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/restaurant/add`, data)
}

Restaurant.getHomeData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/restaurant/home`)
}

Restaurant.getAllData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/restaurant/all`)
}

Restaurant.submitReservation = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/restaurant/send/reservation`, data)
}

Restaurant.submitEdit = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/restaurant/edit`, data)
}

Restaurant.getOne = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/restaurant/show/${id}`)
}

Restaurant.getImages = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/restaurant/images/${id}`)
}

Restaurant.submitFormUp = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/restaurant/update/popUp/resto`, data)
}


export default Restaurant;