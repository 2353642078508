import React from 'react'
import chambreBaniere from '../../../ASSETS/tourisme/Image/room-interior-hotel-bedroom.jpg'
import plus from '../../../ASSETS/tourisme/icone/add.png'
import {Link} from 'react-router-dom'

function Barniere() {
  return (
    <div  className='h-[500px] w-screen bg-cover bg-center bg-no-repeat mb-20 flex items-center s:justify-center xs:justify-end xs:pr-10' style={{backgroundImage:`url(${chambreBaniere})`}}>
        <div className='bg-[#233D4D] opacity-80 text-white xs:w-[550px] xs:h-[350px] s:w-[260px] sm:w-[300px] p-5 '>
            <p className='text-2xl font-bold'>Amplifiez votre visibilité !</p>
            <p className='pt-8'>Propulsez votre résidence au cœur de l'aventure ivoirienne ! 
            Rejoignez notre plateforme dédiée au tourisme en Côte d'Ivoire pour accroître 
            votre visibilité. Offrez des séjours inoubliables, simplifiez les réservations 
            avec notre interface conviviale, et devenez un acteur clé de l'épanouissement 
            du tourisme dans ce joyau d'Afrique de l'Ouest. Faites briller votre hébergement 
            et participez à l'essor d'une communauté passionnée de voyageurs en quête d'expériences uniques.
            </p>
            <Link to="/Dashboard/Publications/Hebergement/Ajouter_des_hebergements">
                <button className='transform active:scale-75 transition-transform mt-10 bg-white text-[#fe7f2d] px-4 h-10 flex items-center space-x-2'>
                    <img src={plus} className='w-4' alt='ajout'/>
                    <p>Ajouter un hébergement</p>
                </button>
            </Link>
        </div>
</div>
  )
}

export default Barniere