import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

function Faq() {

  // interface WebFaq {
  //   id_faq: number;	
  //   user_category: number;	
  //   question: string;	
  //   fr_question: string;	
  //   answer: string;	
  //   fr_answer: string;
  // }

  const [open, setOpen] = React.useState(0);
 
  const [faqs, setFaqs] = useState([]);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const removeTags = (text) => {
    return text.replace(/<\/?(p|strong)>/g, '');
  };
 
  useEffect(() => {
    async function getFaq(){
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/webfaq`)
          setFaqs(res.data.web_faq);
      }catch (err) {
        console.log(err);
    }
    }
    getFaq()
    //  .then(res => {
    //     //console.log(res.data.web_faq);
    //   })
    //  .catch(err => {
    //     console.log(err);
    //   })
  }, []);


  return (
    <div className="flex flex-col space-y-3">
      {faqs.map((faq, index) => (
        <Accordion className="px-3 bg-white rounded-2xl" open={open === index} icon={<Icon id={index} open={open} />}>
          <AccordionHeader className="text-[#fe7f2d] text-lg" onClick={() => handleOpen(index)}>
            {faq.fr_question ? removeTags(faq.fr_question) : ""}
          </AccordionHeader>
          <AccordionBody className='text-lg'>
            {faq.fr_answer ? removeTags(faq.fr_answer) : ""}
          </AccordionBody>
        </Accordion>  
      ))}
      {/* <Accordion className="px-3 bg-white rounded-2xl" open={open === 1} icon={<Icon id={1} open={open} />}>
        <AccordionHeader className="text-[#fe7f2d] text-lg" onClick={() => handleOpen(1)}>{}</AccordionHeader>
        <AccordionBody className='text-lg'>
          We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion> */}
      {/* <Accordion className="px-3 bg-white rounded-2xl" open={open === 2} icon={<Icon id={2} open={open} />}>
        <AccordionHeader className="text-[#fe7f2d] text-lg" onClick={() => handleOpen(2)}>
          How Excepteur sint occaecat cupidatat non proident ?
        </AccordionHeader>
        <AccordionBody className='text-lg'>
        We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion>
      <Accordion className="px-3 bg-white rounded-2xl" open={open === 3} icon={<Icon id={3} open={open} />}>
        <AccordionHeader className="text-[#fe7f2d] text-lg" onClick={() => handleOpen(3)}>
          What can I Lorem ipsum dolor sit amet ?
        </AccordionHeader>
        <AccordionBody className='text-lg'>
          We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion>
      <Accordion className="px-3 bg-white rounded-2xl" open={open === 4} icon={<Icon id={4} open={open} />}>
        <AccordionHeader className="text-[#fe7f2d] text-lg" onClick={() => handleOpen(4)}>in voluptate velit esse cillum ?</AccordionHeader>
        <AccordionBody className='text-lg'>
          We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion>
      <Accordion className="px-3 bg-white rounded-2xl" open={open === 5} icon={<Icon id={5} open={open} />}>
        <AccordionHeader className="text-[#fe7f5d] text-lg" onClick={() => handleOpen(5)}>
          How Excepteur sint occaecat cupidatat non proident ?
        </AccordionHeader>
        <AccordionBody className='text-lg'>
        We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion>
      <Accordion className="px-3 bg-white rounded-2xl" open={open === 6} icon={<Icon id={6} open={open} />}>
        <AccordionHeader className="text-[#fe7f2d] text-lg" onClick={() => handleOpen(6)}>
          What can I Lorem ipsum dolor sit amet ?
        </AccordionHeader>
        <AccordionBody className='text-lg'>
          We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion>
      <Accordion className="px-3 bg-white rounded-2xl" open={open === 7} icon={<Icon id={7} open={open} />}>
        <AccordionHeader className="text-[#fe7f2d] text-lg" onClick={() => handleOpen(7)}>in voluptate velit esse cillum ?</AccordionHeader>
        <AccordionBody className='text-lg'>
          We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion>
      <Accordion className="px-3 bg-white rounded-2xl" open={open === 8} icon={<Icon id={8} open={open} />}>
        <AccordionHeader className="text-[#fe7f2d] text-lg" onClick={() => handleOpen(8)}>
          How Excepteur sint occaecat cupidatat non proident ?
        </AccordionHeader>
        <AccordionBody className='text-lg'>
        We&apos;re not always in the position that we want to be at. We&apos;re constantly
          growing. We&apos;re constantly making mistakes. We&apos;re constantly trying to express
          ourselves and actualize our dreams.
        </AccordionBody>
      </Accordion> */}
     
    </div>
  );
}

export default Faq