import React from 'react'
import Navbar from '../../COMPONENTS/Navbar/Navbar'
import accueil from '../../../ASSETS/tourisme/Image/accueil.png'
import BarresearchP from '../../COMPONENTS/BarreSearchP/BarreSearchP'
import ImgCi from '../../../ASSETS/tourisme/Image/Ci.jpg'
import basilique from '../../../ASSETS/tourisme/Image/baselique.jpg'
import mosque from '../../../ASSETS/tourisme/Image/Mosque.jpg'
import fort from '../../../ASSETS/tourisme/Image/fortDabou.jpg'
import cascade from '../../../ASSETS/tourisme/Image/cascade_man.jpg'
import mario from '../../../ASSETS/tourisme/Image/mario.png'
import quenns from '../../../ASSETS/tourisme/Image/quenns.png'
import laBrise from '../../../ASSETS/tourisme/Image/laBrise.png'
import havana from '../../../ASSETS/tourisme/Image/havana.png'
import voiture from '../../../ASSETS/tourisme/Image/Voiture.jpg'
import voiture2 from '../../../ASSETS/tourisme/Image/voiture2.jpg'
import voiture3 from '../../../ASSETS/tourisme/Image/voiture3.jpg'
import botanique from '../../../ASSETS/tourisme/Image/image_loisirs/botanique.png'
import cecile from '../../../ASSETS/tourisme/Image/image_loisirs/galerieCecile.png'
import cosmos from '../../../ASSETS/tourisme/Image/image_loisirs/cosmos.png'
import mall from '../../../ASSETS/tourisme/Image/image_loisirs/Mall.png'
import paradisia from '../../../ASSETS/tourisme/Image/image_loisirs/paradisia.png'
import musee from '../../../ASSETS/tourisme/Image/image_loisirs/Musée_des_civilisations1.png'
import sofitel from '../../../ASSETS/tourisme/Image/image_loisirs/sofitel-abidjan-hotel.png'
import africa from '../../../ASSETS/tourisme/logo/logoAfrica.png'
import deluxe from '../../../ASSETS/tourisme/logo/Deluxe.png'
import Footer from '../../COMPONENTS/Footer/Footer'
import {Link} from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import drapeau from '../../../ASSETS/tourisme/icone/ivory-coast.png'
import capitale from '../../../ASSETS/tourisme/icone/yamoussoukro.png'
import lieu from '../../../ASSETS/tourisme/icone/pin.png'
import SaintPaul from '../../../ASSETS/tourisme/Image/cathedralePaul.jpg'
import banco from '../../../ASSETS/tourisme/Image/banco.jpg'
import paradis from '../../../ASSETS/tourisme/Image/small-bathroom-with-modern-style-ai-generated.jpg'
import paradis2 from '../../../ASSETS/tourisme/Image/living-room-mid-century-style-with-warm-colors-ai-generative.jpg'
import paradis3 from '../../../ASSETS/tourisme/Image/luxury-bedroom-hotel.jpg'
import paradis5 from '../../../ASSETS/tourisme/Image/beautiful-shot-modern-house-kitchen.jpg'
import paradis6 from '../../../ASSETS/tourisme/Image/modern-kitchen-design-interior.jpg'
import paradis4 from '../../../ASSETS/tourisme/Image/3d-rendering-white-wood-living-room-near-bedroom-upstair.jpg'
import pin from '../../../ASSETS/CaImmigration/icones/pin.png'
import price from '../../../ASSETS/CaImmigration/icones/banknotes.png'
import { VscEye } from "react-icons/vsc";
import { IoIosArrowRoundForward } from "react-icons/io";
import Heber from '../../../API/tourisme/hebergement/index'
import Locat from '../../../API/tourisme/location/index'
import Restau from '../../../API/tourisme/restaurant/index'
import Tourisme from '../../../API/tourisme/lieu/index'
import Loisir from '../../../API/tourisme/loisir/index'
import { AnnoncesProps} from "../../../props";
import { ToastContainer, toast } from "react-toastify"


export default function Home() {

    localStorage.setItem('url', JSON.stringify('/hometourisme'));

    const [heberge, setHeberge] = useState<AnnoncesProps[]>([]);
    const [location, setLocation] = useState<AnnoncesProps[]>([]);
    const [restaurant, setRestaurant] = useState<AnnoncesProps[]>([]);
    const [lieuTour, setLieu] = useState<AnnoncesProps[]>([]);
    const [loisir, setLoisir] = useState<AnnoncesProps[]>([]);

    const getHomeHeber = () => {
        Heber.getHomeData()
        .then((res:any) => {
            if (res.data.status === 200) {
                setHeberge(res.data.data)
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const getHomeVoiture = () => {
        Locat.getHomeData()
        .then((res:any) => {
            if (res.data.status === 200) {
                console.log(res);
                setLocation(res.data.data)
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const getHomeRestau = () => {
        Restau.getHomeData()
        .then((res:any) => {
            if (res.data.status === 200) {
                console.log(res);
                setRestaurant(res.data.data)
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const getHomeLieu = () => {
        Tourisme.getHomeData()
        .then((res:any) => {
            if (res.data.status === 200) {
                console.log(res);
                setLieu(res.data.data)
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const getHomeLoisir = () => {
        Loisir.getHomeData()
        .then((res:any) => {
            if (res.data.status === 200) {
                console.log(res);
                setLoisir(res.data.data)
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    function truncate (str : any) {
        return str.length > 200 ? str.substring(0, 200) + "..." : str;
    }

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        getHomeHeber();
        getHomeVoiture();
        getHomeRestau();
        getHomeLieu();
        getHomeLoisir();
        /* const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes */

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        //return () => clearTimeout(timer);
    }, []);
    
  
  return (
    <div className=' flex flex-col justify-center '>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading/>
          ) : (
        <>

        {/* navbar */}
        <Navbar/>
        <div>
                        <ToastContainer />
                    </div>
        {/* back image */}
        <div className='bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative flex justify-center items-center' style={{ backgroundImage: `url(${accueil})` }}>
            <div className='navbar_animation flex flex-col items-center justify-center space-y-5 '>
                <div className='text-white xs:text-3xl s:text-xl italic font-semibold  '>
                    BIENVENUE EN
                </div>
                <div className='text-white md:text-7xl xs:text-5xl s:text-3xl font-semibold uppercase '>
                     côte d'ivoire
                </div>
                <div className='text-white md:text-3xl xs:text-2xl text-center s:text-xl  italic font-semibold  '>
                    pays d'émerveillement côtier et d'aventures inoubliables !
                </div>
            
            </div>

        </div>

        {/* barre de recherche principale */}
        <div className=' absolute inset-x-0 md:-bottom-20 s:-bottom-64 xs:-bottom-40 flex justify-center  '>
        <BarresearchP/>
        </div>

       <div className='relative md:mt-40 s:mt-80 flex flex-col justify-center items-center'>

            {/* container1 */}
            <div className='flex items-center justify-center md:space-x-14  md:flex-row s:flex-col-reverse s:space-x-0  px-5'>

                <div className='flex flex-col space-y-4 pt-2 '>

                    <div className='titre_ci_home uppercase '>La côte d'ivoire</div>
                    <div className='md:w-[500px] xs:w-[700px] s:w-[260px] sm:w-[300px] pt-3 text-justify '>La Côte d'Ivoire est un pays d'Afrique de l'Ouest doté de stations balnéaires,
                        de forêts tropicales et d'un patrimoine colonial français. Abidjan, sur la côte 
                        Atlantique, est le principal centre urbain du pays. Ses sites modernes regroupent
                        la Pyramide, un édifice en béton faisant penser à une ziggourat. La cathédrale 
                        Saint-Paul est une structure inclinée rattachée à une immense croix. Au nord du 
                        quartier central des affaires, le parc national du Banco est une réserve de forêt 
                        tropicale au sein de laquelle serpentent des chemins de randonnée ...  
                        
                    </div>
                    <div className='flex space-x-3 items-center'>
                        <img src={drapeau} alt='drapeau' className='w-8'/>
                        <div> <b>Devise : </b> Union - Discipline - Travail</div>
                    </div>
                   <div className='flex space-x-3 items-center'>
                            <img src={capitale} alt='capitale' className='w-8'/>
                            <div> <b>Capitale : </b> Yamoussokro</div>
                    </div>
                        
                    
                </div>
                <div>
                    <img src={ImgCi} className='xs:w-[700px] md:w-[600px] s:w-[260px] sm:w-[300px] ' alt='Ci'/>
                </div>
            </div>


            {/* container2 */}
            <div className='container2_home px-5 w-screen flex flex-col md:space-y-6 items-center justify-center py-10 my-20 '>
                <div className='flex flex-col items-center justify-center text-white space-y-3 pb-10'>
                    <div className='md:text-3xl sm:text-xl'> Culture & <span className='text-orange-400'>Découverte,</span></div>
                    <div className='md:text-lg sm:text-lg '>Découvrez les différents sites touristiques à visités et diverses activités sur la plateforme </div>
                </div>

                <div className='grid gap-8 lg:grid-cols-4 md:grid-cols-3 xs:grid-cols-2 sm:grid-cols-1 pb-10 '>

                {lieuTour.map((data, index )=> (

                    <div className='flex  flex-col items-center'>
                        {/* titre */}
                        <div className='flex items-center space-x-2 bg-[#FCCA46] py-2 pl-3 w-[250px] rounded-md relative top-12 md:right-9'>
                            {/* icone */}
                            <img src={lieu} alt='lieu' className='w-6'/>
                            {/* texte */}
                            <div className=' text-white text-sm '>{data.title_announce}</div>
                        </div>
                        {/* image du site touristique */}
                    
                        <img src={`data:image/png;base64,${data[0]}`} alt={data.title_announce} className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 md:w-[350px] cursor-zoom-in  sm:w-[320px] h-[250px] s:w-[260px] rounded-md'/>

                        {/* bloc blan description */}
                        <div className='md:w-[320px] rounded-md  sm:w-[300px] s:w-[260px] h-[250px]  bg-white p-5 relative bottom-12'>
                            <div className='md:w-[280px] sm:w-[260px] s:w-[230px] '>
                            {truncate(data?.description_announce)}
                                
                            </div>
                            <div className='grid place-items-end mt-4 '>
                            <Link to="/culture/Voir_Plus" 
                            state={ 
                                data.id_announce 
                             }
                             >  
                            <button className='transform active:scale-75 transition-transform text-sm flex items-center space-x-2 font-bold bg-orange-400 px-2 h-8 rounded  text-white '>
                                <p>Voir plus</p>
                                <VscEye className='text-lg' />
                            </button>
                            </Link> 
                            </div>
                        </div>
                    </div>
)
)}
                   

                </div>
                <Link to='/culture'>
                    <button className='bg-white md:px-9 s:px-6 md:py-4 s:py-2 md:text-lg s:text-sm font-semibold rounded  hover:bg-slate-800 hover:text-white flex items-center space-x-2 '>
                       <p> Decouvrir plus</p>
                        <IoIosArrowRoundForward className='text-xl' />
                    </button>
                </Link>
            </div>

            <hr className='sm:w-80 md:w-96 '/>

            {/* container3 */}
            <div className='my-20 px-5 flex flex-col items-center space-y-16'>
                <div className='flex flex-col justify-center items-center space-y-5'>
                    <div className='uppercase text-orange-400 md:text-3xl sm:text-xl'> hébergements</div>
                    <div  className='  text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                        Découvrez le parfait équilibre entre confort et élégance avec nos 
                        résidences en location. Chaque espace a été soigneusement conçu pour 
                        créer une atmosphère accueillante, offrant un style de vie contemporain. 
                        
                    </div>
                </div>

                <div className='grid gap-20  xs:grid-cols-2 md:grid-cols-3 sm:grid-cols-1 pt-5 mt-10'> 
                  
                {heberge.map((list, index )=> (

                            <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        <Link to="/hebergement/Voir_plus" state={ 
                                           list.id_announce 
                                        }>
                        {/* image de la residence */}
                        <img className='shadow-xl xs:w-72  s:w-64 xs:h-52  lg:h-60  s:h-40 ' src={`data:image/png;base64,${list[0]}`} alt={list.title_announce} />
                        {/* description */}
                        <div className=' xs:w-72  s:w-64  h-[350px] bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                        {list.status === "enabled" && (
                                    <div className='bg-[#3ec53e] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px] '>Disponible</div>
                                    )}
                                    {list.status === "disabled" && (
                                    <div className='bg-[#fc5151] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px]'>Réservé</div>
                                    )}
                            
                            {/* lieu */}
                            <div className='flex items-center space-x-2  py-2'>
                                <img src={pin} className='w-4' alt='localisation'/>
                                <p className=' font-thin '>{list.contact_address}</p>
                            </div>

                            <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>{list.title_announce}</p>
                            <p className=' font-light md:pb-3 sm:pb-2 '>{truncate(list.description_announce)}</p>
                            <hr className='s:pb-1 lg:pb-0' />
                            {/* Prix */}
                            <div className='flex items-center space-x-2  pt-4'>
                               <img src={price} className='w-6' alt='prix'/>
                                <div className='font-semibold text-lg sm:text-sm s:text-xs'>{list.price_announce} FCFA / Nuit</div>
                            </div>
                            {list.status === "enabled" && (
                                    <Link to="/Reservation_Hebergement" state={ 
                                        list.id_announce 
                                     } >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                                </Link>
                                    )}
                        </div>
                        </Link>
                    </div>
)
                      )}

                   

                </div>
                <Link to="/hebergement">
                <button className='border-2 mt-10 rounded font-medium  flex items-center space-x-2 justify-center border-slate-800 text-slate-800 px-9 py-2  hover:bg-slate-800 hover:text-white'>
                    <p>Voir plus</p>
                    <IoIosArrowRoundForward className='text-xl' />
                </button>
                </Link>
            </div>

            <hr className='sm:w-80 md:w-96 '/>

            {/* container4 */}
            <div className='my-20  px-8 flex flex-col items-center justify-center'>
            <div className='flex items-center justify-center flex-col space-y-5 text-slate-800'>
                <p className='uppercase md:text-3xl sm:text-xl'>restaurant</p>
                <p className='text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                    Savourez l'essence de la gastronomie dans nos restaurants 
                    uniques. Des saveurs exquises, une ambiance chaleureuse et 
                    un service exceptionnel vous attendent.
                </p>
            </div> 

            <div className='pt-20 grid md:gap-16 s:gap-32 md:grid-cols-4 xs:grid-cols-2 s:grid-cols-1 '>
            {restaurant.map((data, index )=> (
                <div className='flex flex-col md:w-full s:w-48 items-center space-y-5 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>
                    <Link to="/restaurants/Voir_Plus" state={ 
                                           data.id_announce 
                                        }>
                    <img className=' rounded-full w-64 border-4 border-yellow-400 shadow-xl' src={`data:image/png;base64,${data[0]}`} alt={data.title_announce}/>
                    </Link>
                    <p className='uppercase md:text-base lg:text-lg s:text-base font-semibold text-slate-800'>{data.title_announce}</p>
                    
                    <div className='w-60 flex flex-col text-center s:text-sm md:text-base lg:text-base'>
                        <p className='text-orange-400 font-medium'>{data.contact_address}</p>
                        {truncate(data.description_announce)}
                    </div>

                    {data.status === "enabled" && (
                                    <Link to="/Reservation_Restaurant" state={ 
                                        data.id_announce 
                                     } >
                <button className='bg-slate-800 text-white py-2 px-8 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>Réserver</button>
                </Link>
                                    )}
                </div>
)
)}
              

            </div>
            <Link to="/restaurants">
                <button className='border-2 mt-20 rounded font-medium  flex flex-row items-center space-x-2 justify-center border-yellow-400 text-yellow-400 px-9 py-2  hover:bg-yellow-400 hover:text-white'>
                   <p> Autres restaurants</p>
                    <IoIosArrowRoundForward className='text-xl' />
                </button>

                </Link>
            </div>

            <hr className='s:w-80 md:w-96 '/>

            {/* container5 */}
            <div className='my-20 px-5 flex flex-col items-center'>
                <div className='flex flex-col justify-center items-center space-y-5'>
                    <p className='uppercase text-orange-400 md:text-3xl sm:text-xl'> location <span className='text-slate-800'>  de véhicule</span> </p>
                    <p  className='text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                        Explorez la liberté de voyager à votre rythme avec notre 
                        service de location de véhicules. Des options variées, des 
                        tarifs compétitifs et un service client dévoué font de chaque 
                        trajet une aventure sans souci. 
                    </p>
                </div>

                <div className='grid gap-20 md:grid-cols-3 xs:grid-cols-2 sm:grid-cols-1 pt-5 mt-10'>

                {location.map((list, index )=> (
                    <div className='flex flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        {/* image de voiture */}
                        <Link to="/Locationdevehicule/Voiture" state={ 
                                           list.id_announce 
                                        }>
                        <img className='shadow-xl xs:w-72  sm:w-64  lg:h-60  sm:h-52 xs:h-52' src={`data:image/png;base64,${list[0]}`} alt={list.title_announce}/>
                        </Link>
                        {/* bloc blanc description de la voiture */}
                        <div className=' xs:w-72  sm:w-64  h-full pb-2   bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-2 sm:space-y-0 px-4  '>

                            <div className='flex items-center space-x-2 sm:text-xs'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.52" height="40.28" viewBox="0 0 67.52 101.28">
                                <g id="localisation" transform="translate(-85.333)">
                                    <path id="Tracé_269" data-name="Tracé 269" d="M119.093,0A33.764,33.764,0,0,0,89.386,49.8l27.861,50.388a2.11,2.11,0,0,0,3.692,0L148.81,49.787A33.766,33.766,0,0,0,119.093,0Zm0,50.64a16.88,16.88,0,1,1,16.88-16.88A16.9,16.9,0,0,1,119.093,50.64Z" fill="#233d4d"/>
                                </g>
                                </svg>
                                <div className=' font-thin text-base'>{list.contact_address}</div>
                            </div>

                            <div className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm'>{list.title_announce}</div>
                            <div className=' font-light md:pb-2 sm:pb-2 sm:text-xs md:text-base'>
                            {truncate(list?.description_announce)}
                            </div>
                            <hr className='sm:pb-1 lg:pb-0'/>
                            {/* prix */}
                            <div className='flex items-center space-x-2  py-4 '>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.742" height="16.53" viewBox="0 0 27.742 16.53">
                                <g id="money" transform="translate(-15.998 -112.999)">
                                    <circle id="Ellipse_29" data-name="Ellipse 29" cx="1.185" cy="1.185" r="1.185" transform="translate(19.28 119.238)" fill="#fe7f2d"/>
                                    <path id="Tracé_270" data-name="Tracé 270" d="M47.629,142,46,143.6v8.359l1.573,1.545h19.45l1.633-1.548V143.6L67.051,142Zm1.023,1.04h4.431a.289.289,0,0,1,0,.578H48.652a.289.289,0,0,1,0-.578Zm-1.289,0h.173a.289.289,0,0,1,0,.578h-.173a.289.289,0,0,1,0-.578Zm1.369,6.471a1.763,1.763,0,1,1,1.763-1.763A1.763,1.763,0,0,1,48.732,149.511ZM57.325,153a5.252,5.252,0,1,1,5.252-5.252A5.252,5.252,0,0,1,57.325,153Zm10.413-5.252a1.821,1.821,0,1,1-1.821-1.821A1.821,1.821,0,0,1,67.738,147.749Z" transform="translate(-28.268 -27.325)" fill="#fe7f2d"/>
                                    <circle id="Ellipse_30" data-name="Ellipse 30" cx="1.243" cy="1.243" r="1.243" transform="translate(36.407 119.181)" fill="#fe7f2d"/>
                                    <path id="Tracé_271" data-name="Tracé 271" d="M165.753,160.6a4.674,4.674,0,1,0,4.674,4.674A4.674,4.674,0,0,0,165.753,160.6Zm0,4.36a1.383,1.383,0,0,1,.291,2.735v.393a.289.289,0,0,1-.578,0V167.7a1.376,1.376,0,0,1-1.1-1.352.288.288,0,1,1,.577,0,.806.806,0,0,0,.8.8h.01a.805.805,0,0,0,0-1.61,1.383,1.383,0,0,1-.287-2.736v-.343a.289.289,0,0,1,.578,0v.343a1.373,1.373,0,0,1,1.093,1.352.29.29,0,1,1-.579,0,.805.805,0,1,0-.8.805Z" transform="translate(-136.696 -44.85)" fill="#fe7f2d"/>
                                    <path id="Tracé_272" data-name="Tracé 272" d="M41.659,127.445a1.364,1.364,0,0,0,.4-.989l-.175-12.094A1.39,1.39,0,0,0,40.493,113H17.39A1.376,1.376,0,0,0,16,114.391l.175,12.095a1.392,1.392,0,0,0,1.391,1.366h23.1A1.374,1.374,0,0,0,41.659,127.445Zm-2.79-.691H19.189a.28.28,0,0,1-.2-.083l-1.759-1.71a.281.281,0,0,1-.079-.208v-8.6a.281.281,0,0,1,.079-.207l1.81-1.764a.289.289,0,0,1,.2-.081H38.9a.282.282,0,0,1,.2.083l1.784,1.764a.284.284,0,0,1,.08.206v8.6a.285.285,0,0,1-.085.212l-1.813,1.709A.291.291,0,0,1,38.87,126.754Z" transform="translate(0 0)" fill="#fe7f2d"/>
                                    <path id="Tracé_273" data-name="Tracé 273" d="M73.22,142.331l.17,11.747a1.96,1.96,0,0,1-1.969,1.989H48.637a1.387,1.387,0,0,0,1.361,1.1H73.1a1.378,1.378,0,0,0,1.391-1.4l-.175-12.094A1.4,1.4,0,0,0,73.22,142.331Z" transform="translate(-30.752 -27.637)" fill="#fe7f2d"/>
                                </g>
                                </svg>
                                <p className='sm:text-xs md:text-base'> Prix : {list.price_announce} FCFA / jour</p>
                            </div>
                            {list.status === "enabled" && (
                                <Link to='/Reservation_Vehicule' state={ 
                                  list.id_announce 
                               }>
                                <button className='bg-[#233D4D] text-white w-[200px] relative left-6 h-8  rounded  
                                            justify-center cursor-pointer transition ease-in-out delay-150 
                                            hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                  Faire une reservation
                                </button>
                              </Link>
                                )}
                        </div>
                        
                    </div>


)
)}

                </div>

                    <Link to='/Locationdevehicule'>
                        <button className='border-2 mt-10 rounded font-medium  flex items-end space-x-2 justify-center border-slate-800 text-slate-800 px-9 py-2  hover:bg-slate-800 hover:text-white'>
                            <p>Voir plus</p>
                            <IoIosArrowRoundForward className='text-xl' />
                        </button>
                    </Link>
            
            </div>

            {/* container6 */}
            <div className='container2_home my-20 s:p-10 sm:pb-32 s:pb-28 0 md:pb-10 s:px-5 w-screen grid s:gap-28 md:gap-8 lg:grid-cols-6 md:grid-cols-4 xs:grid-cols-2 sm:grid-cols-1 place-content-center place-items-center'>
                <div className=' space-y-4'>
                    <div className='uppercase text-white '><span className='text-yellow-400'>les activités en</span> côte d'ivoire</div>
                    <div className='sm:w-72 s:w-60 text-white md:text-base sm:text-sm text-justify'>
                        Des excursions culturelles aux aventures en plein air, plongez dans une expérience vibrante. Que vous soyez passionné d'histoire, amateur de nature ou en quête d'émotions fortes, notre sélection d'activités vous promet des moments inoubliables pendant votre séjour
                    </div>
                </div>
                
                {loisir.map((liste, index )=> (
                <div className='lg:w-72 lg:h-72 md:w-64 md:h-64 s:w-40 s:h-40 text-sm grid place-items-end  transition ease-in-out delay-150 hover:-translate-y-1  cursor-pointer'>
                    <img className='rounded-md' src={`data:image/png;base64,${liste[0]}`} alt='botanique'/>
                    <div className='bg-orange-400 text-white w-60 h-8 pb-2  px-4 grid place-items-end relative s:bottom-20 sm:bottom-10'> {liste.title_announce}</div>
                </div>
                )
)}
              
            </div>
        
             {/* partenaires */}
             <div className='flex flex-col justify-center items-center'>
             <div className='uppercase md:text-2xl sm:text-lg font-medium text-neutral-800 pb-8'>nos partenaires</div>
             <hr className='sm:w-80 md:w-96 '/>
            <div className='my-20 px-5 grid gap-20 md:grid-cols-7 xs:grid-cols-3 sm:grid-cols-1'>
                <img src={africa} alt='africa' className='w-40'/>
                <img src={deluxe} alt='deluxe' className='w-40'/>
                <img src={africa} alt='africa' className='w-40'/>
                <img src={deluxe} alt='deluxe' className='w-40'/>
                <img src={africa} alt='africa' className='w-40'/>
                <img src={deluxe} alt='deluxe' className='w-40'/>
                <img src={africa} alt='africa' className='w-44'/>
            </div>
            </div>

            <Footer/>

       </div>
        </>
          )}
    </div>
  )
}



