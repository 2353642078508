import React from 'react'
import { RiMessage2Line } from "react-icons/ri";
import { useState } from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";
import profil from "../../../../ASSETS/tourisme/Image/close-up-portrait-lovely-young-african-american-woman-with-curly-afro-haircut-smiling-with-happy-pleasant-expression-enjoying-winter-holidays-wearing-sweater-blue-wall.jpg"

function Commentaire() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

  return (
    <div>
          <form className='flex md:flex-row s:flex-col s:space-y-4 md:space-y-0  md:space-x-4 xs:space-x-0'>
              <div className='border-2 rounded sm:w-[300px] s:w-[260px] xs:w-[500PX]   flex flex-row space-x-4 items-center '>
                  <RiMessage2Line className='text-[#8f8e8e] pl-2 text-2xl' />
                  <input type='text' name='commentaire'  placeholder='Ecrire un commentaire' className='sm:w-[300px] s:w-[260px]  xs:w-[500PX] border-0' />
              </div>
              <button className='bg-[#233D4D] w-[190px] h-10 text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Envoyer</button>
          </form>

          {isShowMore && (
              <div className='mt-10 flex flex-col space-y-5'>
                 {/* le commentaire 1 */}
                  <div className='md:w-[500px] xs:w-[400px] s:w-[260px] sm:w-[300px]  bg-[#f7f7f7] p-4 rounded-lg'>
                      <div className='flex flex-row items-center space-x-4'>
                          {/* Photo de profil */}
                          <img src={profil} className='rounded-full w-12 h-12' alt='profil_photo' />
                          {/* Nom d'utilisateure */}
                          <div className='text-[#4e4e4e] font-semibold'>Kouakou Roseline</div>
                      </div>
                      <div className='md:w-[478px] xs:w-[378px] s:w-[240px] sm:[280px] h-full'>Augue interdum velit euismod in pellentesque. Pulvinar elementum integer
                          enim neque volutpat ac. Fermentum et sollicitudin ac orci phasellus egestas
                          tellus rutrum tellus.
                      </div>
                  </div>
                  {/* le commentaire 2 */}
                  <div className='md:w-[500px] xs:w-[400px] s:w-[260px] sm:w-[300px]  bg-[#f7f7f7] p-4 rounded-lg'>
                      <div className='flex flex-row items-center space-x-4'>
                          {/* Photo de profil */}
                          <img src={profil} className='rounded-full w-12 h-12' alt='profil_photo' />
                          {/* Nom d'utilisateure */}
                          <div className='text-[#4e4e4e] font-semibold'>Kouakou Roseline</div>
                      </div>
                      <div className='md:w-[478px] xs:w-[378px] s:w-[240px] sm:[280px] h-full'>Augue interdum velit euismod in pellentesque. Pulvinar elementum integer
                          enim neque volutpat ac. Fermentum et sollicitudin ac orci phasellus egestas
                          tellus rutrum tellus.
                      </div>
                  </div>
              </div>
          )}
          <button className='text-[#4e4e4e] flex flex-row space-x-5 items-center mt-5' onClick={toggleReadMoreLess}>
              {isShowMore ? "Voir moins" : "Voir les avis"}
              <IoIosArrowRoundForward className="text-lg " />
          </button>  
    </div>
  )
}

export default Commentaire