import axios from 'axios';

let Reservation : any = {};

Reservation.heberReservation = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/hebergement/send/reservation`, data)
}

Reservation.voitureRes = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/location/send/reservation`, data)
}

Reservation.restauRes = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/restaurant/send/reservation`, data)
}

Reservation.loisirRes = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/loisir/send/reservation`, data)
}

Reservation.cultureRes = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/culture/send/reservation`, data)
}

export default Reservation;