import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function BasicPie({
  data,
}: {
  data: any;
}) 

{

  return (
    <div className='bg-white  shadow-md xs:w-[400px] s:w-[340px] rounded-lg'>

      <div  className=" text-[#233D4D] text-xl p-4 font-semibold mb-4">
        Statistiques de réservation du mois de {new Date().toLocaleString("fr-FR", { month: "long" })}
      </div>

      <PieChart className='mt-20  s:w-[300px] xs:w-[400px]'
        series={[
          {
            data: [
              { id: 0, value: data[0], label: 'Residence' },
              { id: 1, value: data[1], label: 'Restaurant' },
              { id: 2, value: data[2], label: 'Véhicule' },
              { id: 3, value: data[3], label: 'Loisir' },
              { id: 4, value: data[4], label: 'Tourisme' },
            ],
          },
        ]}
        
        height={200}
      />
    </div>
  );
}