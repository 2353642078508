import axios from 'axios';

let Lieu : any = {};

Lieu.submitForm = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/loisir/add`, data)
}

Lieu.submitFormPopUp = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/lieu/loisir/update/popuploi`, data)
}

Lieu.getHomeData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/loisir/home`)
}

Lieu.getAllData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/loisir/all`)
}

Lieu.submitReservation = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/loisir/send/reservation`, data)
}

Lieu.submitEdit = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/loisir/edit`, data)
}

Lieu.getOne = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/loisir/show/${id}`)
}

Lieu.getImages = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/loisir/images/${id}`)
}


export default Lieu;