import React from 'react'
import { useState, FormEvent } from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import { Link, useNavigate} from 'react-router-dom'
import Header from '../../COMPONNENT/Header/Header';
// import Africa from '../../../ASSETS/tourisme/Image/A.D 2.png'
import { useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import rdv2_barniere from '../../../ASSETS/CaImmigration/images/rdv2_barniere.png'
import { ToastContainer, toast } from "react-toastify"
// import Register from "../../../API/inscription/Inscription"
import left from "../../../ASSETS/CaImmigration/icones/back.png"
import Footer from '../../COMPONNENT/Footer/Footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import RDV from "../../../API/immigration/rendezvous/Rdv"
function Rdv_Service() {
  //Spécification du type des données reçues de l'API
  interface Item {
    Items_Numbers: number;
    Categories:	number;
    Names: string;
    fr_Name: string;	
    Types:	string;
  }

  //localStorage.setItem('url', JSON.stringify('/appointement/RendezVousService'));

  const [data, setData] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
  });

  const [url, setUrl] = useState(() => {
    // getting stored value
    const link = localStorage.getItem("url");
    const value = link !== null ? JSON.parse(link) : null;
    return value;
  });
  
    const votrenom = data !== null ? data.userLastname + ' ' + data.userName : "";
    //console.log(data)
    const navigate = useNavigate();
    
    const [nom, setNom] = useState(votrenom || "")
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [tel, setTel] = useState(data !== null ? data.userTel : "" || "")
    const [sujet, setSujet] = useState("")
    const [usermail, setUserEmail] = useState(data !== null ? data.userMail : "" || "")
    const [sujetItem, setSujetItem] = useState<Item[]>([]);
    const [sujets, setSujets] = useState<Item[]>([]);
    const [msg, setMsg] = useState("")

  
    const [loading, setLoading] = useState(true);
    const id=useParams()
    useEffect(() => {
        //récupération du service par rapport à son id
        if(id)
            {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/items/${id.item_id}`)
              .then(response => {
                  // Mettez à jour l'état avec les données récupérées
                  setSujetItem(response.data.data);
                  setSujet(response.data.data[0].fr_Name)
                  //console.log(response.data.data);
              })
            .catch(err => console.log(err));
        }

              //récupération de tous les services
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/items`)
              .then(response => {
                  // Mettez à jour l'état avec les données récupérées
                  setSujets(response.data.data);
                  //console.log(response);
              })
              .catch(err => console.log(err));
        //const user = localStorage.getItem('userNumber');
        //console.log(user);
        if (!data) {
            navigate("/Connexion");
        }
        //fetchData();
        //console.log(res);
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        
        return () => clearTimeout(timer);
    },[]);
  
    
    function submit(e: FormEvent) {
      e.preventDefault()

      setLoading(true)
      // console.log(sujet)
      var templateParams = {
          nom: nom,
          usermail: usermail,
          tel: tel,
          date: date,
          time: time,
          sujet: sujet,
          msg: msg,
          user: data.userId,
      };

      RDV.submitForm(templateParams)
        .then((res: any) => {
          if (res.data.status === 201) {
              toast.success(res.data.message);
              navigate("/homeCaimmigration");
              //console.log(res);
              // navigate(url);
              // url !== null ? navigate(url)  : navigate('/');
          } else {
              toast.error(res.data.message);
              console.log(res);
          }
      })
      .catch((err: any) => {
          toast.error('Erreur merci de reessayer !');
          console.log(err);
      })
      .finally(() => {
          setLoading(false);
      })
  }
   
  return (
    <div>
          {
              loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
              ) : (
                <>
          <Header/>
          
          <div>
            <ToastContainer />
          </div>
          <Link to='/accueil_afrique_immigration'>
            <div className='relative flex items-center ml-6 space-x-2 top-8'>
              <img src={left} className='w-4' alt='arrow_left'/>
              <p className='text-lg text-[#233D4D] hover:text-[#fe7f2d]'>Retour à la page d'accueil</p>
            </div>
          </Link>

          <div className='flex items-center justify-center my-20 xs:flex-row s:flex-col-reverse '>
            <div className='relative bg-white shadow-3xl' style={{ height: 926, width: 462 }}>
                {/* Image de fond */}
              <img src={rdv2_barniere} className='absolute inset-0 object-cover w-full h-full' alt='Bannière de rendez-vous' />
                {/* Contenu au-dessus de l'image */}
              <div className='absolute inset-0 flex flex-col items-center justify-center'>
                  <p hidden className='pt-24 text-3xl font-bold text-center text-white'>Bienvenue !</p>
                <div className='flex justify-center pt-10'>
                  <p className='pl-5 pr-5 text-4xl font-bold text-center text-white pt-30'>
                  Formulaire de rendez-vous
                    {/* <em className='text-[#fe7f2d]'>
                      <br />{sujet ? sujet : ""}
                    </em> */}
                  </p>
                </div>
              </div>
            </div>

            <div className='bg-white shadow-3xl xs:w-[500px] s:w-[310px] h-auto'>
                {/* logo */}
                <Link to='/'>
                <div style={{ borderBottomRightRadius: "100px" }} className='w-40 pb-4 pr-4 bg-white sm:pl-5 s:pl-2'>
                    <img src={logo1} className='h-16 pt-6 s:pl-0 ' alt='logo' />
                </div>
                </Link>
                <p className='text-2xl font-bold text-[#fe7f2d] text-center relative s:bottom-5 xs:bottom-14'>Prendre un rendez-vous </p>

                <p  className='relative font-thin text-center mx-5 xs:bottom-10 s:bottom-5'>Veuillez remplir tous les champs du formulaire afin que votre demande de prise de rendez-vous soit prise en compte</p>
              <form action="" onSubmit={submit}>
                <div className='relative flex flex-col items-center pt-6 space-y-4 bottom-10'>

                        {/*Nom */}
                        <div className='flex flex-col space-y-3'>
                            <label className='text-lg'>Nom & Prénom <span className='text-red-600'>*</span></label>
                            <input type='hidden' value={data.userId} required />
                            <input name='name' type='text' 
                              style={{ 
                                backgroundColor: "#f3f3f3", 
                                border: "0px solid #fe7f2d",
                                outline: "none",
                                
                            }} 
                            value={nom} onChange={(e) => setNom(e.currentTarget.value)}
                            className='border-none s:w-[280px] xs:w-[360px] focus:outline-none h-9' required placeholder='Nom prénom' />
                        </div>

                        {/* Mail */}
                        <div className='flex flex-col space-y-3'>
                            <label className='text-lg'>Adresse e-mail <span className='text-red-600'>*</span></label>
                            <input name='mail' type='email' placeholder='Adresse mail' 
                              style={{ 
                                backgroundColor: "#f3f3f3", 
                      
                                border: "0px solid #fe7f2d",
                               
                                outline: "none",
                                
                            }} 
                            value={usermail} onChange={(e) => setUserEmail(e.currentTarget.value)}
                            className='border-none s:w-[280px] xs:w-[360px] focus:outline-none h-9' required />
                        </div>
                  
                        {/* portable */}
                        <div className='flex flex-col space-y-3'>
                            <label className='text-lg'>Numéro de portable <span className='text-red-600'>*</span></label>
                            <input name='numero' type='tel' placeholder='Telephone' 
                              style={{ 
                                backgroundColor: "#f3f3f3", 
                                border: "0px solid #fe7f2d",
                                outline: "none",
                            }} 
                            value={tel} onChange={(e) => setTel(e.currentTarget.value)}
                            className='border-none s:w-[280px] xs:w-[360px] focus:outline-none h-9' required />
                        </div>

                         {/* Date du rendez-vous */}
                        <div className='flex flex-col space-y-3'>
                            <label className='text-lg'>Date du rendez-vous <span className='text-red-600'>*</span></label>
                            <input name='date' type='date' 
                              style={{ 
                                backgroundColor: "#f3f3f3", 
                                border: "0px solid #fe7f2d",
                                outline: "none",
                            }} 
                            value={date} onChange={(e) => setDate(e.currentTarget.value)}
                            className='border-none s:w-[280px] xs:w-[360px] focus:outline-none h-9' required />
                        </div>

                         {/* Heure du rendez-vous */}
                        <div className='flex flex-col space-y-3'>
                            <label className='text-lg'>Heure du rendez-vous <span className='text-red-600'>*</span></label>
                            <input type='time' 
                              style={{ 
                                backgroundColor: "#f3f3f3", 
                                border: "0px solid #fe7f2d",
                                outline: "none",
                            }} 
                            value={time} onChange={(e) => setTime(e.currentTarget.value)}
                            className='border-none s:w-[280px] xs:w-[360px] focus:outline-none h-9' required />
                        </div>


                         {/* Sujet */}
                        <div className='flex flex-col space-y-3'>
                            <label className='text-lg'>Sujet <span className='text-red-600'>*</span></label>
                            <select value={sujet} name='sujet' onChange={(e)=>setSujet(e.currentTarget.value)} 
                            style={{ 
                              backgroundColor: "#f3f3f3", 
                              border: "0px solid #fe7f2d",
                              outline: "none",
                              }} className='border-none pl-3 s:w-[280px] xs:w-[360px] focus:outline-none h-9' required>
                              
                              <option value=''>Choisir un sujet</option>
                              {sujets.map((item, index)=>(
                                <option key={index} value={item.fr_Name}>{item.fr_Name}</option>
                              ))} 
                            </select>
                        </div>
                        
                        {/* Message */}
                        <div className='flex flex-col space-y-3'>
                                <label className='text-lg'>Message <span className='text-red-600'>*</span></label>
                                <textarea 
                                  style={{ 
                                    backgroundColor: "#f3f3f3", 
                                    border: "0px solid #fe7f2d",
                                    outline: "none",  
                                }} 
                                value={msg} onChange={(e) => setMsg(e.currentTarget.value)}
                                className='border-none pl-3 s:w-[280px] xs:w-[360px] focus:outline-none h-20 scroll-m-1' required></textarea>
                        </div>
                    
                        
                        <span id="msgerror" className="text-danger"> </span>
                </div>

                <button className='border-[#fe7f2d] border-solid border-2 text-[#fe7f2d] ml-16 mt-4 relative bottom-12 px-10 h-10 text-lg rounded transform active:scale-75 transition-transform'>Envoyer</button>
              </form>
            </div>
          </div>
        <Footer/>
         </>
              
         )
          }
    </div>
  )
}

export default Rdv_Service