import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENT/Header/Header';
import backimage from '../../../ASSETS/AfImmigration/img.png'
import {Link} from 'react-router-dom'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import BoutonLogin from '../../../TOURISME/COMPONENTS/BoutonLogin/BtnLogin'
import { FaCheck } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";
import { LiaTelegramPlane } from "react-icons/lia";
import conseil from '../../../ASSETS/AfImmigration/personnes-taille-moyenne-dans-bureau-agence-voyages.png'
import iconeConseil from '../../../ASSETS/AfImmigration/customer-service.png'
import iconeRecommandation from '../../../ASSETS/AfImmigration/reputation.png'
import iconeAssistance from '../../../ASSETS/AfImmigration/social-care.png'
import { IoIosArrowDropright } from "react-icons/io";
import { IoPin } from "react-icons/io5";
import Assistance from '../../../ASSETS/AfImmigration/equipe-collegues-travaillant-documents-entreprise-faisant-travail-equipe-pour-analyser-documents-informations-recherche-ligne-planification-presentation-entrep.png'
import recommandation from '../../../ASSETS/AfImmigration/collegues-masculins-feminins-noirs-assis-dans-bureau-regardant-ensemble-ecran-ordinateur.png'
import benin from '../../../ASSETS/AfImmigration/000_32JN7YL.png'
import drapeauBenin from '../../../ASSETS/AfImmigration/drapeau-du-benin.png'
import CI from '../../../ASSETS/AfImmigration/participation-sitho.png'
import drapeauCI from '../../../ASSETS/AfImmigration/drapeau-cote-ivoire.png'
import zambie from '../../../ASSETS/AfImmigration/b39df0e9-299f-4a28-ae24-0323c2adbc35fcfff1ad1cd191d150_IMG_0239_Fotor.png'
import drapeauzambie from '../../../ASSETS/AfImmigration/drapeau-zambie.png'
import nigeria from '../../../ASSETS/AfImmigration/VIDEO-Lagos-State-unveils-Abiola-statue-to-mark-June-12-1.png'
import drapeaunigeria from '../../../ASSETS/AfImmigration/drapeau-du-nigeria.png'
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import Faq from './Faq'
import filleImg from '../../../ASSETS/AfImmigration/image.png'
import Footer from '../../COMPONNENT/Footer/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

//Importation des icones
import { IoIosAdd } from 'react-icons/io';
import { IoIosRemove } from 'react-icons/io';

// Importation des pop ups
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//formatages des variables
import DOMPurify from 'dompurify';

//importation des button du pop up
import Button from '@mui/material/Button';

function Home(){
    interface ItemCats {
        //Items_Numbers: number;
        Category_id: number;	
        group_category: number;	
        Names: string;	
        fr_Name: string;	
        Descriptions: string;	
        fr_description: string;
      }

    interface GroupCat {
        Group_id: number;	
        Names: string;	
        fr_Name: string;	
        Description: string;	
        fr_Description: string;	
      }

    interface Items{      
        Items_Numbers: number;	
        Categories: number;	
        Names: string;	
        fr_Name: string;	
        //Types: string;	
        //Prices: double;	
        //nb_place: string;	
        //Available: string;	
        //Status: string;	
        Overview: string;	
        fr_overview: string;	
        Descriptions: string;	
        fr_description: string;	
        Pictures: string;	
        //subscription_frequency: string;	
        //initiale_prices: string;	
        //related_link: string;	
        //item_doc: string;	
        //contract: string;	
        //Category_id: number;	
      }
    localStorage.setItem('url', JSON.stringify('/accueil_afrique_immigration'));

    // Creer la varible déclencheuse du pop up
    const [openItemCat, setOpenItemCat] = React.useState(false);
    //const [openAllItem, setOpenAllItem] = React.useState(false);
    const [loading, setLoading] = useState(true);

    const [itemcats, setItemCats] = useState<ItemCats[]>([]);
    const [items, setItems] = useState<Items[]>([]);

    const [allitems, setAllItem] = useState<Items[]>([]);
    const [allitemcats, setAllItemCats] = useState<ItemCats[]>([]);
    const [itemsToShow, setItemsToShow] = useState<number>(6);
    const [showAllItems, setShowAllItems] = useState<boolean>(false);
    
    const [group_category, setGroup_Category] = useState<GroupCat[]>([]);
    
    //recherche
    const [searchTerm, setSearchTerm] = useState("");
    const [itemFilters, setItemFilters] = useState<Items[]>([]);

    const nav=useNavigate()
    const navrdv=useNavigate()
    const navCat=useNavigate()
    const BtnItmCat=(Category_id : any)=>{
        navCat('/accueil_afrique_immigration_category/'+Category_id)
    }

    const BtnSeeMore=(Items_Numbers : any)=>{
        //console.log(Items_Numbers)
        nav('/accueil_afrique_immigration/Conseil/'+Items_Numbers)
    }

    const BtnPrendreRdv=(Items_Numbers : any)=>{
        //console.log(Items_Numbers)
        navrdv('/appointement/RendezVousService/'+Items_Numbers)
    }

    const removeTagsAndEntities = (text: string) => {
        return text.replace(/<\/?(p|strong)>|&nbsp;/g, '');
      };
    //voir plus de services
    const handleShowAllItems = () => {
        setShowAllItems(true);
        setItemsToShow(items.length); // Afficher tous les éléments
       
        // Calculer la position vers laquelle faire défiler la page vers le bas
        const scrollPosition = window.scrollY + window.innerHeight;

        // Faire défiler la page vers la position calculée
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

      //voir moins de service 
      const handleHideAllItems = () => {
        setShowAllItems(false);
        //setItemsToShow(6);
        // Faire défiler la page vers le haut
        window.scrollTo({ top: 1000, behavior: 'smooth' });
      };


  // Pour le scroll dans le pop up
        const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

        // fonction pour éteindre le pop up
        const handleCloseItemCat = () => {
            setOpenItemCat(false);
        };


    //Recherche
    const handleSearchInputChange = (event : any) => {
        setSearchTerm(event.target.value);
        //Filtrer la recherche
        if(event.target.value !== ""){  
            setItemFilters(allitems.filter((item) =>
                item.fr_Name.toLowerCase().includes(event.target.value.toLowerCase()))
            );
        }

    };    


     // États de chargement individuels
     const [loadingCategoryItem, setLoadingCategoryItem] = useState(true);
     const [loadingItem, setLoadingItem] = useState(true);
     const [loadingGroupCat, setLoadingGroupCat] = useState(true);
 
   
    useEffect(() =>{
        
        //liste des sous catégorie
        async function getCategoryItem(){
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/items/category`)
                setItemCats(response.data.data.slice(0, 4))
                setAllItemCats(response.data.data)
            }catch (err) {
                console.log(err);
            }
            // finally {
            //     setLoadingCategoryItem(false);
            // }
            
        }
        getCategoryItem()
        
            //liste des items
            async function getItem(){
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/items`)
                    // Récupérer les 6 premiers éléments
                    const initialItemsArray = response.data.data.slice(0, 6);
                    // Mélanger les 6 premiers éléments
                    const shuffledInitialItems = [...initialItemsArray].sort(() => Math.random() - 0.5);
                    // Stocker les 6 premiers éléments dans l'état
                    setItems(shuffledInitialItems);
                    // Stocker tous les éléments dans l'état
                    setAllItem(response.data.data);
                    //console.log(response.data.data);
                }catch (err) {
                    console.log(err);
                }
                // finally {
                //     setLoadingItem(false);
                // }
            }
            getItem()

            //liste des group catégorie
                async function getGroupCat(){
                    try {
                        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/group/category/items`)
                        setGroup_Category(response.data.data)
                        //console.log(response.data.data)
                    }catch (err) {
                        console.log(err);
                    }
                    // finally {
                    //     setLoadingGroupCat(false);
                    // }
                }
                getGroupCat()

                const timer = setTimeout(() =>{
                    // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
                    setLoading(false);
                }, 2000); // 5000 millisecondes = 5 secondes

                // Nettoyer le timer si le composant est démonté avant la fin du délai
                return () => clearTimeout(timer);
    }, []);

    
    const itemsToDisplay = showAllItems ? allitems : items;
    //console.log(itemsToDisplay)
    const filteredItems=itemsToDisplay;

    // Utiliser useEffect pour surveiller les états de chargement individuels
    // useEffect(() => {
    //     if (!loadingCategoryItem && !loadingItem && !loadingGroupCat) {
    //         setLoading(false);
    //     }
    // }, [loadingCategoryItem, loadingItem, loadingGroupCat]);

    // if (loading) {
    //     return <div>Loading spinner...</div>;
    // }
    
  return (
    <div className='bg-[#F7F7F7]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading/>
          ) : (
        <>
        <Header/>
        <div style={{ backgroundImage:`url(${backimage})` }} className='w-screen h-screen bg-center bg-no-repeat bg-cover'>
            <div className='flex s:flex-col sm:flex-row sm:justify-between'>
            <Link to='/'>
                <div style={{ borderBottomRightRadius: "100px" }} className='w-40 pb-4 pr-4 bg-white sm:pl-5 s:pl-2'>
                    <img src={logo1} className='h-20 pt-6 s:pl-0 ' alt='logo' />
                </div>
            </Link>
            <BoutonLogin/>
            </div>

            <div className=' sm:pl-10 s:pl-4 sm:pt-10'>
                <div className='xs:text-4xl s:text-lg sm:text-2xl font-semibold text-[#233D4D] xs:w-[600px]'>
                    Conseils et accompagnements en matière d'installation et intégration en Afrique
                </div>
                <div className='flex flex-col space-y-4 s:pt-4 sm:pt-10'>
                        {itemcats.map((item, index)=> (
                            <div key={index} className='flex flex-row items-center space-x-2'>
                                <FaCheck className='text-[#fe7f2d] text-2xl' />
                                <div onClick={()=>BtnItmCat(item.Category_id ? item.Category_id : "")} className='xs:text-xl s:text-base sm:text-lg text-[#233D4D] hover:bg-[#FCCA46] hover:text-white'  
                                style={{ cursor: 'pointer' }}>{item.fr_Name ? item.fr_Name.replace(/^<p>|<\/p>$/g, '') : ""}</div>
                            </div>
                        ))}
                    
                        <button onClick={() => setOpenItemCat(true)} className='s:mt-8 sm:mt-12 bg-[#fe7f2d] flex flex-row items-center space-x-2 text-white h-8 w-[90px]  px-2 transform active:scale-75 transition-transform'>
                            <p>Voir plus</p>
                        </button>
                </div>
                <Link to='/appointement/RendezVousService'>
                    <button className='s:mt-8 sm:mt-12 bg-[#FCCA46] items-center space-x-2 text-white h-10 w-[200px]  px-2 transform active:scale-75 transition-transform'>
                        <p className='flex'>Prendre rendez-vous

                        <IoIosSend style={{marginTop: '5px'}}/>
                        </p>
                    </button>
                </Link>
            </div>

        </div>

        <div className='flex flex-col items-center space-y-4 py-14'>
            <div className='flex flex-row items-center space-x-2 '>
                <LiaTelegramPlane className='text-2xl text-[#fe7f2d]' />
                <div className='text-2xl font-semibold text-[#233D4D]'>Nos services</div>
            </div>
            <div className='px-2 text-lg xs:w-[700px] text-center'>
                {
                group_category[0]?.fr_Description
                    ? removeTagsAndEntities(group_category[0].fr_Description)
                    : "Aucune description n'est associée"
                }
            </div>
            {/* Search Item */}
            <div className='px-2 py-2 w-[70%]'>
                <input
                    name='searchItem'
                    type='search' 
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    style={{ 
                        backgroundColor: "#f7f7f7", 
                        borderRadius: "1.2rem",
                        border: "1px solid #233d4d", // Bordure autour de l'input
                        paddingLeft: "0.5rem", // Espacement à gauche de l'input
                        paddingRight: "0.5rem", // Espacement à droite de l'input
                        width: "100%",
                        outline: "none", // Suppression de la bordure bleue autour de l'input lorsqu'il est sélectionné
                        
                        height: "2.5rem", // Hauteur de l'input
                        fontSize: "1rem", // Taille de la police de caractères de l'input
                        color: "#233D4D" // Couleur du texte de l'input
                    }} 
                    className='w-full border-none' 
                    placeholder="Rechercher un service ici..."
                    required
                />
            </div>
            <div className='grid gap-10 pt-10 place-content-center place-items-center md:grid-cols-3 s:grid-cols-1 xs:grid-cols-2'>
                {/* Conseil */}
                {searchTerm === "" ? (filteredItems.map((item, index) => (
                    <div key={index} className='bg-white md:w-[360px] sm:w-[300px] px-2 s:w-[260px] h-auto shadow-lg rounded-t-2xl'>
                        {/* image */}
                        {item.Pictures ? 
                            (<img src={`data:image/png;base64,${item.Pictures ? item.Pictures : ""}`} 
                            alt='conseil' className='md:w-[360px] sm:w-[300px] h-[220px] s:w-[260px] rounded-2xl'/>) : 
                            (<img src={recommandation} alt='conseil' className='md:w-[360px] s:w-[260px] sm:w-[300px] rounded-2xl'/>)
                        }
                        {/* icone cercle */}
                        <div className='bg-[#233D4D] rounded-full w-14 h-14 flex items-center justify-center relative bottom-8 md:left-72 s:left-48 sm:left-56'>
                            <img src={iconeConseil} alt='iconeconseil' className='h-8 w-18'/>
                        </div>
                        <div className='relative flex flex-col space-y-3 bottom-8 left-5'>
                            {/* description */}
                            <div className='text-xl line-clamp-1 uppercase font-semibold text-[#233D4D]'>{item.fr_Name ? item.fr_Name.replace(/^<p>|<\/p>$/g, '') : ""}</div>
                            <div className='md:w-[300px] sm:w-[260px] line-clamp-5 s:w-[230px] text-start'>
                                {item.fr_overview ? item.fr_overview.replace(/^<p>|<\/p>$/g, '')
                                     :""  
                                }
                            </div>
                            <div className='flex s:flex-col md:flex-row md:items-center s:space-y-3 md:space-y-0 md:space-x-4'>
                                {/* Voir plus */}
                                <button onClick={() => BtnSeeMore(item.Items_Numbers ? item.Items_Numbers : "")} className='flex items-center space-x-2 text-[#fe7f2d] text-lg transform active:scale-75 transition-transform '>
                                    <div>En savoir plus</div>
                                    <IoIosArrowDropright />
                                </button>
                                <button onClick={() => BtnPrendreRdv(item.Items_Numbers ? item.Items_Numbers : "")} className='bg-[#233D4D] text-white h-8 w-[170px] rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 '>
                                    Prendre rendez-vous
                                </button>
                            </div>
                        </div>
                    </div>)
                )) : (itemFilters.map((item, index) => (
                    <div key={index} className='bg-white md:w-[360px] sm:w-[300px] s:w-[260px] h-auto shadow-lg rounded-t-2xl'>
                        {/* image */}
                        {item.Pictures ? 
                            (<img src={`data:image/png;base64,${item.Pictures ? item.Pictures : ""}`} 
                            alt='conseil' className='md:w-[360px] sm:w-[300px] h-[220px] s:w-[260px] rounded-2xl'/>) : 
                            (<img src={recommandation} alt='conseil' className='md:w-[360px] s:w-[260px] sm:w-[300px] rounded-2xl'/>)
                        }
                        {/* icone cercle */}
                        <div className='bg-[#233D4D] rounded-full w-14 h-14 flex items-center justify-center relative bottom-8 md:left-72 s:left-48 sm:left-56'>
                            <img src={iconeConseil} alt='iconeconseil' className='h-8 w-18'/>
                        </div>
                        <div className='relative flex flex-col space-y-3 bottom-8 left-5'>
                            {/* description */}
                            <div className='text-xl line-clamp-1 uppercase font-semibold text-[#233D4D]'>{item.fr_Name ? item.fr_Name.replace(/^<p>|<\/p>$/g, '') : ""}</div>
                            <div className='md:w-[300px] sm:w-[260px] line-clamp-5 s:w-[230px] text-start'>
                                {item.fr_overview ? item.fr_overview.replace(/^<p>|<\/p>$/g, '')
                                     :""  
                                }
                            </div>
                            <div className='flex s:flex-col md:flex-row md:items-center s:space-y-3 md:space-y-0 md:space-x-4'>
                                {/* Voir plus */}
                                <button onClick={() => BtnSeeMore(item.Items_Numbers ? item.Items_Numbers : "")} className='flex items-center space-x-2 text-[#fe7f2d] text-lg transform active:scale-75 transition-transform '>
                                    <div>En savoir plus</div>
                                    <IoIosArrowDropright />
                                </button>
                                <button onClick={() => BtnPrendreRdv(item.Items_Numbers ? item.Items_Numbers : "")} className='bg-[#233D4D] text-white h-8 w-[170px] rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 '>
                                    Prendre rendez-vous
                                </button>
                            </div>
                        </div>
                    </div>)
                ))
            }
            </div>
            {!showAllItems && (
                <button onClick={handleShowAllItems} className='bg-[#fe7f2d] flex flex-row items-center space-x-2 text-white h-8 w-[125px] px-2 transform active:scale-75 transition-transform'>
                    <IoIosAdd style={{ fontWeight: 'bold', fontSize: '25px' }}/> {/* Icône "+" */}
                    <p>Voir plus</p>
                </button>
            ) }
            {showAllItems && items.length >= itemsToShow && (
                <button onClick={handleHideAllItems} className='bg-[#fe7f2d] flex flex-row items-center space-x-2 text-white h-8 w-[125px] px-2 transform active:scale-75 transition-transform'>
                    <IoIosRemove style={{ fontWeight: 'bold', fontSize: '25px' }}/> {/* Icône "-" */}
                    <p>Voir moins</p>
                </button>
            )}
        </div>

            <div className='flex flex-col items-center space-y-4 bg-white py-14'>
                <div className='flex flex-row items-center space-x-2 '>
                    <IoPin className='text-2xl text-[#fe7f2d]' />
                    <div className='text-2xl font-semibold text-[#233D4D]'>Votre destination</div>
                </div>
                <div className='text-lg xs:w-[700px] text-center px-2'>
                    <span className='text-bold'>Mon Bon Séjour</span>  vous accompagne dans votre processus
                    d'installation et d'intégration dans les pays ci-dessous et partout ailleurs dans le monde.
                </div>
                <div className='grid gap-4 pt-10 place-content-center place-items-center md:grid-cols-4 s:grid-cols-1 xs:grid-cols-2'>
                {/* BENIN */}
                <div className='flex flex-col items-center example'>
                        <img src={benin} alt='benin' className='sm:w-[290px] s:w-[260px]'/>
                        <div className="fadedbox">
                            <div className="title text">BENIN</div>
                        </div>
                        <img src={drapeauBenin} alt='drapeaubenin' className='w-[60px] relative bottom-7'/>
                    </div>
                    {/* COTE D IVOIRE */}
                    <Link to='/accueil_afrique_immigration/Destination/VoirPlus'>
                        <div className='flex flex-col items-center example'>
                            <img src={CI} alt='CI' className='sm:w-[290px] s:w-[260px]'/>
                            <div className="fadedbox">
                                <div className="title text">Côte d'ivoire</div>
                            </div>
                            <img src={drapeauCI} alt='drapeauCI' className='w-[60px] relative bottom-7'/>
                        </div>
                    </Link>
                    {/* ZAMBIE */}
                    <div className='flex flex-col items-center example'>
                        <img src={zambie} alt='zambie' className='sm:w-[290px] s:w-[260px]'/>
                        <div className="fadedbox">
                            <div className="title text">ZAMBIE</div>
                        </div>
                        <img src={drapeauzambie} alt='drapeauzambie' className='w-[60px] relative bottom-7'/>
                    </div>
                    {/* NIGERIA */}
                    <div className='flex flex-col items-center example'>
                        <img src={nigeria} alt='benin' className='sm:w-[290px] s:w-[260px]'/>
                        <div className="fadedbox">
                            <div className="title text">NIGERIA</div>
                        </div>
                        <img src={drapeaunigeria} alt='drapeaubenin' className='w-[60px] relative bottom-7'/>
                    </div>
                </div>

                <Link to='/accueil_afrique_immigration/Destination'>
                    <div className='flex flex-col items-center text-[#233D4D] space-y-3 text-xl'>
                        <button>Tous les pays</button>
                        <HiOutlineChevronDoubleDown />
                    </div>
                </Link>
            </div>

            <div className='flex md:flex-row s:flex-col s:space-y-10 md:space-y-0 s:items-center md:justify-center md:space-x-[200px] py-14'>

                <div className='flex flex-col space-y-6'>
                <div className='text-[#233D4D] font-semibold s:text-2xl sm:text-3xl  xs:text-4xl  s:w-[260px]  sm:w-[300px] xs:w-[400px]'>Questions fréquemment posées</div>
                <div className='xs:w-[400px] s:w-[260px]  sm:w-[300px] text-lg text-[#4E4E4E]'>
                    Des réponses à certaines questions fréquentes pour vous aiguiller.
                </div>
                    <img src={filleImg} alt='fille' className='xs:w-[400px] s:w-[260px]  sm:w-[300px]'/>
                </div>

                <div className='xs:w-[500px] s:w-[260px]  sm:w-[300px]'>
                    <Faq/>
                </div>
            </div>
        <Footer/>
        </>
          )}
          {/* Le pop up de item category */}
        <Dialog
                open={openItemCat}
                onClose={handleCloseItemCat}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">

                <DialogTitle id="scroll-dialog-title" className='text-xl font-semibold text-orange-500' >Toutes nos différentes catégories de services</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                                                
                    <DialogContentText
                        id="scroll-dialog-description">
                        <div className='flex flex-col space-y-4 s:pt-4 sm:pt-10'>
                            {allitemcats.map((item, index)=>(

                                <div key={index} className='flex flex-row items-center space-x-2'>
                                    <FaCheck className='text-[#fe7f2d] text-2xl' />
                                    <div onClick={()=>BtnItmCat(item.Category_id ? item.Category_id : "")} 
                                        className='xs:text-xl s:text-base sm:text-lg text-[#233D4D] hover:bg-[#fe7f2d] hover:text-white'  
                                            style={{ cursor: 'pointer' }}  >{item.fr_Name ? item.fr_Name : ""}</div>
                                </div>
                            ))}
                    
                </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='transition-transform transform active:scale-75' style={{backgroundColor:"#fe7f2d", color:"white"}} onClick={handleCloseItemCat}>Retour</Button>
                    {/* <Button className='transition-transform transform active:scale-75' style={{border:"2px solid #233D4D", color:"#233D4D"}} 
                    onClick={()=>{
                      setAcceptItemCat(true);
                      setOpenItemCat(true);
                    }}>Accepter</Button> */}
                    
                </DialogActions>
        </Dialog>
    </div>
  )
}

export default Home