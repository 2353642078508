import React, { Component } from 'react'
import { useState, useEffect, FormEvent } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Footer from '../../COMPONNENTS/Footer/Footer'
import Header from '../../COMPONNENTS/Header/Header'
import rdv1 from '../../../ASSETS/CaImmigration/images/rdv2_barniere.png'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';
import Navbar from './Navbar';
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify"
//import RDV from "../../../API/immigration/rendezvous/Rdv"
import Rdvbien from "../../../API/immigration/rendezvous/Rdvbien"
import Paie from "../../../API/immigration/paiement/paiment"
//import PopopPaiement from '../../COMPONNENTS/PopopPaiement/PopopPaiement'

import { FedaCheckoutButton, FedaCheckoutContainer } from 'fedapay-reactjs';

import axios from 'axios';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import info from '../../../ASSETS/CaImmigration/icones/credit-card.png'
import { useRef } from 'react';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

// function PopopFeda({ action, price }: any) {

//     const [open, setOpen] = React.useState(action);

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

//     const checkoutEmbedOptions = {
//         public_key: PUBLIC_KEY,
//         transaction: {
//           amount: price,
//           description: 'Prise de RDV avec Mon Bon Sejour'
//         },
//         currency: {
//           iso: 'XOF'
//         }
//       };


//     return (
//         <React.Fragment>
//             {/* variant="outlined" */}
//             {/* <Button onClick={handleClickOpen} style={{ backgroundColor: "#FCCA46", color: "white" }} className='w-40 h-12 text-lg rounded '>
//                 Payer Maintenant
//             </Button> */}
//             <BootstrapDialog
//                 onClose={handleClose}
//                 aria-labelledby="customized-dialog-title"
//                 open={open}
//             >
//                 <FedaCheckoutContainer options={ checkoutEmbedOptions } />
//             </BootstrapDialog>
//         </React.Fragment>
//     );
// }



function PopopPaiement({ open, price }: any) {
    const [data, setOpen] = React.useState(open);

    const [action, setAction] = React.useState(false);
    const [prix, setPrix] = React.useState(price);

    const handleClickOpen = () => {
        //setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

    const checkoutButtonOptions = {
        public_key: PUBLIC_KEY,
        transaction: {
          amount: price,
          description: 'Prise de RDV avec Mon Bon Sejour'
        },
        currency: {
          iso: 'XOF'
        },
        button: {
          class: 'btn btn-primary',
          text: 'Payer ' +price+ ' FCFA'
        },
        onComplete(resp : any) {
        //   const FedaPay = window['FedaPay'];
        //   if (resp.reason === FedaPay.DIALOG_DISMISSED) {
        //     alert('Vous avez fermé la boite de dialogue');
        //   } else {
        //     alert('Transaction terminée: ' + resp.reason);
        //   }
    
          console.log(resp.transaction);
        }
      };


    const payBtn = () => {

        setAction(true);

    };

    return (
        <React.Fragment>
            {/* variant="outlined" */}
            {/* <Button onClick={handleClickOpen} style={{ backgroundColor: "#FCCA46", color: "white" }} className='w-40 h-12 text-lg rounded '>
                Payer Maintenant
            </Button> */}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={data}
            >

                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='flex items-center text-[#FE7F2D] space-x-3'>
                    <img src={info} alt='information' className='w-8' />
                    <p> Veuillez choisir le mode de paiement</p>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom className='text-[#4e4e4e]'>
                        Pour les paiements avec votre compte Az, choisissez l'option payer
                        avec mon compte Az-Pay. Et pour les paiements par carte bancaire
                        ou mobile money, choisissez l'option payer par carte ou mobile money
                    </Typography>
                </DialogContent>
                
                <DialogActions className='flex s:flex-col xs:flex-row s:space-y-3 xs:space-y-0'>
                    <Button className='' style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }} onClick={handleClose}>
                        Az-Pay : {price} F CFA
                    </Button>
                    <FedaCheckoutButton options={ checkoutButtonOptions } />
                    <Button className='' style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }} onClick={payBtn} >
                        Carte bancaire /Mobile money : {price} F CFA
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

function Rdv() {

    localStorage.setItem('url', JSON.stringify('/PrendreRendezVous'));
    const firstInputRef = useRef<HTMLInputElement>(null); // Spécifier le type attendu
    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        //console.log(saved)
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        //console.log(initialValue)
        return initialValue;
    });

    //Spécification du type des données reçues de l'API
    interface Item {
        Items_Numbers: number;
        Categories:	number;
        Names: string;
        fr_Name: string;	
        Types:	string;
       
        // Autres propriétés si nécessaire
    }

    const [open, setOpen] = React.useState(false);

    const votrenom = data !== null ? data.userLastname + ' ' + data.userName : "";
    //console.log(data)
    const navigate = useNavigate();

    const [res, setRes] :any = useState();

    const [price, setPrice] :any = useState();

    const [nom, setNom] = useState(votrenom || "")
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [tel, setTel] = useState(data !== null ? data.userTel : "" || "")
    const [sujet, setSujet] = useState("")
    const [usermail, setUserEmail] = useState(data !== null ? data.userMail : "" || "")
    const [sujetItem, setSujetItem] = useState<Item[]>([]);
    const [sujets, setSujets] = useState<Item[]>([]);
    const [msg, setMsg] = useState("")

    // const { FedaPay, Transaction } = require('fedapay')

    // const fetchData = async () =>{
    // await  fetch(`${process.env.REACT_APP_BASE_URL}/api/rdv/price`)
    // .then(response => response.json())
    // .then(data => setRes(data))
    // .catch(err => console.log(err))
    // }

    const [loading, setLoading] = useState(true);
    const id=useParams()
    useEffect(() => {
       
        window.scrollTo(0, 0);
        //récupération du bien par rapport à son id
        if(id)
            {
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/bien/info/${id.item_id}`)
                    .then(response => {
                          // Mettez à jour l'état avec les données récupérées
                        setSujetItem(response.data.data);
                        setSujet(response.data.data[0].fr_Name)
                          //console.log(response.data.data);
                    })
                .catch(err => console.log(err));
            }

              //récupération de tous les bien
              async function getBienItem(){
                  await axios.get(`${process.env.REACT_APP_BASE_URL}/api/get/bien/item`)
                    .then(response => {
                            // Mettez à jour l'état avec les données récupérées
                        setSujets(response.data.data);
                            //console.log(response);
                    })
                    .catch(err => console.log(err));
              }
              getBienItem()
        
        if (!data) {
            navigate("/Connexion");
        }
        //fetchData();
        //console.log(res);
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        
        return () => clearTimeout(timer);
    },[]);

    // get all search params (including ?)
    //const queryString = window.location.search;
    // it will look like this: ?product=shirt&color=blue&newuser&size=m

    // parse the query string's paramters
    //const urlParams = new URLSearchParams(queryString);

    // To get a parameter simply write something like the follwing
    // const paramValue = urlParams.get('status');

    // if (paramValue != null && paramValue === 'approved') {
    //     const paramId = urlParams.get('id');
    //     var templateParams = {
    //         nom: nom,
    //         usermail: usermail,
    //         tel: tel,
    //         date: date,
    //         time: time,
    //         sujet: sujet,
    //         msg: msg,
    //         user: data.userId,
    //         reference: paramId,
    //     };
    //     RDV.submitForm(templateParams)
    //         .then((res: any) => {
    //             if (res.data.status === 201) {
    //                 toast.success(res.data.message);
    //                 navigate("/homeCaimmigration");
    //                 console.log(res);
    //             } else {
    //                 toast.error(res.data.message);
    //                 console.log(res);
    //             }
    //         })
    //         .catch((err: any) => {
    //             toast.error('Erreur merci de reessayer !');
    //             console.log(err);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         })
    // }



    function submit(e: FormEvent) {
        e.preventDefault()

        setLoading(true)
        // console.log(sujet)
        var templateParams = {
            nom: nom,
            usermail: usermail,
            tel: tel,
            date: date,
            time: time,
            sujet: sujet,
            msg: msg,
            user: data.userId,
        };
        // console.log(templateParams)
        // if(res.status === 201){
        // // prix de rdv connu
        // localStorage.setItem('rdv', JSON.stringify(templateParams));
        // setOpen(true)
        // setPrice(res.data)
        // }else{

        Rdvbien.submitForm(templateParams)
        .then((res: any) => {
            if (res.data.status === 201) {
                toast.success(res.data.message);
                navigate("/homeCaimmigration");
                console.log(res);
            } else {
                toast.error(res.data.message);
                console.log(res);
            }
        })
        .catch((err: any) => {
            toast.error('Erreur merci de reessayer !');
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })

        //}
        /* Remplacez VOTRE_CLE_API par votre véritable clé API */
        // FedaPay.setApiKey("VOTRE_CLE_API_SECRETE");

        // /* Précisez si vous souhaitez exécuter votre requête en mode test ou live */
        // FedaPay.setEnvironment('sandbox'); //ou setEnvironment('live');

        /* Créer la transaction */
        // const transaction = await Transaction.create({
        //   description: 'Description',
        //   amount: 2000,
        //   callback_url: 'https://maplateforme.com/callback',
        //   currency: {
        //       iso: 'XOF'
        //   },
        //   customer: {
        //       firstname: 'John',
        //       lastname: 'Doe',
        //       email: 'john.doe@example.com',
        //       phone_number: {
        //           number: '97808080',
        //           country: 'BJ'
        //       }
        //   }
        // });

    }


    return (
        <div style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }}>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <Header />

                    <div>
                        <ToastContainer />
                    </div>

                    {/* back image */}
                    <div className='relative z-0 flex-col w-screen h-screen bg-center bg-no-repeat bg-cover back_image ' style={{ backgroundImage: `url(${rdv1})` }}>
                        <Navbar />
                        <div className='flex mt-20 text_home s:justify-center md:justify-end md:pl-20 s:pl-5 s:pt-20 md:pt-0'>
                            <div className='flex pt-20  text-center  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg  font-semibold xs:w-[470px] md:w-[770px] '>
                                <p>PRENDRE RENDEZ-VOUS</p>
                            </div>
                        </div>

                    </div>

                    {/* option */}
                    <OptionCard />

                    {/* en tete */}
                    <div id="form" className='flex flex-col items-center justify-center pb-12 mt-10 space-y-6'>
                        <div style={{ color: "##4e4e4e" }} className='font-bold text-center xs:text-2xl s:text-lg'>Nous vous orientons dans le monde de l'immigration</div>
                        <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
                    </div>

                    {/* formulaire de prise de rdv */}
                    <div className='flex justify-center py-20 border-t-2 border-gray-200 '>
                        <form action="" onSubmit={submit} className='bg-white md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-6 justify-center items-center'>
                            <p className='xs:w-[400px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez remplir tout les champs du formulaire afin que votre demande de prise de rendez-vous soit prise en compte</p>
                            <input type='hidden' value={data.userId} required ref={firstInputRef}/>
                            <div className='flex flex-col space-y-3 '>
                                <label className='text-lg'>Nom & Prénom <span className='text-red-600'>*</span></label>
                                <input name='name' value={nom} onChange={(e) => setNom(e.currentTarget.value)} type='text' style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required />
                            </div>

                            <div className='flex flex-col space-y-3 '>
                                <label className='text-lg'>Adresse e-mail <span className='text-red-600'>*</span></label>
                                <input name='mail' value={usermail} onChange={(e) => setUserEmail(e.currentTarget.value)} type='email' style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required />
                            </div>

                            <div className='flex flex-col space-y-3 '>
                                <label className='text-lg'>Numero de portable <span className='text-red-600'>*</span></label>
                                <input name='numero' value={tel} onChange={(e) => setTel(e.currentTarget.value)} type='tel' style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required />
                            </div>

                            <div className='flex flex-col space-y-3 '>
                                <label className='text-lg'>Date du rendez-vous <span className='text-red-600'>*</span></label>
                                <input name='date' value={date} onChange={(e) => setDate(e.currentTarget.value)} type='date' style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required />
                            </div>

                            <div className='flex flex-col space-y-3 '>
                                <label className='text-lg'>Heure du rendez-vous <span className='text-red-600'>*</span></label>
                                <input value={time} onChange={(e) => setTime(e.currentTarget.value)} type='time' style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' />
                            </div>

                            {/* <div className='flex flex-col space-y-3 '>
                                <label className='text-lg'>Sujet <span className='text-red-600'>*</span></label>
                                <input name='sujet' value={sujet} onChange={(e) => setSujet(e.currentTarget.value)} type='text' style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required />
                            </div> */}

                            <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            {/* <IoMdPin className=' text-[#fe7f2d]' /> */}
                                            <label className='text-lg'>Sujet <span className='text-red-600'>*</span></label>
                                        </div>
                                        <select required value={sujet} name='sujet'
                                         onChange={(e)=>setSujet(e.currentTarget.value)} 
                                         style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }} 
                                         className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14 pl-3'>
                                        {/* Affichez les options de la liste déroulante à partir des données sujet */}                                                                                  
                                                <option value="">Sélectionner un bien</option>
                                            {sujets.map((item, index)=>(
                                                <option key={index} value={item.fr_Name}>{item.fr_Name}</option>
                                            ))}                                        
                                        </select>
                            </div>

                            <div className='flex flex-col space-y-3 '>
                                <label className='text-lg'>Message <span className='text-red-600'>*</span></label>
                                <textarea value={msg} onChange={(e) => setMsg(e.currentTarget.value)} style={{ backgroundColor: "#f7f7f7", border: "1px solid #233d4d" }} className='border-none p-2 xs:w-[600px] s:w-[240px] sm:w-[300px] h-60' required > </textarea>
                            </div>

                            {/* <PopopPaiement open={open} price={price} /> */}

                            <button style={{ backgroundColor: "#FCCA46", color: "white" }} className='w-40 h-12 text-lg rounded '>Envoyer</button>
                        </form>
                    </div>

                    <Footer />
                </>
            )}
        </div>
    )
}

export default Rdv