import React from 'react'
import Navbar from './Navbar'
import BarresearchP from '../../COMPONENTS/BarreSearchP/BarreSearchP'
import accueil from '../../../ASSETS/tourisme/Image/accueilHebergement.png'

function BackImage() {
  return (
    <div>
       {/* navbar */}
            <Navbar />
            {/* back image */}
            <div className='navbar_animation bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative flex justify-center items-center' style={{ backgroundImage: `url(${accueil})` }}>
                <div className='flex flex-col items-center justify-center space-y-5 sm:pt-40 md:pt-0'>
                    <div className='navbar_animation text-white md:text-7xl sm:text-5xl font-semibold uppercase '>
                        Hebergements
                    </div>
                </div>
            </div>

            {/* barre de recherche principale */}
            <div className=' absolute inset-x-0 md:-bottom-20 xs:-bottom-40 s:-bottom-64 flex justify-center  '>
                <BarresearchP />
            </div>

            {/* title */}
            <div className='relative md:mt-40 s:mt-80 flex flex-col justify-center items-center space-y-5  px-10 pb-10'>
                <p className=' font-semibold xs:text-2xl s:text-xl text-center'><span className='text-[#FE7F2D]'>FAITES LE CHOIX DE</span> VOTRE HÉBERGEMENTS</p>
                <p className='text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                Réservez votre escapade parfaite en Côte d'Ivoire en quelques clics ! Explorez notre sélection exclusive 
                d'hébergements, choisissez votre destination de rêve, et sécurisez votre séjour en toute simplicité. 
                </p>
            </div>
    </div>
  )
}

export default BackImage