import React from 'react'
import axios from 'axios';
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";
import { FaGasPump } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { GiCarWheel } from "react-icons/gi";
import { RxDimensions } from "react-icons/rx";
import { WiSnowflakeCold } from "react-icons/wi";
import { MdOutlineWifi } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { FaUser } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";
import { MdOutlineRoomPreferences } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import { IoMdPin, IoIosMail } from "react-icons/io";
import SidebarTop from '../../SidebarTop';
import Voiture from "../../../../API/tourisme/location/index"
import { ToastContainer, toast } from "react-toastify"
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlineHomeWork, MdOutlinePayment } from "react-icons/md";
import { FaPhoneAlt } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import { FedaCheckoutButton, FedaCheckoutContainer } from 'fedapay-reactjs';
import {Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { loadStripe } from '@stripe/stripe-js';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

// Clé publique Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PaymentPopup({ open,tel, user_Id, montant, email, onSuccess, userPrenom,userNom, formData, onClose }) {
    const [isDialogOpen, setIsDialogOpen] = useState(open);
    useEffect(() => {
        setIsDialogOpen(open);
    }, [open]);

    useEffect(() => {
        setIsDialogOpen(open);
    }, [open]);

    
    
    const handleOpenAZ = () => {
        //alert("AZPay")
        // axios.get('/api/payment/create', {
        //     params: {
        //         provider: 'AZPay',
        //         item_id: 'item_id',
        //         item_name: 'item',
        //         amount: montant, // Assurez-vous de convertir les devises correctement
        //         currency: 'XOF',
        //         email: email,   
        //         description: 'Publicité sur Mon Bon Sejour', 
        //         phone: tel,
        //         email: email,
        //         last_name: userNom,  // Remplacez par les informations du client
        //         first_name: userPrenom,  
        //         success_url: 'successUrl',
        //         customer_number: userId
        //     }
        // }).then(response => {
        //     if (response.data.fail) {
        //         if (response.data.type_error === "balance_insufficient") {
        //             //setError("Votre solde est insuffisant pour effectuer cette transaction.");
        //         }
        //     } else if (response.data.success_url) {
        //         window.location.href = response.data.success_url;
        //     } else {
        //         //setError("Une erreur s’est produite, veuillez réessayer !");
        //     }
        // }).catch(error => {
        //     console.error("Erreur lors de la requête AZPay :", error);
        // });
    };

    

    const handleClose = () => {
        setIsDialogOpen(false);
        if (onClose) onClose();  
    };


    const handlePayCancel = () => {
        //console.log('Paiement annulé');
        handleClose();
    };
    const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
    const checkoutButtonOptions = {
        public_key: PUBLIC_KEY,
        transaction: {
          amount: montant,
          description: 'Publicité sur Mon Bon Sejour',
          callback_url: 'Dashboard/Publications/Hebergement/Ajouter_des_hebergements',
          custom_metadata: {
                user_id: user_Id,
                //additional_info: 'Some additional info'
            }
        },
        currency: {
          iso: 'XOF'
        },
        customer: {
          lastname: userNom,
          firstname: userPrenom,
          email: email
        },
        button: {
          class: 'btn btn-primary',
          text: 'Payer ' + montant + ' FCFA'
        },
        onComplete(resp) {
          const FedaPay = window['FedaPay'];
          if (resp.reason === FedaPay.DIALOG_DISMISSED) {
            //window.location.reload();
            toast.success('Vous avez fermé la boite de dialogue');
          } else {
            setIsDialogOpen(false);
            onSuccess();
    
            // Extraire les données de la transaction
            const transactionData = {
              transaction_id: resp.transaction.id,
              reference: resp.transaction.reference,
              amount: resp.transaction.amount,
              description: resp.transaction.description,
              status: resp.transaction.status,
              approved_at: resp.transaction.approved_at,
              customer: {
                firstname: resp.transaction.customer.firstname,
                lastname: resp.transaction.customer.lastname,
                email: resp.transaction.customer.email,
              },
              transaction_key: resp.transaction.transaction_key,
              fees: resp.transaction.fees,
              mode: resp.transaction.mode,
              amount_debited: resp.transaction.amount_debited,
              custom_metadata:resp.transaction.custom_metadata.user_id,
            };
           
            // Envoie les données de la transaction au backend
            fetch(`${process.env.REACT_APP_BASE_URL}/api/fedapay/callback`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify(transactionData),
            })
            .then(response => {
              if (!response.ok) {
                return response.json().then(errorData => {
                  throw new Error(errorData.message || 'Something went wrong');
                });
              }
              return response.json();
            })
            .then(data => {
              console.log('Transaction saved:', data);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
          }
    
          console.log(resp.transaction);
          
        },
        onCancel: handlePayCancel
    };
    
    // Lancer le paiement FedaPay
    const handlePayment = () => {
    const FedaPay = window['FedaPay'];
    FedaPay.init(checkoutButtonOptions).open();
    };

    //stripe
    const createPaymentSession = async () => {
        try {
            const successUrl = `${window.location.origin}/Dashboard/Publications/Location_Vehicule/Ajouter_les_vehicules`;
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/payment/stripe`, {
            provider: 'stripe',
            item_id: 'item_id',
            item_name: 'item',
            amount: montant*100,
            currency: 'XOF',
            email: email,
            description: 'Publicité sur Mon Bon Sejour',
            last_name: userNom,
            first_name: userPrenom,
            success_url: successUrl,
            customer_number:1,
          });
  
          if (response.data) {
              return response.data.sessionId;
              
            } else {
                console.error("Erreur lors de la création de la session de paiement :", response.data);
            }
        } catch (error) {
            console.error("Erreur lors de la requête Card :", error);
        }
    };
    
    const handleStripePayment = async () => {
        const sessionId = await createPaymentSession();
        if (sessionId) {
            const stripe = await stripePromise;
            //console.log(sessionId)
            
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionId,
            });
    
            if (error) {
                console.error("Erreur lors de la redirection vers Stripe :", error);
            } else {
                // Paiement réussi, appel de la fonction onSuccess
                onSuccess();
            }
            
        }
    };
    
    return (
        
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isDialogOpen} maxWidth="md" fullWidth={true}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='flex items-center text-[#FE7F2D] space-x-3'>
                <p>Veuillez choisir le mode de paiement</p>
                <IconButton aria-label="close" onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Pour les paiements avec votre compte Az, choisissez l'option payer avec mon compte Az-Pay. Et pour les paiements par carte bancaire ou mobile money, choisissez l'option payer par carte ou mobile money
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOpenAZ} style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}>
                    Az-Pay : {montant} F CFA
                </Button>
                
                <Button onClick={handleStripePayment} style={{ textTransform: 'none', backgroundColor: '#233D4D', color: 'white' }}>
                    Carte bancaire : {montant} F CFA
                </Button>
                
                
                <button onClick={handlePayment} className={checkoutButtonOptions.button.class} 
                    style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}>
                    Mobile money :{checkoutButtonOptions.button.text}
                </button>

            </DialogActions>

        </BootstrapDialog>
        
    );
}

function AjoutVehicule() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Location_Vehicule/Ajouter_les_vehicules'));

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [address, setAddress] = useState([]);

    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({});

    const [brand, setBrand] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCountryChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

    //province
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/province/list/${value}`)
     .then(response => {setProvinces(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const handleProvinceChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

    //city
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/city/list/${value}`)
     .then(response => {setCities(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    //console.log(formData)

    const navigate = useNavigate();

    useEffect(() => {
        if (!data) {
            navigate("/Connexion");
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/brand/all/`)
        .then(response => {setBrand(response.data.data)})
        //.then(response => console.log(response))
        .catch(err => console.log(err))

        //country
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/country/list`)
        .then(response => {setCountries(response.data.data)})
        //.then(response => console.log(response.data.data))
        .catch(err => console.log(err))

        //adresse
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/utilisateur/adresse/${data.azId}`)
        .then(response => {
        setAddress(response.data.data)
        //console.log(response);
   })
     .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    // États pour gérer le formulaire et le popup
    const [showPopup, setShowPopup] = useState(false);

    
    const submitForm = (event) => {
        //event.preventDefault();

        let fd = new FormData();

        fd.append("user", data.userId);
        fd.append("nom", formData.name_voiture);
        fd.append("type", formData.type);
        fd.append("consommation", formData.consommation);
        fd.append("transmission", formData.transmission);
        fd.append("methode", formData.methode);
        fd.append("date", formData.date);
        fd.append("adresse", formData.lieu);
        fd.append("size", formData.km);
        fd.append("camera", formData.TV);
        fd.append("clim", formData.climatiseur);
        fd.append("desc", formData.msg);
        fd.append("place", formData.nbre);
        fd.append("prix", formData.prix);
        fd.append("wifi", formData.wifi);
        fd.append("puissance", formData.chevaux);
        fd.append("date_debut", formData.date_debut);
        fd.append("date_fin", formData.date_fin);

        fd.append("tel", formData.phone ? formData.phone : data.userTel);
        fd.append("mail", formData.mail ? formData.mail : data.userMail);
        fd.append("pays", formData.country);
        fd.append("ville", formData.ville);
        fd.append("province", formData.province);

        if (formData.date_debut && formData.prix && formData.date_fin) {
            fd.append("montant", parseFloat(formData.prix) * 
            Math.ceil((new Date(formData.date_fin) - 
            new Date(formData.date_debut)) / (1000 * 60 * 60 * 24)));
        }
        
        for(let i = 0; i < document.getElementById("image")?.files.length; i++){
            fd.append("photo[]", document.getElementById("image")?.files[i]);
        }
    
        setLoading(true)
        Voiture.submitForm(fd)
            .then((res) => {
                if (res.data.status === 201) {
                    toast.success(res.data.message);
                    console.log(res);
                } else {
                    toast.error(res.data.message);
                    console.log(res);
                }
            })
            .catch((err) => {
                toast.error('Erreur merci de reessayer !');
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };

     // Fonction de gestion de la soumission du formulaire
     const handleSubmit = (event) => {
        event.preventDefault();
        // Afficher le popup de paiement
        setShowPopup(true);
    };

    // Callback de succès du popup de paiement
    const handlePopupSuccess = () => {
        //setShowPopup(false);
        submitForm();
    };

    // Callback d'annulation du popup de paiement
    const handlePopupCancel = () => {
        setShowPopup(false);
    };
    //console.log("consommation", formData.consommation)


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen h-screen barre_scroll'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules" className="opacity-60 md:text-base s:text-xs">
                                    Location de vehicule
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Ajouter
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <div>
                <ToastContainer />
            </div>

                        <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précedente</p>
                            </div>
                        </Link>
                        {/* container formulaire */}
                        <div className='flex justify-center py-20 '>
                            <form onSubmit={handleSubmit} className='flex flex-col space-y-3 '>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                    {/* Ajout des images */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                                            <label> Ajouter des images du vehicule</label>
                                        </div>
                                        <input type="file" required onChange={handleInputChange} accept="image/*" multiple name="image" id="image" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 ' />
                                    </div>
                                    {/* Nom du vehicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaCar className='text-xl text-[#fe7f2d]' />
                                            <label>Nom du vehicule</label>
                                        </div>
                                        <input type='text' required onChange={handleInputChange} name='name_voiture' placeholder='Ex : Toyota gris ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Marque du vehicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiSteeringWheel className='text-xl text-[#fe7f2d]' />
                                            <label>Marque du vehicule</label>
                                        </div>
                                        <select onChange={handleInputChange} required name='type' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                            <option value=''></option>
                                            {brand.map((option, index) => (
                                            <option key={index} value={option.id}>{option.brand}</option>
                                            ))}
                                            
                                        </select>
                                    </div>
                                    {/* Type de consommation */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaGasPump className='text-xl text-[#fe7f2d]' />
                                            <label>Type de consommation</label>
                                        </div>
                                        <select onChange={handleInputChange} required name='consommation' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                            <option value=''></option>
                                            <option value='diesel'>Diesel</option>
                                            <option value='essence'>Essence</option>
                                            <option value='electrique'>Electrique</option>
                                        </select>
                                        
                                    </div>
                                    {/* Transmission automobile */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiCarWheel className='text-xl text-[#fe7f2d]' />
                                            <label>Transmission automobile</label>
                                        </div>
                                        <select onChange={handleInputChange} required name='transmission' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                            <option value=''></option>
                                            <option value='automatic'>Automatique</option>
                                            <option value='manual'>Manuel</option>
                                            <option value='hybrid'>Hybride</option>
                                        </select>
                                    </div>
                                    {/* Type de paiement */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlinePayment className='text-xl text-[#fe7f2d]' />
                                            <label>Méthode de paiement</label>
                                        </div>
                                        <select onChange={handleInputChange} name="methode" required className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4' >
                                        <option value=""></option>
                                        <option value="1">Paiement en ligne et sur place</option>
                                        <option value="2">Paiement en ligne</option>
                                        <option value="3">Paiement sur place</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                {/* Tel */}
                                <div className='flex flex-col space-y-3'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <FaPhoneAlt className=' text-[#fe7f2d]' />
                                        <label>Telephone</label>
                                    </div>
                                    <input type='tel' name='phone' defaultValue={data.userTel} onChange={handleInputChange} placeholder="Ex : +225 xxx xxx xxxx " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                </div>
                                {/* Mail */}
                                <div className='flex flex-col space-y-3'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <IoIosMail className=' text-[#fe7f2d]' />
                                        <label>E-mail</label>
                                    </div>
                                    <input defaultValue={data.userMail} type='email' name='mail' onChange={handleInputChange} placeholder="Ex : info@monbonsejour.com " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                </div>
                                {/* Country */}
                                <div className='flex flex-col space-y-3'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <IoMdPin className=' text-[#fe7f2d]' />
                                        <label>Pays</label>
                                    </div>
                                    <select onChange={handleCountryChange} required name='country' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                        <option value={address.code}>{address.fr_Name}</option>
                                                            {countries.map((option, index) => (
                                        <option key={index} value={option.code}>{option.fr_Name}</option>
                                        ))}
                                                        
                                    </select>
                                </div>
                                {/* Province */}
                                <div className='flex flex-col space-y-3'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <IoMdPin className=' text-[#fe7f2d]' />
                                        <label>Province</label>
                                    </div>
                                    <select onChange={handleProvinceChange} required name='province' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                    <option value={address.code_province}>{address.pname}</option>
                                                        {provinces.map((option, index) => (
                                        <option key={index} value={option.code_province}>{option.fr_Name}</option>
                                        ))}
                                                        
                                    </select>
                                </div>
                                {/* Ville */}
                                <div className='flex flex-col space-y-3'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <IoMdPin className=' text-[#fe7f2d]' />
                                        <label>Ville</label>
                                    </div>
                                    <select onChange={handleInputChange} required name='ville' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                    <option value={address.code_city}>{address.cname}</option>
                                                                        {cities.map((option, index) => (
                                    <option key={index} value={option.code_city}>{option.fr_Name}</option>
                                    ))}
                                                                        
                                    </select>
                                </div>
                                {/* Lieu */}
                                <div className='flex flex-col space-y-3'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <IoMdPin className=' text-[#fe7f2d]' />
                                        <label>Lieu</label>
                                    </div>
                                    <input defaultValue={address.Adresses} type='text' name='lieu' onChange={handleInputChange} placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                </div>
                            </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    {/* caractéristique de la voiture */}
                                    <p className='text-lg font-semibold'>Dans votre vehicule</p>
                                    <div className='grid s:grid-cols-2 xs:grid-cols-3 gap-y-8'>
                                        {/* Climatiseur*/}
                                        <div className='flex flex-col space-y-2 '>
                                            <div className='flex flex-row items-center '>
                                                <WiSnowflakeCold className='text-2xl text-[#fe7f2d]' />
                                                <label>Climatiseur</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input onChange={handleInputChange} type='radio' name='climatiseur' value="1" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input onChange={handleInputChange} type='radio' name='climatiseur' value="0" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* Wifi  */}
                                        <div className='flex flex-col space-y-2 '>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <MdOutlineWifi className='text-xl text-[#fe7f2d]' />
                                                <label>Wifi </label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input onChange={handleInputChange} type='radio' name='wifi' value="1" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input onChange={handleInputChange} type='radio' name='wifi' value="0" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* Mini TV */}
                                        <div className='flex flex-col space-y-2 '>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <SlScreenDesktop className='text-xl text-[#fe7f2d]' />
                                                <label>Mini TV</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input onChange={handleInputChange} type='radio' name='TV' value="1" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input onChange={handleInputChange} type='radio' name='TV' value="0" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                <div className='flex flex-col space-y-3'>
                                <div className='flex flex-row items-center space-x-2'>
                                    <CiCalendarDate className=' text-[#fe7f2d]' />
                                    <label>Date de disponibilité</label>
                                </div>
                                <input name='date' onChange={handleInputChange} required type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'  />
                            </div>
                                    {/* Kilométrage du véhicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <RxDimensions className=' text-[#fe7f2d]' />
                                            <label>Kilométrage du véhicule</label>
                                        </div>
                                        <input type='number' onChange={handleInputChange} name='km' placeholder='Ex : 10 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Nombre de place */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaUser className=' text-[#fe7f2d]' />
                                            <label>Nombre de place</label>
                                        </div>
                                        <input type='number' required name='nbre' onChange={handleInputChange} placeholder='Ex : 5' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Puissance du véhicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineRoomPreferences className=' text-[#fe7f2d]' />
                                            <label>Puissance du véhicule</label>
                                        </div> 
                                        <input type='number' name='chevaux' onChange={handleInputChange}  placeholder='Ex : 10 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Tarif */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                                            <label>Tarif</label>
                                        </div>
                                        <input type='number' name='prix' required onChange={handleInputChange} placeholder='Ex : 70000 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                        <small>Le tarif doit être par jours</small>
                                    </div>
                                    {/* Description */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                                            <label>Description du véhicule</label>
                                        </div>
                                        <textarea required onChange={handleInputChange} name="msg" className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                                    </div>
                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                    {/* date debut */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <CiCalendarDate className=' text-[#fe7f2d]' />
                            <label>Date de debut affichage</label>
                        </div>
                        <input name='date_debut' required onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'  />
                    </div>

                    {/* date fin */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <CiCalendarDate className=' text-[#fe7f2d]' />
                            <label>Date de fin affichage</label>
                        </div>
                        <input name='date_fin' required onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'  />
                    </div>

                    {formData.date_debut && formData.prix && formData.date_fin ? 
                            <div className='flex flex-col space-y-3'>
                                <div className='flex flex-row items-center space-x-2 '>
                                    <GiMoneyStack className='text-xl text-[#1f2937]' />
                                    <label className='text-[#1f2937] text-bold'>Montant total: {(parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut))/ (1000 * 60 * 60 * 24)))} F CFA</label>
                                    <input hidden id="montant" onChange={handleInputChange} value={(parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut))/ (1000 * 60 * 60 * 24)))} name='montant' type='number'/>
                                    
                                </div>
                            </div>
                       
                        : ""
                    }
                    
                </div>
                {formData.date_debut && formData.prix && formData.date_fin ?
                    <button className='bg-[#FCCA46] w-[250px] h-10 rounded
                     text-white'>Procéder au paiement</button>
                
                    :
                    <button className='bg-[#FCCA46] w-[150px] h-10 rounded
                     text-white'>Ajouter</button>
                }

                {showPopup && (
                    <PaymentPopup
                        open={showPopup}
                        montant={parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut)) / (1000 * 60 * 60 * 24))}
                        onSuccess={handlePopupSuccess}
                        userNom={data.userLastname}
                        userPrenom={data.userName}
                        user_Id={data.userId}
                        email={data.userMail}
                        formData={formData}
                        onCancel={handlePopupCancel}
                    />
                )}
                

                                {/* <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Ajouter</button> */}
                            </form>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default AjoutVehicule