import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import SidebarTop from '../SidebarTop';
import { Typography } from "@material-tailwind/react";
import { FcCancel } from "react-icons/fc";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import {
    ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { HiArrowUpTray } from "react-icons/hi2";
import { RxDotsVertical } from "react-icons/rx";
import card from '../../../ASSETS/tourisme/Image/Rectangle arrondi 2.jpg'
import AZPAY from '../../../ASSETS/tourisme/Image/LogoAZ.png'
import { LiaQrcodeSolid } from "react-icons/lia";
import {
    Card,
    CardHeader,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
const TABLE_HEAD = ["Correspondant",  "Date & Heure", "Montant", "Statut", "Action",];

const TABLE_ROWS = [
    {
        name: "Koukou marie",
        email: "info@gmail.com",
        portable:"+225 01 00 00 00 00",
        date_heure: "22/10/2024, 08H45",
        montant: "10000",
        statut: "En attente",
    },
    {
        name: "Koukou marie",
        email: "info@gmail.com",
        portable:"+225 01 00 00 00 00",
        date_heure: "22/10/2024, 08H45",
        montant: "10000",
        statut: "Reçu",
    },
    {
        name: "Koukou marie",
        email: "info@gmail.com",
        portable:"+225 01 00 00 00 00",
        date_heure: "22/10/2024, 08H45",
        montant: "10000",
        statut: "Envoyé",
    },
    {
        name: "Koukou marie",
        email: "info@gmail.com",
        portable:"+225 01 00 00 00 00",
        date_heure: "22/10/2024, 08H45",
        montant: "10000",
        statut: "Echec",
    },
    {
        name: "Koukou marie",
        email: "info@gmail.com",
        portable:"+225 01 00 00 00 00",
        date_heure: "22/10/2024, 08H45",
        montant: "10000",
        statut: "Echec",
    },
];



function CreditAZ() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee] '>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>

          <div className='w-screen  barre_scroll h-screen'>
            {/* sidebar top  */}
            <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs >
                <Link to="" className="opacity-60">
                  Achat de credit
                </Link>
                
              </Breadcrumbs>
            </div>
            {/* container formulaire */}
            <div className=' h-screen md:p-10 s:p-4  '>
                <div className='grid md:grid-cols-3 s:grid-cols-1 gap-10'>
                    <div style={{ backgroundImage:`url(${card})` }} className='xs:w-[430px] xs:h-[250px]  p-6 flex flex-col space-y-2 text-white  rounded-lg shadow-md '>
                        <div className='flex flex-row  justify-between'>
                            <div className='w-24 h-14 rounded-xl bg-white flex justify-center items-center'>
                                <img src={AZPAY} alt='logo' className='w-20'/>
                            </div>
                            <LiaQrcodeSolid className='text-white text-6xl' />
                        </div>
                        <div className='pt-6 flex flex-row space-x-10'>
                            <div className='flex flex-col space-y-2'>
                                <div className='flex flex-col space-y-2'>
                                    <div>DATE D'EMMISSION</div>
                                    <div className=' font-bold'>25/09/2020</div>
                                </div>
                                <div className='flex flex-col space-y-'>
                                    <div>VALIDE JUSQU'AU</div>
                                    <div className=' font-bold'>25/09/2025</div>
                                </div>
                            </div>
                            <div className='flex flex-col space-y-2'>
                                <div>TITULAIRE DE LA CARTE</div>
                                <div className=' font-bold'>Franklin koulo junior ange</div>
                            </div>
                        </div>
                       
                       
                    </div>
                    <div style={{ backgroundImage:`url(${card})` }} className='xs:w-[430px] xs:h-[250px]  p-6 flex flex-col space-y-2 text-white  rounded-lg shadow-md '>
                        <div className='flex flex-row items-center space-x-4'>
                            <div  className='bg-white w-[260px] h-14'></div>
                            <div className='w-[100px]'>
                                Identification Code AZ PAY
                            </div>
                        </div>
                        <div className='pt-6 flex flex-row '>
                         Important : Cette carte est personnelle et vous permet d'effectuer toutes transactions 
                           sur la plateformes AZ en toutes tranquilité.
                        </div>
                       
                       
                    </div>
                    <div style={{ backgroundImage:`url(${card})` }} className='xs:w-[430px] xs:h-[250px]  p-6 flex flex-col space-y-2 text-white  rounded-lg shadow-md '>
                        <div className='uppercase font-thin'>Somme du compte</div>
                        <div className='text-3xl font-semibold'>200.000.000.000 FCFA</div>
                        
                       
                       
                    </div>
                   
                </div>

                <Link to='/Dashboard/Credit_AZ/Recharger'>
                    <div className='bg-white  mt-6 text-[#4E4E4E]  w-[140px] h-8 flex flex-row justify-center items-center space-x-2'>
                        <HiArrowUpTray />
                        <button >Recharger</button>
                    </div>
                 </Link>

                <div className='flex justify-center md:p-10 s:p-4'>

                            <Card className="h-full w-full border-t-8 border-[#fe7f2d] ">
                                <CardHeader floated={false} shadow={false} className="rounded-none">
                                    <Typography className='md:text-xl s:text-lg font-semibold' color="blue-gray">
                                    Historique de transaction credit AZ
                                    </Typography>
                                </CardHeader>
                                <CardBody className="overflow-scroll px-0">
                                    <table className="mt-4 w-full min-w-max overflow-scroll  table-auto text-left">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head, index) => (
                                                    <th
                                                        key={head}
                                                        className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                                    >
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                                        >
                                                            {head}{" "}
                                                            {index !== TABLE_HEAD.length - 1 && (
                                                                <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                                            )}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {TABLE_ROWS.map(
                                                ({ name, email, portable,  date_heure, montant, statut }, index) => {
                                                    const isLast = index === TABLE_ROWS.length - 1;
                                                    const classes = isLast
                                                        ? "p-4"
                                                        : "p-4 border-b border-blue-gray-50";

                                                    return (
                                                        <tr key={name} className='hover:bg-slate-100'>

                                                            <td className={classes}>
                                                                <div className="flex items-center space-x-2 ">
                                                                    <div className="flex flex-col">
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal"
                                                                        >
                                                                            {name}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal opacity-70"
                                                                        >
                                                                            {email}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal opacity-70"
                                                                        >
                                                                            {portable}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <div className="flex flex-col">
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {date_heure}
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal"
                                                                >
                                                                    {montant} XOF
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal"
                                                                >
                                                                    {statut}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Tooltip content="Action sur la publication">
                                                                    <IconButton variant="text">
                                                                        <Menu>
                                                                            <MenuHandler>
                                                                                <IconButton>
                                                                                    <RxDotsVertical />
                                                                                </IconButton>
                                                                            </MenuHandler>
                                                                            <MenuList className="flex flex-col gap-2">
                                                                                {/* Refuser */}
                                                                                <Link to="">
                                                                                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                                        <FcCancel className='text-lg' />
                                                                                        <div className="flex flex-col gap-1">
                                                                                            <Typography variant="small" color="gray" className="font-semibold">
                                                                                                Suprimer
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                </Link>
                                                                            </MenuList>
                                                                        </Menu>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </CardBody>
                                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        Page 1 of 10
                                    </Typography>
                                    <div className="flex gap-2">
                                        <Button variant="outlined" size="sm">
                                            Previous
                                        </Button>
                                        <Button variant="outlined" size="sm">
                                            Next
                                        </Button>
                                    </div>
                                </CardFooter>
                            </Card>

                        </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default CreditAZ