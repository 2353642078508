import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";
import { FaGasPump } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { GiCarWheel } from "react-icons/gi";
import { RxDimensions } from "react-icons/rx";
import { WiSnowflakeCold } from "react-icons/wi";
import { MdOutlineWifi } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { FaUser } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";
import { MdOutlineRoomPreferences } from "react-icons/md";
import { MdOutlineHomeWork, MdOutlinePayment } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { IoMdPin, IoIosMail } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import Voiture from "../../../../API/tourisme/location/index"
import { ToastContainer, toast } from "react-toastify"
import { FaPhoneAlt } from 'react-icons/fa';



function EditVehicule() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Location_Vehicule/Ajouter_les_vehicules'));

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

    const navigate = useNavigate();

    const [records, setRecords] = useState([]);
    const [tmprecords, setTmpecords] = useState([]);

    const [formData, setFormData] = useState({});

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [brand, setBrand] = useState([]);
    const [address, setAddress] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const location = useLocation();
    const id = location.state

    const handleCountryChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //province
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/province/list/${value}`)
     .then(response => {setProvinces(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const handleProvinceChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //city
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/city/list/${value}`)
     .then(response => {setCities(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }
        
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/location/show/${id}`)
     .then(response => {
        setRecords(response.data.data)
        setTmpecords(response.data.data)
    })
     //.then(response => console.log(response))
     .catch(err => console.log(err))

      //country
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/country/list`)
      .then(response => {setCountries(response.data.data)})
      //.then(response => console.log(response.data.data))
      .catch(err => console.log(err))

      axios.get(`${process.env.REACT_APP_BASE_URL}/api/brand/all/`)
     .then(response => {setBrand(response.data.data)})
     //.then(response => console.log(response))
     .catch(err => console.log(err))

     //adresse
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/annonce/info/${id}`)
     .then(response => {
       setAddress(response.data.data)
   })
     .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        let fd = new FormData();

        fd.append("id", id);
        fd.append("nom", formData.nom ?? records.title_announce);
        fd.append("methode", formData.methode ?? records.payment_method);
        fd.append("adresse", formData.lieu ?? records.contact_address);
        fd.append("clim", formData.climatiseur ?? records.realstate_air_conditioner);
        fd.append("camera", formData.TV ?? records.realstate_security);
        fd.append("desc", formData.msg ?? records.description_announce);
        fd.append("prix", formData.prix ?? records.price_announce);
        fd.append("wifi", formData.wifi ?? records.realstate_wifi);
        fd.append("date_debut", formData.date_debut ?? records.date_debut);
        fd.append("date_fin", formData.date_fin ?? records.date_fin);
        fd.append("marque", formData.type ?? records.car_brand);
        fd.append("conso", formData.consommation ?? records.car_fuel_type);
        fd.append("puissance", formData.vitesse ?? records.car_horsepower);
        fd.append("transmission", formData.transmission ?? records.car_transmission);
        fd.append("km", formData.km ?? records.car_mileage);
        fd.append("place", formData.nbre ?? records.realstate_number_pieces);

        fd.append("mail", formData.mail ?? records.contact_email);
        fd.append("tel", formData.phone ?? records.contact_phone);
        fd.append("pays", formData.country ?? records.contact_country);
        fd.append("ville", formData.ville ?? records.contact_city);
        fd.append("province", formData.province ?? records.contact_province);

        for(let i = 0; i < document.getElementById("photo")?.files.length; i++){
        fd.append("photo[]", document.getElementById("photo")?.files[i]);
    }
        setLoading(true)
        Voiture.submitEdit(fd)
            .then((res) => {
                if (res.data.status === 201) {
                    toast.success(res.data.message);
                    console.log(res);
                } else {
                    toast.error(res.data.message);
                    console.log(res);
                }
                navigate("/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules");
            })
            .catch((err) => {
                toast.error('Erreur merci de reessayer !');
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules" className="opacity-60 md:text-base s:text-xs">
                                    Location de vehicule
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Modifier
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <div>
                <ToastContainer />
            </div>

                        <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précedente</p>
                            </div>
                        </Link>
                        {/* container formulaire */}
                        <div className='flex justify-center py-20 '>
                            <form onSubmit={handleSubmit} className='flex flex-col space-y-3   '>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                    {/* Ajout des images */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                                            <label> Ajouter des images du vehicule</label>
                                        </div>
                                        <input type="file" accept="image/*" multiple name="photo" id='photo' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 ' />
                                    </div>
                                    {/* Nom du vehicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaCar className='text-xl text-[#fe7f2d]' />
                                            <label>Nom du vehicule</label>
                                        </div>
                                        <input type='text' name='nom' defaultValue={tmprecords.title_announce} onChange={handleInputChange} required placeholder='Ex : Toyota gris ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Marque du vehicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiSteeringWheel className='text-xl text-[#fe7f2d]' />
                                            <label>Marque du vehicule</label>
                                        </div>
                                        <select onChange={handleInputChange} required name='type' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                            <option value=''></option>
                                            {brand.map((option, index) => (
          <option key={index} value={option.id}>{option.brand}</option>
        ))}
                                            
                                        </select>
                                    </div>
                                    {/* Type de consommation */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaGasPump className='text-xl text-[#fe7f2d]' />
                                            <label>Type de consommation</label>
                                        </div>
                                        <select onChange={handleInputChange} required name='consommation' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                            <option value='diesel'>Diesel</option>
                                            <option value='essence'>Essence</option>
                                            <option value='electrique'>Electrique</option>
                                        </select>

                                    </div>
                                    {/* Transmission automobile */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiCarWheel className='text-xl text-[#fe7f2d]' />
                                            <label>Transmission automobile</label>
                                        </div>
                                        <select onChange={handleInputChange} required name='transmission' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                            <option value=''></option>
                                            <option value='automatic'>Automatique</option>
                                            <option value='manual'>Manuel</option>
                                            <option value='hybrid'>Hybride</option>
                                        </select>
                                    </div>
                                     {/* Type de paiement */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <MdOutlinePayment className='text-xl text-[#fe7f2d]' />
            <label>Méthode de paiement</label>
        </div>
        <select onChange={handleInputChange} name="methode" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4' required>
        <option value=""></option>
        {tmprecords.payment_method === 1 
        ? <option value="1"  selected>Paiement en ligne et sur place</option>
        : <option value="1" >Paiement en ligne et sur place</option>
        }

{tmprecords.payment_method === 2 
        ? <option value="2" selected>Paiement en ligne</option>
        : <option value="2">Paiement en ligne</option>
        }

{tmprecords.payment_method === 3 
        ? <option value="3" selected>Paiement sur place</option>
        : <option value="3">Paiement sur place</option>
        }
        
        </select>
    </div>
                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    {/* caractéristique de la voiture */}
                                    <p className='font-semibold text-lg'>Dans votre vehicule</p>

                                    <div className='grid sm:grid-cols-3 s:grid-cols-2 xs:grid-cols-4 gap-y-8'>
                                        {/* piscine */}
        <div className=' flex flex-col space-y-2'>
            <div className='flex flex-row items-center space-x-2'>
                <WiSnowflakeCold className='text-xl text-[#fe7f2d]' />
                <label>Climatiseur</label>
            </div>
               
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_air_conditioner === 1
        ? <input onChange={handleInputChange} type='radio' name='climatiseur' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='climatiseur' value="1" />
        }
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_air_conditioner === 0
        ? <input onChange={handleInputChange} type='radio' name='climatiseur' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='climatiseur' value="0" />
        }
                
                <label>Non</label>
            </div>
        </div>
        {/* Wifi  */}
        <div className=' flex flex-col space-y-2'>
            <div className='flex flex-row items-center space-x-2'>
                <MdOutlineWifi className='text-xl text-[#fe7f2d]' />
                <label>Wifi </label>
            </div>
             
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_wifi === 1
        ? <input onChange={handleInputChange} type='radio' name='wifi' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='wifi' value="1" />
        } 
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_wifi === 0
        ? <input onChange={handleInputChange} type='radio' name='wifi' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='wifi' value="0" />
        } 
                
                <label>Non</label>
            </div>
        </div>
        {/* TV */}
        <div className=' flex flex-col space-y-2'>
            <div className='flex flex-row items-center space-x-2'>
                <SlScreenDesktop className='text-xl text-[#fe7f2d]' />
                <label>Mini TV</label>
            </div>
              
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_security === 1
        ? <input onChange={handleInputChange} type='radio' name='TV' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='TV' value="1" />
        } 
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_security === 0
        ? <input onChange={handleInputChange} type='radio' name='TV' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='TV' value="0"  />
        } 
                
                <label>Non</label>
            </div>
        </div>
        
                                        
                                    </div>
                                    
                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    {/* Kilométrage du véhicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <RxDimensions className=' text-[#fe7f2d]' />
                                            <label>Kilométrage du véhicule</label>
                                        </div>
                                        <input type='number' name='km' defaultValue={tmprecords.car_mileage} onChange={handleInputChange} placeholder='Ex : 10 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Nombre de place */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaUser className=' text-[#fe7f2d]' />
                                            <label>Nombre de place</label>
                                        </div>
                                        <input type='number' name='nbre' defaultValue={tmprecords.realstate_number_pieces} onChange={handleInputChange} placeholder='Ex : 5' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Puissance du véhicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineRoomPreferences className=' text-[#fe7f2d]' />
                                            <label>Puissance du véhicule</label>
                                        </div>
                                        <input type='tel' name='phone' defaultValue={tmprecords.car_horsepower} onChange={handleInputChange} className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Tarif */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                                            <label>Tarif</label>
                                        </div>
                                        <input type='number' name='prix' defaultValue={tmprecords.price_announce} onChange={handleInputChange} placeholder='Ex : 70000 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                        <small>Le tarif doit être par nuitée</small>
                                    </div>
                                    {/* Description */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                                            <label>Description du véhicule</label>
                                        </div>
                                        <textarea name='msg' defaultValue={tmprecords.description_announce} onChange={handleInputChange} className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                                    </div>
                                </div>

                                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                    {/* Tel */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <FaPhoneAlt className=' text-[#fe7f2d]' />
                            <label>Telephone</label>
                        </div>
                        <input type='tel' defaultValue={tmprecords.contact_phone} name='phone' onChange={handleInputChange} required placeholder="Ex : +225 xxx xxx xxxx " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Mail */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoIosMail className=' text-[#fe7f2d]' />
                            <label>E-mail</label>
                        </div>
                        <input type='email' defaultValue={tmprecords.contact_email} name='mail' onChange={handleInputChange} placeholder="Ex : info@monbonsejour.com " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Country */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Pays</label>
                        </div>
                        <select onChange={handleCountryChange} name='country' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code}>{address.fr_Name}</option>
                                            {countries.map((option, index) => (
          <option key={index} value={option.code}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Province */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Province</label>
                        </div>
                        <select onChange={handleProvinceChange} name='province' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_province}>{address.pname}</option>
                                            {provinces.map((option, index) => (
          <option key={index} value={option.code_province}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Ville */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Ville</label>
                        </div>
                        <select onChange={handleInputChange}  name='ville' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_city}>{address.cname}</option>
                                            {cities.map((option, index) => (
          <option key={index} value={option.code_city}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Lieu */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Lieu</label>
                        </div>
                        <input type='text' defaultValue={tmprecords.contact_address} name='lieu' onChange={handleInputChange} placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                </div>

                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
    {/* date debut */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de debut affichage</label>
        </div>
        <input defaultValue={tmprecords.date_debut} name='date_debut' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>

    {/* date fin */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de fin affichage</label>
        </div>
        <input defaultValue={tmprecords.date_fin} name='date_fin' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>
    
</div>

                                <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Modifier</button>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default EditVehicule