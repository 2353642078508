import React from 'react'
import Navbar from './Navbar'
import BarresearchP from '../../COMPONENTS/BarreSearchP/BarreSearchP'
import accueil from '../../../ASSETS/tourisme/Image/image_loisirs/accueilLoisirs.png'

function BackImage() {
    return (
        <div>
            {/* navbar */}
            <Navbar />
            {/* back image */}
            <div className='bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative flex justify-center items-center' style={{ backgroundImage: `url(${accueil})` }}>
                <div className='flex flex-col items-center justify-center space-y-5 sm:pt-40 md:pt-0'>
                    <div className='navbar_animation text-white md:text-7xl xs:text-5xl s:text-2xl font-semibold uppercase '>
                       Loisirs & Activité
                    </div>
                </div>

            </div>

            {/* barre de recherche principale */}
            <div className=' absolute inset-x-0 md:-bottom-20 xs:-bottom-40 s:-bottom-64 flex justify-center  '>
                <BarresearchP />
            </div>

            {/* title */}
            <div className='relative md:mt-40 s:mt-80 flex flex-col justify-center items-center space-y-5  pb-10'>
                <p className='text-center font-semibold xs:text-2xl s:text-xl'> Découvrez plus de <span className='text-[#fe7f2d]'>50 activités à faire</span></p>
                <p className='text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                Explorez les trésors de la Côte d'Ivoire ! Découvrez une myriade d'activités et de loisirs 
                captivants au cœur de ce joyau africain. De l'aventure en plein air aux festivals culturels, 
                trouvez votre passion et vivez des expériences uniques.
                </p>
            </div>


        </div>
    )
}

export default BackImage