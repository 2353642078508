import React, {useState, useEffect} from 'react'
import { IoMdNotifications } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import {
    IconButton,
} from "@material-tailwind/react";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Avatar,
    Typography

} from "@material-tailwind/react";
import {
    Drawer,
    Button,
    List,
    ListItem,
    ListItemPrefix,
} from "@material-tailwind/react";
import { TfiDashboard } from "react-icons/tfi";
import { RiFileList3Line } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import { GiPayMoney } from "react-icons/gi";
import { FaPowerOff } from "react-icons/fa6";
import profil_photo_entreprise from '../../ASSETS/tourisme/Image/Dashbord_image/Diapositive3.JPG';
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import lgoSecondaire from '../../ASSETS/tourisme/logo/LOGO_secondaire-removebg-preview.png'
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify"
import { PiWechatLogoBold } from "react-icons/pi";
import { FaRegCreditCard } from "react-icons/fa6";
import Utilisateur from '../../API/profil/index';
import Image_Profil_User from '../../ASSETS/user/Image_Profil_User.jpg';

// icone horloge
function ClockIcon() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99998 14.9C9.69736 14.9 11.3252 14.2257 12.5255 13.0255C13.7257 11.8252 14.4 10.1974 14.4 8.49998C14.4 6.80259 13.7257 5.17472 12.5255 3.97449C11.3252 2.77426 9.69736 2.09998 7.99998 2.09998C6.30259 2.09998 4.67472 2.77426 3.47449 3.97449C2.27426 5.17472 1.59998 6.80259 1.59998 8.49998C1.59998 10.1974 2.27426 11.8252 3.47449 13.0255C4.67472 14.2257 6.30259 14.9 7.99998 14.9ZM8.79998 5.29998C8.79998 5.0878 8.71569 4.88432 8.56566 4.73429C8.41563 4.58426 8.21215 4.49998 7.99998 4.49998C7.7878 4.49998 7.58432 4.58426 7.43429 4.73429C7.28426 4.88432 7.19998 5.0878 7.19998 5.29998V8.49998C7.20002 8.71213 7.28434 8.91558 7.43438 9.06558L9.69678 11.3288C9.7711 11.4031 9.85934 11.4621 9.95646 11.5023C10.0536 11.5425 10.1577 11.5632 10.2628 11.5632C10.3679 11.5632 10.472 11.5425 10.5691 11.5023C10.6662 11.4621 10.7544 11.4031 10.8288 11.3288C10.9031 11.2544 10.9621 11.1662 11.0023 11.0691C11.0425 10.972 11.0632 10.8679 11.0632 10.7628C11.0632 10.6577 11.0425 10.5536 11.0023 10.4565C10.9621 10.3593 10.9031 10.2711 10.8288 10.1968L8.79998 8.16878V5.29998Z"
                fill="#90A4AE"
            />
        </svg>
    );
}

function SidebarTop() {

  const navigate = useNavigate();

    function logOutUser() {
      //alert(3);
        localStorage.clear();
        //console.log(!!localStorage.getItem("data"));
        toast.success("Deconnexion avec succès !");
        navigate("/hometourisme");
    };

    const [open, setOpen] = React.useState(false);
    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);
    const [customer, setCustomer] = useState();
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const [data, setData] = React.useState(() => {
      // getting stored value
      const saved = localStorage.getItem("data");
      //console.log(saved)
      const initialValue = saved !== null ? JSON.parse(saved) : null;
      return initialValue;
    });

    const getInformation = () => {
      Utilisateur.getUserInfo(data.azId)
      .then((res) => {
        //console.log(res)
          if (res.data.status === 201) {
            //console.log(res.data.data)
            setCustomer(res.data.data)
          }
      })
      .catch((err) => {
          console.log(err);
      })
    }

    useEffect(() => {

    getInformation();
  }, []);

  return (
    <div className='bg-[#233D4D] shadow-lg fixed w-screen z-10 h-12 flex flex-row items-center justify-between '>
        {/* sidebar left */}
          <div className=''>
              <React.Fragment >
                  <Button variant='text'  onClick={openDrawer}>
                      <div className='flex flex-row items-center pl-2 space-x-2 font-semibold text-white md:text-xl sm:text-base s:text-xs'>
                          <MdDashboard />
                          <p>Mon Espace</p>
                      </div>
                  </Button>
                  <Drawer open={open} onClose={closeDrawer} className="barre_scroll bg-[#233D4D]" >
                      <div className=''>
                         <div className='flex flex-row items-center justify-between'>
                              <Link to='/'>
                                <img className='w-20 h-20 ' src={lgoSecondaire} alt='logoSecondaire' />
                              </Link>
                              <IconButton variant="text" color="white" onClick={closeDrawer}>
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={2}
                                      stroke="currentColor"
                                      className="w-5 h-5"
                                  >
                                      <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"
                                      />
                                  </svg>
                              </IconButton>
                         </div>
                          {/* barre grise */}
                          <div className='border-t border-[#9898982b] pb-6'></div>

                          {/* dashbord */}
                          <Link to="/Dashboard/Profil">
                          <div className='flex flex-col items-center pb-5'>
                            {customer?.Picture ? (
                              <img src={`data:image/png;base64,${customer?.Picture}`} className='w-20 h-20 rounded-full border-2 border-[#fe7f2d]' alt='photo_de_profil' />
                            ): (
                              <img src={Image_Profil_User} className="rounded-full w-24 h-24 border-2 border-[#FCCA46]" alt={customer?.Names}/>

                            )}
                              {/* <img src={`data:image/png;base64,${customer?.Picture}`} className='w-20 h-20 rounded-full border-2 border-[#fe7f2d]' alt='photo_de_profil' /> */}
                              <p className='pt-3 text-white'>{customer?.Names}</p>
                              {/* <p className='pt-2 text-sm font-thin text-white'>Abidjan, Côte d'ivoire</p> */}
                          </div>
                          </Link>


                          {/* navigation */}
                          <div className='flex flex-col space-y-4 '>
                              {/* dashboard*/}
                              <Link to="/Dashboard/Graph">
                                <div className='flex flex-row space-x-3 items-center hover:bg-white py-3 pl-5 hover:text-[#fe7f2d]  text-white cursor-pointer  '>
                                    <TfiDashboard className='text-xl text-[#fe7f2d]' />
                                    <p className='text-lg '>Tableau de bord</p>
                                </div>
                              </Link>

                              {/* Mes publications */}
                              <Accordion
                                  open={open === 1}
                                  icon={
                                      <ChevronDownIcon
                                          strokeWidth={2.5}
                                          className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""} `}
                                      />
                                  }
                              >
                                  <ListItem className="p-0  hover:bg-white pl-3 rounded-none hover:text-[#fe7f2d] text-white cursor-pointer" selected={open === 1}>
                                      <AccordionHeader onClick={() => handleOpen(1)} className="p-3 border-b-0">
                                          <ListItemPrefix>
                                              <RiFileList3Line className='text-xl text-[#fe7f2d]' />
                                          </ListItemPrefix>
                                          <Typography color="blue-gray" className="mr-auto text-lg font-normal">
                                              Mes publications
                                          </Typography>
                                      </AccordionHeader>
                                  </ListItem>
                                  <AccordionBody className="py-1 text-white ">
                                      <List className="p-0">

                                          {/* publier un hebergement */}
                                          <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements">
                                              <ListItem className='hover:text-[#fe7f2d]'>
                                                  <ListItemPrefix>
                                                      <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                  </ListItemPrefix>
                                                  Hébergements
                                              </ListItem>
                                          </Link>

                                          {/* publier un restaurants */}
                                          <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants">
                                            <ListItem className='hover:text-[#fe7f2d]'>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                </ListItemPrefix>
                                                Restaurants
                                            </ListItem>
                                          </Link>

                                          {/* publier une voiture */}
                                          <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules">
                                            <ListItem className='hover:text-[#fe7f2d]'>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                </ListItemPrefix>
                                                Location de véhicules
                                            </ListItem>
                                          </Link>
                                          {/* publier une decouverte */}
                                          <Link to="/Dashboard/Publications/Culture_Decouverte/Voir_les_decouvertes">
                                            <ListItem className='hover:text-[#fe7f2d]'>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                </ListItemPrefix>
                                               Culture & Decouverte
                                            </ListItem>
                                          </Link>
                                          {/* publier une activite ou loisirs*/}
                                          <Link to="/Dashboard/Publications/Loisirs_activites/Voir_des_activites">
                                            <ListItem className='hover:text-[#fe7f2d]'>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                </ListItemPrefix>
                                               Loisirs et Activités
                                            </ListItem>
                                          </Link>
                                      </List>
                                  </AccordionBody>
                              </Accordion>

                              {/*  Mes reservations */}
                              <Accordion
                                  open={open === 2}
                                  icon={
                                      <ChevronDownIcon
                                          strokeWidth={2.5}
                                          className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? "rotate-180" : ""} `}
                                      />
                                  }
                              >
                                  <ListItem className="p-0  hover:bg-white pl-3 rounded-none hover:text-[#fe7f2d] text-white cursor-pointer" selected={open === 2}>
                                      <AccordionHeader onClick={() => handleOpen(2)} className="p-3 border-b-0">
                                          <ListItemPrefix>
                                              <GiReceiveMoney className='text-xl text-[#fe7f2d]' />
                                          </ListItemPrefix>
                                          <Typography color="blue-gray" className="mr-auto text-lg font-normal">
                                             Les réservations
                                          </Typography>
                                      </AccordionHeader>
                                  </ListItem>
                                  <AccordionBody className="py-1 text-white ">
                                      <List className="p-0">

                                          {/* hebergement */}
                                          <Link to="/Dashboard/Reservation/Hebergement/Liste">
                                              <ListItem className='hover:text-[#fe7f2d]'>
                                                  <ListItemPrefix>
                                                      <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                  </ListItemPrefix>
                                                  Hébergements
                                              </ListItem>
                                          </Link>
                                          {/*une voiture */}
                                          <Link to="/Dashboard/Reservation/Vehicule/Liste">
                                            <ListItem className='hover:text-[#fe7f2d]'>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                </ListItemPrefix>
                                                Location de véhicules
                                            </ListItem>
                                          </Link>
                                           {/*Restaurant */}
                                           <Link to="/Dashboard/Reservation/Restaurant/Liste">
                                            <ListItem className='hover:text-[#fe7f2d]'>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                </ListItemPrefix>
                                                Restaurant
                                            </ListItem>
                                          </Link>
                                           {/*Tourisme */}
                                           <Link to="/Dashboard/Reservation/Tourisme/Liste">
                                            <ListItem className='hover:text-[#fe7f2d]'>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                </ListItemPrefix>
                                                Tourisme
                                            </ListItem>
                                          </Link>
                                           {/*Loisirs */}
                                           <Link to="/Dashboard/Reservation/Loisir/Liste">
                                            <ListItem className='hover:text-[#fe7f2d]'>
                                                <ListItemPrefix>
                                                    <ChevronRightIcon strokeWidth={3} className="w-5 h-3" />
                                                </ListItemPrefix>
                                                Loisirs & Activités
                                            </ListItem>
                                          </Link>
                                      </List>
                                  </AccordionBody>
                              </Accordion>
                               
                              {/* Transaction */}
                              <Link to='/Dashboard/Transation'>
                                <div className='flex flex-row space-x-3 items-center hover:bg-white py-3 pl-5 hover:text-[#fe7f2d] text-white cursor-pointer'>
                                    <GiPayMoney className='text-xl text-[#fe7f2d]' />
                                    <p className='text-lg'>  Mes transactions</p>
                                </div>
                              </Link>
                              {/* CREDIT AZ*/}
                              <Link to="/Dashboard/Credit_AZ">
                                <div className='flex flex-row space-x-3 items-center hover:bg-white py-3 pl-5 hover:text-[#fe7f2d]  text-white cursor-pointer  '>
                                    <FaRegCreditCard className='text-xl text-[#fe7f2d]' />
                                    <p className='text-lg '>Achat de credit AZ</p>
                                </div>
                              </Link>
                              {/* Messagerie */}
                              <Link to='/Dashboard/Messagerie'>
                                <div className='flex flex-row space-x-3 items-center hover:bg-white py-3 pl-5 hover:text-[#fe7f2d] text-white cursor-pointer'>
                                    <PiWechatLogoBold className='text-xl text-[#fe7f2d]' />
                                    <p className='text-lg'>Messagerie</p>
                                </div>
                              </Link>
                              {/* Deconnexion */}
                              <div onClick={logOutUser} className='flex flex-row space-x-3 items-center hover:bg-white py-3 pl-5 hover:text-[#fe7f2d] text-white cursor-pointer'>
                                  <FaPowerOff className='text-xl text-[#fe7f2d]' />
                                  <p className='text-lg'>Deconnexion</p>
                              </div>
                          </div>


                      </div>
                  </Drawer>
              </React.Fragment>
          </div>

          {/* sidebar top */}
              
              <div className='flex flex-row md:space-x-10 s:space-x-3 md:pr-6 s:pr-4 '>
                {/* notification */}
                <Menu>
                  <MenuHandler className="relative bottom-2">
                    <IconButton variant="text"  >
                      <p className='bg-[#f7554c] shadow-3xl rounded-full w-3 h-3 text-white relative top-3 left-3'></p>
                      <IoMdNotifications className='text-2xl text-white' />

                    </IconButton>
                  </MenuHandler>
                  <MenuList className="flex flex-col gap-2">
                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8">
                      <Avatar
                        variant="circular"
                        alt="tania andrew"
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                      />
                      <div className="flex flex-col gap-1">
                        <Typography variant="small" color="gray" className="font-semibold">
                         Vous avez reçu un message
                        </Typography>
                        <Typography className="flex items-center gap-1 text-sm font-medium text-blue-gray-500">
                          <ClockIcon />
                          Il y'a 13 minutes
                        </Typography>
                      </div>
                    </MenuItem>
                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8">
                      <Avatar
                        variant="circular"
                        alt="natali craig"
                        src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"
                      />
                      <div className="flex flex-col gap-1">
                        <Typography variant="small" color="gray" className="font-semibold">
                           Vous avez reçu un message
                        </Typography>
                        <Typography className="flex items-center gap-1 text-sm font-medium text-blue-gray-500">
                          <ClockIcon />Il y'a 1 heure
                        </Typography>
                      </div>
                    </MenuItem>
                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8">
                      <Avatar
                        variant="circular"
                        alt="paypal"
                        src="https://dwglogo.com/wp-content/uploads/2016/08/PayPal_Logo_Icon.png"
                      />
                      <div className="flex flex-col gap-1">
                        <Typography variant="small" color="gray" className="font-semibold">
                          Paiement effectué
                        </Typography>
                        <Typography className="flex items-center gap-1 text-sm font-medium text-blue-gray-500">
                          <ClockIcon />Il y'a 5 heures
                        </Typography>
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
                {/* photo profil navigation */}
                <Menu>
                  <MenuHandler>
                    <div className='flex flex-row items-center space-x-2'>
                      {customer?.Picture ? (
                        <Avatar
                          alt="tania andrew"
                          className="w-8 h-8 rounded-full cursor-pointer "
                          src={`data:image/png;base64,${customer?.Picture}`}
                        />
                      ) : (
                        <Avatar
                          alt="tania andrew"
                          className="w-8 h-8 rounded-full cursor-pointer "
                          src={Image_Profil_User}
                        />
                        )}
                      {/* <Avatar
                        alt="tania andrew"
                        className="w-8 h-8 rounded-full cursor-pointer "
                        src={`data:image/png;base64,${customer?.Picture}`}
                      /> */}
                      <p className='text-white cursor-pointer s:hidden xs:block'>{data.userName}</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.959" height="10.925" viewBox="0 0 123.959 67.925">
                        <g id="down" transform="translate(0 -28.017)">
                          <path id="Tracé_269" data-name="Tracé 269" d="M117.979,28.017h-112a5.962,5.962,0,0,0-4.2,10.2l56,56a5.977,5.977,0,0,0,8.4,0l56-56a5.962,5.962,0,0,0-4.2-10.2Z" fill="#fff" />
                        </g>
                      </svg>
                      

                    </div>
                  </MenuHandler>

                  <MenuList className='flex flex-col gap-y-2'>
                    <MenuItem className="flex items-center gap-2 hover:bg-gray-100">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM10 5C10 5.53043 9.78929 6.03914 9.41421 6.41421C9.03914 6.78929 8.53043 7 8 7C7.46957 7 6.96086 6.78929 6.58579 6.41421C6.21071 6.03914 6 5.53043 6 5C6 4.46957 6.21071 3.96086 6.58579 3.58579C6.96086 3.21071 7.46957 3 8 3C8.53043 3 9.03914 3.21071 9.41421 3.58579C9.78929 3.96086 10 4.46957 10 5ZM8 9C7.0426 8.99981 6.10528 9.27449 5.29942 9.7914C4.49356 10.3083 3.85304 11.0457 3.454 11.916C4.01668 12.5706 4.71427 13.0958 5.49894 13.4555C6.28362 13.8152 7.13681 14.0009 8 14C8.86319 14.0009 9.71638 13.8152 10.5011 13.4555C11.2857 13.0958 11.9833 12.5706 12.546 11.916C12.147 11.0457 11.5064 10.3083 10.7006 9.7914C9.89472 9.27449 8.9574 8.99981 8 9Z"
                          fill="#90A4AE"
                        />
                      </svg>
                      <Link to="/Dashboard/Profil">      
                      <Typography variant="small" className="font-medium">
                        Mon profile
                      </Typography>
                      </Link>
                    </MenuItem>


                    <Link to='/Dashboard/Messagerie'>
                      <MenuItem className="flex items-center gap-2 hover:bg-gray-100">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12V2C14 1.46957 13.7893 0.960859 13.4142 0.585786C13.0391 0.210714 12.5304 0 12 0H2ZM2 2H12V9H10L9 11H5L4 9H2V2Z"
                            fill="#90A4AE"
                          />
                        </svg>

                        <Typography variant="small" className="font-medium">
                          Messagerie
                        </Typography>
                      </MenuItem>
                    </Link>
                    <hr className=" border-blue-gray-50" />
                    <MenuItem onClick={logOutUser} className="flex items-center gap-2 hover:bg-gray-100 ">
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14C1.26522 14 1.51957 13.8946 1.70711 13.7071C1.89464 13.5196 2 13.2652 2 13V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0ZM11.293 9.293C11.1108 9.4816 11.01 9.7342 11.0123 9.9964C11.0146 10.2586 11.1198 10.5094 11.3052 10.6948C11.4906 10.8802 11.7414 10.9854 12.0036 10.9877C12.2658 10.99 12.5184 10.8892 12.707 10.707L15.707 7.707C15.8945 7.51947 15.9998 7.26516 15.9998 7C15.9998 6.73484 15.8945 6.48053 15.707 6.293L12.707 3.293C12.6148 3.19749 12.5044 3.12131 12.3824 3.0689C12.2604 3.01649 12.1292 2.9889 11.9964 2.98775C11.8636 2.9866 11.7319 3.0119 11.609 3.06218C11.4861 3.11246 11.3745 3.18671 11.2806 3.2806C11.1867 3.3745 11.1125 3.48615 11.0622 3.60905C11.0119 3.73194 10.9866 3.86362 10.9877 3.9964C10.9889 4.12918 11.0165 4.2604 11.0689 4.3824C11.1213 4.50441 11.1975 4.61475 11.293 4.707L12.586 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8H12.586L11.293 9.293Z"
                          fill="#90A4AE"
                        />
                      </svg>
                      <Typography variant="small" className="font-medium">
                        Deconnexion
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </Menu>

              </div>
            </div>
  )
}

export default SidebarTop