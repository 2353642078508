import React from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import { useState, useEffect, FormEvent  } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import Footer from '../../COMPONENTS/Footer/Footer';
import BackImage from './BackImage';
import { ToastContainer, toast } from "react-toastify"
import Tourisme from '../../../API/tourisme/lieu/index'
import Reservation from '../../../API/tourisme/reservation/index'
import { AnnoncesProps} from "../../../props";

function ReservationTourisme() {

  const [loading, setLoading] = useState(true);

  localStorage.setItem('url', JSON.stringify('/culture'));

    const [culture, setCulture] = useState<AnnoncesProps>();

    const location = useLocation();
    const id = location.state

    const navigate = useNavigate();

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

  const getTourisme = () => {
    Tourisme.getOne(id)
    .then((res:any) => {
        if (res.data.status === 201) {
            setCulture(res.data.data)
        }
        console.log(res);
    })
    .catch((err:any) => {
        console.log(err);
    })
    .finally(() => {
        setLoading(false);
    })

}

  const votrenom = data !== null ? data.userLastname + ' ' + data.userName : "";
    const [nom, setNom] = useState(votrenom || "")
    const [date, setDate] = useState("")
    const [heure, setHeure] = useState("")
    const [place, setPlace] = useState("")
    const [tel, setTel] = useState(data !== null ? data.userTel : "" || "")
    const [usermail, setUserEmail] = useState(data !== null ? data.userMail : "" || "")

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }

        getTourisme();

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    function submit(e: FormEvent) {
        e.preventDefault()

        setLoading(true)

        var templateParams = {
            pnom: nom,
            mail: usermail,
            phone: tel,
            date: date,
            nbre: place,
            time: heure,
            id: culture?.id_announce,
            user: data.userId,
        };


        Reservation.cultureRes(templateParams)
        .then((res: any) => {
            if (res.data.status === 201) {
                toast.success(res.data.message);
                navigate("/hometourisme");
                console.log(res);
            } else {
                toast.error(res.data.message);
                console.log(res);
            }
        })
        .catch((err: any) => {
            toast.error('Erreur merci de reessayer !');
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })


    }


  return (
    <div>
       {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

          <BackImage/>
       {/* formulaire de prise de rdv */}
       <div className='py-20 relative md:mt-40 s:mt-80 flex justify-center border-t-2 border-gray-200 '>
            <form action="" onSubmit={submit}  className='bg-white md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-6 justify-center items-center'>
                <p className='xs:w-[400px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez remplir tout les champs du formulaire afin que votre reservation soit prise en compte</p>
                <input type='hidden'   required/>
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Nom & Prénom <span className='text-red-600'>*</span></label>
                    <input name='name' value={nom} onChange={(e) => setNom(e.currentTarget.value)} type='text' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Adresse e-mail <span className='text-red-600'>*</span></label>
                    <input name='mail' type='email' value={usermail} onChange={(e) => setUserEmail(e.currentTarget.value)} style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Numero de portable <span className='text-red-600'>*</span></label>
                    <input name='numero' value={tel} onChange={(e) => setTel(e.currentTarget.value)}  type='tel' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Date d'arrivée <span className='text-red-600'>*</span></label>
                    <input name='date'  type='date'  value={date} onChange={(e) => setDate(e.currentTarget.value)} style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'> Heure d'arrivée <span className='text-red-600'>*</span></label>
                    <input name='time'  type='time' value={heure} onChange={(e) => setHeure(e.currentTarget.value)} style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>


                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Site touristique <span className='text-red-600'>*</span></label>
                    <input name='sujet' readOnly value={culture?.title_announce} type='text' style={{backgroundColor:"#d9d9d9"}} placeholder='Foret du banco; Fort de dabou'  className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Nombre de personne <span className='text-red-600'>*</span></label>
                    <input name='nbre' min="0" value={place} onChange={(e) => setPlace(e.currentTarget.value)}  type='number' style={{backgroundColor:"#d9d9d9"}}  className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Voulez-vous faire le paiement ? <span className='text-red-600'>*</span></label>
                    <div className='flex space-x-10'>
                      <button className='xs:w-[270px] s:w-[100px] sm:w-[120px] h-14 bg-[#233D4D] text-white text-lg rounded'>Plus tard</button>
                      <button className='xs:w-[270px] s:w-[100px] sm:w-[120px] h-14 border-2 border-[#233D4D] text-[#233D4D] rounded text-lg'>Payer</button>
                    </div>
                   
                </div>

                

                {/* <PopopPaiement/> */}
               
                 <button style={{backgroundColor:"#FCCA46", color:"white"}} className='w-40 h-12 text-lg rounded '>Reserver</button>
            </form>
          </div>
          <Footer />
                </>
            )}
    </div>
  )
}

export default ReservationTourisme