import React from 'react'
import Navbar from './Navbar'
import BarresearchP from '../../COMPONENTS/BarreSearchP/BarreSearchP'
import accueil from '../../../ASSETS/tourisme/Image/image_restaurants/accueilRestaurant.png'

function BackImage() {
    return (
        <div>
            {/* navbar */}
            <Navbar />
            {/* back image */}
            <div className='bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative flex justify-center items-center' style={{ backgroundImage: `url(${accueil})` }}>
                <div className='flex flex-col items-center justify-center space-y-5 sm:pt-40 md:pt-0'>
                    <div className='navbar_animation text-white md:text-7xl xs:text-5xl s:text-2xl font-semibold uppercase '>
                        Restaurants
                    </div>
                </div>

            </div>

            {/* barre de recherche principale */}
            <div className=' absolute inset-x-0 md:-bottom-20 xs:-bottom-40 s:-bottom-64 flex justify-center  '>
                <BarresearchP />
            </div>

           
        </div>
    )
}

export default BackImage