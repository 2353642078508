import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENTS/Header/Header'
import Navbar from './Navbar'
import Footer from '../../COMPONNENTS/Footer/Footer'
import visa from '../../../ASSETS/CaImmigration/images/VisaTouriste_barniere.png'
import visatouriste1 from '../../../ASSETS/CaImmigration/images/family-traveling-together-through-woods.jpg'
import touriste2 from '../../../ASSETS/CaImmigration/images/closeup-shot-young-attractive-lady-wearing-headphones-taking-selfie.jpg'
import touriste3 from '../../../ASSETS/CaImmigration/images/visiteur4.jpeg'
import { Link } from 'react-router-dom'
import basilique from '../../../ASSETS/CaImmigration/icones/cathedral.png'
import randon from '../../../ASSETS/CaImmigration/icones/hiking.png'
import TestEligibilite from '../../COMPONNENTS/BoutonTestEligibilite/TestEligibilite';
import BarniereRdvVisa from '../../COMPONNENTS/BarniereRdvVisa/BarniereRdvVisa';
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';

function VisaEtude() {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
      <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
                  <Header />

                  {/* back image */}
                  <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${visa})` }}>
                      <Navbar />
                      <div className='text_home flex  justify-end md:pl-10 s:pl-3  s:pt-20 md:pt-0 mt-20'>
                              <div className='flex space-y-4 pt-20 flex-col text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg    font-semibold xs:w-[470px] md:w-[770px] '>
                              <p>SERVICES DE VISA CANADIEN</p>
                              <p className=' md:text-3xl sm:text-lg s:text-sm'>VISA TOURISTE</p>
                        </div>

                      </div>

                  </div>

                  {/* option */}
                 <OptionCard/>

                  {/* en tete */}
                  <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
                      <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
                      <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
                  </div>

                <div className='bg-white py-20'>
                   {/* titre */}
                    <div className='flex  xs:flex-row xs:px-5 md:px-0 md:pl-0 flex-col justify-center items-center xs:space-x-40 xl:space-x-[700px] lg:space-x-[300px] s:space-y-10'>
                    <div>
                        <p className=' text-sm font-semibold'><span style={{ color:"#FE7F2D"}} className=' font-bold sm:text-xl s:text-lg'>/</span>QUE PROPOSONS-NOUS </p>
                        <p style={{ color: "##4e4e4e" }} className=' sm:text-2xl s:text-xl font-bold w-[250px]'>Conseils en Services de visa d'immigration exceptionnels.</p>
                    </div>

                    <div style={{ color: "##4e4e4e" }} className='xs:w-[650px] md:w-[800px] sm:w-[300px] s:w-[200px] sm:text-lg s:text-base text-justify '>
                    "À la recherche d'une expertise exceptionnelle en matière d'immigration au Canada ? 
                    Nous sommes là pour vous accompagner tout au long de votre parcours. Nos consultants 
                    chevronnés vous offrent des conseils personnalisés et des services de visa d'immigration 
                    de haute qualité. Avec notre équipe dévouée, nous vous guiderons à chaque étape de votre 
                    processus d'immigration, vous aidant à réaliser votre rêve canadien en toute simplicité. 
                    Faites confiance à notre expérience pour une immigration réussie et sans stress."</div>
                    </div>

                    {/* container1 */}
                    <div className='flex xs:flex-row s:flex-col xs:space-x-40 s:space-y-20 s:justify-center items-center s:py-20 xs:py-0 xs:pb-20'>
                        {/* filtre */}
                        <div className='sm:w-[300px] s:w-[260px] h-[400px] flex flex-col justify-center items-center space-y-5  ' style={{backgroundColor:"#f7f7f7"}}>

                            {/* permis d'etudes */}
                            <Link to='/visa/visaEtude'>
                            <div style={{  backgroundColor: 'white', color:"#FE7F2D" }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base  text-white rounded-xl shadow-lg font-semibold justify-center items-center space-x-5'>
                            <p>Visa et Permis d'etudes</p>
                              <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                           
                            </div>
                            </Link>
                            {/* visa travail */}
                            <Link to='/visa/visaTravail'>
                            <div style={{backgroundColor: 'white', color:"#FE7F2D"  }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-5'>
                                    <p>Visa et Permis de travail</p>
                                     <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                               
                            </div>
                            </Link>
                            {/* visa touriste */}
                            <Link to='/visa/visaTouriste'>
                            <div style={{ backgroundColor: '#FE7F2D', color:'white' }} className='transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa touriste</p>

                                     <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                <g id="rigth" transform="translate(-1.974 -1.315)">
                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fff" />
                                </g>
                                </svg>
                                </p>
                            </div>
                            </Link>
                            {/* visa transit */}
                            <Link to='/visa/visaTransit'>
                            <div style={{ backgroundColor: 'white', color:"#FE7F2D" }} className='transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa transit</p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                            </div>
                            </Link>
                            {/* visa familiale*/}
                            <Link to='/visa/visaFamiliale'>
                            <div style={{ backgroundColor: 'white', color:"#FE7F2D" }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa familiale</p>
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                            </div>
                            </Link>
                        </div>

                        {/* texte1 */}
                        <div>
                            <img className='md:w-[700px] sm:w-[300px] s:w-[210px]' src={visatouriste1} alt='etudiant'/>
                            <p className='text-xl font-semibold pt-6' style={{color:"#FCCA46"}}>Visa touriste</p>
                            <p className='md:w-[680px] sm:w-[300px] s:w-[210px] pt-6'>
                                Un visa touristique canadien, également connu sous le nom de "visa de visiteur", est un document officiel délivré par le gouvernement du Canada qui permet à des ressortissants étrangers de visiter le Canada à des fins touristiques, de loisirs ou pour rendre visite à des amis et à la famille. Le visa de visiteur est nécessaire pour les ressortissants de nombreux pays, bien que certains pays bénéficient d'une exemption de visa, ce qui signifie que leurs citoyens peuvent voyager au Canada sans visa pour de courtes périodes.
                            </p>
                        </div>
                    </div>

                    {/* container2 */}
                    <div className='flex md:flex-row s:flex-col justify-center s:items-center xs:items-start md:space-x-32 s:space-x-0 s:space-y-16 xs:space-y-0 xs:mr-24 s:mr-0'>

                        <div className=' flex md:flex-col xs:flex-row s:flex-col s:space-y-10 xs:space-y-0 xs:space-x-10 md:space-x-0 md:space-y-10'>
                            <div><img src={touriste2} className='md:w-[400px] sm:w-[300px] s:w-[210px]' alt='etudiant1'/></div>
                            <div><img src={touriste3} className='md:w-[400px] sm:w-[300px] s:w-[210px]' alt='etudiant1'/></div>
                        </div>

                        <div className='flex flex-col items-center space-y-10' >
                            {/* avantages */}
                            <div>
                            <p className='text-2xl font-semibold xs:w-[500px] sm:w-[300px] s:w-[210px]' style={{color:"#233D4D"}}>Pourquoi faire du tourisme au canada</p>
                            <p className='md:w-[500px] xs:w-[600px] sm:w-[300px] s:w-[210px] pt-6'>
                                <b>- Paysages époustouflants :</b> Le Canada est célèbre pour sa beauté naturelle, des montagnes majestueuses aux lacs cristallins, en passant par les forêts luxuriantes et les vastes étendues de toundra. Les paysages canadiens offrent d'innombrables opportunités pour les amateurs de plein air.<br/><br/>
                                <b>- Diversité culturelle :</b> Le Canada est un pays multiculturel, où vous pouvez découvrir une grande variété de cultures, de langues et de traditions. Cela se reflète dans la cuisine, l'art, la musique et les festivals à travers le pays.<br/><br/>
                                <b>- Parcs nationaux :</b> Le Canada abrite de nombreux parcs nationaux et aires protégées, offrant des environnements naturels préservés et des opportunités uniques pour l'exploration.<br/><br/>
                                <b>Festivals et événements :</b> Le Canada propose une variété de festivals et d'événements tout au long de l'année, tels que le Carnaval de Québec, le Festival international de jazz de Montréal et le Festival du film de Toronto.<br/><br/>
                                <b>Opportunités d'observation de la faune :</b> Le Canada est un endroit idéal pour observer la faune, notamment les baleines, les ours, les orignaux, les aigles et bien d'autres espèces.<br/><br/>
                               </p>
                            </div>
                           
                        </div>
                        
                    </div>
                    {/* guide */}
                    <div className='flex flex-col items-center'>
                        <p className='text-2xl font-semibold md:w-[800px] xs:w-[500px] sm:w-[300px] s:w-[210px] mt-10' style={{ color: "#233D4D" }}>Nous vous guidons dans les processus d'obtention du visa touriste</p>
                        <p className='text-center md:w-[800px] xs:w-[600px] sm:w-[300px] s:w-[210px] pt-6'>
                             Explorez le monde en toute sérénité. Mon Bon Séjour simplifie le processus d'obtention de votre visa touristique. Faites équipe avec nous pour des voyages inoubliables, sans souci administratif.
                        </p>
                    </div>

                    {/* icone */}
                     <div className='flex justify-center xs:flex-row s:flex-col items-center mt-10 xs:space-x-4 s:space-x-0 s:space-y-4 xs:space-y-0'>
                          {/* touriste */}
                          <div className='flex flex-row items-center justify-center  text-xl font-bold space-x-6' style={{ color: "#233D4D" }}>
                              {/* icones */}
                              <p>
                                  <img src={randon} className='w-14' alt='randonnée' />
                              </p>
                              <p>Randonnée sur les montagnes</p>
                          </div>
                          {/* basilique */}
                          <div className='flex  flex-row items-center  text-xl font-bold space-x-6' style={{ color: "#233D4D" }}>
                              {/* icones */}
                              <p>
                                  <img src={basilique} className='w-14' alt='viste' />
                              </p>
                              <p>Visiter la basilique Notre Dame</p>
                          </div>
                     </div>


                   {/* eligibilité */}

                    <TestEligibilite />

                    {/* banniere */}
                    <BarniereRdvVisa/>
                    
                </div>

                  <Footer />
              </>
          )}
      </div>
  )
}

export default VisaEtude