import React from 'react'
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import { Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { IoMdPin } from "react-icons/io";
import { useState, useEffect } from 'react';
import { GoArrowLeft } from "react-icons/go";
import Loading from '../../../COMPONENTS/Loading/Loading'
import {
    Card,
    CardHeader,
    CardBody,
} from "@material-tailwind/react";
import { BiHotel } from "react-icons/bi";
import { TbFridge } from "react-icons/tb";
import { IoBedOutline } from "react-icons/io5";
import { RxDimensions } from "react-icons/rx";
import { MdOutlinePool, MdElevator, MdOutlineSevereCold } from "react-icons/md";
import { MdOutlineWifi, MdBalcony } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { IoRestaurant } from "react-icons/io5";
import { LuMicrowave, LuArmchair } from "react-icons/lu";
import { GiBathtub } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import { IoIosEyeOff, IoIosEye  } from "react-icons/io";
import { GiMoneyStack, GiCctvCamera } from "react-icons/gi";
import { FaBellConcierge } from "react-icons/fa6";
import { RiParkingBoxFill } from "react-icons/ri";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify"

import SidebarTop from '../../SidebarTop';
import img1 from "../../../../ASSETS/tourisme/Image/voiture2.jpg";
import img2 from "../../../../ASSETS/tourisme/Image/voiture3.jpg";
import img3 from "../../../../ASSETS/tourisme/Image/Voiture.jpg";
import Carousel from '../Carousel';



function VoirPlus() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Hebergement/Voir_des_hebergements'));

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

    const navigate = useNavigate();

    const [records, setRecords] = useState([]);
    const [image, setImage] = useState([]);

    const slides = [img1,img2,img3];

    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const id = location.state

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }
        
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/show/${id}`)
     .then(response => {setRecords(response.data.data)})
     //.then(response => console.log(response))
     .catch(err => console.log(err))

     axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/images/${id}`)
     .then(response => {setImage(response.data.data)})
     //.then(response => console.log(response))
     .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const changeStatut = (id) => {

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/hebergement/change/statut`, {id: id})
         .then(res => {
          window.location.reload();
          if (res.data.status === 201) {
            toast.success(res.data.message);
            //console.log(res);
        } else {
            toast.error(res.data.message);
            console.log(res);
        }
        })
         .catch(err =>
          {
            toast.error("Erreur Merci de ressayer");
           console.log(err)
          })
    
        }


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* sidebar top  */}
                        <SidebarTop />
                        <div>
                <ToastContainer />
            </div>
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements" className="opacity-60 md:text-base s:text-xs">
                                    Hebergements
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Voir
                                </Link>
                            </Breadcrumbs>
                        </div>

                            <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements">
                                <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                    <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                    <p>Retour à la page précedente</p>
                                </div>
                            </Link>

                        {/* container */}
                        <div className='flex justify-center md:p-10 s:px-4 s:pt-14 xs:pt-0'>

                            <Card className="h-full xs:w-[700px] s:w-[260px] sm:w-[340px]">
                                <CardHeader floated={false} shadow={false} className="rounded-none pl-4 flex items-center justify-between ">
                                    <Typography className='sm:text-lg s:text-base font-semibold text-[#fe7f2d]' color="blue-gray">
                                    {records.title_announce}
                                    </Typography>
                                    <Tooltip  content="Action sur la publication">
                                    <IconButton variant="text">
                                        <Menu>
                                        <MenuHandler>
                                            <IconButton variant="text">
                                            <RxDotsVertical className='text-lg' />
                                            </IconButton>
                                        </MenuHandler>
                                        <MenuList className="flex flex-col gap-2">
                                        <Link 
                                         to="/Dashboard/Publications/Hebergement/Voir_des_hebergements/Modifier" 
                                         state={ 
                                            records.id_announce 
                                        }
                                          >
                                            {/* modifier */}
                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <FiEdit className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                Modifier
                                                </Typography>
                                            </div>
                                            </MenuItem>
                                            </Link>
                                            {/* Desactiver */}
                                            {records.status === "enabled" && (
                                            <MenuItem onClick={() => changeStatut(records.id_announce)} className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <IoIosEyeOff className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                Désactiver
                                                </Typography>
                                            </div>
                                            </MenuItem>
                                            )}
                                            {/* Activer */}
                                          {records.status === "disabled" && (
                                            
                                            <MenuItem onClick={() => changeStatut(records.id_announce)} className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <IoIosEye className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                Activer
                                                </Typography>
                                            </div>
                                            </MenuItem>
                                            )}
                                        </MenuList>
                                        </Menu>
                                    </IconButton>
                                </Tooltip>

                                </CardHeader>
                                <CardBody className=" px-8 pb-20 ">
                                   <div className='flex justify-center'>
                                        <div className="md:w-[500px]  xs:w-[400px] sm:w-[300px] s:w-[260px] ">
                                            <Carousel slides={image}  />
                                        </div>
                                   </div>
                                    <div className='flex sm:flex-row s:flex-col sm:justify-between py-3'>
                                        <p className='font-semibold text-[#233D4D]  xs:text-lg s:text-base '>
                                        {records.realstate_type === "other" &&  'Autre' }
                                        {records.realstate_type === "house" &&  'Maison' }
                                        {records.realstate_type === "hotel" &&  'Hôtel' }
                                        {records.realstate_type === "appartment" &&  'Appartement' }
                                        </p>
                                        <div className='flex items-center'><p className=' font-medium text-[#233D4D] xs:text-xl s:text-base'>{records.price_announce} XOF</p> <p className=' font-thin text-gray-400'>/ Nuitée</p></div>
                                    </div>
                                    <div className='flex sm:flex-row s:flex-col sm:justify-between pt-2'>
                                        <div className='flex space-x-2 items-center '>
                                        <IoMdPin className=' text-[#fe7f2d] ' />
                                        <p className='s:text-xs xs:text-base'>{records.contact_address}</p>
                                        </div>
                                        {records.status === "enabled" && (
                                        <div className='bg-[#56b256] h-8 xs:w-[100px] s:w-[80px] s:text-sm xs:text-base text-white rounded font-thin justify-center items-center flex'>
                                            Disponible
                                        </div>
                                        )}
                                        {records.status === "disabled" && (
                                        <div className='bg-[#fc5151] h-8 xs:w-[100px] s:w-[80px] s:text-sm xs:text-base text-white rounded font-thin justify-center items-center flex'>
                                        Réservé
                                        </div>
                                        )}
                                    </div>
                                    <div className='grid xs:grid-cols-3 s:grid-cols-1 gap-y-6  pt-6'>
                                        {/* nombre de chambre */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <BiHotel className='text-xl ' />
                                                <p>Nombre de pièce</p>
                                            </div>
                                            <p className='pl-2'>{records.realstate_number_pieces} Pièce(s)</p>
                                        </div>
                                        {/* Perimetre */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <RxDimensions className='text-xl ' />
                                                <p>Périmètre</p>
                                            </div>
                                            <p className='pl-2'>{records.realstate_size} m<sup>2</sup></p>
                                        </div>
                                        {/* Type de lit */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <IoBedOutline className='text-xl ' />
                                                <p>Type de lit</p>
                                            </div>
                                            {records.realstate_bedtype === "Lit simple" &&
                                            <p className='pl-2'>Lit simple</p>
                                        }
                                            {records.realstate_bedtype === "Lit double" &&
                                            <p className='pl-2'>Lit double</p>
}
{records.realstate_bedtype === "Lit triple" &&
                                            <p className='pl-2'>Lit triple</p>
                                        }

                                            
                                        </div>
                                        {/* Capacité de la salle */}
                                        {/* <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <FaUser  className='' />
                                                <p>Capacité de la salle</p>
                                            </div>
                                            <p className='pl-2'>2-5 Personnes</p>
                                        </div> */}
                                    </div>
                                    <div className='pt-6'>
                                        <p className='text-lg  font-medium text-[#4e4e4e]'>Les équipements</p>
                                        <p className='xs:w-[600px] s:w-[210px] sm:w-[300px] pt-3'> {records.note}</p>
                                    </div>

                                    <div className='pt-6 grid xs:grid-cols-3 s:grid-cols-1  gap-y-6'>
                                        <div className='flex items-center space-x-2'>
                                            <MdOutlineWifi className='text-xl text-[#fe7f2d] ' />
                                            <p>Wifi : {records.realstate_wifi === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <MdOutlinePool className='text-xl text-[#fe7f2d]' />
                                            <p>Piscine : {records.realstate_swimming_pool === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <GiCctvCamera className='text-xl text-[#fe7f2d]' />
                                            <p>Cameras : {records.realstate_security === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <MdBalcony className='text-xl text-[#fe7f2d]' />
                                            <p>Balcon : {records.realstate_balcony === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <MdElevator className='text-xl text-[#fe7f2d]' />
                                            <p>Ascenseur : {records.realstate_elevator === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <FaBellConcierge className='text-xl text-[#fe7f2d]' />
                                            <p>Conciegre : {records.realstate_concierge === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <RiParkingBoxFill className='text-xl text-[#fe7f2d]' />
                                            <p>Parking : {records.realstate_parking === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <MdOutlineSevereCold className='text-xl text-[#fe7f2d]' />
                                            <p>Climatiseur : {records.realstate_air_conditioner === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <LuArmchair  className='text-xl text-[#fe7f2d]' />
                                            <p>Meublé : {records.realstate_furnished === 1 ? 'Oui' : 'Non' } </p>
                                        </div>
                                         
                                    </div>

                                    <div className='pt-6'>
                                        <p className='text-lg  font-medium text-[#4e4e4e]'>Description</p>
                                        <p className='xs:w-[600px] s:w-[210px] sm:w-[300px] pt-3'> 
                                        {records.description_announce}
                                        </p>
                                    </div>
                                </CardBody>
                               
                            </Card>

                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default VoirPlus