import React from 'react'
import rdv from '../../../ASSETS/CaImmigration/images/Banniere_RDV.png'
import {Link} from 'react-router-dom'

function BarniereRdvVisa() {
  return (
      <div className='mb-10 w-screen mt-20 md:h-[470px] s:h-[400px] flex justify-center items-center bg-no-repeat bg-center' style={{ backgroundImage: `url(${rdv})` }}>
          <div className='flex flex-col space-y-20 items-center'>
              <div className=' md:text-5xl xs:text-3xl s:text-2xl font-semibold text-white text-center s:w-[260px] sm:w-[300px] xs:w-[600px] md:w-[870px]'>
                  Besoin de Conseil pour tes demarches ? prends un rendez-vous pour nous rencontrer
              </div>
              <Link to='/PrendreRendezVous'>
              <button style={{ backgroundColor: "#FCCA46" }} className=' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150 text-white w-36 h-12 font-medium '>Prendre RDV</button>
              </Link>
          </div>
      </div>
  )
}

export default BarniereRdvVisa