import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const data = [
  { value: 0 ,  label: 'Depenses', color: '#0088FE' },
  { value: 0, label: 'Revenus', color: '#00C49F' },
  
];

const size = {
  height: 200,
  
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieChartWithCenterLabel() {
  return (
    <div className='bg-white shadow-md  rounded-lg xs:w-[500px] s:w-[340px]'>
        <div  className=" text-[#233D4D] text-xl p-4 font-semibold mb-4">
            Statistiques des transactions
        </div>

        <PieChart className='s:w-[300px] xs:w-[400px]'  series={[{ data, innerRadius: 50 }]} {...size}>
        </PieChart>
        <div className='text-xl p-4 font-semibold flex items-center space-x-3'>
            <p>Revenu actuel :</p>
            <p className='text-2xl text-[#fe7f2d]'> 0 XOF</p>
        </div>
    </div>
  );
 
}