import axios from 'axios';

let Location : any = {};

Location.submitForm = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/location/add`, data)
}

Location.submitFormPopUp = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/location/voititure/update/popup`, data)
}

Location.getHomeData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/location/home`)
}

Location.getAllData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/location/all`)
}

Location.submitReservation = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/location/send/reservation`, data)
}

Location.submitEdit = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/location/edit`, data)
}

Location.getOne = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/location/show/${id}`)
}

Location.getImages = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/location/images/${id}`)
}

Location.getBrand = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/brand/all/${id}`)
}

export default Location;