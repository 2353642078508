import imgVoiture2 from '../../../ASSETS/tourisme/Image/Voiture.jpg'
import imgVoiture3 from '../../../ASSETS/tourisme/Image/voiture3.jpg'
import imgVoiture from '../../../ASSETS/tourisme/Image/voiture2.jpg'
import * as React from 'react';
import { GiMoneyStack } from "react-icons/gi";
import {Link} from 'react-router-dom'
import Footer from '../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import BarniereLvoiture from './BarniereLvoiture';
import BackImage from './BackImage';
import Location from '../../../API/tourisme/location/index'
import { AnnoncesProps} from "../../../props";


function Lvoiture() {

  const [loading, setLoading] = useState(true);

  const [loc, setLoc] = useState<AnnoncesProps[]>([]);

  const getAllVehicule = () => {
    Location.getAllData()
    .then((res:any) => {
      //console.log(res)
        if (res.data.status === 200) {
          setLoc(res.data.data)
        }
    })
    .catch((err:any) => {
        console.log(err);
    })
    .finally(() => {
        setLoading(false);
    })
}

function truncate (str : any) {
  return str.length > 200 ? str.substring(0, 200) + "..." : str;
}

  useEffect(() => {

    getAllVehicule();

    //const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      //setLoading(false);
    //}, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    //return () => clearTimeout(timer);
  }, []);
   
  return (
    <div>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
          
          <BackImage/>
          
            {/* filtre */}
            <div className='grid gap-6 pt-10 md:grid-cols-5 xs:grid-cols-3 s:grid-cols-1 place-items-center'>
              <button className='w-[200px] h-10 bg-[#233D4D] text-lg text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Tout</button>
              {/* type de vehicule */}
              <select className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'>
                <option>Type de véhicule</option>
              </select>
              {/* Marque du vehicule */}
              <select className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'>
                <option>Marque du véhicule</option>
              </select>
              {/* Lieu ou la voiture se trouve */}
              <input type='text' name='lieu_voiture' placeholder='Lieu' className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'/>
              {/* prix de la voiture */}
              <input type='text' name='lieu_voiture' placeholder='Prix' className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg' />
            </div>

          {/* container 1 */}
          <div className='grid py-20 xs:grid-cols-2 md:grid-cols-4 md:gap-2 xs:gap-8 s:gap-y-8 s:grid-cols-1 place-items-center '>
            
          {loc.map((list, index )=> (
             
                    <div className='flex flex-col transition duration-300 ease-in-out delay-150 cursor-pointer hover:-translate-y-1 hover:scale-110 '>
                        <Link to="/Locationdevehicule/Voiture" state={ 
                                           list.id_announce 
                                        }>
                        {/* image de voiture */}
                        <img className='shadow-xl xs:w-72 sm:w-64 xs:h-52 lg:h-60 sm:h-52' src={`data:image/png;base64,${list[0]}`} alt={list.title_announce}/>
                        {/* bloc blanc description de la voiture */}
                        <div className='h-full px-4 pb-6 bg-white border-l-4 border-yellow-400 shadow-xl xs:w-72 sm:w-64 md:space-y-2 sm:space-y-0'>
                          <div className='bg-[#3ec53e] text-white w-[90px] h-6 text-center relative bottom-6  s:left-[146px] xs:left-[178px]'>Disponible</div>
                              <div className='flex items-center space-x-2 sm:text-xs'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16.52" height="40.28" viewBox="0 0 67.52 101.28">
                                  <g id="localisation" transform="translate(-85.333)">
                                      <path id="Tracé_269" data-name="Tracé 269" d="M119.093,0A33.764,33.764,0,0,0,89.386,49.8l27.861,50.388a2.11,2.11,0,0,0,3.692,0L148.81,49.787A33.766,33.766,0,0,0,119.093,0Zm0,50.64a16.88,16.88,0,1,1,16.88-16.88A16.9,16.9,0,0,1,119.093,50.64Z" fill="#233d4d"/>
                                  </g>
                                  </svg>
                                  <div className='text-base font-thin '>{list.contact_address}</div>
                              </div>
                              

                            <div className='font-medium text-orange-400 md:text-lg lg:text-lg sm:text-sm'>{list.title_announce}</div>
                            <div>
                            <p>{truncate(list.description_announce)} </p>
                            </div>
                            <hr className='sm:pb-1 lg:pb-0'/>
                            {/* prix */}
                            <div className='flex items-center py-2 space-x-2 '>
                              <GiMoneyStack className='text-lg' />
                              <p className='sm:text-xs md:text-base'> Prix : {list.price_announce} FCFA / jour</p>
                            </div>
                            {list.status === "enabled" && (
                                <Link to='/Reservation_Vehicule' state={ 
                                  list.id_announce 
                               }>
                                <button className='bg-[#233D4D] text-white w-[200px] relative left-6 h-8  rounded  
                                            justify-center cursor-pointer transition ease-in-out delay-150 
                                            hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                  Faire une reservation
                                </button>
                              </Link>
                                )}
                            
                        </div>
                        </Link>
                    </div>
              
              )
              )}
          </div>

          {/* Container 2 */}
          <BarniereLvoiture/>
       
          <Footer/>
        </>
      )}
    </div>
  )
}

export default Lvoiture