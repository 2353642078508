import React from 'react'
import Footer from '../../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import BackImage from '../BackImage'
import Carousel from './Carousel';
import { IoMdPin } from "react-icons/io";
import img1 from '../../../../ASSETS/tourisme/Image/luxury-bedroom-hotel.jpg'
import img2 from '../../../../ASSETS/tourisme/Image/living-room-mid-century-style-with-warm-colors-ai-generative.jpg'
import img3 from '../../../../ASSETS/tourisme/Image/small-bathroom-with-modern-style-ai-generated.jpg'
import { SlScreenDesktop } from "react-icons/sl";
import { IoRestaurant } from "react-icons/io5";
import { LuMicrowave } from "react-icons/lu";
import { GiBathtub } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { TbFridge } from "react-icons/tb";
import { BiHotel } from "react-icons/bi";
import { IoBedOutline } from "react-icons/io5";
import { RxDimensions } from "react-icons/rx";
import { FaUser, FaBellConcierge } from "react-icons/fa6";
import Barniere from "../Barniere"
import Commentaire from './Commentaire';
import Suggestion from "../Suggestion"
import {Link} from "react-router-dom"
import Hebergement from '../../../../API/tourisme/hebergement/edit'
import { AnnoncesProps, ImagesProps} from "../../../../props";
import {useLocation} from 'react-router-dom';
import { MdOutlinePool, MdElevator } from "react-icons/md";
import { MdOutlineWifi, MdBalcony } from "react-icons/md";
import { RiParkingBoxFill } from "react-icons/ri";
import { GiCctvCamera } from "react-icons/gi";
import { MdOutlineSevereCold } from "react-icons/md";
import { LuArmchair } from "react-icons/lu";



function VoirPlus() {

  const location = useLocation();
    const id = location.state

  const [hebergement, setHebergement] = useState<AnnoncesProps>();

  const [image, setImage] = useState([]);
  const slides = [img1, img2, img3];
  //console.log(slides);

    const getHeber = () => {
      Hebergement.getOne(id)
      .then((res:any) => {
          if (res.data.status === 201) {
            setHebergement(res.data.data)
          }
          console.log(res);
      })
      .catch((err:any) => {
          console.log(err);
      })
      .finally(() => {
          setLoading(false);
      })
  }

  const getImages = () => {
    Hebergement.getImages(id)
    .then((res:any) => {
        if (res.data.status === 201) {
          setImage(res.data.data)
        }
        console.log(res);
    })
    .catch((err:any) => {
        console.log(err);
    })
    .finally(() => {
        setLoading(false);
    })
}

    const [loading, setLoading] = useState(true);

    useEffect(() => {

      getImages();
      getHeber();

        //const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            //setLoading(false);
        //}, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        //return () => clearTimeout(timer);
    }, []);

  return (
    <div>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>

                 <BackImage />

                   <div className='flex xs:flex-row s:flex-col xs:justify-center s:items-center xs:items-start xs:space-x-14 s:space-x-0'>
                      <div className="md:w-[700px] xs:w-[500px] sm:w-[300px] s:w-[260px] mt-8  ">
                          {image ? 
                            (<Carousel slides={image} />)
                            : (<Carousel slides={slides} />)
                          }
                      </div>
                   
                      <div className='flex flex-col my-8 space-y-6'>
                        <div className='flex flex-col space-y-3 '>
                          {/* <p className='uppercase text-[#233d4d] font-bold xs:text-xl s:text-base'>Nom de la structure</p> */}
                          <div className='flex items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d] ' />
                            <p className=' text-[#fe7f2d]'>{hebergement?.contact_address}</p>
                          </div>
                        </div>
                        <p className='text-lg font-semibold text-[#FCCA46]'>{hebergement?.title_announce}</p>

                        <div className='grid md:grid-cols-3 xs:grid-cols-2 s:grid-cols-1 gap-y-6 gap-x-6'>
                          {/* nombre de chambre */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex items-center space-x-2 text-sm font-thin text-gray-400'>
                                                <BiHotel className='text-xl ' />
                                                <p>Nombre de chambre</p>
                                            </div>
                                            <p className='pl-2'>{hebergement?.realstate_number_pieces} Chambre(s)</p>
                                        </div>
                                        {/* Perimetre */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex items-center space-x-2 text-sm font-thin text-gray-400'>
                                                <RxDimensions className='text-xl ' />
                                                <p>Périmètre</p>
                                            </div>
                                            <p className='pl-2'>{hebergement?.realstate_size} m<sup>2</sup></p>
                                        </div>
                                        {/* Type de lit */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex items-center space-x-2 text-sm font-thin text-gray-400'>
                                                <IoBedOutline className='text-xl ' />
                                                <p>Type de lit</p>
                                            </div>
                                            <p className='pl-2'>{hebergement?.realstate_bedtype}</p>
                                        </div>
                                        {/* Capacité de la salle */}
                                        {/* <div className='flex flex-col space-y-2' >
                                            <div className='flex items-center space-x-2 text-sm font-thin text-gray-400'>
                                                <FaUser  className='' />
                                                <p>Capacité de la salle</p>
                                            </div>
                                            <p className='pl-2'>2-5 Personnes</p>
                                        </div> */}
                        </div>

                        <p className='uppercase text-[#233d4d] xs:w-full s:w-[260px] sm:w-[300px] font-bold xs:text-xl s:text-base '>Service et équipement de l'appartement</p>
                        <div className='grid s:grid-cols-1 md:grid-cols-3 xs:grid-cols-2 gap-y-6 text-[#4e4e4e]'>
                          <div className="flex items-center space-x-2">
                            <MdOutlinePool className='text-xl' />
                            <p>Piscine : {hebergement?.realstate_swimming_pool === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <MdOutlineWifi className='text-xl' />
                            <p>wifi : {hebergement?.realstate_wifi === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <GiCctvCamera className='text-xl' />
                            <p>Cameras : {hebergement?.realstate_security === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <MdBalcony className='text-xl' />
                            <p>Balcon : {hebergement?.realstate_balcony === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <MdElevator className='text-xl' />
                            <p>Ascenceur : {hebergement?.realstate_elevator === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <FaBellConcierge className='text-xl' />
                            <p>Concierge : {hebergement?.realstate_concierge === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RiParkingBoxFill className='text-xl' />
                            <p>Parking: {hebergement?.realstate_parking === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <MdOutlineSevereCold className='text-xl' />
                            <p>Climatiseur : {hebergement?.realstate_air_conditioner === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <LuArmchair className='text-xl' />
                            <p>Meublé : {hebergement?.realstate_furnished === 1 ? 'Oui' : 'Non'}</p>
                          </div>
                        </div>
                      
                      </div>

                   </div>


                   <div className='flex flex-col space-y-6 xs:ml-12 s:mx-2 sm:mx-4 mb-14'>
                      <p className='text-[#4e4e4e] text-xl pt-4 font-semibold '>Description</p>
                        <p className='md:w-[900px] xs:w-[500px] s:w-[260px] sm:w-[300px] pt-2 text-justify'>
                          {hebergement?.description_announce}
                        </p>
                        {hebergement?.status === "enabled" && (
                        <Link to="/Reservation_Hebergement" 
                        state={ 
                          hebergement?.id_announce 
                       } 
                        >
                          <button className='w-[170px] h-10 bg-[#FE7F2D] text-white text-lg rounded transition 
                          ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white
                          hover:text-[#FE7F2D] '>Réserver</button>
                        </Link>
)}
                        <Commentaire/>
                   </div>
                      
                    <Suggestion/>

                    <Barniere/>

                <Footer />
                  </>
            )}
    </div>
  )
}

export default VoirPlus