import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import SidebarTop from '../SidebarTop';
import profil from '../../../ASSETS/tourisme/Image/Dashbord_image/pp.jpg'
import profil2 from '../../../ASSETS/tourisme/Image/Dashbord_image/pp2.jpg'
import profil3 from '../../../ASSETS/tourisme/Image/Dashbord_image/pp3.jpg'
import { MdPostAdd } from "react-icons/md";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  Typography
} from "@material-tailwind/react";
import { RxDotsVertical } from "react-icons/rx";
import { VscSend } from "react-icons/vsc";

function Message() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee] '>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>

          <div className='w-screen  barre_scroll h-screen'>
            {/* sidebar top  */}
            <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs >
                <Link to="" className="opacity-60">
                  Messagerie
                </Link>
                
              </Breadcrumbs>
            </div>
            {/* container formulaire */}
            <div className=' flex flex-row h-screen md:px-10 md:py-2 s:py-10 '>

              {/* Barre des conversation */}
              <div className='bg-white barre_scroll w-1/4 md:h-[521px]  s:h-[521px] xs:h-[721px] divide-y '>

                <div className='md:text-xl xs:text-lg flex flex-row items-center justify-between font-semibold py-4 px-6 text-[#4E4E4E]'>
                  <p className='s:hidden xs:block'>Conversations</p>
                  <Tooltip content="Nouvelle conversation">
                  <IconButton variant="text">
                    <Menu>
                      <MenuHandler>
                        <IconButton variant='text'>
                        <MdPostAdd className='text-xl' />
                        </IconButton>
                      </MenuHandler>
                      <MenuList className="flex flex-col gap-2">
                        {/*Contact */}
                          <Link to="">
                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                               <div className="flex flex-col gap-1">
                                  <Typography variant="small" color="gray" className="font-semibold flex flex-row items-center space-x-3">
                                    <img src={profil2} alt='profil' className='w-6 h-6 rounded-full'/>
                                    <div className='text-lg text-center'>Eric Koffi</div>
                                  </Typography>
                                </div>
                            </MenuItem>
                          </Link>
                          <Link to="">
                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                               <div className="flex flex-col gap-1">
                               <Typography variant="small" color="gray" className="font-semibold flex flex-row items-center space-x-3">
                                    <img src={profil2} alt='profil' className='w-6 h-6 rounded-full'/>
                                    <div className='text-lg text-center'>Eric Koffi</div>
                                  </Typography>
                                </div>
                            </MenuItem>
                          </Link>
                          <Link to="">
                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                               <div className="flex flex-col gap-1">
                               <Typography variant="small" color="gray" className="font-semibold flex flex-row items-center space-x-3">
                                    <img src={profil2} alt='profil' className='w-6 h-6 rounded-full'/>
                                    <div className='text-lg text-center'>Eric Koffi</div>
                                  </Typography>
                                </div>
                            </MenuItem>
                          </Link>
                          <Link to="">
                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                               <div className="flex flex-col gap-1">
                               <Typography variant="small" color="gray" className="font-semibold flex flex-row items-center space-x-3">
                                    <img src={profil2} alt='profil' className='w-6 h-6 rounded-full'/>
                                    <div className='text-lg text-center'>Eric Koffi</div>
                                  </Typography>
                                </div>
                            </MenuItem>
                          </Link>
                      </MenuList>
                    </Menu>
                  </IconButton>
                </Tooltip>
                </div>


                {/* conversation 1 */}
                <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                  {/* Photo user */}
                  <img src={profil} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                  <div className='w-full flex md:flex-col s:flex-col-reverse '> 
                    {/* Nom & Heure d'envoie du message */}
                    <div className='flex md:flex-row s:flex-col items-center md:justify-between'>
                      <div className=' s:text-xs sm:text-lg text-center '>Mariam Konan</div>
                      <div className=' font-light'>12:03</div>
                    </div>

                    <div className='flex flex-row  items-center space-x-2 pt-2'>
                      <div className=' font-light italic s:hidden md:block'>Nouveau message</div>
                      <div className='w-6 h-5 bg-[#FE7F2D] text-sm rounded-full text-white text-center relative xs:left-[47px] md:left-0'>9+</div>
                    </div>
                  </div>
                </div>

                {/* conversation 2 */}
                <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                  {/* Photo user */}
                  <img src={profil2} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                  <div className='w-full flex md:flex-col s:flex-col-reverse'> 
                    {/* Nom & Heure d'envoie du message */}
                    <div className='flex md:flex-row s:flex-col items-center md:justify-between'>
                      <div className='s:text-xs sm:text-lg text-center'>Eric Koffi</div>
                      <div className=' font-light'>02:38</div>
                    </div>

                    <div className='flex flex-row items-center space-x-2 pt-2'>
                      <div className=' font-light italic s:hidden md:block'>Pas de nouveau message</div>
                    </div>
                  </div>
                </div>

                {/* conversation 3 */}
                <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                  {/* Photo user */}
                  <img src={profil3} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                  <div className='w-full flex md:flex-col s:flex-col-reverse'> 
                    {/* Nom & Heure d'envoie du message */}
                    <div className='flex md:flex-row s:flex-col items-center md:justify-between'>
                      <div className='s:text-xs sm:text-lg text-center'>Carolie Touré</div>
                      <div className=' font-light'>Hier</div>
                    </div>

                    <div className='flex flex-row items-center space-x-2 pt-2'>
                      <div className=' font-light italic md:block s:hidden'>Nouveau message</div>
                      <div className='w-6 h-5 bg-[#FE7F2D] text-sm rounded-full text-white text-center relative xs:left-[47px] md:left-0'>2</div>
                    </div>
                  </div>
                </div>

                 {/* conversation 4 */}
                <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                  {/* Photo user */}
                  <img src={profil} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                  <div className='w-full flex xs:flex-col s:flex-col-reverse'> 
                    {/* Nom & Heure d'envoie du message */}
                    <div className='flex md:flex-row s:flex-col items-center justify-between'>
                      <div className='s:text-xs sm:text-lg text-center'>Mariam Konan</div>
                      <div className=' font-light'>12/03/2024</div>
                    </div>

                    <div className='flex flex-row items-center space-x-2 pt-2'>
                      <div className=' font-light italic md:block s:hidden'>Pas de nouveau message</div>
                    </div>
                  </div>
                </div>

                  {/* conversation 5 */}
                  <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                      {/* Photo user */}
                      <img src={profil2} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                      <div className='w-full flex md:flex-col s:flex-col-reverse'> 
                        {/* Nom & Heure d'envoie du message */}
                        <div className='flex md:flex-row s:flex-col items-center md:justify-between'>
                          <div className='s:text-xs sm:text-lg text-center'>Eric Koffi</div>
                          <div className=' font-light'>02/02/2024</div>
                        </div>

                        <div className='flex flex-row items-center space-x-2 pt-2'>
                          <div className=' font-light italic s:hidden md:block'>Pas de nouveau message</div>
                        </div>
                      </div>
                  </div>

                {/* conversation 6 */}
                <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                  {/* Photo user */}
                  <img src={profil3} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                  <div className='w-full flex md:flex-col s:flex-col-reverse'> 
                    {/* Nom & Heure d'envoie du message */}
                    <div className='flex md:flex-row s:flex-col items-center md:justify-between'>
                      <div className='s:text-xs sm:text-lg text-center'>Carolie Touré</div>
                      <div className=' font-light'>23/01/2024</div>
                    </div>

                    <div className='flex flex-row items-center space-x-2 pt-2'>
                      <div className=' font-light italic md:block s:hidden'>Nouveau message</div>
                      <div className='w-6 h-5 bg-[#FE7F2D] text-sm rounded-full text-white text-center relative xs:left-[47px] md:left-0'>5</div>
                    </div>
                  </div>
                </div>

                 {/* conversation 7 */}
                 <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                      {/* Photo user */}
                      <img src={profil} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                      <div className='w-full flex md:flex-col s:flex-col-reverse '> 
                        {/* Nom & Heure d'envoie du message */}
                        <div className='flex md:flex-row s:flex-col items-center md:justify-between'>
                          <div className='s:text-xs sm:text-lg text-center '>Mariam Konan</div>
                          <div className=' font-light'>12/11/2023</div>
                        </div>

                        <div className='flex flex-row items-center space-x-2 pt-2'>
                          <div className=' font-light italic s:hidden md:block'>Nouveau message</div>
                          <div className='w-6 h-5 bg-[#FE7F2D] text-sm rounded-full text-white text-center relative xs:left-[47px] md:left-0'>9+</div>
                        </div>
                      </div>
                  </div>

                {/* conversation 8 */}
                <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                  {/* Photo user */}
                  <img src={profil2} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                  <div className='w-full flex md:flex-col s:flex-col-reverse'> 
                    {/* Nom & Heure d'envoie du message */}
                    <div className='flex md:flex-row s:flex-col items-center md:justify-between'>
                      <div className='s:text-xs sm:text-lg text-center'>Eric Koffi</div>
                      <div className=' font-light'>22/10/2023</div>
                    </div>

                    <div className='flex flex-row items-center space-x-2 pt-2'>
                      <div className=' font-light italic s:hidden md:block'>Pas de nouveau message</div>
                    </div>
                  </div>
                </div>

                {/* conversation 9 */}
                <div className='flex md:flex-row md:space-x-4 s:flex-col items-center  hover:bg-slate-50 cursor-pointer sm:px-6 py-3 '>

                  {/* Photo user */}
                  <img src={profil3} alt='profil' className='xs:w-12 xs:h-12 sm:w-10 sm:h-10 s:w-8 s:h-8 rounded-full'/>

                  <div className='w-full flex md:flex-col s:flex-col-reverse'> 
                    {/* Nom & Heure d'envoie du message */}
                    <div className='flex md:flex-row s:flex-col items-center md:justify-between'>
                      <div className='s:text-xs sm:text-lg text-center'>Carolie Touré</div>
                      <div className=' font-light'>14/08/2023</div>
                    </div>

                    <div className='flex flex-row items-center space-x-2 pt-2'>
                      <div className=' font-light italic md:block s:hidden'>Nouveau message</div>
                      <div className='w-6 h-5 bg-[#FE7F2D] text-sm rounded-full text-white text-center relative xs:left-[47px] md:left-0'>1</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-3/4  '>
                
                <div className='bg-white   h-14 flex justify-between items-center px-2' >

                  <div className='flex  flex-row items-center space-x-3'>
                    <img src={profil3} alt='profil' className='w-10 h-10 rounded-full'/>
                    <div className='s:text-xs sm:text-lg text-center '>Mariam Konan</div>
                  </div>

                  <Tooltip content="Action">
                    <IconButton variant="text">
                      <Menu>
                        <MenuHandler>
                          <IconButton variant='text'>
                            <RxDotsVertical className='text-lg' />
                          </IconButton>
                        </MenuHandler>
                        <MenuList className="flex flex-col gap-2">
                          {/* supprimer */}
                            <Link to="">
                              <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                <div className="flex flex-col gap-1">
                                    <Typography variant="small" color="gray" className="font-semibold">
                                      supprimer
                                    </Typography>
                                  </div>
                              </MenuItem>
                            </Link>
                        </MenuList>
                      </Menu>
                    </IconButton>
                  </Tooltip>

                </div>

                {/* conversation */}
                <div className='p-6 barre_scroll md:h-[410px] s:h-[410px] xs:h-[610px]  flex-col flex space-y-8'>

                  <div className='grid place-items-start'>
                    <div className='flex flex-row items-end space-x-3 '>
                      <img src={profil3} alt='profil' className='w-6 h-6 rounded-full'/>
                      <div className='bg-[#FE7F2D] text-white min-w-[80px] max-w-[400px] rounded-lg shadow-lg  h-full p-3 '>

                      <div>Sit amet nisl purus in mollis nunc sed. Vel turpis nunc eget lorem dolor sed 
                        viverra ipsum nunc. Euismod elementum nisi quis eleifend quam adipiscing vitae. 
                        Cras semper auctor neque vitae tempus quam pellentesque nec nam. Consectetur 
                        lorem donec massa sapien faucibus et molestie ac feugiat.
                        </div>

                        <div className='grid place-items-end font-semibold' >12:07</div>

                      </div>
                    </div>
                  </div>
                  <div className='grid place-items-start'>
                    <div className='flex flex-row items-end space-x-3 '>
                      <img src={profil3} alt='profil' className='w-6 h-6 rounded-full'/>
                      <div className='bg-[#FE7F2D] text-white min-w-[80px] max-w-[400px] rounded-lg shadow-lg  h-full p-3 '>

                      <div>Sit amet nisl purus in mollis nunc sed. Vel turpis nunc eget lorem dolor sed 
                        viverra ipsum nunc. Euismod elementum nisi quis eleifend quam adipiscing vitae. 
                        Cras semper auctor neque vitae tempus quam pellentesque nec nam. Consectetur 
                        lorem donec massa sapien faucibus et molestie ac feugiat.
                        </div>

                        <div className='grid place-items-end font-semibold' >12:07</div>

                      </div>
                    </div>
                  </div>

                 <div className=' grid place-items-end'>
                  <div className='flex flex-row items-end space-x-3 '>
                      <div className='bg-[#233D4D] text-white min-w-[80px] max-w-[400px] rounded-lg shadow-lg  h-full p-3 '>
                      <div>Sit amet nisl purus in mollis nunc sed. Vel turpis nunc eget lorem dolor sed 
                        viverra ipsum nunc.
                        </div>
                        <div className='grid place-items-end font-semibold' >12:07</div>
                      </div>
                      <img src={profil3} alt='profil' className='w-6 h-6 rounded-full'/>
                  </div>
                 </div>

                 <div className='grid place-items-start'>
                    <div className='flex flex-row items-end space-x-3 '>
                      <img src={profil3} alt='profil' className='w-6 h-6 rounded-full'/>
                      <div className='bg-[#FE7F2D] text-white min-w-[80px] max-w-[400px] rounded-lg shadow-lg  h-full p-3 '>
                        <div>
                          D'accord
                        </div>
                        <div className='grid place-items-end font-semibold' >12:07</div>
                      </div>
                    </div>
                  </div>

                   <div className='grid place-items-start'>
                    <div className='flex flex-row items-end space-x-3 '>
                      <img src={profil3} alt='profil' className='w-6 h-6 rounded-full'/>
                      <div className='bg-[#FE7F2D] text-white min-w-[80px] max-w-[400px] rounded-lg shadow-lg  h-full p-3 '>
                        <div>
                          D'accord
                        </div>
                        <div className='grid place-items-end font-semibold' >12:07</div>
                      </div>
                    </div>
                  </div>
                </div>

                 {/* Input conversation */}
                   <div className='bg-white   h-14 flex justify-between items-center px-2' >
                        <div className='flex  flex-row items-center space-x-3'>
                         <textarea className='s:w-[160px] sm:w-[220px] xs:w-[500px] md:w-[900px] h-14 border-none p-4 bg-transparent' placeholder='Taper un message'>
                         </textarea>
                         <button>
                           <VscSend className='text-xl' />
                         </button>
                         
                        </div>

                    </div>
              
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Message