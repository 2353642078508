import axios from 'axios';

let Hebergement : any = {};
Hebergement.submitForm = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/hebergement/add`, data)
}

Hebergement.getHomeData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/home`)
}

Hebergement.getAllData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/all`)
}

Hebergement.submitReservation = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/hebergement/send/reservation`, data)
}
Hebergement.submitFormUp = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/hebergement/send/update/popup/hebergement`, data)
}

export default Hebergement;