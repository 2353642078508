import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Footer from '../../COMPONNENTS/Footer/Footer'
import Header from '../../COMPONNENTS/Header/Header'
import citoyennete from '../../../ASSETS/CaImmigration/images/citoyennete_barniere.png'
import citoyennete1 from '../../../ASSETS/CaImmigration/images/pexels-andre-furtado-2916828.png'
import citoyennete2 from '../../../ASSETS/CaImmigration/images/pexels-cottonbro-studio-4880395.png'
import FAQ from '../FAQ/Faq'
import {Link} from 'react-router-dom'
import cible from '../../../ASSETS/CaImmigration/icones/cible.png'
import aide from '../../../ASSETS/CaImmigration/images/femmes-affaires-parlant-pres-du-bureau-pendant-pause-cafe-dans-couloir-grande-societe-removebg-preview.png'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visaN from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import pin from '../../../ASSETS/CaImmigration/icones/thumbtacks.png'
import feuille from '../../../ASSETS/CaImmigration/icones/maple-leaf.png'
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';


function CitoyenneteCa() {
  let [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 1000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          {/* back image */}
          <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${citoyennete})` }}>
             
               {/* Navbar */}
                <div className='navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0  bg-transparent flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
                        <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center space-x-[600px]    justify-between'>

                            <Link to='/'>
                            <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                                <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                            </div>
                            </Link>
                            <div onClick={() => setOpen(!open)} className=' lg:hidden text-end relative   s:text-3xl pr-4 s:text-white cursor-pointer'>
                            <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
                            </div>
                        </div>
                        {/* element_menu */}
                        <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

                            <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>
                            
                                {/* Accueil */}
                                <Link to='/homeCaimmigration'>
                                <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
                                    <div>
                                    <img className='w-6' src={home} alt='' />
                                    </div>
                                    <div> Accueil</div>
                                </div>
                                </Link>

                            {/* Visa */}
                                <div style={{ fontSize: "16px",  }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
                                <div className='relative bottom-1'>
                                    <img className='w-4' src={visaN} alt='' />
                                </div>
                                <div className='navbar1 '>
                                    <ul>
                                    <li> <Link to='/visa'> <p className='flex space-x-2 items-center'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p>
                                    <ul className='z-10  '>
                                        <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaVisiteur'> Visa visiteur </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                                    </ul>
                                    </Link>
                                    </li>
                                    </ul>
                                </div>
                                </div>

                            {/* Résidence permanente */}
                            <Link to='/ResidencePermante'>
                            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={Rpermanent} alt='' />
                                </div>
                                <div>Résidence permanente</div>
                            </div>
                            </Link>

                            {/*Citoyenneté Canadienne */}
                            <Link to='/CitoyenneteCa'>
                            <div style={{ fontSize: "16px",color:"#FCCA46" }} className='flex space-x-3 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={CitoyenCA} alt='' />
                                </div>
                                <div> Citoyenneté Canadienne</div>
                            </div>
                            </Link>

                            {/* Achat de biens immobilers */}
                            <Link to='/BienImmobilier'>
                                <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
                                <div>
                                    <img className='w-6' src={immobilierN} alt='' />
                                </div>
                                <div>Achat de biens immobilers</div>
                                </div>
                            </Link>
                            </div>
                        </div>
                </div>
                
              <div className='text_home flex justify-end md:pl-20 s:pl-5  s:pt-20 md:pt-0 mt-20'>
                  <div className='flex pt-20 text-center  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg  font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>CITOYENNETE CANADIENNE</p>
                  </div>
                
              </div>

          </div>

          {/* option */}
        <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
              <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/*Le Statut de Citoyenneté CanadienneLe */}
          <div className='bg-white py-20'>
              {/* titre */}
              <div className=' md:ml-24 s:ml-4'>
                <p className=' xs:text-3xl s:text-xl font-bold' style={{color:"#4e4e4e"}}><span style={{ color: "#FE7F2D" }} className=' font-bold xs:text-4xl s:text-2xl '>/</span>Le Statut de Citoyenneté Canadienne </p>
               </div>
              {/* bloc */}

              <div className='mt-10 flex flex-col space-y-14'>
                {/* bloc1 */}
                <div className='flex xs:flex-row s:flex-col md:space-x-28 xs:space-x-10 px-5 s:space-y-10 xs:space-y-0 justify-center s:items-center xs:items-start'>
                  <div><img className='xs:w-[400px] s:w-[200px] sm:w-[300px]' src={citoyennete1} alt='residence'/></div>
                  <div className=' '>
                    <div className='text-justify md:w-[500px] xs:w-[400px] s:w-[260px] sm:w-[320px] text-lg'>
                        La citoyenneté canadienne est le statut légal accordé aux individus qui sont reconnus 
                        comme des membres à part entière de la société canadienne. Elle confère certains droits, 
                        responsabilités et avantages aux citoyens du Canada. Voici quelques éléments clés de la 
                        citoyenneté canadienne et les avantages qui y sont associés :<br/><br/>

                        <div className='flex items-start space-x-3 '>
                        <img src={pin} alt='epingle' className='w-8 h-8'/>
                        <div>
                            <span className='font-bold text-[#233D4D]'> Droits civiques et politiques :</span> <br/>
                            - Les citoyens canadiens ont le droit de participer pleinement à la vie démocratique du 
                            pays en votant lors des élections fédérales, provinciales et municipales.<br/>
                            - Ils ont également le droit de se présenter comme candidats lors d'élections et de 
                            s'impliquer dans des activités politiques.
                        </div>
                        </div><br/>

                        <div className='flex items-start space-x-3 '>
                            <img src={pin} alt='epingle' className='w-8 h-8'/>
                            <div>
                                <span className='font-bold text-[#233D4D]'> Mobilité internationale :</span> <br/>
                               Les citoyens canadiens ont la possibilité de voyager plus facilement à l'étranger en
                               utilisant un passeport canadien, qui offre un accès sans visa ou avec des exigences de 
                               visa réduites dans de nombreux pays.
                            </div>
                        </div><br/>

                    </div>
                    </div>
                </div>

                 {/* bloc2 */}
                 <div className='flex xs:flex-row s:flex-col md:space-x-28 xs:space-x-10 px-5 s:space-y-10 xs:space-y-0 justify-center s:items-center xs:items-start'>
                  <div><img className='xs:w-[400px] s:w-[200px] sm:w-[300px]' src={citoyennete2} alt='residence'/></div>
                  <div className=' '>
                    <div className='text-justify md:w-[500px] xs:w-[400px] s:w-[260px] sm:w-[320px] text-lg'>
                        <div className='flex items-start space-x-3 '>
                            <img src={pin} alt='epingle' className='w-8 h-8'/>
                            <div>
                                <span className='font-bold text-[#233D4D]'> Protection consulaire :</span> <br/>
                                En tant que citoyen canadien à l'étranger, vous avez droit à la protection 
                                consulaire fournie par les ambassades et les consulats canadiens, ce qui peut
                                être utile en cas d'urgence ou de situation difficile à l'étranger.
                            </div>
                        </div><br/>

                         <div className='flex items-start space-x-3 '>
                            <img src={pin} alt='epingle' className='w-8 h-8'/>
                            <div>
                                <span className='font-bold text-[#233D4D]'> Accès aux services sociaux :</span> <br/>
                                Les citoyens canadiens ont accès à un large éventail de services sociaux, y compris 
                                les soins de santé, l'éducation, le chômage et d'autres programmes sociaux.
                            </div>
                        </div><br/>

                        <div className='flex items-start space-x-3 '>
                            <img src={pin} alt='epingle' className='w-8 h-8'/>
                            <div>
                                <span className='font-bold text-[#233D4D]'> Droit de résider au Canada :</span> <br/>
                                 Les citoyens canadiens ont le droit de résider indéfiniment au Canada et ne sont pas 
                                 soumis à des restrictions de séjour.
                            </div>
                        </div><br/>

                        <div className='flex items-start space-x-3 '>
                            <img src={pin} alt='epingle' className='w-8 h-8'/>
                            <div>
                                <span className='font-bold text-[#233D4D]'> Transmission de la citoyenneté :</span> <br/>
                                 La citoyenneté canadienne peut être transmise aux enfants nés de parents canadiens, 
                                 même s'ils naissent à l'étranger.
                            </div>
                        </div><br/>
                    </div>
                    </div>
                </div>
                

               
                <div className='flex flex-col justify-center mt-16'>
                  <div style={{ color:"#FE7F2D"}} className='xs:text-2xl s:text-xl font-medium md:ml-24 sm:ml-8 s:ml-2 s:w-[260px] sm:w-[300px] xs:w-full'>Comment obtenir la Citoyenneté Canadienne ?</div>
                  {/* etape */}
                  <div className=' flex flex-col items-center mt-4'>
                    <div className='md:w-[1060px] xs:w-[800px] s:w-[260px] sm:w-[320px] text-justify '>
                        Pour obtenir la citoyenneté canadienne, vous devez suivre un processus qui 
                        comprend plusieurs étapes. Voici une vue d'ensemble générale du processus. 
                        Veuillez noter que les détails peuvent varier, et il est toujours recommandé 
                        de consulter les ressources officielles du gouvernement canadien pour les 
                        informations les plus à jour. Les informations fournies ici sont basées sur 
                        ma dernière mise à jour en janvier 2022.
                    </div>
                  </div><br/>
                  {/* etape 1 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <div>
                                <b>Devenir résident permanent :</b> LAvant de demander la 
                                citoyenneté, vous devez d'abord devenir résident permanent 
                                du Canada. Cela peut être réalisé par le biais de programmes 
                                tels que l'Express Entry, le Programme des travailleurs 
                                qualifiés, le Programme des candidats des provinces, ou par 
                                le biais de la réunification familiale.
                            </div>
                        </div>
                     
                    </div><br/>
                    {/* etape 2 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <div>
                                <b>Remplir les exigences de résidence : </b> LVous devez avoir 
                                résidé physiquement au Canada pendant une période minimale avant 
                                de pouvoir demander la citoyenneté. La période précise peut varier, 
                                mais elle est généralement d'au moins 1 095 jours (trois ans) sur 
                                les cinq années précédant votre demande.
                            </div>
                        </div>
                     
                    </div><br/>
                    {/* etape 4 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <div>
                                <b>Préparer la documentation :  </b>  Vous devrez recueillir 
                                les documents nécessaires, tels que des preuves de votre 
                                résidence au Canada, des documents d'identité, des preuves 
                                de votre compétence linguistique, etc.
                            </div>
                        </div>
                     
                    </div><br/>
                    {/* etape 5 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <div>
                                <b>Remplir le formulaire de demande : </b>  Vous devrez 
                                remplir le formulaire de demande de citoyenneté canadienne. 
                                Assurez-vous de fournir toutes les informations requises et 
                                de suivre attentivement les instructions.
                            </div>
                        </div>
                     
                    </div><br/>
                    {/* etape 6 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <div>
                                <b>Payer les frais :  </b>  Les demandes de citoyenneté sont 
                                soumises à des frais, qui doivent être payés au moment de la 
                                soumission de la demande.
                            </div>
                        </div>
                     
                    </div><br/>
                    {/* etape 7 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <div>
                                <b>Passer l'examen de citoyenneté : </b> Si votre demande est acceptée, 
                                vous pourriez être invité à passer un examen de citoyenneté pour évaluer
                                 vos connaissances sur le Canada
                            </div>
                        </div>
                     
                    </div><br/>
                    {/* etape 8 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <div>
                                <b>Assister à la cérémonie de citoyenneté : </b> Si vous réussissez 
                                l'examen, vous serez invité à participer à une cérémonie de citoyenneté 
                                où vous prêterez serment de citoyenneté et recevrez votre certificat de citoyenneté.
                            </div>
                        </div>
                     
                    </div><br/>
                    

                </div>
              </div>

              {/* eligibilité */}
              <Link to="/TestEligibiliteVisa">
              <div className='transform active:scale-75 s:px-4 md:px-0 transition-transform flex flex-row items-center justify-center md:ml-28 sm:ml-10 s:ml-6 md:mt-28 s:mt-14 shadow-lg xs:w-[500px] sm:w-[280px] s:w-[230px] space-x-10 md:h-16 s:h-14 rounded-xl ' style={{ backgroundColor: "#F3F3F3" }}>
                <div className='md:text-lg sm:text-sm s:text-xs font-semibold' style={{ color: "#233D4D" }}>Tester votre éligibilité à la Résidence Permanente</div>

                <div><svg xmlns="http://www.w3.org/2000/svg" width="19.173" height="25.244" viewBox="0 0 29.173 45.244">
                  <g id="left" transform="translate(-1.974 -1.315)">
                    <path id="Tracé_40" data-name="Tracé 40" d="M5.427,1.316a3.223,3.223,0,0,0-2.116,5.7L23,23.876,3.3,40.732a3.223,3.223,0,1,0,4.2,4.865L30.018,26.344a3.223,3.223,0,0,0,0-4.9L7.494,2.13a3.223,3.223,0,0,0-2.068-.815Z" transform="translate(0 0)" fill="#fe7f2d" />
                  </g>
                </svg>
                </div>
              </div>
              </Link>

              {/* nous vous aidons */}
              <div style={{backgroundColor:"#4e4e4e"}} className='pt-10 mt-20 flex xs:flex-row s:flex-col-reverse  md:space-x-40 px-5 '>

                <img className='w-[500px]' src={aide} alt='aide'/>

                <div className='bg-white s:mb-20 xs:mb-0 s:ml-3  xs:w-[400px] sm:w-[350px] s:w-[260px] sm:h-[230px] xs:h-full p-2 s:h-[300px] flex flex-col items-center justify-center mt-10 '>
                  <div className='flex items-center space-x-8 '>
                    {/* icones cible */}
                    <div><img className='w-10' src={cible} alt='cible' /></div>
                    {/* texte */}
                    <div style={{ color: "#4e4e4e" }} className=' font-semibold xs:w-[250px] s:w-[180px] sm:w-[250px] md:text-xl  s:text-base sm:text-lg'>
                      Nous vous accompagnons dans le processus de demande de  Citoyenneté Canadienne.
                    </div>
                 </div>
                  <Link to="/PrendreRendezVous">
                  <button style={{ backgroundColor: "#FE7F2D" }} className='text-white font-semibold w-[160px] h-12 text-lg transform active:scale-75 transition-transform mt-10 s:ml-16 sm:ml-36'>Contactez-nous</button>
                  </Link>
                </div>
              </div>

              <FAQ/>
          </div>
        
          <Footer />
              </>
          )}
      </div>
  )
}

export default CitoyenneteCa