import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import SidebarTop from '../SidebarTop';
import { LuClipboardList } from "react-icons/lu";
import { FaCar } from "react-icons/fa";
import BarChat from './BarChat';
import { MdOutlineHotel } from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import Calendar from './Calendar';
import PieChart  from './PieChart';
import { ImStarEmpty } from "react-icons/im";
import TransationGraph from "./TransationGraph"
import Footer from '../Footer';
import Stats from '../../../API/stat/index'
import { useNavigate } from 'react-router-dom';
import {Navigate} from "react-router-dom"

function Dashboard() {

  //localStorage.setItem('url', JSON.stringify('/Dashboard/Graph'));

  const [loading, setLoading] = useState(true);

  const [records, setRecords] = useState([]);
  const [records1, setRecords1] = useState([]);
  
  const [data, setData] = useState(() => {
    // getting stored value
    if(localStorage.getItem("data")){
      const saved = localStorage.getItem("data");
      const initialValue = saved !== null ? JSON.parse(saved) : null;
      return initialValue;
    }
  });

  const navigate = useNavigate();

  const [url, setUrl] = useState(() =>{
    // getting stored value
    const link = localStorage.getItem("url");
    const value = link !== null ? JSON.parse(link) : null;
    return value;
  });

  const getData = () => {
    if(data){
      Stats.getResInfo(data.userId)
      .then((res:any) => {
          if (res.data.status === 200) {
              //console.log(res);
              setRecords(res.data.data)
              setRecords1(res.data.pie)
          }
      })
      .catch((err:any) => {
          console.log(err);
      })
      .finally(() => {
          setLoading(false);
      })
    }else{
      navigate('/Connexion');
    }
}

  useEffect(() => {
    
    // // Vérifiez si l'utilisateur est connecté
    // const isLoggedIn = !!data;
    // //console.log(isLoggedIn);
    // // Si l'utilisateur n'est pas connecté, redirigez-le vers la page de connexion
    
    // if (!isLoggedIn) {
    //   //navigate(url);
    //   //url !== null ? navigate(url)  : navigate('/'); 
    //   window.location.reload();
    //   navigate('/Connexion');
    //   // return <Navigate to="/Connexion" />;
    // }
    getData();

    //const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      //setLoading(false);
    //}, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    //return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee]'>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
        <div className='flex flex-col'>
              <div className='w-screen h-screen barre_scroll'>
                      {/* sidebar top  */}
                      <SidebarTop />
                      {/* navbar top gris*/}
                      <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                        <Breadcrumbs >
                          <Link to="" className="opacity-60">
                          Tableau de bord
                          </Link>
                        </Breadcrumbs>
                      </div>
                      {/* container */}
                      <div className='mt-10 '>
                          <div className='grid md:grid-cols-4 s:grid-cols-1 xs:grid-cols-2 s:gap-y-4 md:gap-y-0 place-content-center place-items-center '>
                            <Link to="/Dashboard/Reservation/Hebergement/Liste">
                                <div className='flex flex-row items-center justify-center h-[100px] w-[280px] space-x-4 bg-white shadow-md hover:bg-gray-50  rounded-lg '>
                                    <MdOutlineHotel className='text-5xl text-[#fe7f2d] bg-[#fe812d31] p-2 rounded' />
                                    <div className=''>
                                        <p className='text-3xl font-semibold'>{records[0]}</p>
                                        <p>Nouvelles Réservations </p>
                                    </div>
                                </div>
                              </Link>

                              <Link to="/Dashboard/Reservation/Restaurant/Liste">
                                <div className='flex flex-row items-center justify-center h-[100px] w-[280px] space-x-4 bg-white shadow-md hover:bg-gray-50  rounded-lg '>
                                    <IoRestaurantOutline className='text-5xl text-[#fe7f2d] bg-[#fe812d31] p-2 rounded' />
                                    <div className=''>
                                        <p className='text-3xl font-semibold'>{records[1]}</p>
                                        <p>Nouvelles Réservations </p>
                                    </div>
                                </div>
                              </Link>

                              <Link to="/Dashboard/Reservation/Vehicule/Liste">
                                <div className='flex flex-row items-center justify-center h-[100px] w-[280px] space-x-4 bg-white shadow-md hover:bg-gray-50  rounded-lg '>
                                    <FaCar className='text-5xl text-[#fe7f2d] bg-[#fe812d31] p-2 rounded' />
                                    <div className=''>
                                        <p className='text-3xl font-semibold'>{records[2]}</p>
                                        <p>Nouvelles Réservations </p>
                                    </div>
                                </div>
                              </Link>

                              <Link to="">
                                <div className='flex flex-row items-center justify-center h-[100px] w-[280px] space-x-4 bg-white shadow-md hover:bg-gray-50  rounded-lg '>
                                    <LuClipboardList className='text-5xl text-[#fe7f2d] bg-[#fe812d31] p-2 rounded' />
                                    <div className=''>
                                        <p className='text-3xl font-semibold'>{records[3] + records[4]}</p>
                                        <p>Nouvelles Réservations </p>
                                    </div>
                                </div>
                              </Link>

                              

                          </div>
                          <div className='grid gap-3 mt-10 ml-6 md:grid-cols-3 s:grid-cols-1 '>
                            <PieChart data={records1}/>
                            <Calendar/>
                            <div className='flex flex-col space-y-3'>
                              <Link to='/Dashboard/Publications/Hebergement/Voir_des_hebergements'>
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>{records[5]}</span> Appartements en ligne</p>
                                </div>
                              </Link>

                              <Link to='/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules'>
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>{records[7]}</span> Véhicule en ligne</p>
                                </div>
                              </Link>

                              <Link to='/Dashboard/Publications/Restaurant/Voir_des_restaurants'>
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>{records[6]}</span> Restaurant en ligne</p>
                                </div>
                              </Link>

                              <Link to="/Dashboard/Publications/Loisirs_activites/Voir_des_activites">
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>{records[8]}</span> Activité en ligne</p>
                                </div>
                              </Link>

                              <Link to="/Dashboard/Publications/Culture_Decouverte/Voir_les_decouvertes">
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>{records[9]}</span> Site touristique en ligne</p>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className='grid my-20 mt-10 ml-6 md:grid-cols-2 s:grid-cols-1 md:gap-40 s:gap-8'>
                          <BarChat/>
                          <TransationGraph/>
                          </div>
                          
                          
                      </div>
              </div>
              <Footer/>
        </div>
          
        </>
      )}
     
    </div>
  )
}

export default Dashboard