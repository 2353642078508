import React from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visaN from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import { Link } from 'react-router-dom'
import { useState, } from 'react';
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'


function Navbar() {
    let [open, setOpen] = useState(false);
  return (
    <div className='navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0  bg-transparent flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
          <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center xs:space-x-[600px]    justify-between'>

              <Link to='/'>
                  <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                      <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                  </div>
              </Link>
              <div onClick={() => setOpen(!open)} className=' lg:hidden text-end relative   s:text-3xl pr-4 s:text-white cursor-pointer'>
                  <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
              </div>
          </div>
          {/* element_menu */}
          <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

              <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>

                  {/* Accueil */}
                  <Link to='/homeCaimmigration'>
                      <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
                          
                              <img className='w-6' src={home} alt='' />
                          
                          <div> Accueil</div>
                      </div>
                  </Link>

                  {/* Visa */}
                  <div style={{ fontSize: "16px", color: "white" }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center  font-semibold cursor-pointer text-white '>
                      <div className='relative bottom-1'>
                          <img className='w-4' src={visaN} alt='' />
                      </div>
                      <div className='navbar1 '>
                          <ul>
                              <li> <Link to='/visa'> <p className='flex space-x-2 items-center'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche' /></p></Link>
                                  <ul className='z-10  '>
                                      <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                                      <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                                      <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                                      <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                                      <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                                  </ul>
                              
                              </li>
                          </ul>
                      </div>
                  </div>

                  {/* Résidence permanente */}
                  <Link to='/ResidencePermante'>
                      <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                              <img className='w-6' src={Rpermanent} alt='' />
                          <div>Résidence permanente</div>
                      </div>
                  </Link>

                  {/*Citoyenneté Canadienne */}
                  <Link to='/CitoyenneteCa'>
                      <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                              <img className='w-6' src={CitoyenCA} alt='' />
                          <div> Citoyenneté Canadienne</div>
                      </div>
                  </Link>

                  {/* Achat de biens immobilers */}
                  <Link to='/BienImmobilier'>
                      <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
                              <img className='w-6' src={immobilierN} alt='' />
                          <div>Achat de biens immobilers</div>
                      </div>
                  </Link>
              </div>
          </div>
      </div>
  )
}

export default Navbar