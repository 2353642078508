import React from 'react'
import Africa from '../../ASSETS/tourisme/Image/A.D 2.png'

function Footer() {
  return (
    <div className='bg-black w-screen text-white text-sm h-10 flex justify-center items-center font-light'>
    @ Design by <img className='w-12 px-2' src={Africa} alt='logoAfrica' /> Africa Digitalizer
    </div>
  )
}

export default Footer