import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENTS/Header/Header'
import Navbar from './Navbar'
import Footer from '../../COMPONNENTS/Footer/Footer'
import visa from '../../../ASSETS/CaImmigration/images/VisaTransit_barniere.png'
import visatransit1 from '../../../ASSETS/CaImmigration/images/black-woman-with-suitcase-airport.png'
import transit2 from '../../../ASSETS/CaImmigration/images/visa_transit2.jpg'
import transit3 from '../../../ASSETS/CaImmigration/images/visa_transit3.jpg'
import { Link } from 'react-router-dom'
import Vtransit from '../../../ASSETS/CaImmigration/icones/passenger.png'
import TestEligibilite from '../../COMPONNENTS/BoutonTestEligibilite/TestEligibilite';
import BarniereRdvVisa from '../../COMPONNENTS/BarniereRdvVisa/BarniereRdvVisa';
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';

function VisaEtude() {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
      <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
                  <Header />

                  {/* back image */}
                  <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${visa})` }}>
                      <Navbar />
                      <div className='text_home flex  justify-end md:pl-10 s:pl-3  s:pt-20 md:pt-0 mt-20'>
                              <div className='flex space-y-4 pt-20 flex-col text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg    font-semibold xs:w-[470px] md:w-[770px] '>
                              <p>SERVICES DE VISA CANADIEN</p>
                              <p className=' md:text-3xl sm:text-lg s:text-sm'>VISA TRANSIT</p>
                          </div>
                      </div>

                  </div>

                  {/* option */}
                  <OptionCard/>

                  {/* en tete */}
                  <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
                      <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
                      <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
                  </div>

                <div className='bg-white py-20'>
                  {/* titre */}
                    <div className='flex  xs:flex-row xs:px-5 md:px-0 md:pl-0 flex-col justify-center items-center xs:space-x-40 xl:space-x-[700px] lg:space-x-[300px] s:space-y-10'>
                    <div>
                        <p className=' text-sm font-semibold'><span style={{ color:"#FE7F2D"}} className=' font-bold sm:text-xl s:text-lg'>/</span>QUE PROPOSONS-NOUS </p>
                        <p style={{ color: "##4e4e4e" }} className=' sm:text-2xl s:text-xl font-bold w-[250px]'>Conseils en Services de visa d'immigration exceptionnels.</p>
                    </div>

                    <div style={{ color: "##4e4e4e" }} className='xs:w-[650px] md:w-[800px] sm:w-[300px] s:w-[200px] sm:text-lg s:text-base text-justify '>
                    "À la recherche d'une expertise exceptionnelle en matière d'immigration au Canada ? 
                    Nous sommes là pour vous accompagner tout au long de votre parcours. Nos consultants 
                    chevronnés vous offrent des conseils personnalisés et des services de visa d'immigration 
                    de haute qualité. Avec notre équipe dévouée, nous vous guiderons à chaque étape de votre 
                    processus d'immigration, vous aidant à réaliser votre rêve canadien en toute simplicité. 
                    Faites confiance à notre expérience pour une immigration réussie et sans stress."</div>
                    </div>

                    {/* container1 */}
                    <div className='flex xs:flex-row s:flex-col xs:space-x-40 s:space-y-20 s:justify-center items-center s:py-20 xs:py-0 xs:pb-20'>
                        {/* filtre */}
                        <div className='sm:w-[300px] s:w-[260px] h-[400px] flex flex-col justify-center items-center space-y-5  ' style={{backgroundColor:"#f7f7f7"}}>

                            {/* permis d'etudes */}
                            <Link to='/visa/visaEtude'>
                            <div style={{  backgroundColor: 'white', color:"#FE7F2D" }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base  text-white rounded-xl shadow-lg font-semibold justify-center items-center space-x-5'>
                            <p>Visa et Permis d'etudes</p>
                              <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                           
                            </div>
                            </Link>
                            {/* visa travail */}
                            <Link to='/visa/visaTravail'>
                            <div style={{backgroundColor: 'white', color:"#FE7F2D"  }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-5'>
                                    <p>Visa et Permis de travail</p>
                                     <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                               
                            </div>
                            </Link>
                            {/* visa touriste */}
                            <Link to='/visa/visaTouriste'>
                            <div style={{ backgroundColor: 'white', color:"#FE7F2D" }} className='transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa touriste</p>
                                       <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                            </div>
                            </Link>
                            {/* visa transit */}
                            <Link to='/visa/visaTransit'>
                            <div style={{ backgroundColor: '#FE7F2D', color:'white' }} className='transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa transit</p>
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                              <g id="rigth" transform="translate(-1.974 -1.315)">
                                                  <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fff" />
                                              </g>
                                          </svg>
                                    </p>
                            </div>
                            </Link>
                            {/* visa familiale*/}
                            <Link to='/visa/visaFamiliale'>
                            <div style={{ backgroundColor: 'white', color:"#FE7F2D" }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa familiale</p>
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                            </div>
                            </Link>
                        </div>

                        {/* texte1 */}
                        <div>
                            <img className='md:w-[700px] sm:w-[300px] s:w-[210px]' src={visatransit1} alt='etudiant'/>
                            <p className='text-xl font-semibold pt-6' style={{color:"#FCCA46"}}>Visa transit</p>
                            <p className='md:w-[680px] sm:w-[300px] s:w-[210px] pt-6'>
                                est un document officiel délivré par le gouvernement du Canada qui permet aux voyageurs étrangers de transiter par le Canada en route vers leur destination finale. Ce type de visa est nécessaire pour les personnes qui ne sont pas éligibles à une exemption de visa de transit et qui prévoient de faire escale au Canada, que ce soit à l'aéroport ou sur un vol de correspondance.
                            </p>
                        </div>
                    </div>

                    {/* container2 */}
                    <div className='flex md:flex-row s:flex-col justify-center s:items-center xs:items-start md:space-x-32 s:space-x-0 s:space-y-16 xs:space-y-0 xs:mr-24 s:mr-0'>

                        <div className=' flex md:flex-col xs:flex-row s:flex-col s:space-y-10 xs:space-y-0 xs:space-x-10 md:space-x-0 md:space-y-10'>
                            <div><img src={transit2} className='md:w-[400px] sm:w-[300px] s:w-[210px]' alt='etudiant1'/></div>
                            <div><img src={transit3} className='md:w-[400px] sm:w-[300px] s:w-[210px]' alt='etudiant1'/></div>
                        </div>

                        <div className='flex flex-col items-center space-y-10' >
                            {/* avantages */}
                            <div>
                            <p className='text-2xl font-semibold xs:w-[500px] sm:w-[300px] s:w-[210px]' style={{color:"#233D4D"}}>Pourquoi avoir le visa transit</p>
                            <p className='md:w-[500px] xs:w-[600px] sm:w-[300px] s:w-[210px] pt-6'>
                                <b>Exigence légale :</b> Dans de nombreux pays, notamment le Canada, un visa de transit est une exigence légale pour les voyageurs qui ne sont pas citoyens ou résidents permanents du pays et qui ont besoin de faire escale sur le territoire national, même si ce n'est que pour une courte période.<br/><br/>
                                <b>Transits avec changement d'aéroport : </b> Lorsque vous effectuez un changement d'aéroport pendant votre transit, il est souvent nécessaire d'entrer dans le pays, de passer par l'immigration, de récupérer vos bagages et de les réenregistrer pour votre vol de correspondance. Dans de tels cas, un visa de transit peut être requis.<br/><br/>
                                <b>Flexibilité de voyage : </b>Un visa de transit vous offre la flexibilité de choisir des itinéraires de voyage qui impliquent des transits dans des pays où un visa de transit est nécessaire.
                            </p>
                            </div>
                           
                        </div>
                    </div>

                     {/* guide */}
                        <div className='flex flex-col items-center'>
                            <p className='text-2xl font-semibold xs:w-[500px] md:w-[800px] sm:w-[300px] s:w-[210px] mt-10' style={{color:"#233D4D"}}>Nous vous guidons dans les processus d'obtention du visa transit</p>
                            <p className='text-center md:w-[800px] xs:w-[600px] sm:w-[300px] s:w-[210px] pt-6'>
                                Votre voyage en toute fluidité. Mon Bon Séjour simplifie le processus pour obtenir votre visa de transit. Comptez sur notre expérience pour des déplacements sans tracas, où que vous alliez.
                            </p>
                        </div>

                           
                     {/* etudes */}
                        <div className='flex justify-center mt-10  flex-row items-center  text-xl font-bold space-x-6' style={{color:"#233D4D"}}>
                             {/* icones */}
                             <p>
                                <img src={Vtransit} className='w-12' alt='transit'/>
                            </p>
                             <p>Voyage d'affaire au Canada ou escale</p>
                        </div>

                    {/* eligibilité */}

                    <TestEligibilite />

                    {/* banniere */}
                   <BarniereRdvVisa/>
                </div>

                  <Footer />
              </>
          )}
      </div>
  )
}

export default VisaEtude