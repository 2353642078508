import React from 'react'
import { MdOutlineSupportAgent } from "react-icons/md";
import { SiYourtraveldottv } from "react-icons/si";
import { GiDiscussion } from "react-icons/gi";
import { IoIosSend } from "react-icons/io";
import barniere from '../../../ASSETS/AfImmigration/barniere.png'
import {Link} from 'react-router-dom'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function BarniereService() {

    interface Items{      
        Items_Numbers: number;	
        Categories: number;	
        Names: string;	
        fr_Name: string;	
        //Types: string;	
        //Prices: double;	
        //nb_place: string;	
        //Available: string;	
        //Status: string;	
        Overview: string;	
        fr_overview: string;	
        Descriptions: string;	
        fr_description: string;	
        Pictures: string;	
        //subscription_frequency: string;	
        //initiale_prices: string;	
        //related_link: string;	
        //item_doc: string;	
        //contract: string;	
      }

      const navrdv=useNavigate()
      const BtnPrendreRdv=(Items_Numbers : any)=>{
          //console.log(Items_Numbers)
          if(Items_Numbers){
  
              navrdv('/appointement/RendezVousService/'+Items_Numbers)
          }
      } 
    const [Item, setItem] = useState<Items[]>([]);

     const id=useParams()
    //const idi=useParams()
    useEffect(() =>{
        //console.log(id)
        
        // .then(res=>{
        //     console.log(res.data.data)
        //     setItem(res.data.data);
        //  })
        //  .catch(err => console.log(err))

         async function getItemDes(){
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/items/${id.item_id}`)
                setItem(res.data.data);
                //console.log(res.data.data)
            }catch (err) {
                console.log(err);
            }
            // .then(res=>{
            //     //console.log(res.data.data[0])
            //  })
            //  .catch(err => console.log(err))
        }
        getItemDes()

         
    }, []);


  return (
    <div className='grid s:grid-cols-1 xs:grid-cols-2 py-14 xs:px-10 s:px-3 '>
        <div className='flex flex-col space-y-10'>
            <div className='text-3xl font-semibold text-[#233D4D] xs:w-[400px] s:w-[260px] sm:w-[300px]'>
                Description
            </div>
            <div className='md:w-[500px] xs:w-[340px] s:w-[260px] sm:w-[300px] text-lg'>
                {Item[0]?.fr_description ? Item[0]?.fr_description.replace(/^<p>|<\/p>$/g, '') : "Aucune description"}
            </div>

            <div className='flex s:flex-col xs:flex-row xs:items-center s:space-y-6 xs:space-y-0 xs:space-x-6'>

                {/* assistance */}
                <div className='flex flex-row items-center space-x-2'>
                    <div className='flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-md'>
                        <MdOutlineSupportAgent className='text-3xl text-[#fe7f2d]' />
                    </div>
                    <div className='text-lg font-semibold text-[#4E4E4E]'>Assistance</div>
                </div>

                {/* immigration */}
                <div className='flex flex-row items-center space-x-2'>
                    <div className='flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-md'>
                        <SiYourtraveldottv className='text-3xl text-[#fe7f2d]' />
                    </div>
                    <div className='text-lg font-semibold text-[#4E4E4E]'>Immigration</div>
                </div>

                {/* conseil */}
                <div className='flex flex-row items-center space-x-2'>
                    <div className='flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-md'>
                        <GiDiscussion className='text-3xl text-[#fe7f2d]' />
                    </div>
                    <div className='text-lg font-semibold text-[#4E4E4E]'>Conseil</div>
            </div>
        </div>

        {/* <Link to='/PrendreRendezVous'>
        <button className=' bg-[#FCCA46] flex flex-row items-center space-x-2 text-white h-10 w-[200px]  px-2 transform active:scale-75 transition-transform'>
                <p>Prendre rendez-vous</p>
                <IoIosSend />
            </button>
        </Link> */}
            <button onClick={()=>BtnPrendreRdv(Item[0].Items_Numbers)} className=' bg-[#FCCA46] flex flex-row items-center space-x-2 text-white h-10 w-[200px]  px-2 transform active:scale-75 transition-transform'>
                <p>Prendre rendez-vous</p>
                <IoIosSend />
            </button>

        </div>
        <img src={barniere} className='w-[500px] s:mt-10 xs:mt-0' alt='barniere'/>
    </div>
  )
}

export default BarniereService