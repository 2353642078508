import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import {Link} from 'react-router-dom'
import { MdOutlineHomeWork } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../SidebarTop';
import { IoMdPin } from "react-icons/io";
import { MdOutlineDateRange } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";


function Modifier() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


  return (
    <div className='flex flex-row bg-[#eeee]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
        {/* sidebar left */}

        <div className='w-screen  barre_scroll h-screen'>
            {/* navbar top orange */}
             <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                <Breadcrumbs>
                      <Link to="" className="opacity-60 md:text-base s:text-xs">
                         Transation
                      </Link>
                      <Link to="/Dashboard/Transation" className="opacity-60 md:text-base s:text-xs">
                         Revenus & Depenses
                      </Link>
                      <Link to="" className='md:text-base s:text-xs'>
                        Modifier
                      </Link>
                </Breadcrumbs>
            </div>

            <Link to="/Dashboard/Transation">
                <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                    <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                    <p>Retour à la page précedente</p>
                </div>
            </Link>
            {/* container formulaire */}
            <div className='flex justify-center py-20  '>
            <form className='flex flex-col space-y-3 items-center  '>

                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                    {/* Titre */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
                            <label>Titre</label>
                        </div>
                        <input type='text' name='name_hotel' placeholder='Ex : Restauration; Location de vehicule; Achat de ticket; Achat de materiel' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* Date */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineDateRange className='text-xl text-[#fe7f2d]' />
                            <label>Date d'achat</label>
                        </div>
                        <input type='date' name='' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                     {/* Lieu */}
                     <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Lieu</label>
                        </div>
                        <input type='text' name='name_hotel' placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Montant */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                            <label>Montant</label>
                        </div>
                        <input type='number' name='' placeholder='Ex : 400000 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                        
                    </div>
                    {/* Section*/}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <BiSolidCategory  className='text-xl text-[#fe7f2d]' />
                            <label>Section</label>
                        </div>
                        <select className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option>Restaurant</option>
                        <option>Location de vehicule</option>
                        <option>Location d'hebergement</option>
                        <option>Achat de ticket (Tourisme)</option>
                        <option>Achat de ticket (Loisirs & activité)</option>
                        </select>
                    </div>
                    {/* categorie*/}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <BiSolidCategory  className='text-xl text-[#fe7f2d]' />
                            <label>categorie</label>
                        </div>
                        <select className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option>Depense</option>
                        <option>Vente</option>
                        <option>Autre</option>
                        </select>
                    </div>
                    
                    
                </div>

                <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Mettre à jour</button>
            </form>
            </div>
        </div>
              </>
          )}
    </div>
  )
}

export default Modifier