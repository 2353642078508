import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENT/Header/Header';
import backimage from '../../../ASSETS/AfImmigration/img.png'
import {Link} from 'react-router-dom'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import BoutonLogin from '../../../TOURISME/COMPONENTS/BoutonLogin/BtnLogin'
import { FaCheck } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";
import { LiaTelegramPlane } from "react-icons/lia";
import conseil from '../../../ASSETS/AfImmigration/personnes-taille-moyenne-dans-bureau-agence-voyages.png'
import iconeConseil from '../../../ASSETS/AfImmigration/customer-service.png'
import iconeRecommandation from '../../../ASSETS/AfImmigration/reputation.png'
import iconeAssistance from '../../../ASSETS/AfImmigration/social-care.png'
import { IoIosArrowDropright } from "react-icons/io";
import { IoPin } from "react-icons/io5";
import Assistance from '../../../ASSETS/AfImmigration/equipe-collegues-travaillant-documents-entreprise-faisant-travail-equipe-pour-analyser-documents-informations-recherche-ligne-planification-presentation-entrep.png'
import recommandation from '../../../ASSETS/AfImmigration/collegues-masculins-feminins-noirs-assis-dans-bureau-regardant-ensemble-ecran-ordinateur.png'
import benin from '../../../ASSETS/AfImmigration/000_32JN7YL.png'
import drapeauBenin from '../../../ASSETS/AfImmigration/drapeau-du-benin.png'
import CI from '../../../ASSETS/AfImmigration/participation-sitho.png'
import drapeauCI from '../../../ASSETS/AfImmigration/drapeau-cote-ivoire.png'
import zambie from '../../../ASSETS/AfImmigration/b39df0e9-299f-4a28-ae24-0323c2adbc35fcfff1ad1cd191d150_IMG_0239_Fotor.png'
import drapeauzambie from '../../../ASSETS/AfImmigration/drapeau-zambie.png'
import nigeria from '../../../ASSETS/AfImmigration/VIDEO-Lagos-State-unveils-Abiola-statue-to-mark-June-12-1.png'
import drapeaunigeria from '../../../ASSETS/AfImmigration/drapeau-du-nigeria.png'
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import Faq from './Faq'
import filleImg from '../../../ASSETS/AfImmigration/image.png'
import Footer from '../../COMPONNENT/Footer/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Base64} from 'js-base64';
// Importation des pop ups
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//importation des button du pop up
import Button from '@mui/material/Button';
import { unescape } from 'querystring';

function HomeByCategorie(){
    interface ItemCats {
        //Items_Numbers: number;
        Category_id: number;	
        group_category: number;	
        Names: string;	
        fr_Name: string;	
        Descriptions: string;	
        fr_description: string;
      }

    interface Items{      
        Items_Numbers: number;	
        Categories: number;	
        Names: string;	
        fr_Name: string;	
        //Types: string;	
        //Available: string;	
        //Status: string;	
        Overview: string;	
        fr_overview: string;	
        Descriptions: string;	
        fr_description: string;	
        Pictures: string;	
        //subscription_frequency: string;	
        //related_link: string;	
        //item_doc: string;	
        //contract: string;	
      }
    localStorage.setItem('url', JSON.stringify('/accueil_afrique_immigration'));

    // Creer la varible déclencheuse du pop up
    const [openItemCat, setOpenItemCat] = React.useState(false);
    const [openAllItem, setOpenAllItem] = React.useState(false);
    const [loading, setLoading] = useState(true);

    const [itemcats, setItemCats] = useState<ItemCats[]>([]);
    const [items, setItems] = useState<Items[]>([]);

    const [allitems, setAllItem] = useState<Items[]>([]);
    const [allitemcats, setAllItemCats] = useState<ItemCats[]>([]);
    
    const [itemFilters, setItemFilters] = useState<Items[]>([]);

    const nav=useNavigate()
    const navrdv=useNavigate()
    const BtnSeeMore=(Items_Numbers : any)=>{
        //console.log(Items_Numbers)
        if(Items_Numbers){

            nav('/accueil_afrique_immigration/Conseil/'+Items_Numbers)
        }
    }

    const BtnPrendreRdv=(Items_Numbers : any)=>{
        //console.log(Items_Numbers)
        if(Items_Numbers){

            navrdv('/appointement/RendezVousService/'+Items_Numbers)
        }
    }
// États de chargement individuels
        const [loadingCategoryItem, setLoadingCategoryItem] = useState(true);
        //const [loadingItem, setLoadingItem] = useState(true);
        const [loadingGroupCat, setLoadingGroupCat] = useState(true);


  // Pour le scroll dans le pop up
        const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');


   const id=useParams()
    useEffect(() =>{
        
        async function getCategoryI() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/category/${id.category_id}`);
                setItemCats(response.data.data);
                //console.log(response.data.data)
            } catch (err) {
                console.log(err);
            }
            // finally {
            //     setLoadingCategoryItem(false);
            // }
        }
        getCategoryI();

            async function getItemCategory(){
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/items/category/${id.category_id}`)
                    setItems(response.data.data);
                    //console.log(response.data.data);    
                }catch (err) {
                    console.log(err);
                }
                // finally {
                //     setLoadingGroupCat(false);
                // }
            }
            getItemCategory()

            
        const timer = setTimeout(() =>{
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);
    
    // useEffect(() => {
    //     if (!loadingCategoryItem && !loadingGroupCat) {
    //         setLoading(false);
    //     }
    // }, [loadingCategoryItem, loadingGroupCat]);

  return (
    <div className='bg-[#F7F7F7]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading/>
          ) : (
        <>
        <Header/>
        <div style={{ backgroundImage:`url(${backimage})` }} className='w-screen h-screen bg-center bg-no-repeat bg-cover'>
            <div className='flex s:flex-col sm:flex-row sm:justify-between'>
            <Link to='/'>
                <div style={{ borderBottomRightRadius: "100px" }} className='w-40 pb-4 pr-4 bg-white sm:pl-5 s:pl-2'>
                    <img src={logo1} className='h-20 pt-6 s:pl-0 ' alt='logo' />
                </div>
            </Link>
            <BoutonLogin/>
            </div>

            <div className=' sm:pl-10 s:pl-4 sm:pt-10'>
                <div className='xs:text-4xl s:text-lg sm:text-2xl font-semibold text-[#233D4D] xs:w-[600px]'>
                    {itemcats[0]?.fr_Name ? itemcats[0]?.fr_Name.replace(/^<p>|<\/p>$/g, '') : ""}
                </div>
                
                <Link to='/appointement/RendezVousService'>
                    <button className='s:mt-8 sm:mt-12 bg-[#FCCA46] flex flex-row items-center space-x-2 text-white h-10 w-[200px]  px-2 transform active:scale-75 transition-transform'>
                        <p>Prendre rendez-vous</p>
                        <IoIosSend />
                    </button>
                </Link>
            </div>

        </div>

        <div className='flex flex-col items-center space-y-4 py-14'>
            <div className='flex flex-row items-center space-x-2 '>
                <LiaTelegramPlane className='text-2xl text-[#fe7f2d]' />
                <div className='text-2xl font-semibold text-[#233D4D]'>Nos services</div>
            </div>
            <div className='px-2 text-lg xs:w-[700px] text-center'>
                {itemcats[0]?.fr_description ? itemcats[0]?.fr_description.replace(/^<p>|<\/p>$/g, '') : "Aucune description n'est associée"}
            </div>
            <div className='grid gap-10 pt-10 place-content-center place-items-center md:grid-cols-3 s:grid-cols-1 xs:grid-cols-2'>
                {/* Conseil */}

                {  items ? (items.map((item, index)=>{
                    return (
                        <div key={index} className='bg-white md:w-[360px] sm:w-[300px] s:w-[260px]  h-[490px] shadow-lg rounded-t-2xl'>
                        {/* image conseil*/}
                        <img src={`data:image/png;base64,${item.Pictures}`} alt='conseil' className='md:w-[360px] sm:w-[300px] s:w-[260px] h-[220px] rounded-2xl'/>
                        {/* <img src={conseil} alt='conseil' className='md:w-[360px] sm:w-[300px] s:w-[260px] rounded-2xl'/> */}
                        {/* icone cercle */}
                        <div className='bg-[#233D4D] rounded-full w-14 h-14 flex items-center justify-center relative bottom-8 md:left-72 s:left-48 sm:left-56'>
                            <img src={iconeConseil} alt='iconeconseil' className='h-8 w-18'/>
                        </div>
                    <div className='relative flex flex-col space-y-3 bottom-8 left-5'>
                            {/* description */}
                            <div className='text-xl line-clamp-1 uppercase font-semibold text-[#233D4D]'>{item.fr_Name ? item.fr_Name.replace(/^<p>|<\/p>$/g, '') : ""}</div>
                            <div className='md:w-[300px] line-clamp-5 sm:w-[260px] s:w-[230px] '>
                                {item.fr_overview ? item.fr_overview.replace(/^<p>|<\/p>$/g, '')
                                        :""  
                                }
                            </div>
                            <div className='flex s:flex-col md:flex-row md:items-center s:space-y-3 md:space-y-0 md:space-x-4'>
                                {/* Voir plus */}
                                
                                    <button onClick={()=>BtnSeeMore(item.Items_Numbers ? item.Items_Numbers : "")} className='flex items-center space-x-2 text-[#fe7f2d] text-lg transform active:scale-75 transition-transform '>
                                        <div>En savoir plus</div>
                                        <IoIosArrowDropright />
                                    </button>
                                    <button onClick={()=>BtnPrendreRdv(item.Items_Numbers ? item.Items_Numbers : "")} className='bg-[#233D4D] text-white h-8 w-[170px] rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 '>
                                            Prendre rendez-vous
                                    </button>
                                    {/* <Link to='/PrendreRendezVous'>
                                        <button className='bg-[#233D4D] text-white h-8 w-[170px] rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 '>
                                            Prendre rendez-vous
                                        </button>
                                    </Link> */}
                                
                            </div>
                    </div>
                    </div>
                    )
                })) : ("Aucun service n'est associé")}
                
            </div>
        </div>
        <Footer/>
        </>
          )}
        
    </div>
  )
}

export default HomeByCategorie