import React from 'react'
import axios from 'axios';
import { Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { FcCancel } from "react-icons/fc";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import {
    ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { RxDotsVertical } from "react-icons/rx";
import {
    Card,
    CardHeader,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import SidebarTop from '../../SidebarTop';






const TABLE_HEAD = ["Client", "Libelle", "Arrivé", "Départ", "Nombre de jours", "Statut", "Action",];

const TABLE_ROWS = [
    {
        name: "Koukou marie",
        email: "info@gmail.com",
        portable:"+225 01 00 00 00 00",
        Typechambre: "Villa",
        arrive: "22/10/2024",
        personne: "100",
        Depart: "22/10/2024",
        Paiement: "Payé",
    },
    {
        name: "Adjagbe christia",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        Typechambre: "studio",
        arrive: "29/05/2024",
        personne: "30",
        Depart: "29/05/2024",
        Paiement: "À l'arrivé",
    },
    {
        name: "Touré pricille",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        Typechambre: "Villa,piscine",
        arrive: "06/09/2024",
        personne: "40",
        Depart: "06/09/2024",
        Paiement: "À l'arrivé",
    },
    {
        name: "Murphy micheal",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        Typechambre: "appartement",
        arrive: "03/01/2024",
        personne: "30",
        Depart: "03/01/2024",
        Paiement: "Payé",
    },
    {
        name: "Coulibaly Lucas",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        Typechambre: "chambre",
        arrive: "22/10/2024",
        personne: "90",
        Depart: "03/01/2024",
        Paiement: "Payé",
    },
];


function Liste() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Reservation/Hebergement/Liste'));

    const [records, setRecords] = useState([]);

    const [data, setData] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
    });


    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/reservation/list/${data.userId}`)
     .then(response => {setRecords(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* sidebar top  */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Les reservations
                                </Link>
                                <Link to="" className="md:text-base s:text-xs">
                                    Hebergements
                                </Link>
                            </Breadcrumbs>
                        </div>

                        {/* container formulaire */}
                        <div className='flex justify-center md:p-10 s:p-4'>

                            <Card className="h-full w-full border-t-8 border-[#fe7f2d] ">
                                <CardHeader floated={false} shadow={false} className="rounded-none">
                                    <Typography className='md:text-xl s:text-lg font-semibold' color="blue-gray">
                                        Liste des personnes qui ont fait une reservation
                                    </Typography>
                                </CardHeader>
                                <CardBody className="overflow-scroll px-0">
                                    <table className="mt-4 w-full min-w-max overflow-scroll  table-auto text-left">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head, index) => (
                                                    <th
                                                        key={head}
                                                        className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                                    >
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                                        >
                                                            {head}{" "}
                                                            {index !== TABLE_HEAD.length - 1 && (
                                                                <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                                            )}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                      {records.length === 0 && (
                      <tbody>
                    <Typography variant="small" color="blue-gray" className="font-normal flex items-center">
                    Pas de donnees
                  </Typography>
                  </tbody> 
)}
{records.length > 0 && (
                                        <tbody>
                                            {records.map((list, index )=> {
                                                    const isLast = index === records.length - 1;
                                                    const classes = isLast
                                                        ? "p-4"
                                                        : "p-4 border-b border-blue-gray-50";

                                                    return (
                                                        <tr key={index} className='hover:bg-slate-100'>

                                                            <td className={classes}>
                                                                <div className="flex items-center space-x-2 ">
                                                                    <div className="flex flex-col">
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal"
                                                                        >
                                                                            {list.Names} {list.LastName}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal opacity-70"
                                                                        >
                                                                            
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal opacity-70"
                                                                        >
                                                                            {list.Phones}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <div className="flex flex-col">
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {list.title_announce}
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <div className="flex flex-col">
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {list.starting_date}
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes} >
                                                                <div className="w-max" >
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"

                                                                    >
                                                                        {list.ending_date}
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal"
                                                                >
                                                                    {list.status}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Tooltip content="Action sur la publication">
                                                                    <IconButton variant="text">
                                                                        <Menu>
                                                                            <MenuHandler>
                                                                                <IconButton>
                                                                                    <RxDotsVertical />
                                                                                </IconButton>
                                                                            </MenuHandler>
                                                                            <MenuList className="flex flex-col gap-2">

                                                                                {/* Refuser */}
                                                                                <Link to="">
                                                                                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                                        <FcCancel className='text-lg' />
                                                                                        <div className="flex flex-col gap-1">
                                                                                            <Typography variant="small" color="gray" className="font-semibold">
                                                                                               Refuser
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                </Link>
                                                                            </MenuList>
                                                                        </Menu>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                        )}
                                    </table>
                                </CardBody>
                                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        Page 1 of 10
                                    </Typography>
                                    <div className="flex gap-2">
                                        <Button variant="outlined" size="sm">
                                            Previous
                                        </Button>
                                        <Button variant="outlined" size="sm">
                                            Next
                                        </Button>
                                    </div>
                                </CardFooter>
                            </Card>

                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Liste