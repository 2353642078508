import { useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import { useState } from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import { Link } from 'react-router-dom'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visa from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilier from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import back from '../../../ASSETS/CaImmigration/images/about.png'
import temoignage from '../../../ASSETS/CaImmigration/images/photo_temoignage.jpg'
import Header from '../../COMPONNENTS/Header/Header';
import Footer from '../../COMPONNENTS/Footer/Footer';
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'



const links = [
  { name: 'Visa touriste', href: '/visa/visaTouriste' },
  { name: 'Visa visiteur', href: '/visa/visaVisiteur' },
  { name: "permis d'étude", href: '/visa/visaEtude' },
  { name: 'Permis de travail', href: '/visa/visaTravail' },
  { name: 'Visa familiale', href: '/visa/visaFamiliale' },
  { name: 'Residence permante', href: '/ResidencePermante' },
  { name: 'Citoyenneté canadienne', href: '/CitoyenneteCa' },
]
const stats = [
  { name: 'Offices worldwide', value: '12' },
  { name: 'Full-time colleagues', value: '300+' },
  { name: 'Heure de travail ', value: '20000+' },
  { name: 'Paid time off', value: 'Unlimited' },
]

export default function About() {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    let [open, setOpen] = useState(false);

  return (
    <div className="relative isolate bg-gray-900 s:full   xs:h-screen">
        {
        loading ? (
          // Afficher le spinner tant que loading est true
          <Loading />
        ) : (
          <>
          <Header/>

      {/* navbar */}
      <div  className='navbar_animation bg-transparent s:relative s:bottom-2  sm:bottom-5 md:bottom-0 flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
      <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center justify-between'>

        <Link to='/'>
          <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
            <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
          </div>
        </Link>
        <div onClick={() => setOpen(!open)} className=' lg:hidden text-end s:text-3xl pr-4 s:text-white cursor-pointer'>
          <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
        </div>
      </div>
      {/* element_menu */}
      <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5 s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

        <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>

          {/* Accueil */}
          <Link to='/homeCaimmigration'>
            <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
              <div>
                <img className='w-6' src={home} alt='' />
              </div>
              <div> Accueil</div>
            </div>
          </Link>

          {/* Visa */}

          <div style={{ fontSize: "16px" }} className='relative top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
            <div className='relative bottom-1'>
              <img className='w-4' src={visa} alt='' />
            </div>
            <div className='navbar1 '>
              <ul>
                <li> <Link to='/visa'> <p className='flex space-x-2 items-center'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p>
                  <ul className='z-10  '>
                    <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                    <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                  </ul>
                </Link>
                </li>
              </ul>
            </div>
          </div>


          {/* Résidence permanente */}
          <Link to='/ResidencePermante'>
            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
              <div>
                <img className='w-6' src={Rpermanent} alt='' />
              </div>
              <div>Résidence permanente</div>
            </div>
          </Link>

          {/*Citoyenneté Canadienne */}
          <Link to='/CitoyenneteCa'>
            <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
              <div>
                <img className='w-6' src={CitoyenCA} alt='' />
              </div>
              <div> Citoyenneté Canadienne</div>
            </div>
          </Link>

          {/* Achat de biens immobilers */}
          <Link to='/BienImmobilier'>
            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
              <div>
                <img className='w-6' src={immobilier} alt='' />
              </div>
              <div>Achat de biens immobilers</div>
            </div>
          </Link>
        </div>
      </div>
      </div> 

      <img 
        src={back}
        alt="fff"
        className="back_image absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-20"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-[#233D4D] opacity-50"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-[#233D4D] opacity-50"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="text_home pt-10 max-w-7xl px-6 lg:px-8">
        <div className=" max-w-2xl lg:mx-0">
           
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Mon Bon Séjour</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Agence de conseil d'immigration Canadaniene.
            Nous vous conseillons et vous accompagnons dans l'obtention de
          </p>
        </div>
        <div className=" mt-10  lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 xs:grid-cols-3 md:grid-cols-7 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-[#fe7f2d] sm:grid-cols-2 md:flex lg:gap-x-10">
            {links.map((link) => (
              <a key={link.name} href={link.href}>
                {link.name} <span aria-hidden="true">&rarr;</span>
              </a>
            ))}
          </div>
          <dl className="mt-16 max-w-2xl grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xs:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className='bg-white mt-10 py-10'>
        <p className='text-3xl font-semibold text-[#4e4e4e] text-center'>Temoignages</p>
        <p className='text-lg text-center pt-4 px-5'>Nos clients Temoignagent de la bonne qualité de nos services et nous remercient. </p>

        <div className='grid md:grid-cols-3 xs:grid-cols-2 s:grid-cols-1 gap-4 place-items-center pt-10'>

            {/* temoignages1 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
                <p className='sm:w-[300px] s:w-[230px] text-lg'>“Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.”</p>
                <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
             {/* temoignages2 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
                <p className='sm:w-[300px] s:w-[230px] text-lg'>“Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.”</p>
                <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
              {/* temoignages2 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
                <p className='sm:w-[300px] s:w-[230px] text-lg'>“Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.”</p>
                <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='grid md:grid-cols-3 xs:grid-cols-2 s:grid-cols-1 gap-4 place-items-center pt-10'>

            {/* temoignages1 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
                <p className='sm:w-[300px] s:w-[230px] text-lg'>“Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.”</p>
                <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
             {/* temoignages2 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
                <p className='sm:w-[300px] s:w-[230px] text-lg'>“Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.”</p>
                <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
              {/* temoignages2 */}
            <div className='bg-[#F7F7F7] sm:w-[320px] s:w-[260px] py-6 px-4 rounded-xl flex flex-col space-y-8'>
                <p className='sm:w-[300px] s:w-[230px] text-lg'>“Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.”</p>
                <div className='flex items-center space-x-5'>
                    <img src={temoignage} className='w-12 h-12 rounded-full' alt='profil'/>
                    <div>
                        <p className='font-semibold'>Kouakou Rosaline</p>
                        <p>Quebec, Canada</p>
                    </div>
                </div>
            </div>
        </div>
        <Link to="/PrendreRendezVous" className='flex justify-center pt-10'>
        <button style={{ backgroundColor:"#FCCA46"}} className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150 text-white w-36 h-12 font-medium '>Prendre RDV</button>
        </Link>
      </div>

      <Footer/>
         </>
        )}
    </div>
  )
}
