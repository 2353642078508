import React from 'react'

function Loading() {
  return (
      <div className=' w-screen h-screen flex justify-center items-center' style={{ backgroundColor:"#233D4D"}}>
          <span className="loader"></span>
    </div>
  )
}

export default Loading