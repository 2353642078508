import React from 'react'
import Footer from '../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import BackImage from './BackImage';
import cafe from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 5.png'
import resto from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 6.png'
import pizza from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 7.png'
import bar from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 8.png'
import patisserie from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 10.png'
import mario from '../../../ASSETS/tourisme/Image/mario.png'
import { VscEye } from "react-icons/vsc";
import quenns from '../../../ASSETS/tourisme/Image/quenns.png'
import laBrise from '../../../ASSETS/tourisme/Image/laBrise.png'
import havana from '../../../ASSETS/tourisme/Image/havana.png'
import {Link} from 'react-router-dom'
import Barniere from './Barniere';
import Restau from '../../../API/tourisme/restaurant/index'
import { AnnoncesProps} from "../../../props";

function Restaurant() {
    const [loading, setLoading] = useState(true);

    const [restaurant, setRestaurant] = useState<AnnoncesProps[]>([]);

    const getAllRestau = () => {
        Restau.getAllData()
        .then((res:any) => {
            //console.log(res.data);
            if (res.data.status === 200) {
                const initialItemsArray = res.data.data.slice(0, 5);
                const shuffledInitialItems = [...initialItemsArray].sort(() => Math.random() - 0.5);
                setRestaurant(shuffledInitialItems)
            }
        })
        .catch((err:any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    function truncate (str : any) {
        return str.length > 200 ? str.substring(0, 200) + "..." : str;
    }

    useEffect(() => {

        getAllRestau();


        //const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            //setLoading(false);
        //}, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        //return () => clearTimeout(timer);
    }, []);

  return (
    <div>
         {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
          
          <BackImage/>
          {/* Services */}
          <div className='relative flex flex-col items-center pb-10 md:mt-40 s:mt-80'>
            <div className='text-[#233D4D] text-2xl font-semibold py-10'>Nos services</div>
            <div className='grid gap-20 md:grid-cols-5 s:grid-cols-1 xs:grid-cols-3 '>
                {/* cafeteria */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={cafe} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Cafétéria</p>
                    <p className='w-[150px] text-center'>l'arôme envoûtant du café fraîchement moulu</p>
                </div>
                {/* Restaurant */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={resto} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Restaurant</p>
                    <p className='w-[150px] text-center'>une expérience culinaire d'exception dans des restaurants </p>
                </div>
                {/* Pizzeria */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={pizza} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Pizzeria</p>
                    <p className='w-[150px] text-center'>déguster des pizzas exquises </p>
                </div>
                {/* Bar */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={bar} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Bar</p>
                    <p className='w-[150px] text-center'>des instants festifs au cœur de notre bar. Cheers </p>
                </div>
                {/* patisserie */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={patisserie} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Patisserie</p>
                    <p className='w-[150px] text-center'>un moment sucré dans notre pâtisserie exquise </p>
                </div>
            </div>
          </div>

           {/* barre bleu */}
            <div className='grid w-2/3 my-16 border-t-4 border-slate-900'></div>

             {/* title */}
            <div className='flex flex-col items-center justify-center my-10 space-y-5 '>
                <p className='font-semibold text-center xs:text-2xl s:text-xl'> RESTAURATIONS</p>
                <p className='text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                Savourez l'excellence culinaire en Côte d'Ivoire ! Découvrez une fusion de saveurs 
                authentiques, de plats traditionnels et de créations innovantes. Faites de votre repas 
                une aventure mémorable.
                </p>
            </div>

             {/* filtre */}
            <div className='grid pt-10 md:grid-cols-4 xs:grid-cols-3 s:gap-6 md:gap-0 s:grid-cols-1 place-items-center'>
              <button className='w-[200px] h-10 bg-[#233D4D] text-lg text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Tout</button>
              {/* etoiles*/}
              <select className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'>
                <option>5 étoiles</option>
                <option>4 étoiles</option>
                <option>3 étoiles</option>
                <option>2 étoiles</option>
              </select>
              {/* Lieu ou la voiture se trouve */}
              <input type='text' name='lieu_voiture' placeholder='Ville ou commune' className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'/>
              {/* prix de la voiture */}
              <input type='text' name='lieu_voiture' placeholder='Prix' className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg' />
            </div>

            {/* container restaurant */}
           <div className='flex flex-wrap justify-center my-20 md:flex-row s:flex-col s:items-center md:items-start s:space-y-10 md:space-y-0'>

                {restaurant.map((list, index )=> (
                    <div key={index} className=' flex flex-col space-y-8 border-l-2 my-3 border-[#fe7f2d] pl-5'>

                        {/* restaurant1 */}
                        <div className='flex space-x-6 space-y-5 xs:flex-row s:flex-col '>
                            <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={`data:image/png;base64,${list[0]}`} alt={list.title_announce}/>
                            {/* Description */}
                            <div>
                                <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>{list.title_announce}</p>
                                <p className='text-[#233D4D]font-medium pb-2'>{list.contact_address}</p>
                                <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>{truncate(list.description_announce)}
                                </p>
                                <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                                    <Link to="/restaurants/Voir_Plus" 
                                        state={ list.id_announce }>
                                        <button className='flex items-center h-8 px-5 my-3 space-x-2 text-white rounded bg-slate-800 hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                            <p>Visiter</p>
                                            <VscEye className='text-lg' />
                                        </button>
                                    </Link>

                                        
                                    {list.status === "enabled" && (
                                        <Link to="/Reservation_Restaurant"
                                            state={ list.id_announce }>
                                        <button className='flex items-center h-8 px-5 my-3 space-x-2 text-white rounded bg-slate-800 hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                            Reserver
                                        </button>
                                    </Link>
                                    )}
                                </div>

                            </div>
                        </div>

                    </div>
                    )
                    )}
           </div>

           <Barniere/>

            <Footer/>
        </>
      )}
    </div>
  )
}

export default Restaurant