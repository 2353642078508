import React from 'react'
import botte from '../../../ASSETS/tourisme/icone/Groupe de masques 21.png'
import mall2 from '../../../ASSETS/tourisme/icone/Groupe de masques 25.png'
import tire from '../../../ASSETS/tourisme/icone/Groupe de masques 24.png'
import feu from '../../../ASSETS/tourisme/icone/Groupe de masques 22.png'
import camera from '../../../ASSETS/tourisme/icone/Groupe de masques 23.png'
import panier from '../../../ASSETS/tourisme/icone/Groupe de masques 26.png'
import femme from '../../../ASSETS/tourisme/Image/image_loisirs/vue-face-belles-filles-posant-ensemble-removebg-preview.png'


function Barniere() {
  return (
    <div className='bg-[#cecece] my-16 flex md:flex-row s:flex-col-reverse sm:px-2'>
                <img className='xs:w-[470px] s:w-[400px]' src={femme} alt='femmes'/>
                <div className='py-5 flex flex-col space-y-3'>

                   <div className='flex xs:flex-row s:flex-col xs:space-x-6 s:space-y-6 xs:space-y-0'>
                        <div className='flex flex-row space-x-4 items-center'>
                            <img src={botte} className='w-16 h-16' alt='icone'/>
                            <div className='flex flex-col space-y-2'>
                                <p className='text-[#fe7f2d] font-semibold'>Randonée</p>
                                <p className='xs:xs:w-[280px] sm:w-[260px] s:w-[200px]'>Évadez-vous dans la nature ivoirienne ! Partez à l'aventure avec nos randonnées guidées.</p>
                            </div>
                        </div>
                        <div className='flex flex-row space-x-4 items-center'>
                            <img src={tire} className='w-16 h-16' alt='icone'/>
                            <div className='flex flex-col space-y-2'>
                                <p className='text-[#fe7f2d] font-semibold'>Jeux</p>
                                <p className='xs:w-[280px] sm:w-[260px] s:w-[200px]'> Plongez dans l'amusement avec nos salles de jeux divertissantes en Côte d'Ivoire. </p>
                            </div>
                        </div>
                   </div>
                    <div className='flex xs:flex-row s:flex-col xs:space-x-6 s:space-y-6 xs:space-y-0'>
                        <div className='flex flex-row space-x-4 items-center'>
                            <img src={feu} className='w-16 h-16' alt='icone'/>
                            <div className='flex flex-col space-y-2'>
                                <p className='text-[#fe7f2d] font-semibold'>Camping</p>
                                <p className='xs:w-[280px] sm:w-[260px] s:w-[200px]'>Vivez l'aventure ultime en Côte d'Ivoire avec notre expérience de camping exceptionnelle.</p>
                            </div>
                        </div>
                        <div className='flex flex-row space-x-4 items-center'>
                            <img src={mall2} className='w-16 h-16' alt='icone'/>
                            <div className='flex flex-col space-y-2'>
                                <p className='text-[#fe7f2d] font-semibold'>Shooping</p>
                                <p className='xs:w-[280px] sm:w-[260px] s:w-[200px]'>Parcourer nos marchés animés, boutiques élégantes et centres commerciaux modernes.</p>
                            </div>
                        </div>
                   </div>
                    <div className='flex xs:flex-row s:flex-col xs:space-x-6 s:space-y-6 xs:space-y-0'>
                        <div className='flex flex-row space-x-4 items-center'>
                            <img src={camera} className='w-16 h-16' alt='icone'/>
                            <div className='flex flex-col space-y-2'>
                                <p className='text-[#fe7f2d] font-semibold'>Visite</p>
                                <p className='xs:w-[280px] sm:w-[260px] s:w-[200px]'>Immergez-vous dans l'histoire et l'art ivoiriens avec nos visites de musées et galeries captivantes.</p>
                            </div>
                        </div>
                        <div className='flex flex-row space-x-4 items-center'>
                            <img src={panier} className='w-16 h-16' alt='icone'/>
                            <div className='flex flex-col space-y-2'>
                                <p className='text-[#fe7f2d] font-semibold'>Pique nique</p>
                                <p className='xs:w-[300px] sm:w-[260px] s:w-[200px]'> Des espaces dans nos parcs pittoresques, le long de nos plages ensoleillées, ou au cœur de nos espaces naturels préservés.</p>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
  )
}

export default Barniere