
import logo from '../../../ASSETS/tourisme/logo/logo.png'
import Africa from '../../../ASSETS/tourisme/Image/A.D 2.png'
import { Link } from 'react-router-dom'
import MentionsLegale from '../MentionsLegale/MentionsLegale'
import PolitiqueConfidentialite from '../PolitiqueConfidentialite/PolitiqueConfidentialite'
import GestionCookies from '../GestionCookies/GestionCookies'



function Footer() {
    const platformEmail = 'info@monbonsejour.com';
    const instagramUsername = 'monbonsejour';
    const facebookUsername = 'profile.php?id=61551029507292&mibextid=LQQJ4d';
    const phoneNumber = '+2250702322868';
   
  return (
    <div className='flex flex-col justify-center items-center'>
          <div className='footer w-screen px-10 pb-10 gap-12 xs:grid-cols-2 s:grid-cols-1 grid md:grid-cols-5'>
            <div className='space-y-4'>
                <img src={logo} alt='' className='w-40'/>
                <p className=' md:w-[300px] sm:w-72 md:text-base s:text-sm text-white'>
                    Agence de conseil d'immigration Canadaniene. Nous vous conseillons et vous accompagnons dans votre demarcheà
                    d'immigration et dans  l'obtention de votre visa ou permis.</p>
            </div>

            <div className='md:ml-20 w-60'>
                <p className='text-lg text-orange-400 font-medium pb-4 pt-6'> Navigation</p>
                <div className='text-sm text-white font-thin flex flex-col space-y-3'>
                   <Link to="/homeCaimmigration"> <p>Accueil</p></Link>
                    <Link to="/visa"><p>Visa</p></Link>
                    <Link to="/ResidencePermante"><p>Résidence permarnent</p></Link>
                    <Link to="/CitoyenneteCa"><p>Citoyenneté Canadienne</p></Link>
                    <Link to="/BienImmobilier"><p>Achat de bien immobilier au Canada</p></Link>
                    <Link to="/PrendreRendezVous"><p>Contactez-nous</p></Link>
                    <Link to="/AboutUs"><p>A propos de nous</p></Link>
                </div>
            </div>

            <div className='md:ml-20 w-60'>
                <p className='text-lg text-orange-400 font-medium pb-4 pt-6'> Accès direct</p>
                <div className='text-sm text-white font-thin flex flex-col space-y-3'>
                      {/* Mentions légales */}
                     <MentionsLegale/>
                      {/* Politique de confidentialité */}
                        <PolitiqueConfidentialite/>
                      {/* Gestion des cookies  */}
                        <GestionCookies/>
                </div>
            </div>

            <div className='md:ml-20'>
                <p className='text-lg text-orange-400 font-medium pb-4 pt-6'> Liens utiles</p>
                <div className='text-sm text-white font-thin flex flex-col space-y-3'>
                    <p><Link to="/FAQ">FAQ</Link> </p>
                    <p><Link to='/Brochure'>Brochures</Link></p>
                </div>
            </div>

            

            <div className=' flex flex-col space-y-6 pt-6 text-sm'>
                {/* whatsapp */}
            <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
            <div className='flex space-x-8'>
            <svg xmlns="http://www.w3.org/2000/svg" width="22.719" height="22.723" viewBox="0 0 25.719 25.723">
                <g id="phone" transform="translate(0)">
                    <path id="Tracé_13" data-name="Tracé 13" d="M16.37,16.736l-3.327,3.327c-.7.7-2.915-.377-4.945-2.408S5,13.417,5.7,12.717L9.024,9.39,3.5,3.862.738,6.619c-2.03,2.03.439,7.8,5.522,12.875S17.1,27.046,19.134,25.009l2.757-2.757ZM12.885,0V2.572A10.3,10.3,0,0,1,23.174,12.861h2.572A12.861,12.861,0,0,0,12.885,0Z" transform="translate(-0.027)" fill="#fcfcfc"/>
                    <path id="Tracé_14" data-name="Tracé 14" d="M187.485,75v2.572a5.146,5.146,0,0,1,5.144,5.144H195.2A7.728,7.728,0,0,0,187.485,75Z" transform="translate(-174.627 -69.856)" fill="#fcfcfc"/>
                </g>
            </svg>
            <p className='text-orange-400'>+225 07 02 32 28 68</p>
            </div>
            </a>

            {/* adresse email */}
            <a href={`mailto:${platformEmail}`}>
            <div className='flex space-x-8'>
            <svg id="gmail" xmlns="http://www.w3.org/2000/svg" width="23.519" height="15.112" viewBox="0 0 33.519 20.112">
            <path id="Tracé_7" data-name="Tracé 7" d="M33.226,41.5l11.293,6.224V35.008ZM11,35.008V47.726L22.293,41.5ZM42.431,31.3H13.089a2.005,2.005,0,0,0-2.037,1.551l16.708,9.6,16.708-9.6A2.026,2.026,0,0,0,42.431,31.3ZM31.292,42.6l-2.965,1.708a1.253,1.253,0,0,1-.567.157.969.969,0,0,1-.567-.157L24.227,42.6l-13.15,7.258a2.028,2.028,0,0,0,2.037,1.551H42.457a1.984,1.984,0,0,0,2.037-1.551Z" transform="translate(-11 -31.3)" fill="#fff"/>
            </svg>

            <p className='text-orange-400'>info@monbonsejour</p>
            </div>
            </a>

            {/* instagram */}
            <a href={`https://www.instagram.com/${instagramUsername}`} target="_blank" rel="noopener noreferrer">
                <div className='flex space-x-9'>
                <svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="Tracé_9" data-name="Tracé 9" d="M218.406,214.7a3.7,3.7,0,1,1-3.7-3.7A3.7,3.7,0,0,1,218.406,214.7Zm0,0" transform="translate(-204.703 -204.703)" fill="#fff"/>
                <path id="Tracé_10" data-name="Tracé 10" d="M120.2,120h-.14a.055.055,0,0,0-.055.055v.14a.055.055,0,0,0,.055.055h.14a.055.055,0,0,0,.055-.055v-.14A.055.055,0,0,0,120.2,120Zm-.07.195a.069.069,0,1,1,.069-.069A.069.069,0,0,1,120.125,120.195Zm.079-.135a.014.014,0,1,1,.014-.014A.014.014,0,0,1,120.2,120.06Zm0,0" transform="translate(-110.125 -110.125)" fill="#fff"/>
                <path id="Tracé_11" data-name="Tracé 11" d="M14.727,0H5.273A5.279,5.279,0,0,0,0,5.273v9.453A5.279,5.279,0,0,0,5.273,20h9.453A5.279,5.279,0,0,0,20,14.727V5.273A5.279,5.279,0,0,0,14.727,0Zm1.758,12.969a3.52,3.52,0,0,1-3.516,3.516H7.031a3.52,3.52,0,0,1-3.516-3.516V7.031A3.52,3.52,0,0,1,7.031,3.516h5.938a3.52,3.52,0,0,1,3.516,3.516Zm0,0" fill="#fff"/>
                </svg>


                <p className='text-orange-400'>mon bon sejour</p>
                </div>
            </a>

            {/* facebook */}
           <a href={`https://www.facebook.com/${facebookUsername}`} target="_blank" rel="noopener noreferrer">
            <div className='flex space-x-12'>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20">
            <g id="facebok" transform="translate(-6.187)">
                <path id="Tracé_12" data-name="Tracé 12" d="M14.361,3.321h1.826V.141A23.573,23.573,0,0,0,13.527,0C10.895,0,9.092,1.656,9.092,4.7V7.5h-2.9v3.555h2.9V20h3.561V11.056H15.44L15.883,7.5H12.652V5.052C12.653,4.024,12.93,3.321,14.361,3.321Z" transform="translate(0)" fill="#fff"/>
            </g>
            </svg>
            <p className='text-orange-400'>mon bon sejour</p>
            </div>
            </a>
           
            </div>
            
        </div>
        <div className='bg-black w-screen text-white text-sm h-10 flex justify-center items-center font-light'>
            @ Design by <img className='w-12 px-2' src={Africa} alt='logoAfrica'/> Africa Digitalizer
        </div>

    </div>
  )
}

export default Footer
