import React from 'react'
import axios from 'axios';
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { IoIosEyeOff } from "react-icons/io";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { IoAddSharp } from "react-icons/io5";
import {
    Menu,
    Button,
    MenuHandler,
    MenuList,
    MenuItem,
    Typography
} from "@material-tailwind/react";
import { FiEdit } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import { IoIosEye } from "react-icons/io";
import SidebarTop from '../../SidebarTop';
import { IoMdPin } from "react-icons/io";
import {
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify"
import { IoIosLaptop } from 'react-icons/io';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CiCalendarDate } from 'react-icons/ci';
import { GiMoneyStack } from 'react-icons/gi';
import Resto from '../../../../API/tourisme/restaurant';

function VoirRestaurant() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Restaurant/Voir_des_restaurants'));

  const [records, setRecords] = useState([]);

  const [data, setData] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
});

const [formData, setFormData] = useState({});
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        //setMontantTotal(montantTotal);
    };

const dateexpired = new Date();
const datejour=dateexpired.toISOString().split('T')[0];
// console.log(datejour);

const [open, setOpen] = React.useState(false);
var [listUpDateResto, setlistUpDateResto] = React.useState([]);
    const handleClickOpen = (id) => {
        setOpen(true);
        async function listOfReafficherHerb(){
          try{
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/restaurant/list/update/pub`, {id: id})
            setlistUpDateResto(res.data.data)
          }catch (err) {
            console.log(err);
          }
        }
        listOfReafficherHerb()
    };
//console.log(identifiantHeber)
    const handleClose = () => {
        setOpen(false);
    };

const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }
      
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/restaurant/list/${data.userId}`)
        .then(response => {
            //console.log(response.data) 
            setRecords(response.data.data)
        
        })
              
           //.then(response => console.log(response.data.data))
           .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    function truncate (str) {
        return str.length > 300 ? str.substring(0, 300) + "..." : str;
    }

    const changeStatut = (id) => {

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/restaurant/change/statut`, {id: id})
         .then(res => {
          window.location.reload();
          if (res.data.status === 201) {
            toast.success(res.data.message);
            //console.log(res);
        } else {
            toast.error(res.data.message);
            console.log(res);
        }
        })
         .catch(err =>
          {
            toast.error("Erreur Merci de ressayer");
           console.log(err)
          })
    
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let fd = new FormData();
        fd.append("identifiant", formData.identifiant ?? listUpDateResto.id_announce);
        fd.append("tarif", formData.tarif ?? listUpDateResto.price_announce);
        fd.append("date_debut", formData.date_debut ?? listUpDateResto.date_debut);
        fd.append("date_fin", formData.date_fin ?? listUpDateResto.date_fin);
    
        if ((formData.date_debut ?? listUpDateResto.date_debut) && (formData.tarif ?? listUpDateResto.price_announce) && (formData.date_fin ?? listUpDateResto.date_fin)) {
            fd.append("montant", parseFloat(formData.tarif ?? listUpDateResto.price_announce) * 
            Math.ceil((new Date(formData.date_fin ?? listUpDateResto.date_fin) - 
            new Date(formData.date_debut ?? listUpDateResto.date_debut)) / (1000 * 60 * 60 * 24)));

            console.log("montant", parseFloat(formData.tarif ?? listUpDateResto.price_announce) * 
            Math.ceil((new Date(formData.date_fin ?? listUpDateResto.date_fin) - 
            new Date(formData.date_debut ?? listUpDateResto.date_debut)) / (1000 * 60 * 60 * 24)))
        }
        else {
            toast.error("Veuillez remplir tous les champs");
            return;
        }
        console.log("identifiant", formData.identifiant ?? listUpDateResto.id_announce)
        console.log("tarif", formData.prix ?? listUpDateResto.price_announce)
        console.log("date_debut", formData.date_debut ?? listUpDateResto.date_debut)
        console.log("date_fin", formData.date_fin ?? listUpDateResto.date_fin)
        console.log(formData)
        setLoading(true);

        Resto.submitFormUp(fd)
        .then((res) => {
            if (res.data.status === 201) {
                //console.log(res.data.data);
                handleClose()
                toast.success(res.data.message);
                //console.log(res);
            } else {
                toast.error(res.data.message);
                //console.log(res);
            }
        })
            .catch((err) => {
                toast.error('Erreur merci de reessayer !');
                //console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

    }
    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* sidebar top  */}
                        <SidebarTop />
                        <div>
                <ToastContainer />
            </div>
                        {/* navbar top gris*/}
                            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants" className="opacity-60">
                                    Restaurants
                                </Link>
                                <Link to="">
                                    Voir
                                </Link>
                            </Breadcrumbs>
                        </div>

                        {/* boutton ajouter un restaurant*/}
                            <div className='p-6  w-[230px]' >
                                <Link to="/Dashboard/Publications/Restaurant/Ajouter_des_restaurants">
                                    <button className='flex flex-row items-center justify-center h-10  space-x-2 bg-[#FCCA46] text-white md:text-base s:text-sm md:w-[230px] s:w-[190px] transform active:scale-75 transition-transform'>
                                        <IoAddSharp />
                                        <p>Ajouter un restaurant</p>
                                    </button>
                                </Link>
                            </div>
                        {/* container formulaire */}
                        <div className='flex justify-center p-10 '>
                            <div className='grid md:grid-cols-4 xs:grid-cols-3 s:grid-cols-1 gap-6 place-items-center'>
                            {records.map((list, index )=> (
                                <div className='bg-white hover:bg-slate-100 s:w-[240px] sm:w-[280px] flex flex-col  p-4 shadow rounded'>
                                    <img src={`data:image/png;base64,${list[0]}`} alt={list.title_announce} className='w-[250px] h-[200px]' />
                                    <p className='text-[#fe7f2d] font-semibold text-lg pt-3 '>{list.title_announce}</p>
                                    <p className='pt-2 font-thin'>{truncate(list.description_announce)}</p>
                                    <div className='flex flex-row items-center justify-between'>
                                        <div className='flex flex-row items-center space-x-2 text-sm pt-2'>
                                            <IoMdPin />
                                            <p>{list.contact_address}</p>
                                        </div>
                                        {/* Action */}
                                        <div>
                                            <Tooltip content="Action sur la publication">
                                            <IconButton variant="text">
                                                <Menu>
                                                <MenuHandler>
                                                    <IconButton variant='text'>
                                                    <RxDotsVertical className='text-lg' />
                                                    </IconButton>
                                                </MenuHandler>
                                                <MenuList className="flex flex-col gap-2">

                                                    {/* modifier */}
                                                    <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants/Modifier"
                                                        state={list.id_announce}>
                                                        <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                            <FiEdit className='text-lg' />
                                                            <div className="flex flex-col gap-1">
                                                                <Typography variant="small" color="gray" className="font-semibold">
                                                                    Modifier
                                                                </Typography>
                                                            </div>
                                                        </MenuItem>
                                                    </Link>
                                                    {/* Voir  */}
                                                    <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants/Voir_Plus"
                                                        state={ list.id_announce }>
                                                        <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                            <IoIosEye className='text-lg' />
                                                            <div className="flex flex-col gap-1">
                                                                <Typography variant="small" color="gray" className="font-semibold">
                                                                    Voir
                                                                </Typography>
                                                            </div>
                                                        </MenuItem>
                                                    </Link>

                                                    {/* Desactiver */}
                                                    {list.status === "enabled" && (
                                                        <MenuItem onClick={() => changeStatut(list.id_announce)} 
                                                            className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <IoIosEyeOff className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                    Désactiver
                                                                    </Typography>
                                                                </div>
                                                        </MenuItem>
                                                    )}
                                                    {/* Activer */}
                                                    {list.status === "disabled" && (
                                                        <MenuItem onClick={() => changeStatut(list.id_announce)} className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                            <IoIosEye className='text-lg' />
                                                            <div className="flex flex-col gap-1">
                                                                <Typography variant="small" color="gray" className="font-semibold">
                                                                    Activer
                                                                </Typography>
                                                            </div>
                                                        </MenuItem>
                                                    )}

                                                    {/* Reafficher */}
                                                    {datejour > list.date_fin ? (
                                                        <MenuItem onClick={() => handleClickOpen(list.id_announce)} className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                            <IoIosLaptop className='text-lg' />
                                                            <div className="flex flex-col gap-1">
                                                                <Typography variant="small" color="gray" className="font-semibold">
                                                                    Reafficher
                                                                </Typography>
                                                            </div>
                                                        </MenuItem>
                                                    ) : "" }


                                                </MenuList>
                                                </Menu>
                                            </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <p className='text-sm flex justify-end'>{list.status === "enabled" && (
                                    "Actif"
                                    )}
                                    {list.status === "disabled" && (
                                    "Inactif"
                                    )}</p>
                                </div>
                                )
                          )}


                            <Dialog
                                open={open}
                                onClose={handleClose}
                                fullWidth
                                maxWidth="md" // Try 'xs', 'sm', 'md', 'lg', 'xl'
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description">

                                <DialogTitle id="scroll-dialog-title" className='text-xl font-semibold text-orange-500' >Modifier</DialogTitle>
                                <DialogContent>                             
                                <form onSubmit={handleSubmit} className='flex flex-col items-center w-full max-w-2xl mx-auto space-y-6'>
                                    <div className='flex flex-col w-full space-y-4 bg-white border-b-8 rounded-md shadow s:p-6 xs:p-10'>
                                        {/* Tarif */}
                                        <div className='flex flex-col space-y-3'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                                                <label>Tarif minimun</label>
                                            </div>
                                            <input id="tarif" defaultValue={listUpDateResto?.price_announce} onChange={handleInputChange} type='number' name='tarif' required min={1} className='w-full h-10 rounded' />
                                            <input hidden id="identifiant" defaultValue={listUpDateResto?.id_announce} onChange={handleInputChange} type='number' name='identifiant' className='w-full h-10 rounded' />
                                        </div>
                                    </div>

                                    <div className='flex flex-col w-full space-y-4 bg-white border-b-8 rounded-md shadow s:p-6 xs:p-10'>
                                        {/* date debut */}
                                        <div className='flex flex-col space-y-3'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <CiCalendarDate className='text-[#fe7f2d]' />
                                                <label>Date de debut affichage</label>
                                            </div>
                                            <input id="date_debut" defaultValue={listUpDateResto?.date_debut} name='date_debut' onChange={handleInputChange} type='date' className='w-full h-10 rounded' required />
                                        </div>

                                        {/* date fin */}
                                        <div className='flex flex-col space-y-3'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <CiCalendarDate className='text-[#fe7f2d]' />
                                                <label>Date de fin affichage</label>
                                            </div>
                                            <input id="date_fin" defaultValue={listUpDateResto?.date_fin} name='date_fin' onChange={handleInputChange} type='date' className='w-full h-10 rounded' required />
                                        </div>

                                        {(formData.date_debut ?? listUpDateResto.date_debut) && (formData.prix ?? listUpDateResto.price_announce) && (formData.date_fin ?? listUpDateResto.date_fin) ? (
                                            <div className='flex flex-col space-y-3'>
                                                <div className='flex flex-row items-center space-x-2'>
                                                    <GiMoneyStack className='text-xl text-[#1f2937]' />
                                                    <label className='text-[#1f2937] font-bold'>
                                                        Montant total: {(parseFloat(formData.tarif ?? listUpDateResto.price_announce) * Math.ceil((new Date(formData.date_fin ?? listUpDateResto.date_fin) - new Date(formData.date_debut ?? listUpDateResto.date_debut)) / (1000 * 60 * 60 * 24)))} F CFA
                                                    </label>
                                                    <input hidden id="montant" onChange={handleInputChange} value={(parseFloat(formData.tarif) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut)) / (1000 * 60 * 60 * 24)))} name='montant' type='number' />
                                                </div>
                                            </div>
                                        ) : ""}
                                    </div>

                                    {(formData.date_debut ?? listUpDateResto.date_debut) && (formData.tarif ?? listUpDateResto.price_announce) && (formData.date_fin ?? listUpDateResto.date_fin) ? (
                                        <button className='bg-[#FCCA46] w-[250px] h-10 rounded text-white'>
                                            Procéder au paiement
                                        </button>
                                    ) : (
                                        <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>
                                            Reafficher
                                        </button>
                                    )}

                                            {/* {showPopup && (
                                            <PaymentPopup
                                                open={showPopup}
                                                montant={parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut)) / (1000 * 60 * 60 * 24))}
                                                onSuccess={handlePopupSuccess}
                                                userNom={data.userLastname}
                                                userPrenom={data.userName}
                                                email={data.userMail}
                                                formData={formData}
                                                urlpay={urlbackpay}
                                                onCancel={handlePopupCancel}
                                            />
                                            )} */}
                                </form>
                                </DialogContent>
                                <DialogActions>
                                    <Button className='transition-transform transform active:scale-75' style={{backgroundColor:"#233D4D", color:"white"}} onClick={handleClose}>Retour</Button>
                                    {/* <Button className='transition-transform transform active:scale-75' style={{border:"2px solid #233D4D", color:"#233D4D"}} onClick={handleClose}>Accepter</Button> */}
                                </DialogActions>
                            </Dialog>

                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default VoirRestaurant