import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENT/Header/Header';
import backimage from '../../../ASSETS/AfImmigration/imgConseil.png'
import {Link} from 'react-router-dom'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import BoutonLogin from '../../../TOURISME/COMPONENTS/BoutonLogin/BtnLogin'
import Footer from '../../COMPONNENT/Footer/Footer';
import { IoIosArrowDropright } from "react-icons/io";
import BarniereService from '../../COMPONNENT/BarniereService/BarniereService';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Conseil() {

    const [loading, setLoading] = useState(true);
    const navrdv=useNavigate()
    const BtnPrendreRdv=(Items_Numbers : any)=>{
        //console.log(Items_Numbers)
        if(Items_Numbers){

            navrdv('/appointement/RendezVousService/'+Items_Numbers)
        }
    }
    interface Items{      
        Items_Numbers: number;	
        Categories: number;	
        Names: string;	
        fr_Name: string;	
        Types: string;	
        //Prices: double;	
        //nb_place: string;	
        Available: string;	
        Status: string;	
        Overview: string;	
        fr_overview: string;	
        Descriptions: string;	
        fr_description: string;	
        Pictures: string;	
        subscription_frequency: string;	
        //initiale_prices: string;	
        related_link: string;	
        item_doc: string;	
        contract: string;	
      }

      interface Items_Ingredient{      
        ingredient_name: string;	
        ingredient_fr_name: string;	
        ingredient_description: string;	
        
      }
    const [getItemIngredients, setGetItemIngredients] = useState<Items_Ingredient[]>([]);
    const [Item, setItem] = useState<Items[]>([]);
    
    // États de chargement individuels
    const [loadingIngredientItem, setLoadingIngredientItem] = useState(true);
    const [loadingItem, setLoadingItem] = useState(true);
    //const [loadingGroupCat, setLoadingGroupCat] = useState(true);
    
    
    //récupération de l'identifiant de l'url
    const id=useParams()
    //const idi=useParams()
    useEffect(() =>{
        //console.log(id)
        async function getItemIngredient(){
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/items/ingredients/${id.item_id}`)
                setGetItemIngredients(res.data.data);
                //console.log(res.data.data)
            }catch (err) {
                console.log(err);
            }
            // finally {
            //     setLoadingIngredientItem(false);
            // }
           
        }
        getItemIngredient()
        
        async function getItemI(){
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/items/${id.item_id}`)
                setItem(res.data.data);
                //console.log(res.data.data)
            }catch (err) {
                console.log(err);
            }
            // finally {
            //     setLoadingItem(false);
            // }
        }
        getItemI()

        const timer = setTimeout(()=> {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
        
    }, []);

    // useEffect(() => {
    //     if (!loadingIngredientItem && !loadingItem) {
    //         setLoading(false);
    //     }
    // }, [loadingIngredientItem, loadingItem]);

    
    return (
        <div className='bg-[#F7F7F7]'>
              {loading ? (
                  // Afficher le spinner tant que loading est true
                  <Loading/>
              ) : (
            <>
            <Header/>
            <div style={{ backgroundImage:`url(data:image/png;base64,${Item[0]?.Pictures})` }} 
            className='w-screen h-screen bg-center bg-no-repeat bg-cover'>
                <div className='h-screen bg-[#000000a1]'>
                        <div className='flex s:flex-col sm:flex-row sm:justify-between'>
                            <Link to='/accueil_afrique_immigration'>
                                <div style={{ borderBottomRightRadius: "100px" }} className='w-40 pb-4 pr-4 bg-white sm:pl-5 s:pl-2'>
                                    <img src={logo1} className='h-20 pt-6 s:pl-0 ' alt='logo' />
                                </div>
                            </Link>
                            <BoutonLogin/>
                        </div>
                    <div className='relative top-[80px] xs:left-20 s:left-6'>
                        <div className='font-semibold text-white uppercase xs:text-6xl s:text-xl sm:text-4xl'>
                            {Item[0]?.fr_Name ? Item[0]?.fr_Name.replace(/^<p>|<\/p>$/g, '') : "Service non trouvé"}
                        </div>
                        <div className='h-2 w-20 bg-[#FCCA46] mt-4'></div>
                    </div>
                    <div className='relative top-[90px]'>
                            <div className=' absolute top-[60px] left-0 xs:text-xl 
                            text-justify s:text-base sm:text-lg text-white' 
                            style={{paddingLeft: "80px", marginRight: "40px"}}>
                                {Item[0]?.fr_overview ? Item[0]?.fr_overview.replace(/^<p>|<\/p>$/g, '') : ""}
                            </div>
                    </div>
                </div>
    
            </div>
    
            <div className='bg-white py-14 xs:px-10 s:px-3'>
    
                <div className='relative ' >
                    <div className=' text-3xl text-[#4E4E4E] font-semibold'>Options de services</div>
                    <div className='h-[4px] w-9 bg-[#fe7f2d] mt-2'></div>
                </div>
    
               <div className='grid pt-10 s:grid-cols-1 gap-y-10 xs:grid-cols-2'>
    
                    {getItemIngredients ?
                        (getItemIngredients.map((Ingredient, index) => (
                            <div className='flex flex-row space-x-5' key={index}>
                            <div className='w-8 h-8 bg-[#233D4D] rounded-full text-center text-xl text-white'>
                                {index + 1}
                            </div>
                            <div className='flex flex-col space-y-4'>
                                <div className='text-xl font-medium text-[#fe7f2d] md:w-[480px] s:w-[220px] sm:w-[300px]'>
                                {Ingredient.ingredient_fr_name ? Ingredient.ingredient_fr_name.replace(/^<p>|<\/p>$/g, '') : "Aucun nom d'option trouvé"}
                                </div>
                                <div className='md:w-[480px] s:w-[220px] sm:w-[300px]'>
                                {Ingredient.ingredient_description ? Ingredient.ingredient_description.replace(/^<p>|<\/p>$/g, '') : "Aucune description"}
                                </div>

                                {/* <Link to='/PrendreRendezVous'>
                                <div className='flex flex-row items-center space-x-2 sm:text-lg text-[#233D4D] font-semibold'>
                                    <button>Prendre Rendez-vous</button>
                                    <IoIosArrowDropright />
                                </div>
                                </Link> */}
                                <div className='flex flex-row items-center space-x-2 sm:text-lg text-[#233D4D] font-semibold'>
                                    <button onClick={()=>BtnPrendreRdv(Item[0].Items_Numbers)}>Prendre Rendez-vous</button>
                                    <IoIosArrowDropright />
                                </div>
                                
                            </div>
                            </div>
                        ))) 
                        : "Options de service indiponible"
                    }
    
                    {/* <div className='flex flex-row space-x-5'>
                        <div className='w-8 h-8 bg-[#233D4D] rounded-full text-center text-xl text-white'>2</div>
                        <div className='flex flex-col space-y-4'>
                            <div className='text-xl font-medium text-[#fe7f2d] md:w-[480px] s:w-[220px] sm:w-[300px]'>Recommandation de business pour votre entreprise</div>
                            <div className='md:w-[480px] s:w-[220px] sm:w-[300px]'>
                                We understand the importance of approaching each work integrally and believe in the 
                                power of simple.Ultrices eros in cursus turpis. Tortor dignissim convallis aenean et. 
                                Ut diam quam nulla porttitor massa id. Bibendum arcu vitae elementum curabitur. 
                            </div>
    
                            <Link to='/PrendreRendezVous'>
                                <div className='flex flex-row items-center space-x-2 sm:text-lg text-[#233D4D] font-semibold'>
                                    <button>Renseigner le formulaire</button>
                                    <IoIosArrowDropright />
                                </div>
                            </Link>
                        </div>
                        
                    </div>
    
                    <div className='flex flex-row space-x-5'>
                        <div className='w-8 h-8 bg-[#233D4D] rounded-full text-center text-xl text-white'>3</div>
                        <div className='flex flex-col space-y-4'>
                            <div className='text-xl font-medium text-[#fe7f2d] md:w-[480px] s:w-[220px] sm:w-[300px]'>Recommandation de partenaire d'affaire</div>
                            <div className='md:w-[480px] s:w-[220px] sm:w-[300px]'>
                                We understand the importance of approaching each work integrally and believe in the 
                                power of simple.Ultrices eros in cursus turpis. Tortor dignissim convallis aenean et. 
                                Ut diam quam nulla porttitor massa id. Bibendum arcu vitae elementum curabitur. 
                            </div>
    
                            <Link to='/PrendreRendezVous'>
                                <div className='flex flex-row items-center space-x-2 sm:text-lg text-[#233D4D] font-semibold'>
                                    <button>Renseigner le formulaire</button>
                                    <IoIosArrowDropright />
                                </div>
                            </Link>
                        </div>
                        
                    </div> */}
    
                    
               </div>
            </div>
    
            <BarniereService/>
            <Footer/>
            </>
              )}
        </div>
      )
}
export default Conseil