import React from 'react'
import echec from '../../../ASSETS/tourisme/icone/cancel.png'
function Echec() {
  return (
    <div className='bg-[#f7f7f7] h-screen flex justify-center items-center'>
      <div className='bg-white w-[350px] h-[350px]  flex flex-col items-center justify-center space-y-4'>
        <img src={echec} alt='echec'  className='w-[100px] '/>
        <p className='text-xl text-[#4e4e4e] font-semibold '>Echec!</p>
        <p className='w-[260px] text-center'>Excepteur sint occaecat cupidatat non proident, sunt in culpa</p>
        <button className='border-[#E24C4B] border-2 rounded-2xl text-[#E24C4B] h-8 w-[140px]'>Retour</button>
      </div>
    </div>
  )
}

export default Echec