import React from 'react'
import { useState } from 'react'
import { FormEvent } from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../COMPONNENTS/Header/Header';
import { useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import login from '../../../ASSETS/CaImmigration/images/img_login.png'
import { ToastContainer, toast } from "react-toastify"
import Conne from "../../../API/connexion/Connexion"
import left from "../../../ASSETS/CaImmigration/icones/back.png"
import Africa from '../../../ASSETS/tourisme/Image/A.D 2.png'
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

function Connexion() {

  //localStorage.setItem('url', JSON.stringify('/'));

  const navigate = useNavigate();

  const [data, setData] = useState(() => {
    // getting stored value
    //console.log('data');
    const saved = localStorage.getItem("data");
    //console.log(saved);
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
  });

  const [url, setUrl] = useState(() => {
    // getting stored value
    const link = localStorage.getItem("url");
    const value = link !== null ? JSON.parse(link) : null;
    return value;
  });

    const [mail, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [disable, setDisable] = useState(false)
    const [visible, setVisible] = useState(false)

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        //console.log(user);
        if (data) {
            navigate("/");
           }
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);
    function submit(e: FormEvent) {
      e.preventDefault()
      //if(mail !== "" && password !== ""){
        //setDisable(true);
      var templateParams = {
          mail: mail,
          password: password,
      };

      setLoading(true)
      Conne.submitForm(templateParams)
          .then((res: any) => {
            if(res.data.status === 201){
              localStorage.setItem('data', JSON.stringify(res.data.data));
              toast.success(res.data.message);
              //console.log(res.data.data);
              url !== null ? navigate(url)  : navigate('/');
            }else{
              toast.error(res.data.message);
              console.log(res);
            }
          })
          .catch((err: any) => {
              toast.error("Erreur merci de ressayer");
              console.log(err);
          })
          .finally(() => {
            setLoading(false);
          })
        // } else{
        //   setDisable(false);
        // }
  }
   

  return (
    <div>
          {
        loading ? (
          // Afficher le spinner tant que loading est true
          <Loading />
        ) : (
          <>
          <Header/>
      
      <div>
        <ToastContainer />
      </div>
      <Link to='/'>
          <div className='relative flex items-center ml-6 space-x-2 top-8'>
            <img src={left} className='w-4' alt='arrow_left'/>
            <p className='text-lg text-[#233D4D] hover:text-[#fe7f2d]'>Retour a l'accueil</p>
          </div>
      </Link>
        <div className='flex items-center justify-center my-20 xs:flex-row s:flex-col '>
          
            <div className='bg-white shadow-3xl xs:w-[500px] s:w-[310px] h-[400px]'>
                {/* logo */}
                <Link to='/'>
                  <div style={{ borderBottomRightRadius: "100px" }} className='w-40 pb-4 pr-4 bg-white sm:pl-5 s:pl-2'>
                      <img src={logo1} className='h-16 pt-6 s:pl-0 ' alt='logo' />
                  </div>
                </Link>
                <p className='text-2xl font-bold text-[#FCCA46] text-center relative s:bottom-4 xs:bottom-10'>Connectez-vous</p>

                <p className='font-thin text-center'>Déjà un compte ? Connectez-vous</p>
                <form action="" onSubmit={submit}>
                <div className='flex flex-col items-center pt-6 space-y-4'>

                    {/* Mail */}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-12 flex pl-3 space-x-2 items-center'>
                            {/* icone mail */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.25" height="15.188" viewBox="0 0 20.25 15.188">
                            <g id="mail" transform="translate(0 -64)">
                                <path id="Tracé_218" data-name="Tracé 218" d="M18.563,64H1.688A1.689,1.689,0,0,0,0,65.688V77.5a1.689,1.689,0,0,0,1.688,1.688H18.563A1.689,1.689,0,0,0,20.25,77.5V65.688A1.689,1.689,0,0,0,18.563,64Zm-16.875.844H18.563a.8.8,0,0,1,.175.035c-1.461,1.337-6.305,5.769-8,7.3a.846.846,0,0,1-1.225,0c-1.7-1.527-6.54-5.959-8-7.3A.806.806,0,0,1,1.688,64.844ZM.844,77.5V65.688a.815.815,0,0,1,.047-.233c1.118,1.023,4.5,4.116,6.69,6.109C5.4,73.44,2.015,76.645.888,77.72a.813.813,0,0,1-.045-.22Zm17.719.844H1.688a.807.807,0,0,1-.19-.039c1.165-1.11,4.567-4.334,6.714-6.17l.737.667a1.685,1.685,0,0,0,2.355,0l.737-.667c2.146,1.836,5.549,5.06,6.714,6.17A.806.806,0,0,1,18.563,78.344Zm.844-.844a.815.815,0,0,1-.045.22c-1.128-1.075-4.508-4.281-6.693-6.157,2.192-1.993,5.572-5.085,6.69-6.109a.815.815,0,0,1,.047.233Z" fill="#4e4e4e"/>
                            </g>
                            </svg>

                            <input name='mail' required onChange={(e) => setEmail(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' value={mail} type='text' placeholder='Adresse mail'/>
                        </div>

                        {/*password */}
                        <div className='bg-[#F3F3F3] s:w-[280px] xs:w-[360px] h-12 flex pl-3 space-x-2 items-center'>
                            {/* icone password */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.478" height="19.597" viewBox="0 0 15.478 19.597">
                            <g id="Groupe_36" data-name="Groupe 36" transform="translate(-985 -805.403)">
                                <path id="Tracé_219" data-name="Tracé 219" d="M696.116,868.536a2.255,2.255,0,0,0-1.154,4.193v1.838h2.307v-1.838a2.255,2.255,0,0,0-1.154-4.193Zm.663,3.674-.2.093v1.574h-.93V872.3l-.2-.093a1.566,1.566,0,1,1,1.327,0Z" transform="translate(296.623 -53.679)" fill="#4e4e4e"/>
                                <path id="Tracé_220" data-name="Tracé 220" d="M204.753,11.052V8.706a4.45,4.45,0,0,0-8.9,0V9.938h.689V8.713a3.762,3.762,0,0,1,7.523,0v2.342h-11.5v5.187a7.74,7.74,0,0,0,15.478,0V11.052Zm2.6,5.18a7.051,7.051,0,0,1-14.1,0V11.74h14.1v4.491Z" transform="translate(792.436 801.102)" fill="#4e4e4e"/>
                            </g>
                            </svg>
                            <input name='password' required onChange={(e) => setPassword(e.currentTarget.value)} className='border-none bg-transparent w-[360px] focus:outline-none' value={password} type={visible ? 'texte' : 'password'} placeholder='Mot de passe'/>
                        <div className='p-2' onClick={() => setVisible(!visible)}>
                          {visible ? <EyeOutlined /> : <EyeInvisibleOutlined /> }
                        </div>
                        </div>
                </div>
                <Link to='/MotDePasseOublie' className='hover:text-[#FCCA46]'>
                  <p className='pt-6 pr-20 font-thin text-end'>Mot de passe oublié ?</p>
                </Link>

                <button className='border-[#FCCA46] border-solid border-2 text-[#FCCA46] ml-16 mt-4 px-10 h-10 text-lg rounded transform active:scale-75 transition-transform'>Connexion</button>
                </form>
            </div>


            {/* image */}
            <div className='bg-white shadow-3xl ' style={{backgroundImage:`url(${login})`, height:400, width:310}}>
              <p className='pt-24 text-3xl font-bold text-center text-white'>Hello !</p>
              <div className='flex justify-center pt-10'>
                <p className='w-[250px] text-center text-white'>Vous n'avez pas encore créer
                un compte ? inscrivrez vous en entrant
                vos informations
                </p>
              </div>
              <Link to='/Inscription'>
                <div className='flex justify-center pt-12'>
                    <button className='bg-[#FCCA46] text-white px-10 h-10 text-lg rounded transform active:scale-75 transition-transform'>S'inscrire</button>
                </div>
              </Link>
            </div>
        </div>

        <div className='flex items-center justify-center w-screen h-10 text-sm font-light text-white bg-black'>
            @ Design by <img className='w-12 px-2' src={Africa} alt='logoAfrica'/> Africa Digitalizer
        </div>


         </>
        )}
    </div>
  )
}

export default Connexion