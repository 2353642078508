import axios from 'axios';

let Hebergement : any = {};
Hebergement.submitForm = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/hebergement/edit`, data)
}

Hebergement.getOne = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/show/${id}`)
}

Hebergement.getImages = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/images/${id}`)
}

export default Hebergement;