import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENT/Header/Header';
import backimage from '../../../ASSETS/AfImmigration/imgDestination.png'
import {Link} from 'react-router-dom'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import BoutonLogin from '../../../TOURISME/COMPONENTS/BoutonLogin/BtnLogin'
import Footer from '../../COMPONNENT/Footer/Footer';
import CI from '../../../ASSETS/AfImmigration/participation-sitho2.png'
import drapeauCI from '../../../ASSETS/AfImmigration/drapeau-cote-ivoire.png'
import { IoPin } from "react-icons/io5";
import { IoIosSend } from "react-icons/io";


function VoirPlus() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div className='bg-[#F7F7F7]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading/>
          ) : (
        <>
        <Header/>
        <div style={{ backgroundImage:`url(${backimage})` }} className='h-screen w-screen bg-cover bg-center bg-no-repeat'>
            <div className='flex s:flex-col sm:flex-row sm:justify-between'>
                <Link to='/accueil_afrique_immigration'>
                    <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                        <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                    </div>
                </Link>
                <BoutonLogin/>
            </div>
          <div className='relative top-[200px] xs:left-20 s:left-6'>
            <div className=' xs:text-6xl uppercase  s:text-xl sm:text-4xl font-semibold text-white xs:w-[600px]'>
             Destination
            </div>
            <div className='h-2 w-20 bg-[#FCCA46] mt-4'></div>
          </div>

        </div>

        <div className='py-14 flex flex-col space-y-4 items-center'>
            <div className='flex flex-row items-center space-x-2 '>
                <IoPin className='text-2xl text-[#fe7f2d]' />
                <div className='text-2xl font-semibold text-[#233D4D]'>Votre destination</div>
            </div>
            <div className='text-lg xs:w-[700px] text-center px-2'>
                Vivamus pretium turpis eget elit condimentum tincidunt. 
                Cras aliquam tincidunt est, in varius lorem laoreet in. 
                Sed quis ex rutrum, vehicula enim vitae, auctor urna. 
            </div>
            <div className='pt-10 place-content-center place-items-center grid md:grid-cols-4 gap-4 s:grid-cols-1 xs:grid-cols-2'>
               
            </div>
        </div>

        <div className='pb-14 grid s:grid-cols-1 md:grid-cols-2 s:px-4 xs:px-14 s:gap-10 xs:gap-20'>
            <img src={CI} alt='Ci' className='w-[600px]'/>
            <div className='flex flex-col space-y-3'>
                <div className='flex items-center space-x-3'>
                    <img src={drapeauCI} alt='drapeau' className='w-10'/>
                    <div className='font-semibold text-xl'>La Côte d'ivoire </div>
                </div>
                <div className='flex items-center space-x-3'>
                    <div className='font-semibold text-xl'>Capitale : </div>
                    <div className='text-xl font-light'> Yamoussokro</div>
                </div>
                <div className='flex items-center space-x-3'>
                    <div className='font-semibold text-xl'>Superficie : </div>
                    <div className='text-xl font-light'> 432 000 m<sup>2</sup></div>
                </div>
                <div className='flex items-center space-x-3'>
                    <div className='font-semibold text-xl'>Président : </div>
                    <div className='text-xl font-light'> Outarra Alassane</div>
                </div>
                <Link to='/PrendreRendezVous '>
                <button className='s:mt-8 sm:mt-12 bg-[#FCCA46] text-base flex flex-row items-center space-x-2 text-white h-10 w-[200px]  px-2 transform active:scale-75 transition-transform'>
                    <p>Prendre rendez-vous</p>
                    <IoIosSend />
                </button>
            </Link>
            </div>
        </div>
        <div className='pb-6 text-lg s:px-2 xs:px-14'>
            Ut ultrices iaculis libero, ac varius lacus euismod sed. Orci varius 
            natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
            Aenean vulputate justo ac lacus lacinia dignissim. Pellentesque quis placerat 
            mauris, ut blandit tortor. Cras aliquam est quis enim ultrices dapibus. 
            Sed fringilla suscipit varius. Nam volutpat tortor suscipit lectus sodales, 
            eu blandit velit semper. Sed tristique nisl et volutpat maximus. Integer suscipit, 
            mauris eu varius ultrices, lacus metus dignissim sapien, non facilisis dui risus at 
            sem. Vivamus auctor quam consectetur urna vulputate tristique. Cras fermentum, nisi 
            sit amet ultricies ultrices, enim mi interdum dolor, id vestibulum nisl nibh at lacus. 
            Maecenas at massa condimentum, suscipit tellus lobortis, sollicitudin ante. Nunc nec 
            vulputate nulla. Nam convallis felis a nibh rhoncus egestas. Nulla elit ligula, vestibulum 
            non ultricies vel, efficitur quis enim.<br/>
            Morbi a odio ultrices sem vulputate suscipit. Pellentesque ut leo ex. Donec ut enim pellentesque, auctor nunc semper, tempus enim. Suspendisse eu nunc id leo porttitor molestie vel ut tellus. Suspendisse vitae urna tellus. Aenean justo dolor, egestas et massa ac, dapibus dapibus ligula. Phasellus nec metus eleifend, auctor nunc non, ullamcorper tellus. Sed vitae accumsan ligula, eu consectetur erat. Donec mollis tempor sollicitudin. Cras lobortis id elit at blandit. Quisque consectetur scelerisque imperdiet. Etiam fringilla fringilla nisl, tristique pellentesque augue gravida eget. Etiam blandit consectetur aliquet. Praesent venenatis dictum metus, ut sodales est posuere ac. Phasellus id faucibus neque, sed ullamcorper purus. Praesent non ex augue.

            Duis sed sagittis tortor. Suspendisse sed imperdiet justo, eu porta orci. Integer quis felis ac nulla interdum luctus. Cras neque dui, semper pretium nibh eget, imperdiet egestas turpis. In hac habitasse platea dictumst. Donec auctor justo ac venenatis pretium. Sed augue nulla, consectetur quis nisl placerat, congue laoreet lacus. Vivamus interdum pharetra mollis.

            Nulla vulputate sem magna, id mollis elit sodales quis. Sed porta vulputate ipsum in tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec rhoncus nulla eu felis interdum, eget elementum elit mattis. Nulla luctus auctor neque ut luctus. Pellentesque viverra sollicitudin volutpat. Aenean cursus sodales sapien, a rhoncus lacus auctor quis. Integer nulla tortor, laoreet et lectus vitae, posuere rhoncus sem. Vivamus pulvinar ex id tincidunt efficitur. Etiam condimentum quis neque sit amet sollicitudin. Nullam placerat felis magna, et pulvinar lorem euismod a. Sed libero orci, iaculis eget enim non, tristique ultrices libero.


            
        </div>
        
       
        <Footer/>
        </>
          )}
    </div>
  )
}

export default VoirPlus