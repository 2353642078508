import * as React from 'react';
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Footer from '../../COMPONNENTS/Footer/Footer'
import Header from '../../COMPONNENTS/Header/Header'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visaN from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import { Link } from 'react-router-dom'
import Achat from '../../../ASSETS/CaImmigration/images/Bien_immobilier_barniere.png'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'
import AchatImmobilier from '../../../API/immigration/achatImmobilier/AchatImmobilier';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify"
import { useParams } from 'react-router-dom';
import axios from 'axios';
function AcheterUnBien() {
    // Dialog
    interface Items{      
        Items_Numbers: number;	
        Categories: number;	
        Names: string;	
        fr_Name: string;	
        Types: string;	
        Prices: number;	
        nb_place: number;	
        Available: string;	
        Status: string;	
        Overview: string;	
        fr_overview: string;	
        Descriptions: string;	
        fr_description: string;	
        Pictures: string;	
        subscription_frequency: string;	
        initiale_prices: number;	
        related_link: string;	
        item_doc: string;	
        contract: string;	
    }

    interface Currency_rates{
        id_currency: number;    
        name: string;    
        sign: string;    
        value_to_usd: number;    
        value_to_xof: number;
        note: string;
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    //localStorage.setItem('url', JSON.stringify('BienImmobilier/VoirBienImmobilier/AcheterUnBien'));
    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        //console.log(saved)
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        //console.log(initialValue)
        return initialValue;
    });

    const [itemImmob, setItemImmob]=useState<Items[]>([])

    const [devises, setDevises]=useState<Currency_rates[]>([])


    //console.log(data);
    //récupération du nom utilisateur
    const lnom = data !== null ? data.userLastname : "";
    //récupération du prénom utilisateur
    const prnom = data !== null ? data.userName : "";
    const [nom, setNom] = useState(lnom || "")
    const [pnom, setPrenom] = useState(prnom || "")
    const [paysresidence, setPaysResidence] = useState("")
    const [paysnationalite, setPaysNationalite] = useState("")
    const [profession, setProfession] = useState("")
    const [tel, setTel] = useState(data !== null ? data.userTel : "" || "")
    const [salaire, setSalaire] = useState("")
    const [usermail, setUserEmail] = useState(data !== null ? data.userMail : "" || "")
    const navigate = useNavigate();
    //bouton radio
    const [raisonAchat, setRaisonAchat] = useState('vivre');

    const [deviseAchat, setDeviseAchat] = useState('');

    //récupérer l'id du bien
    const id=useParams();
    //console.log(id);
    const handleRaisonAchatChange = (e : any) =>{
        setRaisonAchat(e.target.value);
    };

    const handleDeviseAchat = (e : any) =>{
        setDeviseAchat(e.target.value);
    };
    //console.log(deviseAchat)
    
    
    
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        //récupération du bien
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/bien/info/${id.item}`)
            .then((response) => {
                //console.log(response.data.data);
                setItemImmob(response.data.data);
            })
        .catch(err => console.log(err))
        
        //récupération des devises
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/devise`)
            .then((response) => {
                //console.log(response);
                setDevises(response.data.data);
            })
        .catch(err => console.log(err))


        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    function submit() {
        setLoading(true);
        var templateParams = {
            nom: nom,
            pnom: pnom,
            paysresidence: paysresidence,
            paysnationalite: paysnationalite,
            profession: profession,
            usermail: usermail,
            tel: tel,
            salaire: salaire,
            user: data.userId,
            item_id: itemImmob[0]?.Items_Numbers,
            raisonAchat: raisonAchat,
            deviseAchat: deviseAchat,
        };
        //console.log(templateParams);
    
        AchatImmobilier.submit(templateParams)
        .then((res: any) => {
            if (res.data.status === 201) {
                toast.success(res.data.message);
                navigate("/homeCaimmigration");
                console.log(res);
            } else {
                toast.error(res.data.message);
                console.log(res);
            }
        })
        .catch((err: any) => {
            toast.error('Erreur merci de reessayer !');
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }   
    
    const handleConfirm = () => {
        setOpen(false); // Fermer la boîte de dialogue après confirmation
        submit(); // Appeler la fonction submit pour enregistrer les données
        //console.log("Enregistrement effectué !");
    };

  return (
       <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          {/* back image */}
          <div className='relative z-0 flex-col w-screen h-screen bg-center bg-no-repeat bg-cover back_image ' style={{ backgroundImage: `url(${Achat})` }}>

                 {/* navbar */}
               <div className='flex justify-between w-screen h-24 bg-transparent navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0 md:flex-row sm:flex-col s:flex-col md:items-center md:text-lg '>
                        <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center space-x-[600px]    justify-between'>

                            <Link to='/'>
                            <div style={{ borderBottomRightRadius: "100px" }} className='w-40 pb-4 pr-4 bg-white sm:pl-5 s:pl-2'>
                                <img src={logo1} className='h-20 pt-6 s:pl-0 ' alt='logo' />
                            </div>
                            </Link>
                            <div onClick={() => setOpen(!open)} className='relative pr-4 cursor-pointer lg:hidden text-end s:text-3xl s:text-white'>
                            <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
                            </div>
                        </div>
                        {/* element_menu */}
                        <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

                            <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>
                            
                                {/* Accueil */}
                                <Link to='/homeCaimmigration'>
                                <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 font-semibold text-white lg:pb-1 '>
                                    
                                    <img className='w-6' src={home} alt='Log' />
                                    <div> Accueil</div>
                                </div>
                                </Link>

                            {/* Visa */}
                                <div style={{ fontSize: "16px",  }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
                                <div className='relative bottom-1'>
                                    <img className='w-4' src={visaN} alt='' />
                                </div>
                                <div className='navbar1 '>
                                    <ul>
                                    <li> <Link to='/visa'> <p className='flex items-center space-x-2'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p>
                                    <ul className='z-10 '>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                                    </ul>
                                    </Link>
                                    </li>
                                    </ul>
                                </div>
                                </div>

                            {/* Résidence permanente */}
                            <Link to='/ResidencePermante'>
                            <div style={{ fontSize: "16px" }} className='flex items-center space-x-2 font-semibold cursor-pointer s:text-white hover:text-yellow-400'>
                                <div>
                                <img className='w-6' src={Rpermanent} alt='' />
                                </div>
                                <div>Résidence permanente</div>
                            </div>
                            </Link>

                            {/*Citoyenneté Canadienne */}
                            <Link to='/CitoyenneteCa'>
                            <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 font-semibold cursor-pointer s:text-white hover:text-yellow-400'>
                                <div>
                                <img className='w-6' src={CitoyenCA} alt='' />
                                </div>
                                <div> Citoyenneté Canadienne</div>
                            </div>
                            </Link>

                            {/* Achat de biens immobilers */}
                            <Link to='/BienImmobilier'>
                                <div style={{ fontSize: "16px" ,color:"#FCCA46"}} className='flex items-center space-x-2 font-semibold cursor-pointer s:text-white hover:text-yellow-400'>
                                <div>
                                    <img className='w-6' src={immobilierN} alt='' />
                                </div>
                                <div>Achat de biens immobilers</div>
                                </div>
                            </Link>
                            </div>
                        </div>
                </div>

              <div className='flex justify-end pr-10 mt-20 text_home md:pl-20 s:pl-5 s:pt-20 md:pt-0'>
                  <div className='flex pt-20 text-center  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg  font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>ACHAT DE BIEN IMMOBIIER</p>
                  </div>
                
              </div>

          </div>

          {/* option */}
            <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col items-center justify-center pb-12 mt-10 space-y-6'>
              <div style={{ color: "##4e4e4e" }} className='font-bold text-center xs:text-2xl s:text-lg'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/* formulaire */}
          <div className='flex flex-col items-center justify-center py-20 space-y-10 bg-white '>
            <p style={{color:"#FE7F2D"}} className='text-2xl font-semibold'>Acheter un bien immobilier</p>
            <p className='xs:w-[600px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez remplir le formulaire pour procéder a l'achat du bien </p>

            <form onSubmit={submit} style={{backgroundColor:"#f3f3f3"}} className=' md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-6 justify-center xs:px-28 s:px-5'>
                <input type='hidden' value={data.userId} required/>
                <input type='hidden' value={itemImmob[0]?.Items_Numbers ? itemImmob[0]?.Items_Numbers: ""} required/>
                {/* <input type='hidden' value={data.userId} required/> */}
               {/* raison d'achat */}
               <label className='text-lg font-semibold'>Je veux acheter le bien pour  <span className='text-red-600'>*</span> :</label>
                <div className='flex flex-row items-center space-x-6 '>
                    <div >
                        <input type='radio' id='vivre' value="vivre" name='raisonAchat' checked={raisonAchat === 'vivre'} onChange={handleRaisonAchatChange} />
                        <label className='pl-3 text-lg'> Y vivre moi même</label>
                    </div>
                    <div>
                        <input type='radio' id='location' value="location" name='raisonAchat' checked={raisonAchat === 'location'}
                            onChange={handleRaisonAchatChange} />
                        <label className='pl-3 text-lg'>Mettre en location</label>
                    </div>
                </div>

                {/* nom */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Nom <span className='text-red-600'>*</span></label>
                    <input name='name' value={nom} onChange={(e) => setNom(e.currentTarget.value)} type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                {/* prenom */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Prénom <span className='text-red-600'>*</span></label>
                    <input name='firstName' value={pnom} onChange={(e) => setPrenom(e.currentTarget.value)}  type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                {/* pays de residence */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Pays de résidence <span className='text-red-600'>*</span></label>
                    <input name='Paysresidence' value={paysresidence} onChange={(e) => setPaysResidence(e.currentTarget.value)} type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                 {/*Pays de Nationalité */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Pays de nationalité <span className='text-red-600'>*</span></label>
                    <input name='PaysNationalite' value={paysnationalite} onChange={(e) => setPaysNationalite(e.currentTarget.value)} type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>


                {/* Profession */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Profession <span className='text-red-600'>*</span></label>
                    <input name='profession' value={profession} onChange={(e) => setProfession(e.currentTarget.value)} type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                {/* email */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Adresse e-mail <span className='text-red-600'>*</span></label>
                    <input name='mail' value={usermail} onChange={(e) => setUserEmail(e.currentTarget.value)} type='email' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                {/* numero de portable */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Numero de portable <span className='text-red-600'>*</span></label>
                    <input name='numero' value={tel} onChange={(e) => setTel(e.currentTarget.value)} type='tel' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>
                <label className='text-lg font-semibold'>Devise<span className='text-red-600'>*</span> :</label>
                <div className='flex flex-wrap items-center space-x-6 '>
                {devises ? devises.map((devise, index)=>(   
                    <div key={index} className='flex flex-row items-center space-x-2'>
                        <label className='pl-3 text-lg'>{(devise.sign ? devise.sign : "")+" "+(devise.name ? devise.name : "") }
                            &nbsp; <input type='radio' id={devise.sign ? devise.sign : ""} value={devise.name ? devise.name : ""} name='devise' checked={deviseAchat === devise.name} onChange={handleDeviseAchat} />
                        
                        </label>
                    </div>
                )): ""}


                </div>

                 {/* salaire*/}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Salaire <span className='text-red-600'>*</span></label>
                    <input name='salaire' value={salaire} onChange={(e) => setSalaire(e.currentTarget.value)} type='number' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                {/* boutton navigation */}
                 <div>
                    <Button style={{backgroundColor:"#FCCA46", color:"white"}} className='w-40 h-12 text-lg transition-transform transform rounded active:scale-75 '  onClick={handleClickOpen}>
                        Envoyer
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {"Demande d'achat d'un bien immobilier ?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Vous allez soumettre une demande d'achat d'un 
                            bien immobilier à Mon Bon Sejour. Connfirmer votre demande
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button onClick={handleConfirm} autoFocus>
                           Confirmer
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </form>
          </div>
        
        
          <Footer />
              </>
          )}
      </div>
  )
}

export default AcheterUnBien