import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import { FaRegBuilding } from "react-icons/fa";
import profil_photo_entreprise from '../../../ASSETS/tourisme/Image/Dashbord_image/pp.jpg';
import SidebarTop from '../SidebarTop';
import { CiMapPin } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { IoLogoTiktok } from "react-icons/io5";
import { TbWorldWww } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";
import Utilisateur from '../../../API/profil/index'
import { CustomerProps} from "../../../props";
import { ToastContainer, toast } from "react-toastify"
import { FiPlus } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FormEvent } from 'react';
import { User } from 'react-feather';
import Image_Profil_User from '../../../ASSETS/user/Image_Profil_User.jpg';
function Profil() {
  const [customer, setCustomer] = useState<CustomerProps>();
  const [data, setData] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
  });

  

  const getInformation = () => {
    Utilisateur.getUserInfo(data.azId)
    .then((res:any) => {
      //console.log(res)
        if (res.data.status === 201) {
          //console.log(res)
          setCustomer(res.data.data)
        }
    })
    .catch((err:any) => {
        console.log(err);
    })
    .finally(() => {
        setLoading(false);
    })
  }

  const handlaChangeImage =(event : any) =>{
    console.log(event.target.files[0])
    const formData = new FormData();
    formData.append('image',event.target.files[0])
    console.log(customer)
    if(customer)
      {
        const id = customer.Customers_Numbers
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/update/user/image/${id}`,formData)
        .then(response => {
            console.log(response)
            window.location.reload()
            //console.log(value)
        })
      }
    
  };
  

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {

    if (!data) {
      navigate("/Connexion");
    }

    getInformation();

    //const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      //setLoading(false);
    //}, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    //return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee]'>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>

          <div>
            <ToastContainer />
          </div>

          <div className='w-screen  barre_scroll h-screen'>
            {/* sidebar top  */}
            <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs >
                <Link to="" className="opacity-60">
                Mon compte
                </Link>
                <Link to="" className="">
                 Profil
                </Link>
              </Breadcrumbs>
            </div>
            {/* container formulaire */}
            <div className='flex md:flex-row s:flex-col md:space-x-10 s:space-y-6 md:space-y-0 justify-center p-10 '>
              {/* box 1 */}
              <div className='flex flex-col space-y-2 bg-white md:[340px] xs:w-[540px] s:w-[260px] sm:w-[310px]  p-5 rounded-md shadow border-t-8 border-[#fe7f2d]'>
                <div className='relative'>
                  {customer?.Picture ? (
                        <img src={`data:image/png;base64,${customer?.Picture}`} className="rounded-full w-24 h-24 border-2 border-[#FCCA46]" alt={customer?.Names}/>
                    ) : (
                      <img src={Image_Profil_User} className="rounded-full w-24 h-24 border-2 border-[#FCCA46]" alt={customer?.Names}/>
                    )}
                  {/* <img src={`data:image/png;base64,${customer?.Picture}`} className="rounded-full w-24 h-24 border-2 border-[#FCCA46]" alt={customer?.Names}/> */}
                  {/* <label htmlFor="image" className='absolute bottom-[-10px] left-[40px] bg-[#FCCA46] ronded-full transform translate-x(5%) w-100 h-100 flex items-center justify-center cursor-pointer'>
                    <FiPlus className="h-10 w-10 text-[#233d4d] hover:text-[#fe7f2d] transition duration-300" />
                  </label> */}

                  <label htmlFor="image" className='absolute bottom-[-2px] left-[35px] bg-[#FCCA46] rounded-full transform translate-x-5 w-5 h-5 flex items-center justify-center cursor-pointer'>
                      <FiPlus className="h-10 w-10 text-[#233d4d] hover:text-white transition duration-300" />
                  </label>
                </div>
                  <input onChange={handlaChangeImage} 
                    accept="image/*" 
                    name="image" id="image" type="file" className="hidden"/>
                
                <p className='text-lg font-semibold text-[#4e4e4e]'>{customer?.Names}</p>
                <p className='text-sm font-thin text-[]#4e4e4e'>{customer?.['E-mails']}</p>
                <div className='md:w-[300px] xs:w-[500px] s:w-[220px] sm:w-[270px]'>
                {customer?.Description}
                </div>
              </div>


              <div className='bg-white p-5 xs:w-[700px] s:w-[260px] sm:w-[320px] rounded-md shadow border-t-8 border-[#fe7f2d]'>
                <div className='flex flex-row space-x-8  text-lg font-light'>
                  <p className=' border-b-2 border-[#FCCA46] cursor-pointer hover:border-none'>Profile</p>
                  <Link to="/Dashboard/Profil/Modifier">
                    <p className='hover:border-b-2 border-[#FCCA46] cursor-pointer '>Modifier</p>
                  </Link>
                </div>
                <hr className='my-8'/>
                <div className=' flex flex-col space-y-5'>
                  {/* Nom de l'entreprise */}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <FaRegBuilding className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Nom de la structure :</p>
                    </div>
                    <p className=' text-lg font-thin'>{customer?.Names}</p>
                  </div>
                  {/* Adresse de la structure */}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <CiMapPin className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Adresse de la structure :</p>
                    </div>
                    <p className='text-lg font-thin'>{customer?.Adresses}</p>
                  </div>
                  {/* Telephone*/}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                      <div className='flex flex-row items-center space-x-2'>
                        <FaPhoneAlt className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                        <p className=' font-medium '>Telephone :</p>
                      </div>
                      <p className='text-lg font-thin'>{customer?.Phones}</p>
                    </div>
                  {/* Facebook */}
                  {/* <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <FaFacebookF className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full ' />
                      <p className=' font-medium '>Facebook :</p>
                    </div>
                    <p className='text-lg font-thin'>Monbonsejour</p>
                  </div> */}
                  {/* Instagram */}
                  {/* <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <SlSocialInstagram className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Instagram :</p>
                    </div>
                    <p className='text-lg font-thin'>Monbonsejour</p>
                  </div> */}
                  {/* Tiktok*/}
                  {/* <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <IoLogoTiktok className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Titok :</p>
                    </div>
                    <p className='text-lg font-thin'>Monbonsejour</p>
                  </div> */}
                   {/* Site web*/}
                  {/* <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <TbWorldWww className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Site web :</p>
                    </div>
                    <p className='text-lg font-thin'>www.monbonsejour.com</p>
                  </div> */}
                    

                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Profil