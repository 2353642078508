import { useState, useEffect } from 'react';
import Loading from '../../../../COMPONENTS/Loading/Loading'
import Footer from '../../../../COMPONENTS/Footer/Footer'
import Carousel from "../Carousel";
import img1 from "../../..//../../ASSETS/tourisme/Image/fort.jpg";
import img2 from "../../../../../ASSETS/tourisme/Image/fort3.jpg";
import img3 from "../../..//../../ASSETS/tourisme/Image/fort2.png";
import img4 from "../../../../../ASSETS/tourisme/Image/fort4.jpg";
import BackImage from '../../BackImage';
import BarniereCD from '../../BarniereCD';
import { IoIosArrowRoundForward } from "react-icons/io";
import Suggestion from '../Suggestion'

function Cvoiplus() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const slides = [img1, img2, img3, img4];
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    return (
        <div >
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

             {/* Back image */}
             <BackImage/>

             {/* barre bleu */}
            <div className='border-t-4 border-slate-900 py-10 w-2/3'></div>

            {/* container1 */}
            <div className='grid md:gap-8 md:grid-cols-2 place-items-center md:px-10 s:gap-10  xs:grid-cols-2 sm:grid-cols-1 pb-10'>
                <div className='space-y-6 md:w-full s:w-[260px] sm:w-[340px]'>
                    <p className='md:text-xl font-medium text-orange-400 '>Le Fort de Dabou</p>
                    <div className=' md:text-base sm:text-sm flex-col space-y-3'>

                        <p>Le fort de Dabou, aussi appelé fort
                            Faidherbe1 ou fort Binger, est un fort historique de la commune de Dabou,
                            dans le district des Lagunes, en Côte d'Ivoire.</p>

                        <p>Il est le bâtiment militaire français le plus ancien de Côte d'Ivoire.
                            La parcelle de terrain du fort aurait été donnée en cadeau par les chefs
                            du village d'Ébrimou lors de la signature du traité de Dabou, qui accordait
                            un monopole du commerce aux négociants français dans la zone des lagunes,
                            et contribuait à contrer l'influence anglaise croissante dans le golfe de Guinée.
                        </p>
                        
                         {isShowMore && (
                        <p>
                            Le fort Faidherbe fut construit en dix jours seulement par le commandant
                            Faidherbe (par ailleurs fondateur de la ville de Saint-Louis au Sénégal)
                            et inauguré le 10 octobre 1853, sur la rive nord-ouest de la lagune Ébrié,
                            à la suite de l'expédition du contre-amiral Auguste Baudin, qui commandait
                            l'escadre des côtes occidentales d'Afrique. Il avait alors pour double but
                            de protéger la zone des navires anglais et de contrôler le commerce dans la
                            lagune. Il s'agissait également de surveiller les tribus Jack-Jacks et Boubourys,
                            avec lesquelles les Français avaient passé des accords de commerce...
                        </p>
                        )}

                        <button className='text-[#FE7F2D] flex flex-row space-x-2 items-center' onClick={toggleReadMoreLess}>
                        {isShowMore ? "Read Less" : "Read More"}
                        <IoIosArrowRoundForward className="text-lg " />
                        </button>
                    </div>

                    {/* Lieu */}
                    <div className='flex items-center space-x-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.161" height="32.741" viewBox="0 0 35.161 52.741">
                            <g id="localisation" transform="translate(-85.333)">
                                <path id="Tracé_149" data-name="Tracé 149" d="M102.913,0a17.582,17.582,0,0,0-15.47,25.935l14.508,26.24a1.1,1.1,0,0,0,1.923,0l14.514-26.248A17.583,17.583,0,0,0,102.913,0Zm0,26.37a8.79,8.79,0,1,1,8.79-8.79A8.8,8.8,0,0,1,102.913,26.37Z" fill="#233d4d" />
                            </g>
                        </svg>
                        <p className='text-slate-800 font-medium'>Lieu : <span className=' font-light'>Dabou, Côte d'ivoire</span></p>
                    </div>

                </div>

                {/* Caroussel */}
                <div className="relative md:w-[400px]  s:w-[260px] sm:w-[340px] ">
                    <div className="max-w-lg">
                        <Carousel slides={slides} />
                    </div>
                </div>

            </div>

            <div className='border-t-4 border-slate-900 py-10 w-2/3 grid place-items-end'></div>

            {/* suggestions */}
            <div className='space-y-4 flex flex-col items-center pb-14'>
                <p className='text-xl font-semibold'>Suggestions</p>
                <p className=' md:w-[900px] xs:w-[700px] s:w-[260px] sm:w-[340px] text-center md:text-base s:text-sm'>Découvrez d'autre sites touristique de la côte d'ivoire ut galisum provident At voluptate galisum non eveniet similique aut impedit consectetur qui quia quia. Ut ipsam accusamus ut odio quos et reiciendis placeat ea accusantium numquam ulpa ut officia consequatur. Id ducimu</p>
            </div>
            <Suggestion/>

           {/* Banniere culture et decouverte */}
            <BarniereCD/>
            
            <Footer />
                </>
            )}

        </div>
    )
}

export default Cvoiplus