import React from 'react'
import axios from 'axios';
import { Breadcrumbs } from "@material-tailwind/react";
import {Link, useNavigate} from 'react-router-dom'
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { BiHotel } from "react-icons/bi";
import { MdOutlineHomeWork, MdOutlinePayment } from "react-icons/md";
import { IoBedOutline } from "react-icons/io5";
import { RxDimensions } from "react-icons/rx";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlinePool, MdElevator } from "react-icons/md";
import { MdOutlineWifi, MdBalcony } from "react-icons/md";
import { RiParkingBoxFill } from "react-icons/ri";
import { SlScreenDesktop } from "react-icons/sl";
import { IoRestaurant } from "react-icons/io5";
import { LuMicrowave, LuArmchair } from "react-icons/lu";
import { GiBathtub } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { FaUser, FaBellConcierge } from "react-icons/fa6";
import { GiMoneyStack, GiCctvCamera } from "react-icons/gi";
import { MdOutlineRoomPreferences, MdOutlineSevereCold } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { TbFridge } from "react-icons/tb";
import Heber from "../../../../API/tourisme/hebergement/index"
import { ToastContainer, toast } from "react-toastify"
import { IoMdPin, IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
// import PopopPaiement from '../../../../COMPONNENTS/PopopPaiement/PopopPaiement'
//import PopopPaiement from '../../../../CAIMMIGRATION/COMPONNENTS/PopopPaiement/PopopPaiement'

import { FedaCheckoutButton, FedaCheckoutContainer } from 'fedapay-reactjs';

//import info from '../../../../ASSETS/CaImmigration/icones/credit-card.png'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function PaymentPopup({ open,tel, user_Id, montant, email, onSuccess, userPrenom,userNom, formData, onClose }) {
    const [isDialogOpen, setIsDialogOpen] = useState(open);

    useEffect(() => {
        setIsDialogOpen(open);
    }, [open]);
    //console.log(email)
    const handleOpenAZ = () => {
        //alert("AZPay")
        // axios.get('/api/payment/create', {
        //     params: {
        //         provider: 'AZPay',
        //         item_id: 'item_id',
        //         item_name: 'item',
        //         amount: montant, // Assurez-vous de convertir les devises correctement
        //         currency: 'XOF',
        //         email: email,   
        //         description: 'Publicité sur Mon Bon Sejour', 
        //         phone: tel,
        //         email: email,
        //         last_name: userNom,  // Remplacez par les informations du client
        //         first_name: userPrenom,  
        //         success_url: 'successUrl',
        //         customer_number: userId
        //     }
        // }).then(response => {
        //     if (response.data.fail) {
        //         if (response.data.type_error === "balance_insufficient") {
        //             //setError("Votre solde est insuffisant pour effectuer cette transaction.");
        //         }
        //     } else if (response.data.success_url) {
        //         window.location.href = response.data.success_url;
        //     } else {
        //         //setError("Une erreur s’est produite, veuillez réessayer !");
        //     }
        // }).catch(error => {
        //     console.error("Erreur lors de la requête AZPay :", error);
        // });
    };


    const handleClose = () => {
        setIsDialogOpen(false);
        if (onClose) onClose();  
    };


    const handlePayCancel = () => {
        //console.log('Paiement annulé');
        handleClose();
    };
    const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
    const checkoutButtonOptions = {
        public_key: PUBLIC_KEY,
        transaction: {
          amount: montant,
          description: 'Publicité sur Mon Bon Sejour',
          callback_url: 'Dashboard/Publications/Hebergement/Ajouter_des_hebergements',
          custom_metadata: {
                user_id: user_Id,
                //additional_info: 'Some additional info'
            }
        },
        currency: {
          iso: 'XOF'
        },
        customer: {
          lastname: userNom,
          firstname: userPrenom,
          email: email
        },
        button: {
          class: 'btn btn-primary',
          text: 'Payer ' + montant + ' FCFA'
        },
        onComplete(resp) {
          const FedaPay = window['FedaPay'];
          if (resp.reason === FedaPay.DIALOG_DISMISSED) {
            //window.location.reload();
            toast.success('Vous avez fermé la boite de dialogue');
          } else {
            setIsDialogOpen(false);
            onSuccess();
    
            // Extraire les données de la transaction
            const transactionData = {
              transaction_id: resp.transaction.id,
              reference: resp.transaction.reference,
              amount: resp.transaction.amount,
              description: resp.transaction.description,
              status: resp.transaction.status,
              approved_at: resp.transaction.approved_at,
              customer: {
                firstname: resp.transaction.customer.firstname,
                lastname: resp.transaction.customer.lastname,
                email: resp.transaction.customer.email,
              },
              transaction_key: resp.transaction.transaction_key,
              fees: resp.transaction.fees,
              mode: resp.transaction.mode,
              amount_debited: resp.transaction.amount_debited,
              custom_metadata:resp.transaction.custom_metadata.user_id,
            };
            console.log('Transaction Data: ',transactionData);
            console.log(checkoutButtonOptions);
            // Envoyer les données de la transaction au backend
            fetch(`${process.env.REACT_APP_BASE_URL}/api/fedapay/callback`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify(transactionData),
            })
            .then(response => {
              if (!response.ok) {
                return response.json().then(errorData => {
                  throw new Error(errorData.message || 'Something went wrong');
                });
              }
              return response.json();
            })
            .then(data => {
              console.log('Transaction saved:', data);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
          }
    
          console.log(resp.transaction);
          
        },
        onCancel: handlePayCancel
      };
    
      // Lancer le paiement FedaPay
      const handlePayment = () => {
        const FedaPay = window['FedaPay'];
        FedaPay.init(checkoutButtonOptions).open();
      };
    

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isDialogOpen}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='flex items-center text-[#FE7F2D] space-x-3'>
                <p>Veuillez choisir le mode de paiement</p>
                <IconButton aria-label="close" onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Pour les paiements avec votre compte Az, choisissez l'option payer avec mon compte Az-Pay. Et pour les paiements par carte bancaire ou mobile money, choisissez l'option payer par carte ou mobile money
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOpenAZ} style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}>
                    Az-Pay : {montant} F CFA
                </Button>
                {/* <Button id="fedapay_btn_" onClick={handleOpenCard} style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}>
                    Carte bancaire / Mobile money : {price} F CFA
                </Button> */}

                {/* <FedaCheckoutButton 
                    style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}
                    options={checkoutButtonOptions}
                /> */}

                <button onClick={handlePayment} className={checkoutButtonOptions.button.class} 
                    style={{ textTransform: "none", backgroundColor: "#233D4D", color: "white" }}>
                    {checkoutButtonOptions.button.text}
                </button>

            </DialogActions>

        </BootstrapDialog>
    );
}


function AjoutHebergement() {

    const urlbackpay=localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Hebergement/Ajouter_des_hebergements'));

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [address, setAddress] = useState([]);
    
    const [loading, setLoading] = useState(true);
    
    //const [montantTotal, setMontantTotal] = useState(0);
    const [formData, setFormData] = useState({});
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        //setMontantTotal(montantTotal);
    };
    
    //console.log(data)
    const handleCountryChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        
        //province
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/province/list/${value}`)
     .then(response => {setProvinces(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const handleProvinceChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        //city
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/city/list/${value}`)
     .then(response => {setCities(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    // États pour gérer le formulaire et le popup
    const [showPopup, setShowPopup] = useState(false);
    
    const navigate = useNavigate();

    //console.log(formData)
    useEffect(() => {
        if (!data) {
            navigate("/Connexion");
        }


        //country
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/country/list`)
     .then(response => {setCountries(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

     //adresse
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/utilisateur/adresse/${data.azId}`)
     .then(response => {
       setAddress(response.data.data)
        })
     .catch(err => console.log(err))


        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const submitForm = () => {
        let fd = new FormData();
        fd.append("nom", formData.name_h);
        fd.append("user", data.userId);
        fd.append("type", formData.type);
        fd.append("methode", formData.methode);
        fd.append("date", formData.date);
        fd.append("adresse", formData.lieu);
        fd.append("size", formData.perimetre);
        fd.append("lit", formData.bed_type);
        fd.append("ascenseur", formData.ascenseur);
        fd.append("balcon", formData.balcon);
        fd.append("concierge", formData.concierge);
        fd.append("camera", formData.camera);
        fd.append("clim", formData.climatiseur);
        fd.append("meuble", formData.meuble);
        fd.append("desc", formData.msg);
        fd.append("chambre", formData.nbre);
        fd.append("note", formData.note);
        fd.append("parking", formData.parking);
        fd.append("piscine", formData.piscine);
        fd.append("prix", formData.prix);
        fd.append("wifi", formData.wifi);
        fd.append("date_debut", formData.date_debut);
        fd.append("date_fin", formData.date_fin);
        fd.append("mail", formData.mail ? formData.mail : data.userMail);
        fd.append("tel", formData.phone ? formData.phone : data.userTel);
        fd.append("pays", formData.country);
        fd.append("ville", formData.ville);
        fd.append("province", formData.province);
        if (formData.date_debut && formData.prix && formData.date_fin) {
            fd.append("montant", parseFloat(formData.prix) * 
            Math.ceil((new Date(formData.date_fin) - 
            new Date(formData.date_debut)) / (1000 * 60 * 60 * 24)));
        }
        for (let i = 0; i < document.getElementById("photo")?.files.length; i++) {
            fd.append("photo[]", document.getElementById("photo")?.files[i]);
        }
        
        setLoading(true);
        Heber.submitForm(fd)
            .then((res) => {
                console.log(res);
                if (res.data.status === 201) {
                    toast.success(res.data.message);
                    console.log(res);
                } else {
                    toast.error(res.data.message);
                    console.log(res);
                }
            })
            .catch((err) => {
                toast.error('Erreur merci de reessayer !');
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Fonction de gestion de la soumission du formulaire
    const handleSubmit = (event) => {
        event.preventDefault();
        // Afficher le popup de paiement
        setShowPopup(true);
    };

    // Callback de succès du popup de paiement
    const handlePopupSuccess = () => {
        setShowPopup(false);
        submitForm();
    };

    // Callback d'annulation du popup de paiement
    const handlePopupCancel = () => {
        setShowPopup(false);
    };


  return (
    <div className='flex flex-row bg-[#eeee]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
        {/* sidebar left */}

        <div className='w-screen h-screen barre_scroll'>
            {/* navbar top orange */}
             <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                <Breadcrumbs>
                      <Link to="" className="opacity-60 md:text-base s:text-xs">
                          Mes publications
                      </Link>
                      <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements" className="opacity-60 md:text-base s:text-xs">
                          Hebergements
                      </Link>
                      <Link to="" className='md:text-base s:text-xs'>
                        Ajouter
                      </Link>
                </Breadcrumbs>
            </div>

            <div>
                <ToastContainer />
            </div>

            <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements">
                <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                    <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                    <p>Retour à la page précedente</p>
                </div>
            </Link>
            {/* container formulaire */}
            <div className='flex justify-center py-20 '>
            <form onSubmit={handleSubmit} className='flex flex-col items-center space-y-3 '>

                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                    {/* nom d'apartement */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
                            <label>Nom</label>
                        </div>
                        <input onChange={handleInputChange} required type='text' name='name_h' placeholder='Ex : Villa la joie' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* Ajout des images */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                            <label> Ajouter des images</label>
                        </div>
                        <input onChange={handleInputChange} required type="file" accept="image/*" multiple id="photo" name="photo" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 '/>
                    </div>
                    {/* Type de paiement */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlinePayment className='text-xl text-[#fe7f2d]' />
                            <label>Méthode de paiement</label>
                        </div>
                        <select onChange={handleInputChange} name="methode" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4' required>
                        <option value=""></option>
                        <option value="1">Paiement en ligne et sur place</option>
                        <option value="2">Paiement en ligne</option>
                        <option value="3">Paiement sur place</option>
                        </select>
                    </div>
                    {/* Type */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
                            <label>Type</label>
                        </div>
                        <select onChange={handleInputChange} name="type" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4' required>
                        <option value=""></option>
                        <option value="other">Autre</option>
                        <option value="house">Maison</option>
                        <option value="hotel">Hotel</option>
                        <option value="appartment">Appartement</option>
                        </select>
                    </div>
                    {/* nombre de la chambre */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <BiHotel className='text-xl text-[#fe7f2d]' />
                            <label>Nombres de pièces</label>
                        </div>
                        <input onChange={handleInputChange} required type='number' name='nbre' placeholder='Ex : 2 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* perimetre chambre */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <RxDimensions className='text-xl text-[#fe7f2d]' />
                            <label>Périmetre(m)</label>
                        </div>
                        <input onChange={handleInputChange} type='number' name='perimetre' placeholder='Ex : 40 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* Type de lit */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoBedOutline className='text-xl text-[#fe7f2d]' />
                            <label>Type de lit</label>
                        </div>
                        <select onChange={handleInputChange} name="bed_type" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value=""></option>
                        <option value="Lit simple">Lit simple</option>
                        <option value="Lit double">Lit double</option>
                        <option value="Lit triple">Lit triple</option>
                        </select>
                    </div>
                     
                </div>

                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                    {/* Tel */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <FaPhoneAlt className=' text-[#fe7f2d]' />
                            <label>Telephone</label>
                        </div>
                        <input type='tel' defaultValue={data.userTel} name='phone' onChange={handleInputChange} placeholder="Ex : +225 xxx xxx xxxx " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Mail */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoIosMail className=' text-[#fe7f2d]' />
                            <label>E-mail</label>
                        </div>
                        <input defaultValue={data.userMail} type='email' name='mail' onChange={handleInputChange} placeholder="Ex : info@monbonsejour.com " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Country */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Pays</label>
                        </div>
                        <select onChange={handleCountryChange} required name='country' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code}>{address.fr_Name}</option>
                                            {countries.map((option, index) => (
          <option key={index} value={option.code}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Province */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Province</label>
                        </div>
                        <select onChange={handleProvinceChange} required name='province' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_province}>{address.pname}</option>
                                            {provinces.map((option, index) => (
          <option key={index} value={option.code_province}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Ville */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Ville</label>
                        </div>
                        <select onChange={handleInputChange} required name='ville' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_city}>{address.cname}</option>
                                            {cities.map((option, index) => (
          <option key={index} value={option.code_city}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Lieu */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Lieu</label>
                        </div>
                        <input defaultValue={address.Adresses} type='text' name='lieu' onChange={handleInputChange} placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                </div>

                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                    {/* caractéristique de la chambre */}
                    <p className='text-lg font-semibold'>Dans votre appartement ou chambre</p>
                    <div className='grid s:grid-cols-2 sm:grid-cols-3 xs:grid-cols-3 gap-y-8'>
                        {/* piscine */}
                        <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <MdOutlinePool className='text-xl text-[#fe7f2d]' />
                                <label>Piscine</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='piscine' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='piscine' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Wifi  */}
                        <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <MdOutlineWifi className='text-xl text-[#fe7f2d]' />
                                <label>Wifi </label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='wifi' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='wifi' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* TV */}
                        <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <GiCctvCamera className='text-xl text-[#fe7f2d]' />
                                <label>Cameras</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='camera' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='camera' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Pétit déjeuner */}
                        <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <MdBalcony className='text-xl text-[#fe7f2d]' />
                                <label>Balcon</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='balcon' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='balcon' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Micro onde */}
                        <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <MdElevator className='text-xl text-[#fe7f2d]' />
                                <label>Ascenseur</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='ascenseur' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='ascenseur' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Baignoire */}
                        <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <FaBellConcierge className='text-xl text-[#fe7f2d]' />
                                <label>Conciegre</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='concierge' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='concierge' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Ustensile de cuisine */}
                        <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <RiParkingBoxFill className='text-xl text-[#fe7f2d]' />
                                <label>Parking</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='parking' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='parking' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* frigo */}
                        <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <MdOutlineSevereCold className='text-xl text-[#fe7f2d]' />
                                <label>Climatiseur</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='climatiseur' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='climatiseur' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                         {/* frigo */}
                         <div className='flex flex-col space-y-2 '>
                            <div className='flex flex-row items-center space-x-2'>
                                <LuArmchair className='text-xl text-[#fe7f2d]' />
                                <label>Meublé</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='meuble' value="1" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input onChange={handleInputChange} type='radio' name='meuble' value="0" />
                                <label>Non</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                    {/* nombre de personne */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <CiCalendarDate className=' text-[#fe7f2d]' />
                            <label>Date de disponibilité</label>
                        </div>
                        <input name='date' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
                    </div>
                    {/* équipement */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineRoomPreferences className=' text-[#fe7f2d]' />
                            <label>Équipements</label>
                        </div>
                        <input type='text' onChange={handleInputChange} name='note' placeholder='Ex : Bureau, Machine à café, Minibar, Fer à rapasser, Netflix, Canal+ ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* Tarif */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                            <label>Tarif</label>
                        </div>
                        <input id="prix"  onChange={handleInputChange} type='number' name='prix'  required placeholder='Ex : 70000 ' min={1} className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                        <small>Le tarif doit être par nuitée</small>
                    </div>
                    {/* Description */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                            <label>Description de la chambre</label>
                        </div>
                        <textarea onChange={handleInputChange} name="msg" className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                    </div>
                </div>

                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                    {/* date debut */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <CiCalendarDate className=' text-[#fe7f2d]' />
                            <label>Date de debut affichage</label>
                        </div>
                        <input id="date_debut"  name='date_debut' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
                    </div>

                    {/* date fin */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <CiCalendarDate className=' text-[#fe7f2d]' />
                            <label>Date de fin affichage</label>
                        </div>
                        <input id="date_fin"  name='date_fin' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
                    </div>
                    {formData.date_debut && formData.prix && formData.date_fin ? 
                            <div className='flex flex-col space-y-3'>
                                <div className='flex flex-row items-center space-x-2 '>
                                    <GiMoneyStack className='text-xl text-[#1f2937]' />
                                    <label className='text-[#1f2937] text-bold'>Montant total: {(parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut))/ (1000 * 60 * 60 * 24)))} F CFA</label>
                                    <input hidden id="montant" onChange={handleInputChange} value={(parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut))/ (1000 * 60 * 60 * 24)))} name='montant' type='number'/>
                                    
                                </div>
                            </div>
                       
                        : ""
                    }
                </div>

                {formData.date_debut && formData.prix && formData.date_fin ?
                    <button className='bg-[#FCCA46] w-[250px] h-10 rounded
                     text-white'>Procéder au paiement</button>
                
                    :
                    <button className='bg-[#FCCA46] w-[150px] h-10 rounded
                     text-white'>Ajouter</button>
                }

                {showPopup && (
                    <PaymentPopup
                        open={showPopup}
                        montant={parseFloat(formData.prix) * Math.ceil((new Date(formData.date_fin) - new Date(formData.date_debut)) / (1000 * 60 * 60 * 24))}
                        onSuccess={handlePopupSuccess}
                        userNom={data.userLastname}
                        userPrenom={data.userName}
                        email={data.userMail}
                        user_Id={data.userId}
                        formData={formData}
                        urlpay={urlbackpay}
                        onCancel={handlePopupCancel}
                    />
                )}
            </form>
            </div>
        </div>
              </>
          )}
    </div>
  )
}

export default AjoutHebergement