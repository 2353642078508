import axios from 'axios';

let Transaction : any = {};

Transaction.getDepenses = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/transaction/depense/${id}`)
}

Transaction.getVentes = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/transaction/vente/${id}`)
}

export default Transaction;