import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENTS/Header/Header'
import Navbar from './Navbar'
import Footer from '../../COMPONNENTS/Footer/Footer'
import visa from '../../../ASSETS/CaImmigration/images/VisaFamiliale_barniere.png'
import visafamiliale1 from '../../../ASSETS/CaImmigration/images/visaFamiliale1.jpg'
import famille2 from '../../../ASSETS/CaImmigration/images/visaFamiliale2.jpg'
import famille3 from '../../../ASSETS/CaImmigration/images/familiale3.jpg'
import { Link } from 'react-router-dom'
import Ifamille from '../../../ASSETS/CaImmigration/icones/family.png'
import TestEligibilite from '../../COMPONNENTS/BoutonTestEligibilite/TestEligibilite';
import BarniereRdvVisa from '../../COMPONNENTS/BarniereRdvVisa/BarniereRdvVisa';
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';

function VisaEtude() {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
      <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
                  <Header />

                  {/* back image */}
                  <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${visa})` }}>
                      <Navbar />
                      <div className='text_home flex  justify-end md:pl-10 s:pl-3  s:pt-20 md:pt-0 mt-20'>
                              <div className='flex space-y-4 pt-20 flex-col text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg    font-semibold xs:w-[470px] md:w-[770px] '>
                              <p>SERVICES DE VISA CANADIEN</p>
                              <p className=' md:text-3xl sm:text-lg s:text-sm'>VISA FAMILIALE</p>
                          </div>
                      </div>

                  </div>

                  {/* option */}
                  <OptionCard/>

                  {/* en tete */}
                  <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
                      <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
                      <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
                  </div>

                <div className='bg-white py-20'>
                   {/* titre */}
                    <div className='flex  xs:flex-row xs:px-5 md:px-0 md:pl-0 flex-col justify-center items-center xs:space-x-40 xl:space-x-[700px] lg:space-x-[300px] s:space-y-10'>
                    <div>
                        <p className=' text-sm font-semibold'><span style={{ color:"#FE7F2D"}} className=' font-bold sm:text-xl s:text-lg'>/</span>QUE PROPOSONS-NOUS </p>
                        <p style={{ color: "##4e4e4e" }} className=' sm:text-2xl s:text-xl font-bold w-[250px]'>Conseils en Services de visa d'immigration exceptionnels.</p>
                    </div>

                    <div style={{ color: "##4e4e4e" }} className='xs:w-[650px] md:w-[800px] sm:w-[300px] s:w-[200px] sm:text-lg s:text-base text-justify '>
                    "À la recherche d'une expertise exceptionnelle en matière d'immigration au Canada ? 
                    Nous sommes là pour vous accompagner tout au long de votre parcours. Nos consultants 
                    chevronnés vous offrent des conseils personnalisés et des services de visa d'immigration 
                    de haute qualité. Avec notre équipe dévouée, nous vous guiderons à chaque étape de votre 
                    processus d'immigration, vous aidant à réaliser votre rêve canadien en toute simplicité. 
                    Faites confiance à notre expérience pour une immigration réussie et sans stress."</div>
                    </div>

                    {/* container1 */}
                    <div className='flex xs:flex-row s:flex-col xs:space-x-40 s:space-y-20 s:justify-center items-center s:py-20 xs:py-0 xs:pb-20'>
                        {/* filtre */}
                        <div className='sm:w-[300px] s:w-[260px] h-[400px] flex flex-col justify-center items-center space-y-5  ' style={{backgroundColor:"#f7f7f7"}}>

                            {/* permis d'etudes */}
                            <Link to='/visa/visaEtude'>
                            <div style={{  backgroundColor: 'white', color:"#FE7F2D" }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base  text-white rounded-xl shadow-lg font-semibold justify-center items-center space-x-5'>
                            <p>Visa et Permis d'etudes</p>
                              <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                           
                            </div>
                            </Link>
                            {/* visa travail */}
                            <Link to='/visa/visaTravail'>
                            <div style={{backgroundColor: 'white', color:"#FE7F2D"  }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-5'>
                                    <p>Visa et Permis de travail</p>
                                     <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                               
                            </div>
                            </Link>
                            {/* visa touriste */}
                            <Link to='/visa/visaTouriste'>
                            <div style={{ backgroundColor: 'white', color:"#FE7F2D" }} className='transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa touriste</p>
                                       <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                            </div>
                            </Link>
                            {/* visa transit */}
                            <Link to='/visa/visaTransit'>
                            <div style={{ backgroundColor: '#white', color:'#FE7F2D' }} className='transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa transit</p>

                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                            <g id="rigth" transform="translate(-1.974 -1.315)">
                                                <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fe7f2d" />
                                            </g>
                                        </svg>
                                    </p>
                                    
                            </div>
                            </Link>
                            {/* visa familiale*/}
                            <Link to='/visa/visaFamiliale'>
                            <div style={{ backgroundColor: '#FE7F2D', color:"white" }} className=' transform active:scale-75 transition-transform flex sm:w-[250px] sm:h-14 s:w-[200px] s:h-12 s:text-sm sm:text-base rounded-xl shadow-lg font-semibold justify-center items-center space-x-20'>
                                    <p>Visa familiale</p>
                    
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.509" viewBox="0 0 10 15.509">
                                              <g id="rigth" transform="translate(-1.974 -1.315)">
                                                  <path id="Tracé_1" data-name="Tracé 1" d="M3.158,1.315A1.1,1.1,0,0,0,2.432,3.27L9.181,9.048,2.428,14.826a1.1,1.1,0,1,0,1.438,1.668l7.721-6.6a1.1,1.1,0,0,0,0-1.68L3.866,1.594A1.1,1.1,0,0,0,3.158,1.315Z" transform="translate(0 0)" fill="#fff" />
                                              </g>
                                          </svg>
                                    </p>
                            </div>
                            </Link>
                        </div>

                        {/* texte1 */}
                        <div>
                            <img className='md:w-[700px] sm:w-[300px] s:w-[210px]' src={visafamiliale1} alt='etudiant'/>
                            <p className='text-xl font-semibold pt-6' style={{color:"#FCCA46"}}>Visa familiale</p>
                            <p className='md:w-[680px] sm:w-[300px] s:w-[210px] pt-6'>
                                Un visa familial offre à vos proches la possibilité de vivre, d'étudier et de travailler au Canada tout en attendant le traitement de leur demande. Une fois qu'ils obtiennent la résidence permanente, ils ont accès aux mêmes avantages sociaux que tous les résidents canadiens, tels que l'accès à l'éducation et aux soins de santé.
                            </p>
                        </div>
                    </div>

                    {/* container2 */}
                    <div className='flex md:flex-row s:flex-col justify-center xs:items-start s:items-center md:space-x-32 s:space-x-0 s:space-y-16 md:space-y-0 xs:mr-24 s:mr-0'>

                        <div className=' flex md:flex-col xs:flex-row s:flex-col s:space-y-10 xs:space-y-0 xs:space-x-10 md:space-x-0 md:space-y-10'>
                            <div><img src={famille2} className='md:w-[400px] sm:w-[300px] s:w-[210px]' alt='etudiant1'/></div>
                            <div><img src={famille3} className='md:w-[400px] sm:w-[300px] s:w-[210px]' alt='etudiant1'/></div>
                        </div>

                        <div className='flex flex-col items-center space-y-10' >
                            {/* avantages */}
                            <div>
                            <p className='text-2xl font-semibold xs:w-[500px] sm:w-[300px] s:w-[210px]' style={{color:"#233D4D"}}>Les avantages d'immigrer avec sa famille au Canada</p>
                            <p className='md:w-[500px] xs:w-[600px] sm:w-[300px] s:w-[210px] pt-6'>
                                <b>- Éducation de qualité :</b> Le Canada offre un système éducatif de renommée mondiale, des écoles primaires aux universités. Vos enfants auront accès à une éducation de qualité et des opportunités pour leur avenir.<br/><br/>
                                <b>- Sécurité et qualité de vie :</b> Le Canada est l'un des pays les plus sûrs au monde, offrant un environnement de vie stable et une qualité de vie élevée pour les familles.<br/><br/>
                                <b>- Opportunités économiques :</b> Le Canada offre des opportunités économiques pour les membres de la famille qui souhaitent travailler ou entreprendre des activités commerciales.<br/><br/>
                                <b>- Immigration facilitée :</b> Si vous êtes un résident permanent, il est plus facile de sponsoriser d'autres membres de votre famille pour qu'ils rejoignent le Canada. Les procédures sont simplifiées.<br/>
                            </p>
                            </div>
                        
                        </div>
                    </div>

                            {/* guide */}
                            <div className='flex flex-col items-center' >
                            <p className='text-2xl md:w-[800px] font-semibold xs:w-[500px] sm:w-[300px] s:w-[210px] mt-10' style={{color:"#233D4D"}}>Nous vous guidons dans les processus immigration avec votre famille </p>
                            <p className='text-center md:w-[800px] xs:w-[600px] sm:w-[300px] s:w-[210px] pt-6'>
                                Votre famille, votre avenir. Avec Mon Bon Séjour, naviguez sereinement le processus d'immigration en famille. Faites équipe avec nous pour réaliser votre rêve de vivre et prospérer ensemble au Canada.
                            </p>
                            </div>

                            <div className='flex xs:flex-row s:flex-col justify-center mt-10   items-center xs:space-x-20 md:space-x-5 s:space-y-10 xs:space-y-0'>
                            {/* famille*/}
                            <div className='flex  flex-row items-center  text-xl font-bold space-x-6' style={{color:"#233D4D"}}>
                                {/* icones */}
                                <p>
                                <img src={Ifamille} className='w-12' alt='familleicone' />
                                </p>
                                <p>Immigrer avec votre famille au Canada</p>
                            </div>
                            
                            </div>

                    {/* eligibilité */}

                    <TestEligibilite />
                   
                   
                    {/* banniere */}
                   <BarniereRdvVisa/>
                    
                </div>

                  <Footer />
              </>
          )}
      </div>
  )
}

export default VisaEtude