import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Footer from '../../COMPONNENTS/Footer/Footer'
import Header from '../../COMPONNENTS/Header/Header'
import residence from '../../../ASSETS/CaImmigration/images/ResidenceP_barniere.png'
import residence1 from '../../../ASSETS/CaImmigration/images/gros-plan-personnes-voyageant-ensemble.png'
import residence2 from '../../../ASSETS/CaImmigration/images/coup-moyen-femme-tenant-tasse.png'
import FAQ from '../FAQ/Faq'
import {Link} from 'react-router-dom'
import cible from '../../../ASSETS/CaImmigration/icones/cible.png'
import aide from '../../../ASSETS/CaImmigration/images/portrait-young-african-businesswoman-showing-something-digital-tablet-her-colleague-removebg-preview.png'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visaN from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'
import pin from '../../../ASSETS/CaImmigration/icones/pin.png'
import feuille from '../../../ASSETS/CaImmigration/icones/maple-leaf.png'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';
function ResidenceP() {

  let [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 1000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);

  return (
     <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          {/* back image */}
          <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${residence})` }}>
              
               {/* Navbar */}
                <div className='navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0  bg-transparent flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
                        <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center xs:space-x-[600px]    justify-between'>

                            <Link to='/'>
                            <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                                <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                            </div>
                            </Link>
                            <div onClick={() => setOpen(!open)} className=' lg:hidden text-end relative   s:text-3xl pr-4 s:text-white cursor-pointer'>
                            <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
                            </div>
                        </div>
                        {/* element_menu */}
                        <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

                            <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>
                            
                                {/* Accueil */}
                                <Link to='/homeCaimmigration'>
                                <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
                                    <div>
                                    <img className='w-6' src={home} alt='' />
                                    </div>
                                    <div> Accueil</div>
                                </div>
                                </Link>

                            {/* Visa */}
                                <div style={{ fontSize: "16px",  }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
                                <div className='relative bottom-1'>
                                    <img className='w-4' src={visaN} alt='' />
                                </div>
                                <div className='navbar1 '>
                                    <ul>
                                    <li> <Link to='/visa'> <p className='flex space-x-2 items-center'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p>
                                    <ul className='z-10  '>
                                        <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                                    </ul>
                                    </Link>
                                    </li>
                                    </ul>
                                </div>
                                </div>

                            {/* Résidence permanente */}
                            <Link to='/ResidencePermante'>
                            <div style={{ fontSize: "16px",color:"#FCCA46" }} className='flex space-x-2 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={Rpermanent} alt='' />
                                </div>
                                <div>Résidence permanente</div>
                            </div>
                            </Link>

                            {/*Citoyenneté Canadienne */}
                            <Link to='/CitoyenneteCa'>
                            <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center md:text-white s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={CitoyenCA} alt='' />
                                </div>
                                <div> Citoyenneté Canadienne</div>
                            </div>
                            </Link>

                            {/* Achat de biens immobilers */}
                            <Link to='/BienImmobilier'>
                                <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center md:text-white s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
                                <div>
                                    <img className='w-6' src={immobilierN} alt='' />
                                </div>
                                <div>Achat de biens immobilers</div>
                                </div>
                            </Link>
                            </div>
                        </div>
                </div>

              <div className='text_home flex justify-end md:pl-20 s:pl-5  s:pt-20 md:pt-0 mt-20'>
                  <div className='flex pt-20 text-center  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg  font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>SERVICES DE RESIDENCE PERMANANTE</p>
                  </div>
                
              </div>

          </div>

          {/* option */}
         <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
              <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/*ResidencePermanente */}
          <div className='bg-white py-20'>
              {/* titre */}
              <div className=' md:ml-24 s:ml-4'>
                <p className=' xs:text-3xl s:text-xl font-bold' style={{color:"#4e4e4e"}}><span style={{ color: "#FE7F2D" }} className=' font-bold xs:text-4xl s:text-2xl '>/</span>Le Statut de Résident Permanent </p>
               </div>
              {/* bloc */}

              <div className='mt-10 flex flex-col justify-center'>

                <div className='flex flex-row xs:space-x-20 s:space-x-10 justify-center px-3'>
                  <div><img className='xs:w-[500px] s:w-[200px]' src={residence1} alt='residence'/></div>
                  <div><img className='xs:w-[500px] s:w-[200px]' src={residence2} alt='residence' /></div>
                </div>

                <div className=' flex justify-center mt-12'>
                  <p className='md:w-[1080px] xs:w-[800px] s:w-[260px] sm:w-[320px] text-justify'>
                    <p>
                        La résidence permanente au Canada est un statut d'immigration qui permet 
                        aux étrangers de vivre, travailler et étudier de façon permanente au Canada. 
                        Les résidents permanents du Canada ont la plupart des mêmes droits et 
                        responsabilités que les citoyens canadiens, à l'exception du droit de vote 
                        et de certaines responsabilités telles que le service militaire. Voici 
                        quelques-unes des caractéristiques de la résidence permanente au Canada :
                    </p><br/>

                    <div className='flex xs:items-center s:items-start '>
                        <img src={pin} alt='epingle' className='w-8 h-8'/>
                        <p>
                            <span className='font-bold text-[#233D4D]'> Durée indéterminée :</span> La résidence permanente au Canada n'a pas de date 
                            d'expiration. Une fois que vous avez obtenu ce statut, vous pouvez y 
                            vivre aussi longtemps que vous le souhaitez, à condition de respecter 
                            les lois canadiennes.
                        </p>
                    </div><br/>

                    <div className='flex xs:items-center s:items-start '>
                     <img src={pin} alt='epingle' className='w-8 h-8'/>
                        <p>
                            <span className='font-bold text-[#233D4D]'>Accès aux avantages sociaux :</span> Les résidents permanents ont accès 
                            aux avantages sociaux canadiens, tels que les soins de santé, 
                            l'éducation publique, et d'autres services sociaux.
                        </p>
                    </div><br/>

                    <div className='flex xs:items-center s:items-start '>
                        <img src={pin} alt='epingle' className='w-8 h-8'/>
                        <p>
                        <span className='font-bold text-[#233D4D]'> Mobilité :</span> Les résidents permanents peuvent vivre, 
                        travailler ou étudier dans n'importe quelle province ou territoire du Canada.
                        </p>
                    </div><br/>

                    <div className='flex xs:items-center s:items-start '>
                        <img src={pin} alt='epingle' className='w-8 h-8'/>
                        <p>
                            <span className='font-bold text-[#233D4D]'>Possibilité de demander la citoyenneté : </span> Après avoir vécu au 
                            Canada en tant que résident permanent pendant une période déterminée,
                            vous pouvez demander la citoyenneté canadienne, à condition de remplir 
                            certaines exigences.
                        </p>
                    </div><br/><br/>

                    <p>
                        Il existe plusieurs programmes d'immigration qui permettent d'obtenir
                         la résidence permanente au Canada, tels que le Programme des travailleurs
                          qualifiés, le Programme de l'expérience québécoise, le Programme des travailleurs 
                          agricoles saisonniers, et d'autres. Chacun de ces programmes a ses propres critères
                           et exigences, et le processus de demande peut varier en fonction du programme.
                    </p>
                  </p>
                </div>

                 
                     
                <div className='flex flex-col justify-center mt-16'>
                  <div style={{ color:"#233D4D"}} className='xs:text-2xl s:text-xl font-medium md:ml-24 sm:ml-8 s:ml-2 s:w-[260px] sm:w-[300px] xs:w-full'>Comment obtenir la Résidence permanente Canadienne ?</div>
                  {/* etape */}
                  <div className=' flex flex-col items-center mt-4'>
                    <p className='md:w-[1050px] xs:w-[800px] s:w-[260px] sm:w-[320px] text-justify '>
                        L'obtention de la résidence permanente au Canada peut se faire à travers 
                        plusieurs programmes d'immigration, chacun ayant ses propres critères et exigences. 
                        Voici les étapes générales pour obtenir la résidence permanente canadienne :
                    </p>
                  </div><br/>
                    {/* etape 1 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <p>
                                <b>Déterminez votre admissibilité :</b> La première étape consiste à déterminer
                                 pour quel programme d'immigration canadien vous êtes admissible. Les principaux
                                  programmes d'immigration sont le Programme des travailleurs qualifiés, le 
                                  Programme de l'expérience québécoise, le Programme de travailleurs agricoles 
                                  saisonniers, le Programme des candidats des provinces, le Programme d'immigration
                                   économique du Québec, le Programme des réfugiés parrainés par le secteur privé, 
                                   et d'autres. Chacun de ces programmes a ses propres critères d'admissibilité. 
                                   Vous devrez répondre à ces critères pour pouvoir présenter une demande.
                            </p>
                        </div>
                     
                    </div><br/>
                    {/* etape 2 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <p>
                               <b> Réunissez les documents nécessaires :</b> Une fois que vous 
                               avez déterminé le programme auquel vous êtes admissible, rassemblez 
                               tous les documents requis. Cela peut inclure des documents d'identité, 
                               des preuves de compétence linguistique en anglais ou en français, des 
                               relevés de casier judiciaire, des évaluations d'éducation, des preuves 
                               d'expérience de travail, et d'autres documents pertinents.
                            </p>
                        </div>
                    </div><br/>
                    {/* etape 3 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <p>
                               <b>Présentez votre demande :</b> Vous devrez remplir les formulaires de 
                               demande appropriés, disponibles sur le site Web d'Immigration, 
                               Réfugiés et Citoyenneté Canada (IRCC). Suivez attentivement les 
                               instructions pour chaque programme et assurez-vous de payer les
                                frais de traitement de la demande.
                            </p>
                        </div>
                    </div><br/>
                    {/* etape 4 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <p>
                                <b>Attendez une invitation (le cas échéant) :</b> Pour certains programmes,
                                comme le Programme des travailleurs qualifiés géré par Entrée Express,
                                 vous devrez créer un profil en ligne et attendre d'être invité à 
                                 présenter une demande. Les candidats sont sélectionnés en fonction 
                                 d'un système de classement.
                            </p>
                        </div>
                    </div><br/>
                    {/* etape 5 */}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <p>
                                <b>Passez un examen médical et des contrôles de sécurité :</b> Une fois que
                                vous avez soumis votre demande, vous pourrez être invité à passer un 
                                examen médical et des contrôles de sécurité. Vous devrez également 
                                fournir des données biométriques, telles que des empreintes digitales.
                            </p>
                        </div>
                    </div><br/>
                    {/* etape 6*/}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <p>
                                <b>Répondez aux exigences de résidence : </b>Après avoir été approuvé 
                                pour la résidence permanente, vous devrez passer une certaine 
                                période de résidence au Canada. Assurez-vous de respecter ces 
                                exigences pour conserver votre statut de résident permanent.
                            </p>
                        </div>
                    </div><br/>
                    {/* etape 7*/}
                    <div className='flex items-center flex-col '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            <img src={feuille} alt='canada' className='w-8 h-8'/>
                            <p>
                                <b>Demandez la citoyenneté (le cas échéant) :</b> Après avoir résidé au
                                Canada en tant que résident permanent pendant une période déterminée, 
                                vous pouvez envisager de demander la citoyenneté canadienne si vous 
                                remplissez les exigences.
                            </p>
                        </div>
                    </div>
                    <br/><br/>

                    <div className='flex items-center flex-col w-full '>
                        <div className='flex space-x-2 xs:w-[1040px] s:w-[260px] sm:w-[360px] '>
                            Le processus de demande de résidence permanente au Canada peut être 
                            complexe, et il est fortement recommandé de consulter un avocat en 
                            immigration ou un consultant en immigration pour obtenir de l'aide 
                            et des conseils spécifiques à votre situation. Il est également 
                            important de s'assurer que votre demande est complète et précise 
                            pour augmenter vos chances de réussite.
                        </div>
                    </div>

                   

                </div>
              </div>

              {/* eligibilité */}
              <Link to="/TestEligibiliteVisa">
                <div className='transform active:scale-75 s:px-4 md:px-0 transition-transform flex flex-row items-center justify-center md:ml-28 sm:ml-10 s:ml-6 md:mt-28 s:mt-14 shadow-lg xs:w-[500px] sm:w-[280px] s:w-[230px] space-x-10 md:h-16 s:h-14 rounded-xl ' style={{ backgroundColor: "#F3F3F3" }}>
                  <div className='md:text-lg sm:text-sm s:text-xs font-semibold' style={{ color: "#233D4D" }}>Tester votre éligibilité à la Résidence Permanente</div>

                  <div><svg xmlns="http://www.w3.org/2000/svg" width="19.173" height="25.244" viewBox="0 0 29.173 45.244">
                    <g id="left" transform="translate(-1.974 -1.315)">
                      <path id="Tracé_40" data-name="Tracé 40" d="M5.427,1.316a3.223,3.223,0,0,0-2.116,5.7L23,23.876,3.3,40.732a3.223,3.223,0,1,0,4.2,4.865L30.018,26.344a3.223,3.223,0,0,0,0-4.9L7.494,2.13a3.223,3.223,0,0,0-2.068-.815Z" transform="translate(0 0)" fill="#fe7f2d" />
                    </g>
                  </svg>
                  </div>
                </div>
              </Link>

               {/* nous vous aidons */}
              <div style={{backgroundColor:"#4e4e4e"}} className='pt-10 mt-20 flex xs:flex-row s:flex-col-reverse  md:space-x-40 px-5 '>

                <div><img className='w-[500px]' src={aide} alt='aide'/></div>

                <div className='bg-white  s:mb-20 xs:mb-0 s:ml-3  xs:w-[400px] sm:w-[350px] s:w-[260px] sm:h-[230px] xs:h-full p-2  s:h-[300px] flex flex-col items-center justify-center mt-10 '>
                  <div className='flex items-center space-x-8 '>
                    {/* icones cible */}
                    <div><img className='w-10' src={cible} alt='cible' /></div>
                    {/* texte */}
                    <div style={{ color: "#4e4e4e" }} className=' font-semibold xs:w-[250px] s:w-[180px] sm:w-[250px] md:text-xl  s:text-base sm:text-lg'>
                      Nous vous accompagnons dans le processus de demande de  Citoyenneté Canadienne.
                    </div>
                 </div>
                  <Link to="/PrendreRendezVous">
                  <button style={{ backgroundColor: "#FE7F2D" }} className='text-white font-semibold w-[160px] h-12 text-lg transform active:scale-75 transition-transform mt-10 s:ml-16 sm:ml-36'>Contactez-nous</button>
                  </Link>
                </div>
              </div>

              <FAQ/>
          </div>
        
          <Footer />
              </>
          )}
      </div>
  )
}

export default ResidenceP