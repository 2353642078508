import React from 'react'
import Header from '../../COMPONNENTS/Header/Header'
import immobilier from '../../../ASSETS/CaImmigration/images/Bien_immobilier_barniere.png'
import Footer from '../../COMPONNENTS/Footer/Footer'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import lieu from '../../../ASSETS/CaImmigration/icones/pin.png'
import homme from '../../../ASSETS/CaImmigration/images/homme_bien.png'
import maison from '../../../ASSETS/CaImmigration/icones/home1.png'
import etage from '../../../ASSETS/CaImmigration/icones/stairs.png'
import perimetre from '../../../ASSETS/CaImmigration/icones/selection-square.png'
import bain from '../../../ASSETS/CaImmigration/icones/shower.png'
import parking from '../../../ASSETS/CaImmigration/icones/parked-car.png'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visaN from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { IoMdPin } from 'react-icons/io'
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const images = [
   
    {
        label: 'Villa',
        imgPath:
            'https://images.unsplash.com/photo-1503174971373-b1f69850bded?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGludGVyaW9yJTIwZGVzaWdufGVufDB8fDB8fHww',
    },
    {
        label: 'Entrée',
        imgPath:
            'https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8aG91c2V8ZW58MHx8MHx8fDA%3D',
    },
    {
        label: 'Salle a mangé',
        imgPath:
            'https://images.unsplash.com/photo-1537726235470-8504e3beef77?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aW50ZXJpb3IlMjBkZXNpZ258ZW58MHx8MHx8fDA%3D0',
    },
    {
        label: 'Salon',
        imgPath:
            'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3IlMjBkZXNpZ258ZW58MHx8MHx8fDA%3D',
    },
];

function VoirBienImmobilier() {

    interface Items{      
        Items_Numbers: number;	
        Categories: number;	
        Names: string;	
        fr_Name: string;	
        Types: string;	
        Prices: number;	
        nb_place: number;	
        Available: string;	
        Status: string;	
        Overview: string;	
        fr_overview: string;	
        Descriptions: string;	
        fr_description: string;	
        Pictures: string;	
        subscription_frequency: string;	
        initiale_prices: number;	
        related_link: string;	
        item_doc: string;	
        contract: string;	
    }

interface Announces{
    id_announce: bigint;	
    items_Num: number;	
    description_announce: string;	
    realstate_number_pieces: string;	
    realstate_size: number;	
    realstate_swimming_pool: number;	
    realstate_type: string;	
    realstate_property_title: string;	
    realstate_parking: number;		
    realstate_elevator: number;	
    realstate_floor: number;	
    realstate_bathroom: number;	
    realstate_balcony: number;	
    realstate_concierge: number;	
    realstate_security: number;	
    realstate_air_conditioner: number;	
    realstate_furnished: number;	
    realstate_wifi: number;	
}

    interface Images{
        ID: number;
        Id_item: number;
        Image: string;
        image_Name: string;	
        color: string;	
    }
    const [itemImmob, setItemImmob]=useState<Items[]>([])
    const [itemImages, setItemImage]=useState<Images[]>([])
    const [itemAnnounces, setItemAnnounces]=useState<Announces[]>([])
    const navrdv=useNavigate()
    const BtnPrendreRdv=(Items_Numbers : any)=>{
        //console.log(Items_Numbers)
        navrdv('/PrendreRendezVous/'+Items_Numbers)
    }
    
    
    
    let [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    const id=useParams();
    const nav=useNavigate()
    const BtnAchatImmo=(Items_Numbers : any)=>{
        //console.log(Items_Numbers)
        nav('/BienImmobilier/VoirBienImmobilier/AcheterUnBien/'+Items_Numbers)
    }
    //console.log(id);
    useEffect(() => {
        //récupération du bien
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/bien/info/${id.item_id}`)
            .then((response) => {
                //console.log(response.data.data);
                setItemImmob(response.data.data);
            })
        .catch(err => console.log(err))
        
        // récupération des images du Bien
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/bien/list/image/${id.item_id}`)
            .then((response) => {
                //console.log(response.data.data);
                setItemImage(response.data.data);
            })
        .catch(err => console.log(err))

        // récupération des informations du Bien dans announces
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/get/info/bien/announce/item/${id.item_id}`)
            .then((response) => {
                console.log(response.data.data);
                setItemAnnounces(response.data.data);
            })
        .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeStepImmo, setActiveStepImmo] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

  return (
    <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          {/* back image */}
            <div className='relative z-0 flex-col w-screen h-screen bg-center bg-no-repeat bg-cover back_image ' style={{ backgroundImage: `url(${immobilier})` }}>
                {/* Navbar */}
                <div className='flex justify-between w-screen h-24 bg-transparent navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0 md:flex-row sm:flex-col s:flex-col md:items-center md:text-lg '>
                        <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center space-x-[600px]    justify-between'>

                            <Link to='/'>
                            <div style={{ borderBottomRightRadius: "100px" }} className='w-40 pb-4 pr-4 bg-white sm:pl-5 s:pl-2'>
                                <img src={logo1} className='h-20 pt-6 s:pl-0 ' alt='logo' />
                            </div>
                            </Link>
                            <div onClick={() => setOpen(!open)} className='relative pr-4 cursor-pointer lg:hidden text-end s:text-3xl s:text-white'>
                            <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
                            </div>
                        </div>
                        {/* element_menu */}
                        <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

                            <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>
                            
                                {/* Accueil */}
                                <Link to='/homeCaimmigration'>
                                <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 font-semibold text-white lg:pb-1 '>
                                    <div>
                                    <img className='w-6' src={home} alt='' />
                                    </div>
                                    <div> Accueil</div>
                                </div>
                                </Link>

                            {/* Visa */}
                                <div style={{ fontSize: "16px",  }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
                                <div className='relative bottom-1'>
                                    <img className='w-4' src={visaN} alt='' />
                                </div>
                                <div className='navbar1 '>
                                    <ul>
                                    <li> <Link to='/visa'> <p className='flex items-center space-x-2'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p> 
                                    <ul className='z-10 '>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaVisiteur'> Visa visiteur </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                                    </ul>
                                    </Link>
                                    </li>
                                    </ul>
                                </div>
                                </div>

                            {/* Résidence permanente */}
                            <Link to='/ResidencePermante'>
                            <div style={{ fontSize: "16px" }} className='flex items-center space-x-2 font-semibold cursor-pointer s:text-white hover:text-yellow-400'>
                                <div>
                                <img className='w-6' src={Rpermanent} alt='' />
                                </div>
                                <div>Résidence permanente</div>
                            </div>
                            </Link>

                            {/*Citoyenneté Canadienne */}
                            <Link to='/CitoyenneteCa'>
                            <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 font-semibold cursor-pointer s:text-white hover:text-yellow-400'>
                                <div>
                                <img className='w-6' src={CitoyenCA} alt='' />
                                </div>
                                <div> Citoyenneté Canadienne</div>
                            </div>
                            </Link>

                            {/* Achat de biens immobilers */}
                            <Link to='/BienImmobilier'>
                                <div style={{ fontSize: "16px" ,color:"#FCCA46"}} className='flex items-center space-x-2 font-semibold cursor-pointer s:text-white hover:text-yellow-400'>
                                <div>
                                    <img className='w-6' src={immobilierN} alt='' />
                                </div>
                                <div>Achat de biens immobilers</div>
                                </div>
                            </Link>
                            </div>
                        </div>
                </div>

              <div className='flex justify-end text_home md:pl-10 s:pl-5 s:pt-20 md:pt-0 s:mt-20 xs:mt-40 lg:mt-44'>
                  <div className='flex pt-20  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg    font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>ACHAT DE BIEN IMMOBILIER</p>
                  </div>
                
              </div>

          </div>

          {/* option */}
         <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col items-center justify-center pb-12 mt-10 space-y-6'>
              <div style={{ color: "##4e4e4e" }} className='font-bold text-center xs:text-2xl s:text-lg'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/* Bloc */}
          <div className='py-20 bg-white'>

              {/* Caroussel */}
              <div className='flex justify-center xs:flex-row s:flex-col s:items-center xs:items-start xs:space-x-10 md:space-x-40 s:space-y-10 xs:space-y-0'>
                {/* image et description du bien immobilier */}
                <div className='flex flex-col s:items-center xs:items-start'>
                    {/* image du bien immobilier */}
                    <Box className="xs:w-[580px] xs:h-full s:w-[260px] s:h-[200px] sm:w-[320px] s:mb-24 xs:mb-0" sx={{ flexGrow: 1 }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 50,
                            pl: 2,
                            color:'#4e4e4e',
                            bgcolor: 'background.default',
                            }}
                        >
                            {itemImages ?
                                (<Typography >{itemImages[0]?.image_Name ? itemImages[activeStep].image_Name : null}</Typography>)
                            : 
                                (<Typography >{images[activeStep].label}</Typography>)
                            }
                            
                        </Paper>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >

                            {itemImages.length>=1 ? 
                                (itemImages.map((item, index) => (
                                    <div  key={index}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                        <Box className="xs:w-[600px] xs:h-full s:w-[260px] s:h-[200px] sm:w-[320px]"
                                            component="img"
                                            sx={{
                                            display: 'block',
                                            overflow: 'hidden',
                                            width: '100%',
                                            height: '400px',
                                            }}
                                            src={`data:image/png;base64,${item.Image ? item.Image : ""}`}
                                            alt={item.color ? item.color : ""}
                                        />
                                        ) : null}
                                    </div>
                                    ))) : 
                                    (images.map((step, index) => (
                                        <div  key={step.label}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                            <Box className="xs:w-[600px] xs:h-full s:w-[260px] s:h-[200px] sm:w-[320px]"
                                                component="img"
                                                sx={{
                                                display: 'block',
                                                overflow: 'hidden',
                                                width: '100%',
                                                }}
                                                src={step.imgPath}
                                                alt={step.label}
                                            />
                                            ) : null}
                                        </div>
                                        )))
                            }

                            {/* {images.map((step, index) => (
                            <div  key={step.label}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                <Box className="xs:w-[600px] xs:h-full s:w-[260px] s:h-[200px] sm:w-[320px]"
                                    component="img"
                                    sx={{
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: '100%',
                                    }}
                                    src={step.imgPath}
                                    alt={step.label}
                                />
                                ) : null}
                            </div>
                            ))} */}
                        </AutoPlaySwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                Next
                                {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                                ) : (
                                <KeyboardArrowRight />
                                )}
                            </Button>
                            }
                            backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                                ) : (
                                <KeyboardArrowLeft />
                                )}
                                Back
                            </Button>
                            }
                        />
                    </Box>

                    {/* Non du bien immobilier */}
                    <p style={{color:"#FE7F2D"}} className='pt-4 text-2xl font-semibold uppercase'>{itemImmob[0]?.fr_Name ? itemImmob[0]?.fr_Name : ""}</p>
                    <p className='pt-4 text-xl'>Maison &nbsp; {itemAnnounces[0]?.realstate_number_pieces ? itemAnnounces[0]?.realstate_number_pieces : 4} &nbsp; pièces</p>
                    {/* <p className='pt-4 text-xl'>Maison {itemAnnounces[0]?.realstate_number_pieces ? itemAnnounces[0]?.realstate_number_pieces : 4} pièces ou 3½</p> */}
                    {/* lieu */}
                    <div className='flex items-center justify-end pt-4 pr-5 space-x-3 '>
                        
                        <img className='w-6' src={lieu} alt='icone'/>
                        <p>Quebec, Canada</p>
                    </div>
                     {/* description */}
                    <p className='md:w-[590px] xs:w-[370px] s:w-[260px] sm:w-[300px] text-justify pt-8'>{itemAnnounces[0]?.description_announce ? itemAnnounces[0]?.description_announce : ""}</p>
                    <p className='pt-10 border-b border-gray-300'></p>
                    <p style={{color:"#FE7F2D"}} className='pt-6 text-lg font-medium '>Equipement de la maison</p>
                    {/* <p className='md:w-[590px] xs:w-[390px]s:w-[260px] sm:w-[300px] pt-3'>Air conditionné, Double vitrage, Fenêtres coulissantes, Volets roulants électriques, Abri de voiture, Éclairage extérieur, Maison de gardien, Gardien, Portail électrique, Porte blindée, Vidéo surveillance, Vidéophone, Piscine</p> */}
                    <p className='md:w-[590px] xs:w-[390px]s:w-[260px] sm:w-[300px] pt-3'>
                        {itemAnnounces[0]?.realstate_parking ? "Abri de voiture, " : ""}
                        {itemAnnounces[0]?.realstate_elevator ? "Ascenceur, " : ""}
                        {itemAnnounces[0]?.realstate_balcony ? "Balcon, " : ""}
                        {itemAnnounces[0]?.realstate_concierge ? "Gardien, " : ""}
                        {itemAnnounces[0]?.realstate_security ? "Maison de gardien, " : ""}
                        {itemAnnounces[0]?.realstate_air_conditioner ? "Air conditionné, " : ""}
                        {itemAnnounces[0]?.realstate_furnished ? "Meublée, " : ""}
                        {itemAnnounces[0]?.realstate_wifi ? "Wi-fi" : ""}

                    </p>
                </div>
                {/* prix et dimenssion du bien */}
                <div>
                    <p style={{color:"#FE7F2D"}} className='text-2xl font-semibold uppercase'>Autres informations</p>
                    <div className='flex flex-col pt-5 space-y-8'>
                        <p style={{color:"#233D4D"}} className='text-lg'><span style={{color:"#4e4e4e"}} className='text-xl font-medium'>Tarif :</span>{itemImmob[0]?.Prices ? itemImmob[0]?.Prices : ""} $CAD</p>

                        {/* categorie de maison */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={maison} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>{itemAnnounces[0]?.realstate_type ? itemAnnounces[0]?.realstate_type : "villa"}</p>
                        </div>

                         {/* etages */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={etage} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>({itemAnnounces[0]?.realstate_floor ? itemAnnounces[0]?.realstate_floor : 2}) Étages </p>
                        </div>

                        {/* perimetres */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={perimetre} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>{itemAnnounces[0]?.realstate_size ? itemAnnounces[0]?.realstate_size : 40} m<sup>2</sup> </p>
                        </div>

                        {/* salle de bain */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={bain} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>({itemAnnounces[0]?.realstate_bathroom ? itemAnnounces[0]?.realstate_bathroom : 1}) Salles d'eau/ de bain </p>
                        </div>

                         {/* salle de bain */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={parking} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>{itemAnnounces[0]?.realstate_parking >=1 ? "Oui" : "Non"} </p>
                        </div>
                    </div>
                    <div className='flex flex-col pt-10 space-y-8'>
                        {/* <Link to='/BienImmobilier/VoirBienImmobilier/AcheterUnBien'><button style={{backgroundColor:"#FE7F2D"}} className='h-12 text-lg text-white transition-transform transform rounded sm:w-80 s:w-56 active:scale-75 '>J'achète</button></Link> */}
                        <button onClick={() => BtnAchatImmo(itemImmob[0]?.Items_Numbers ? 
                            itemImmob[0]?.Items_Numbers : "")} style={{backgroundColor:"#FE7F2D"}} className='h-12 text-lg text-white transition-transform transform rounded sm:w-80 s:w-56 active:scale-75 '>
                            J'achète
                        </button>
                        {/* <Link to='/PrendreRendezVous'><button className='h-12 text-lg transition-transform transform rounded btn_detail_bien sm:w-80 s:w-56 active:scale-75 '>Demander plus de details</button></Link> */}
                        <button onClick={() => BtnPrendreRdv(itemImmob[0]?.Items_Numbers ? 
                            itemImmob[0]?.Items_Numbers : "")} className='h-12 text-lg transition-transform transform rounded btn_detail_bien sm:w-80 s:w-56 active:scale-75 '>
                            Demander plus de details
                        </button>
                    </div>
                </div>
              </div>

           <div style={{backgroundColor:"#4e4e4e"}} className='flex items-center pt-5 mt-20 xs:flex-row s:flex-col-reverse md:space-x-32'>
            <div>
                <img className='w-[450px]' src={homme} alt='homme'/>
            </div>
            <div className='pb-5 space-y-8 '>
                <p className='md:text-3xl xs:text-2xl s:text-xl font-bold text-white s:w-[260px] sm:w-[300px] xs:w-[400px] md:w-[500px]'>Obtenez votre maison plus facilement avec Mon Bon Sejour</p>
                <p className='text-justify xs:w-[360px] md:w-[450px] s:w-[260px] sm:w-[300px] text-xl text-white'>Explorez une gamme exceptionnelle de biens immobiliers sur notre site. De maisons élégantes à des appartements modernes, trouvez la propriété parfaite qui correspond à vos besoins. Avec notre équipe dévouée, chaque étape de votre parcours immobilier est facilitée.</p>
                {/* <Link to="/PrendreRendezVous"><button className='mt-10 font-medium text-white transition duration-150 ease-in-out delay-150 border-2 border-white hover:-translate-y-1 hover:scale-110 xs:w-36 xs:h-12 s:w-28 s:h-10 s:text-sm xs:text-base hover:bg-white hover:text-orange-400'>Prendre RDV</button></Link> */}
                <button onClick={() => BtnPrendreRdv(itemImmob[0]?.Items_Numbers ? 
                    itemImmob[0]?.Items_Numbers : "")} className='mt-10 font-medium text-white transition duration-150 ease-in-out delay-150 border-2 border-white hover:-translate-y-1 hover:scale-110 xs:w-36 xs:h-12 s:w-28 s:h-10 s:text-sm xs:text-base hover:bg-white hover:text-orange-400'>Prendre RDV</button>
            </div>
           </div>

          </div>
        
          <Footer />
              </>
          )}
    </div>
  )
}

export default VoirBienImmobilier