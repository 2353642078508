import React from 'react'
import depanage from '../../../ASSETS/tourisme/icone/tools.png'
import maintenance from '../../../ASSETS/tourisme/icone/car-wash.png'
import BG from '../../../ASSETS/tourisme/Image/bg.png'
import {Link} from 'react-router-dom'
import { IoMdAdd } from "react-icons/io";


function BarniereLvoiture() {
  return (
      <div className='img_culture_C2 s:py-10 bg-cover bg-center bg-no-repeat s:space-y-10   flex flex-col justify-center items-center  lg:h-screen lg:w-screen mb-10 text-white ' style={{ backgroundImage: `url(${BG})` }}>
          {/* title */}
          <div className='flex flex-col justify-center items-center space-y-3'>
              <p className='md:text-4xl s:text-xl font-semibold'><span className='text-orange-400'>Avez vous besoin</span> de service ?</p>
              <p className='md:w-[900px] xs:w-[500px] text-center md:text-base s:text-sm '>
              Facilitez la croissance de votre flotte ! Intégrez vos véhicules à notre plateforme de location 
              en ligne en quelques étapes simples. Bénéficiez d'une visibilité accrue auprès de voyageurs en quête d'aventures.
              </p>
          </div>
          
          {/* option */}
          <div className='grid gap-28 md:grid-cols-3 xs:grid-cols-2 sm:grid-cols-1 place-content-center'>
              {/* Depannage */}
              <div className='shadow-xl bg-white w-52 h-40 rounded-md flex flex-col space-y-4 items-center justify-center'>
                  <img src={depanage} className='w-16' alt='icone depane' />
                  <p className=' text-lg text-[#FCCA46] font-bold'> Dépannage</p>
              </div>
              {/* vente */}
              <div className='shadow-xl bg-yellow-400 w-52 h-40 rounded-md flex flex-col space-y-4 items-center justify-center'>
                  <svg id="buy" xmlns="http://www.w3.org/2000/svg" width="51.863" height="51.863" viewBox="0 0 71.863 71.863">
                      <path id="Tracé_53" data-name="Tracé 53" d="M73.863,46.316A20.37,20.37,0,0,0,54.7,26.016V15.175a1.2,1.2,0,0,0-.351-.847L42.372,2.351A1.2,1.2,0,0,0,41.525,2H5.593A3.593,3.593,0,0,0,2,5.593V70.27a3.593,3.593,0,0,0,3.593,3.593H51.107A3.593,3.593,0,0,0,54.7,70.27V66.616a20.37,20.37,0,0,0,19.164-20.3ZM42.723,6.089l7.888,7.888H43.92a1.2,1.2,0,0,1-1.2-1.2Zm8.384,65.379H5.593a1.2,1.2,0,0,1-1.2-1.2V5.593a1.2,1.2,0,0,1,1.2-1.2H40.327V12.78a3.593,3.593,0,0,0,3.593,3.593H52.3v9.643a20.334,20.334,0,0,0,0,40.6V70.27A1.2,1.2,0,0,1,51.107,71.468Zm2.4-7.186A17.966,17.966,0,1,1,71.468,46.316,17.966,17.966,0,0,1,53.5,64.282Z" transform="translate(-2 -2)" fill="#fff" />
                      <path id="Tracé_54" data-name="Tracé 54" d="M11.593,29.559v1.2a3.593,3.593,0,1,0,7.186,0v-1.2H23.57v1.2a3.593,3.593,0,1,0,7.186,0v-1.2a3.593,3.593,0,0,0,3.593-3.593V21.175a3.585,3.585,0,0,0-2.515-3.409l-.32-2.58H34.35v-2.4H31.215l-.206-1.643A3.593,3.593,0,0,0,27.444,8H14.906a3.593,3.593,0,0,0-3.566,3.148l-.206,1.643H8v2.4h2.835l-.32,2.58A3.585,3.585,0,0,0,8,21.175v4.791A3.593,3.593,0,0,0,11.593,29.559Zm4.791,1.2a1.2,1.2,0,1,1-2.4,0v-1.2h2.4Zm11.977,0a1.2,1.2,0,1,1-2.4,0v-1.2h2.4ZM14.906,10.4H27.444a1.2,1.2,0,0,1,1.189,1.049l.767,6.137H22.2a4.768,4.768,0,0,0-9.242,0H12.95l.767-6.137A1.2,1.2,0,0,1,14.906,10.4Zm4.738,7.186H15.519a2.375,2.375,0,0,1,4.125,0ZM10.4,21.175a1.2,1.2,0,0,1,1.2-1.2H30.757a1.2,1.2,0,0,1,1.2,1.2v4.791a1.2,1.2,0,0,1-1.2,1.2H11.593a1.2,1.2,0,0,1-1.2-1.2Z" transform="translate(-0.814 -0.814)" fill="#fff" />
                      <path id="Tracé_55" data-name="Tracé 55" d="M12.791,20h4.791v2.4H12.791Zm11.977,0h4.791v2.4H24.768ZM52.316,31.977a4.791,4.791,0,0,1,4.791,4.791h2.4a7.194,7.194,0,0,0-5.989-7.079v-2.5h-2.4v2.5a7.186,7.186,0,0,0,1.2,14.265,4.791,4.791,0,1,1-4.791,4.791h-2.4a7.194,7.194,0,0,0,5.989,7.079v2.5h2.4v-2.5a7.186,7.186,0,0,0-1.2-14.265,4.791,4.791,0,1,1,0-9.582Zm-33.536,2.4H9.2A1.2,1.2,0,0,0,8,35.57v9.582a1.2,1.2,0,0,0,1.2,1.2h9.582a1.2,1.2,0,0,0,1.2-1.2V35.57A1.2,1.2,0,0,0,18.779,34.373Zm-1.2,9.582H10.4V36.768h7.186Zm1.2,7.186H9.2a1.2,1.2,0,0,0-1.2,1.2V61.92a1.2,1.2,0,0,0,1.2,1.2h9.582a1.2,1.2,0,0,0,1.2-1.2V52.339A1.2,1.2,0,0,0,18.779,51.141Zm-1.2,9.582H10.4V53.536h7.186Zm4.791-23.954h7.186v2.4H22.373Zm0,4.791h7.186v2.4H22.373Zm4.791,11.977h4.791v2.4H27.164Zm0,4.791h4.791v2.4H27.164Zm-4.791-4.791h2.4v2.4h-2.4Zm0,4.791h2.4v2.4h-2.4Z" transform="translate(-0.814 1.559)" fill="#fff" />
                  </svg>
                  <p className=' text-white font-bold text-center'> Assurance
                  </p>
              </div>

              {/* Maintenance */}
              <div className=' shadow-xl bg-white w-52 h-40 rounded-md flex flex-col space-y-4 items-center justify-center'>
                  <img src={maintenance} className='w-16' alt='icone depane' />
                  <p className='text-lg text-[#FCCA46] font-bold text-center'> Entretien
                      & maintenance
                  </p>
              </div>

          </div>

          {/* button */}
          <Link to="/Dashboard/Publications/Location_Vehicule/Ajouter_les_vehicules" className='flex justify-center'>
                <button className='bg-white p-2 text-[#4e4e4e] transition ease-in-out delay-150 rounded 
                    hover:-translate-y-1 hover:scale-110 duration-300  s:absolute s:right-0 md:relative md:left-0 flex items-center space-x-2'>
                    <IoMdAdd />
                    <p> Ajouter un véhicule</p>
                </button>
          </Link>

      </div>
  )
}

export default BarniereLvoiture