import React from 'react'
import Header from '../../COMPONENTS/Header/Header'
import BoutonLogin from '../../COMPONENTS/BoutonLogin/BtnLogin'
import BarreHeader from '../../COMPONENTS/BarreHeader/BarreHeader'
import { useState } from 'react'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import {Link} from 'react-router-dom'
import { IoMdAdd } from "react-icons/io";

function Navbar() {
    let [open, setOpen]=useState(false);
  return (
    <div>

        <div className='fixed z-10 drop-shadow-md'>

        <BarreHeader/>
        <Header/>
        <div className='navbar_animation navbar  flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
            <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2  items-center xs:space-x-[600px] justify-between'>
                
             <Link to='/'>
                <div className=' sm:pl-5 s:pl-2  '>
                    <img src={logo1} className='h-14 pl-10 s:pl-0 ' alt='logo'/>
                </div>
             </Link>
                <div onClick={()=>setOpen(!open)} className=' lg:hidden justify-end pr-5 s:text-3xl text-black  cursor-pointer'>
                <ion-icon name={open? 'close':'menu'}></ion-icon>
                </div>
            </div>
            {/* element_menu */}
            <div className={`el flex md:relative s:absolute s:z-10 s:py-5 text-black  md:flex-row md:space-x-5 s:flex-col s:bg-sky-50 sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open? 's:left-0 s:top-32 md:top-auto md:left-auto':'s:left-[-490px]  md:left-auto'} `}>

            <div className={`flex md:flex-row md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-sm lg:text-lg s:space-y-5 `}>
                {/* Accueil */}
                 <Link to='/hometourisme' >
                 <div className='flex  space-x-2 items-center lg:pt-4 md:pt-5  s:pt-0 hover:border-b-2 border-orange-400'> 
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21.673" height="18.687" viewBox="0 0 21.673 18.687">
                            <g id="home" transform="translate(0 -17.319)">
                                <path id="Tracé_21" data-name="Tracé 21" d="M49.692,135.551a.646.646,0,0,0-.646.646v6.559H35.927V136.2a.646.646,0,0,0-1.293,0V143.4a.646.646,0,0,0,.646.646H49.692a.646.646,0,0,0,.646-.646V136.2A.646.646,0,0,0,49.692,135.551Z" transform="translate(-31.649 -108.043)" fill="#fe7f2d"/>
                                <path id="Tracé_22" data-name="Tracé 22" d="M21.484,27.7,11.293,17.508a.646.646,0,0,0-.914,0L.19,27.7a.646.646,0,0,0,.914.914l9.732-9.732,9.733,9.732a.646.646,0,1,0,.914-.914Z" transform="translate(0 0)" fill="#fe7f2d"/>
                            </g>
                            </svg>
                        </div>
                        <div>
                            Accueil
                        </div>
                </div>
                </Link>

                {/* Culture */}
               <Link to='/culture' > 
               <div className='flex space-x-2 items-center hover:border-b-2 border-orange-400 cursor-pointer'> 
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.234" height="21.229" viewBox="0 0 26.234 21.229">
                        <g id="decouverte" transform="translate(-1.947 -8)">
                            <path id="Tracé_30" data-name="Tracé 30" d="M27.884,31.466h-.63c-.385-.282-7.513-13.228-8.136-13.176-.3-.239-2.414,3.017-2.6,3.143-3.075.936-2.518.882-4.155,3.487-.362-.271-1.207-2.9-1.939-2.083l-7.335,8.63c-.726-.219-1.743.491-.676.878C2.367,31.876,31.407,33.433,27.884,31.466ZM14.56,23.045c2.821-.863,2.533-.866,4.232-3.177l.27,3.253c-1.739-.34-2.062.805-2.949,1.933a22.273,22.273,0,0,0-.217,2.854c-.343-.72-2.855-1.267-3.026-2.146Zm-3.867.826,1.729,2.882-.526.7c-1.745-.947-3.021.637-4.592.4ZM4.238,31.466l2.379-2.8a5.411,5.411,0,0,0,3.729-.693c1.581.324,1.786.935,2.787-.7L15.1,28.285l.34.568a3.421,3.421,0,0,0-.788.225.225.225,0,0,0-.16.245.221.221,0,0,0,.22.193,2.2,2.2,0,0,0,1.026-.169l.181.3a14.827,14.827,0,0,0-2.742.529.271.271,0,0,0,.144.521c1-.19,2-.239,3.008-.368l.68,1.134Zm13.793,0-1.323-2.205.189-3.788L18,24c.264-.051,1.693.131,1.861-.142.277-.079-.162-3.006-.125-3.215l3.621,6.037c-.175.09-.481.195-.376.447.123.265.46.091.666.038l.17.283c-.315.111-2.335.449-1.755.964a1.025,1.025,0,0,0,.631.012,10,10,0,0,0,1.491-.365l.314.523a41.558,41.558,0,0,0-4.895,1.226c-.2.07-.184.408.053.388,1.776-.164,3.5-.673,5.268-.9l1.3,2.175ZM8.559,20.046a3.079,3.079,0,0,0,3.075-3.075C9.925,9.06.647,18.311,8.559,20.046Zm0-5.271a2.2,2.2,0,0,1,0,4.392A2.2,2.2,0,0,1,8.559,14.776Z" transform="translate(0 -3.262)" fill="#fe7f2d"/>
                            <path id="Tracé_31" data-name="Tracé 31" d="M10.148,9.757V8.439a.439.439,0,1,0-.878,0V9.757a.439.439,0,1,0,.878,0Zm-3.234,1.6a.442.442,0,0,0,.311-.75C5.291,8.285,4.937,10.432,6.913,11.354ZM4,13.71c-.05.668,2.253.676,2.2,0C6.245,13.041,3.942,13.034,4,13.71Zm2.6,2.485c-.3.347-1.788,1.44-.622,1.681.284.059,1.043-.92,1.242-1.06.408-.4-.224-1.03-.621-.621Zm2.666,1.468v1.318a.439.439,0,1,0,.878,0V17.663a.439.439,0,0,0-.878,0Zm2.924-.847.932.932a.439.439,0,1,0,.621-.621l-.932-.931a.439.439,0,0,0-.621.621Zm3.225-3.106c.05-.668-2.253-.676-2.2,0C13.172,14.378,15.476,14.386,15.418,13.71ZM13.125,9.673l-.932.931a.439.439,0,0,0,.621.622l.932-.932a.439.439,0,1,0-.621-.621Zm.241,17.356a.343.343,0,1,0,0,.485.343.343,0,0,0,0-.485Z" transform="translate(-1.15)" fill="#fe7f2d"/>
                        </g>
                        </svg>

                    </div>
                    <div className=''>
                        Culture et Decouverte
                    </div>
                </div>
                </Link>

                {/* hebergement */}
                <Link to="/hebergement">
                <div className='flex space-x-2 items-center hover:border-b-2 border-orange-400 cursor-pointer'> 
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.49" height="18.993" viewBox="0 0 28.49 18.993">
                        <g id="hotel" transform="translate(-3 -10)">
                            <path id="Tracé_23" data-name="Tracé 23" d="M27.42,14.07H15.21a.68.68,0,0,0-.678.678v5.427H6.392v-9.5A.68.68,0,0,0,5.713,10H3.678A.68.68,0,0,0,3,10.678V28.315a.68.68,0,0,0,.678.678H5.713a.68.68,0,0,0,.678-.678V24.923H28.1v3.392a.68.68,0,0,0,.678.678h2.035a.68.68,0,0,0,.678-.678V18.14A4.073,4.073,0,0,0,27.42,14.07Zm-12.888,9.5H5.713a.68.68,0,0,0-.678.678v3.392H4.357V11.357h.678v9.5a.68.68,0,0,0,.678.678h8.818Zm15.6,4.07h-.678V24.245a.68.68,0,0,0-.678-.678H15.888v-8.14H27.42a2.713,2.713,0,0,1,2.713,2.713Z" fill="#fe7f2d"/>
                            <path id="Tracé_24" data-name="Tracé 24" d="M9,17.392A3.392,3.392,0,1,0,12.392,14,3.393,3.393,0,0,0,9,17.392Zm5.427,0a2.035,2.035,0,1,1-2.035-2.035A2.041,2.041,0,0,1,14.427,17.392Z" transform="translate(-1.93 -1.287)" fill="#fe7f2d"/>
                        </g>
                        </svg>
                    </div>
                    <div>
                        Hébergement
                    </div>
                </div>
                </Link>

                {/* Restaurant */}
                <Link to="/restaurants">
                <div className='flex space-x-2 items-center  border-b-2 border-orange-400 cursor-pointer'> 
                    <div>
                        <svg id="restaurant" xmlns="http://www.w3.org/2000/svg" width="18.729" height="18.729" viewBox="0 0 18.729 18.729">
                        <path id="Tracé_25" data-name="Tracé 25" d="M121.121,117a5.121,5.121,0,1,0,5.121,5.121A5.127,5.127,0,0,0,121.121,117Zm0,9.511a4.39,4.39,0,1,1,4.39-4.39A4.395,4.395,0,0,1,121.121,126.511Zm0,0" transform="translate(-111.757 -112.72)" fill="#fe7f2d"/>
                        <path id="Tracé_26" data-name="Tracé 26" d="M18.363,12.576V.366a.366.366,0,0,0-.655-.224A11.488,11.488,0,0,0,15.6,4.605a7.952,7.952,0,0,0-.673-.766A7.865,7.865,0,0,0,3.8,3.84q-.074.074-.145.15V.366a.366.366,0,0,0-.732,0v4.39H2.195V.366a.366.366,0,1,0-.732,0v4.39H.732V.366A.366.366,0,0,0,0,.366V5.121A1.836,1.836,0,0,0,.732,6.584v5.992c-.222,2.04-.366,4.346-.366,4.69a1.463,1.463,0,1,0,2.926,0c0-.258-.08-1.615-.214-3.137a7.961,7.961,0,0,0,.725.834,7.865,7.865,0,0,0,11.122,0,7.916,7.916,0,0,0,1.148-1.452c-.167,1.757-.271,3.464-.271,3.755a1.463,1.463,0,1,0,2.926,0C18.729,16.922,18.585,14.616,18.363,12.576ZM16.9,10.059h.732v.732H16.9ZM16.171,5.5a10.753,10.753,0,0,1,1.46-3.959v7.79h-.946l-.517-.517ZM1.463,10.059h.732v.732H1.463Zm1.4-4.572a1.1,1.1,0,0,1-.486.585.366.366,0,0,0-.183.317v2.94H1.463V6.388a.366.366,0,0,0-.183-.317,1.1,1.1,0,0,1-.486-.585ZM1.829,18a.732.732,0,0,1-.732-.732c0-.279.366-4.657.366-4.67V11.523h.732V12.6c0,.013.366,4.391.366,4.67A.732.732,0,0,1,1.829,18Zm7.535-1.463a7.142,7.142,0,0,1-6.438-4.063V6.584a1.836,1.836,0,0,0,.731-1.457,7.127,7.127,0,0,1,11.782.54l0,3.3a.366.366,0,0,0,.107.259l.624.624v1.7A7.145,7.145,0,0,1,9.364,16.534ZM17.266,18a.732.732,0,0,1-.732-.732c0-.279.366-4.657.366-4.67V11.523h.732V12.6c0,.013.366,4.391.366,4.67A.732.732,0,0,1,17.266,18Zm0,0" fill="#fe7f2d"/>
                        <path id="Tracé_27" data-name="Tracé 27" d="M247.8,250.292a.366.366,0,0,0,.005.732h.006a.366.366,0,1,0-.011-.732Zm2.88-2.926a2.912,2.912,0,0,1-1.627,2.623.366.366,0,0,0,.163.694.359.359,0,0,0,.162-.038,3.658,3.658,0,0,0,2.033-3.278.366.366,0,0,0-.732,0Zm0,0" transform="translate(-238.384 -237.965)" fill="#fe7f2d"/>
                        </svg>

                    </div>
                    <div>
                        Restaurant
                    </div>
                </div>
                </Link>

                {/* Location de vehicule */}
                <Link to='/locationdevehicule' >
                <div className='flex space-x-2 items-center hover:border-b-2 border-orange-400 cursor-pointer'> 
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.152" height="16.094" viewBox="0 0 22.152 16.094">
                        <g id="vehicule" transform="translate(-2.22 -11.185)">
                            <path id="Tracé_28" data-name="Tracé 28" d="M23.555,14.959h-1.3a1.881,1.881,0,0,0-.9.226,8.833,8.833,0,0,0-.828-2.078,3.4,3.4,0,0,0-2.521-1.649,40.639,40.639,0,0,0-9.417,0,3.338,3.338,0,0,0-2.524,1.649,8.71,8.71,0,0,0-.831,2.078,1.9,1.9,0,0,0-.9-.226H3.041a.821.821,0,0,0-.821.818v1.054a.822.822,0,0,0,.821.821H4.349a4.572,4.572,0,0,0-1.088,2.774l0,6.1a.751.751,0,0,0,.754.75h2.71a.751.751,0,0,0,.754-.75V24.883h11.64v1.646a.748.748,0,0,0,.75.75h2.713a.751.751,0,0,0,.754-.75c0-3.663,0-4.54,0-6.1a4.6,4.6,0,0,0-1.091-2.774h1.311a.821.821,0,0,0,.818-.821V15.777a.821.821,0,0,0-.818-.818Zm.142,1.872a.148.148,0,0,1-.142.145H21.483a.339.339,0,0,0-.237.581,4.373,4.373,0,0,1,1.412,2.869V23.8a.418.418,0,0,1-.416.409H4.345a.41.41,0,0,1-.409-.409V20.426a4.434,4.434,0,0,1,1.406-2.869.339.339,0,0,0-.233-.581H3.041a.146.146,0,0,1-.145-.145V15.777a.148.148,0,0,1,.145-.142H4.335a1.185,1.185,0,0,1,.848.351.007.007,0,0,0,.007.007.337.337,0,0,0,.578-.152,8.706,8.706,0,0,1,.882-2.4,2.671,2.671,0,0,1,2.017-1.314,40.021,40.021,0,0,1,9.258,0,2.7,2.7,0,0,1,2.014,1.314,8.592,8.592,0,0,1,.882,2.4.334.334,0,0,0,.287.26.331.331,0,0,0,.311-.128l0,0a1.184,1.184,0,0,1,.835-.338h1.3a.147.147,0,0,1,.142.142Z" fill="#fe7f2d"/>
                            <path id="Tracé_29" data-name="Tracé 29" d="M25.04,16.708a1.919,1.919,0,0,0-1.426-.926,38.715,38.715,0,0,0-9.072,0,1.9,1.9,0,0,0-1.429.926,9.359,9.359,0,0,0-1,3.392H26.034A9.368,9.368,0,0,0,25.04,16.708ZM13.214,21.922c-1.213-.385-1.747-.622-2.081.03a1.89,1.89,0,0,0-.182.855,1.081,1.081,0,0,0,.956,1.135,3.259,3.259,0,0,0,.5-.017,15.593,15.593,0,0,1,1.946-.084.428.428,0,0,0,.328-.632A2.441,2.441,0,0,0,13.214,21.922ZM17.123,23.9h1.433a.392.392,0,0,0,.392-.392v-.862a.392.392,0,0,0-.392-.392H16.265a.39.39,0,0,0-.392.392,1.249,1.249,0,0,0,.365.885A1.232,1.232,0,0,0,17.123,23.9Zm4.693,1.5H16.336a.191.191,0,0,0-.186.237,1.037,1.037,0,0,0,1.01.8h3.832a1.037,1.037,0,0,0,1.01-.8.193.193,0,0,0-.186-.237Zm.558-3.149H20.083a.392.392,0,0,0-.392.392V23.5a.392.392,0,0,0,.392.392h1.433a1.232,1.232,0,0,0,.885-.368,1.249,1.249,0,0,0,.365-.885A.392.392,0,0,0,22.374,22.25Zm3.051-.328a2.432,2.432,0,0,0-1.466,1.287.425.425,0,0,0,.324.632,15.642,15.642,0,0,1,1.95.084,3.235,3.235,0,0,0,.5.017,1.078,1.078,0,0,0,.953-1.135,1.933,1.933,0,0,0-.179-.855C27.169,21.3,26.635,21.537,25.425,21.922Z" transform="translate(-5.78 -2.867)" fill="#fe7f2d"/>
                        </g>
                        </svg>
                    </div>
                    <div>
                    Location de vehicule
                    </div>
                </div>
                </Link>

                {/* Losirs & activité */}
                <Link to="/Loisirs_Activite">
                    <div className='flex space-x-2 items-center hover:border-b-2 border-orange-400 cursor-pointer'> 
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.872" height="18.595" viewBox="0 0 12.872 18.595">
                            <g id="Groupe_4" data-name="Groupe 4" transform="translate(-212.02 -84)">
                                <path id="Tracé_32" data-name="Tracé 32" d="M221.5,535.292a1.372,1.372,0,0,1-1.341-1.6,2.127,2.127,0,0,1,.05-.223,1.352,1.352,0,0,1,.218-.443l2.28-3.045a.934.934,0,0,0,.181-.463l.031-2.545a.358.358,0,0,1,.358-.353h0a.358.358,0,0,1,.353.362l-.031,2.56a.2.2,0,0,1,0,.028,1.651,1.651,0,0,1-.322.839L221,533.454a.642.642,0,0,0-.1.209,1.5,1.5,0,0,0-.033.148.651.651,0,0,0,.235.621.638.638,0,0,0,.643.1l.012-.005.009,0a.64.64,0,0,0,.266-.209l2.723-3.63.289-1.3a.358.358,0,0,1,.7.155l-.305,1.374a.354.354,0,0,1-.063.137l-2.771,3.692a1.359,1.359,0,0,1-.567.441l-.007,0-.024.01a1.369,1.369,0,0,1-.5.1Z" transform="translate(-7.942 -432.728)" fill="#fe7f2d"/>
                                <path id="Tracé_33" data-name="Tracé 33" d="M354.96,260.168a.358.358,0,0,1-.335-.479l1.763-4.868a1.808,1.808,0,0,1,1.25-1.145,1.722,1.722,0,0,1,1.32.2,2.208,2.208,0,0,1,.733.7l1.5,2.268a.3.3,0,0,0,.249.134h1.741a1.134,1.134,0,0,1,.958.522.905.905,0,0,1-.083,1.09,1.127,1.127,0,0,1-.889.425h-2.863a1.808,1.808,0,0,1-1.489-.766c-.021-.03-.042-.062-.061-.095l-.213-.354-.234-.389c-.14-.232-.249-.409-.353-.574l-.237-.375a.358.358,0,1,1,.6-.382l.238.373c.107.169.218.35.361.586.079.13.156.261.235.391l.211.352c.012.019.024.038.036.056a1.093,1.093,0,0,0,.9.46h2.863a.422.422,0,0,0,.329-.155c.105-.132.078-.2.036-.267a.413.413,0,0,0-.352-.186h-1.741a1.011,1.011,0,0,1-.845-.453l-1.5-2.267a1.491,1.491,0,0,0-.494-.471,1.017,1.017,0,0,0-.788-.124,1.1,1.1,0,0,0-.754.7l-1.763,4.868A.358.358,0,0,1,354.96,260.168Z" transform="translate(-139.396 -165.83)" fill="#fe7f2d"/>
                                <path id="Tracé_34" data-name="Tracé 34" d="M349.515,409.577a1.262,1.262,0,0,1-.41-.069c-.666-.229-1.042-.649-1.032-1.153l.013-.607v-1.654a.935.935,0,0,0-.223-.606l-3.328-3.917a.358.358,0,1,1,.545-.463l3.329,3.917a1.652,1.652,0,0,1,.393,1.069v1.666l-.014.611c-.005.251.419.417.549.461a.5.5,0,0,0,.36-.006.706.706,0,0,0,.348-.574v-3.506a.933.933,0,0,0-.246-.632l-1.671-1.824a2.024,2.024,0,0,1-.425-2.023l.654-1.914a.358.358,0,1,1,.677.231l-.654,1.914a1.309,1.309,0,0,0,.274,1.309l1.671,1.824a1.646,1.646,0,0,1,.435,1.116v3.533a1.394,1.394,0,0,1-.782,1.2A1.174,1.174,0,0,1,349.515,409.577Z" transform="translate(-129.47 -307.09)" fill="#fe7f2d"/>
                                <path id="Tracé_35" data-name="Tracé 35" d="M215.047,94.735a.726.726,0,0,1-.238-.04l-.231-.081c-.159-.057-.324-.115-.485-.168l-1.021-.332a1.563,1.563,0,0,0-.175-.047,1.1,1.1,0,0,1-.794-.479.68.68,0,0,1-.039-.567l0-.007c.011-.03.022-.059.031-.086l1.727-5.244c.048-.146.087-.3.128-.455l.011-.043a.8.8,0,0,1,.289-.435.921.921,0,0,1,.8-.159l1.7.45a1.359,1.359,0,0,1,.956.63,1.525,1.525,0,0,1,.1,1.164l-.679-.226v0a.819.819,0,0,0-.039-.586.693.693,0,0,0-.489-.286l-.02,0-1.712-.452a.2.2,0,0,0-.176.031.087.087,0,0,0-.037.05l-.011.043c-.042.163-.085.332-.141.5l-1.729,5.245c-.012.036-.024.069-.036.1a.7.7,0,0,0,.3.114,2.351,2.351,0,0,1,.252.067l1.021.333c.171.055.34.116.5.174l.226.08c.015,0,.1-.04.214-.227a.358.358,0,0,1,.318-.17c.022,0,2.235.059,3.014-1.482a.358.358,0,0,1,.639.322,3.38,3.38,0,0,1-2.235,1.687,5.486,5.486,0,0,1-1.243.186.891.891,0,0,1-.7.4Zm5.26-7.009a1.863,1.863,0,1,1,1.864-1.863A1.863,1.863,0,0,1,220.308,87.726Zm0-3.011a1.148,1.148,0,1,0,1.149,1.148A1.148,1.148,0,0,0,220.308,84.715Zm3.464,17.88a.358.358,0,0,1-.358-.358V92.819a.358.358,0,1,1,.715,0v9.418A.358.358,0,0,1,223.772,102.6Zm0-10.741a.358.358,0,0,1-.358-.358V89.54a.358.358,0,0,1,.715,0V91.5A.358.358,0,0,1,223.772,91.854Z" fill="#fe7f2d"/>
                                <path id="Tracé_36" data-name="Tracé 36" d="M280.811,284.268a.362.362,0,0,1-.1-.014l-3.267-.937a.358.358,0,1,1,.2-.687l3.267.937a.358.358,0,0,1-.1.7Z" transform="translate(-63.709 -194.177)" fill="#fe7f2d"/>
                            </g>
                            </svg>

                        </div>
                        <div>
                        Loisirs & Activités
                        </div>
                    </div>
                </Link>

            </div>
        <div className='md:hidden '>
        <BoutonLogin/>
        </div>
            </div>
        
            <div className='md:visible s:invisible'>
        <BoutonLogin/>
        </div>

        </div>

        <Link to="/Dashboard/Publications/Restaurant/Ajouter_des_restaurants">
            <button className='bg-orange-400 p-2 text-white s:absolute s:right-0 md:relative md:left-0 flex items-center space-x-2'>
                <IoMdAdd />
            <p> Ajouter un restaurant</p>
            </button>
        </Link>
    </div>

    </div>
  )
}

export default Navbar
