
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputHote from './InputHote'



function BarreSearchP() {
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  return (
    <div className=' '>
      

      <div className='barreSearch bg-sky-950   s:py-5 md:h-24  s:h-full xs:w-[600px] md:w-full grid s:grid-cols-1 xs:grid-cols-2 md:grid-cols-5 gap-4 items-center  px-10  '>

        {/* Destination */}
      
          <Box className=" s:w-[200px]  md:w-52 h-14 pt-[4px]"  sx={{ }}>
            <FormControl fullWidth className="sm:w-60 s:w-40 xs:w-96 md:w-52 h-14 bg-white rounded-md">
              <InputLabel id="demo-simple-select-label">Destination</InputLabel>
              <Select 
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Abidjan"}>Abidjan</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Bouake"}>Bouaké</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Dimokro"}>Dimokro</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Man"}>Man</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Divo"}>Divo</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Issia"}>Issia</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Daloa"}>Daloa</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Yamoussokro"}>Yamoussokro</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Jacqueville"}>Jacqueville</MenuItem>
                <MenuItem className="sm:w-60 s:w-40 xs:w-96 md:w-52" value={"Sassandra"}>Sassandra</MenuItem>
              </Select>
            </FormControl>
          </Box>

        {/* arrivée */}
       
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DemoContainer components={['DatePicker'] } >
            <DatePicker label="Arrivée" className='  s:w-20  md:w-52 h-14 bg-white px-5 rounded-md outline-none ' />
          </DemoContainer>
        </LocalizationProvider>

        {/* Depart */}
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DemoContainer components={['DatePicker'] } >
            <DatePicker label="Départ" className='  s:w-40 md:w-52 h-14 bg-white px-5 rounded-md outline-none ' />
          </DemoContainer>
        </LocalizationProvider>



        {/* Hote */}
       <div className='pt-[4px]'>
          <InputHote />
       </div>
        {/* search */}
            <button className='bg_search_p h-12  s:w-[200px] md:w-40 rounded-md'>
                Recherche
            </button>

      </div>
    </div>
  )
}



export default BarreSearchP
