import axios from 'axios';

let Utilisateur : any = {};

Utilisateur.submitForm = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/profil/edit`, data)
}

Utilisateur.getUserInfo = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/profil/info/${id}`)
}

Utilisateur.getAllData = async () =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/culture/all`)
}

Utilisateur.submitReservation = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/culture/send/reservation`, data)
}

Utilisateur.submitEdit = async (data:any) =>{
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/culture/edit`, data)
}

Utilisateur.getOne = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/culture/show/${id}`)
}

Utilisateur.getImages = async (id:any) =>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/culture/images/${id}`)
}


export default Utilisateur;