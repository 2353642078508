
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color:'#fe7f2d' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#fff',
    color:'#fe7f2d',
    fontSize:'12rem',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Faq() {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

  return (
      <div className='md:px-12 s:px-5 mt-10'>
        <div style={{color:'#4e4e4e'}} className='uppercase text-2xl font-semibold pb-8'>questions fréquentes</div>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography >Quel est le moyen le plus facile d'immigrer au Canada ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                     Immigrer au Canada peut être un processus complexe, et il n'y a pas de moyen
                     unique et facile pour tout le monde, car les exigences d'immigration varient 
                     en fonction de votre situation personnelle, de votre expérience et de votre 
                     objectif d'immigration. Cependant, voici quelques-unes des voies les plus 
                     courantes pour immigrer au Canada :<br/><br/>
                     <p>
                      1- <b>Programme d'immigration économique :</b> Le Canada propose plusieurs programmes
                       d'immigration économique, notamment le Programme des travailleurs qualifiés, 
                       le Programme des travailleurs de métiers spécialisés, le Programme de l'expérience 
                       canadienne, le Programme des candidats des provinces, et d'autres. Ces programmes 
                       sont conçus pour attirer des travailleurs qualifiés, des entrepreneurs et des investisseurs.
                     </p><br/>
                     <p>
                      2- <b>Regroupement familial :</b> Si vous avez des membres de votre famille au 
                      Canada qui sont citoyens canadiens ou résidents permanents, vous 
                      pourriez être admissible à immigrer au Canada grâce à une demande de 
                      parrainage familial.
                     </p><br/>
                     <p>
                      3- <b>Programme de réfugiés :</b> Si vous craignez des persécutions dans votre pays
                      d'origine, vous pourriez être admissible à demander l'asile au Canada.
                     </p><br/>
                     <p>
                      4- <b>Études :</b> Vous pouvez obtenir un permis d'études pour étudier au Canada, et après 
                      l'obtention de votre diplôme, vous pourriez être admissible à un permis de travail
                       postdiplôme ou à un programme de résidence permanente.
                     </p><br/>
                     <p>
                      5- <b>Investissement :</b> Si vous êtes un entrepreneur ou un investisseur, 
                      certaines provinces canadiennes offrent des programmes spécifiques 
                      pour les entrepreneurs et les investisseurs qui souhaitent créer ou investir 
                      dans des entreprises au Canada.
                     </p><br/>
                     <p>
                      6- <b>Programme des travailleurs agricoles temporaires :</b> Si vous avez de l'expérience 
                      dans l'agriculture, vous pourriez être admissible au Programme des travailleurs 
                      agricoles temporaires, qui permet de travailler au Canada temporairement.
                     </p><br/>
                     <p>
                      Il est important de noter que chaque programme d'immigration a ses propres critères 
                      d'admissibilité, et le processus peut prendre du temps. Il est recommandé de consulter
                      le site Web officiel d'Immigration, Réfugiés et Citoyenneté Canada (IRCC) pour obtenir 
                      des informations à jour sur les programmes d'immigration et pour déterminer celui qui 
                      correspond le mieux à votre situation. Il est également conseillé de consulter un avocat 
                      en immigration ou un consultant en immigration agréé pour obtenir une orientation spécifique 
                      à votre cas.
                     </p>
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>Quel est le délai de traitement d'un visa canadien ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                    Le délai de traitement d'un visa canadien varie en fonction du type de 
                    visa que vous demandez, de votre pays de résidence et du volume de demandes 
                    que le gouvernement canadien reçoit à un moment donné. Voici quelques exemples 
                    de délais approximatifs pour certains types de visas :<br/><br/>
                    <p>
                      1- <b>Visa de visiteur (tourisme, affaires, famille) :</b> Les délais de traitement 
                      pour les visas de visiteur varient généralement de quelques semaines à 
                      quelques mois, en fonction de votre pays de résidence.
                    </p><br/>
                    <p>
                      2- <b>Permis d'études :</b> Les délais de traitement pour les permis d'études dépendent 
                      du programme d'études, de la durée du programme et d'autres facteurs. En général,
                      le traitement d'une demande de permis d'études peut prendre de quelques semaines 
                      à plusieurs mois.
                    </p><br/>
                    <p>
                      3- <b>Permis de travail :</b> Les délais de traitement des permis de travail varient 
                      en fonction du type de permis. Les permis de travail temporaires peuvent prendre 
                      quelques semaines, tandis que les permis de travail postdiplôme pour les étudiants
                       diplômés au Canada sont généralement traités plus rapidement.
                    </p><br/>
                    <p>
                      4- <b>Programme de résidence permanente :</b> Les délais de traitement pour 
                      les programmes de résidence permanente, tels que le Programme des travailleurs 
                      qualifiés ou le Programme des candidats des provinces, varient considérablement 
                      en fonction du programme et de votre situation. Certains programmes peuvent avoir 
                      des délais de traitement plus longs en raison de la demande élevée.
                    </p>
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>Quelle somme d'argent pour entrer au Canada ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Pour avoir une estimation approximative, voici quelques éléments à prendre en compte pour un voyageur :<br/><br/>
                      <p>
                        1- <b>Billets d'avion :</b> Les prix des billets d'avion varient en fonction de la saison, de la destination et de
                        la compagnie aérienne. Il est recommandé de faire des recherches pour trouver les meilleures offres, mais prévoyez 
                        un budget pour les billets aller-retour.
                      </p><br/>
                      <p>
                        2- <b>Hébergement :</b> Les coûts d'hébergement dépendent du type d'endroit où vous séjournez
                        (hôtel, auberge de jeunesse, location de logement, etc.). Les grandes villes canadiennes comme 
                        Toronto, Vancouver et Montréal ont tendance à avoir des tarifs d'hébergement plus élevés que les 
                        régions moins urbanisées.
                      </p><br/>
                      <p>
                        3- <b>Nourriture :</b> Les dépenses alimentaires varient en fonction de vos préférences alimentaires, 
                        de l'endroit où vous mangez (restaurants, restauration rapide, cuisine maison), et de la fréquence de 
                        vos repas à l'extérieur.
                      </p><br/>
                      <p>
                        4- <b>Transport :</b> Les coûts de transport local dépendent de la ville où vous séjournez et de la
                         manière dont vous vous déplacez (transports en commun, location de voiture, taxis, etc.).
                      </p><br/>
                      <p>
                        5- <b>Activités et loisirs :</b> Les coûts liés aux activités touristiques, aux visites de musées, 
                        aux excursions, aux divertissements, etc., dépendent de vos préférences et de ce que vous souhaitez 
                        faire pendant votre séjour.
                      </p><br/>
                      <p>
                        5- <b>Assurance voyage :</b> Il est recommandé de souscrire une assurance voyage pour couvrir d'éventuels frais médicaux, d'annulation de voyage ou de perte de bagages.
                      </p><br/><br/>

                      <p>Le budget global peut varier de quelques centaines à quelques milliers de dollars canadiens par semaine, en fonction de ces facteurs. Il est recommandé de planifier soigneusement votre voyage, de rechercher les coûts locaux et de créer un budget en fonction de vos préférences et de vos besoins.
                      </p>
                  </Typography>
              </AccordionDetails>
          </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography >Comment eviter le refus de visa Canada ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      L'obtention d'un visa pour le Canada dépend de plusieurs facteurs, 
                      et il n'y a aucune garantie absolue de succès. Cependant, voici quelques 
                      conseils qui peuvent aider à minimiser le risque de refus :<br/><br/>

                      <p>1- <b>Préparez une demande complète : </b>Assurez-vous de remplir correctement
                       tous les formulaires requis, en fournissant des informations exactes et complètes. 
                       Suivez attentivement les instructions du guide d'application.</p><br/>

                      <p>2- <b>Documents à l'appui :</b> Fournissez tous les documents nécessaires, tels que les relevés bancaires, preuves 
                        d'emploi, lettres d'invitation, certificats de mariage, etc. Assurez-vous qu'ils sont 
                        à jour et pertinents.</p><br/>

                      <p>3- <b>Respectez les délais : </b>Soumettez votre demande suffisamment tôt pour éviter tout problème lié aux délais. 
                        Les demandes soumises à la dernière minute peuvent être refusées si elles ne peuvent 
                        pas être traitées à temps.</p><br/>

                      <p>4- <b>Justification financière :</b> Assurez-vous de démontrer que vous avez les moyens 
                        financiers nécessaires pour votre séjour au Canada. Cela peut inclure des relevés bancaires, 
                        des lettres de soutien financières, etc.</p><br/>

                      <p>5- <b>Respect des conditions de retour :</b> Fournissez des preuves solides de vos attaches
                       avec votre pays d'origine, comme un emploi stable, des biens immobiliers, des liens familiaux, 
                       etc. Cela montre que vous avez des raisons de retourner dans votre pays après votre séjour au Canada.</p><br/>

                      <p>6- <b>Communication claire :</b> Si une entrevue est requise, soyez prêt à expliquer clairement
                       le but de votre voyage, votre plan d'itinéraire, et tout autre aspect pertinent. Soyez honnête et 
                       transparent.</p><br/>

                      <p>7- <b>Consultez un professionnel :</b>Si vous avez des préoccupations spécifiques ou des 
                      circonstances complexes, envisagez de consulter un consultant en immigration ou un avocat 
                      spécialisé pour obtenir des conseils professionnels.</p>
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <Typography>Quel est le délai de traitement d'un permis d'étude?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Selon le pays à partir duquel vous faites une demande, il faut compter 
                      jusqu'à 3 mois avant d'obtenir un permis d'études.
                  </Typography>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography>Quel diplôme pour immigrer au Canada ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                  </Typography>
              </AccordionDetails>
          </Accordion>
      </div>
  )
}

export default Faq