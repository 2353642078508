import React from 'react'

function index() {
  return (
    <div>
      <div className='B_header w-screen h-1 '></div>
    </div>
  )
}

export default index
