import React from 'react'
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom'
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { BiHotel } from "react-icons/bi";
import { MdOutlineHomeWork, MdOutlinePayment } from "react-icons/md";
import { IoBedOutline } from "react-icons/io5";
import { RxDimensions } from "react-icons/rx";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlinePool, MdElevator } from "react-icons/md";
import { MdOutlineWifi, MdBalcony } from "react-icons/md";
import { RiParkingBoxFill } from "react-icons/ri";
import { SlScreenDesktop } from "react-icons/sl";
import { IoRestaurant } from "react-icons/io5";
import { LuMicrowave, LuArmchair } from "react-icons/lu";
import { GiBathtub } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { FaUser, FaBellConcierge } from "react-icons/fa6";
import { GiMoneyStack, GiCctvCamera } from "react-icons/gi";
import { MdOutlineRoomPreferences, MdOutlineSevereCold } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { TbFridge } from "react-icons/tb";
import { IoMdPin, IoIosMail } from "react-icons/io";
import Heber from "../../../../API/tourisme/hebergement/edit"
import { ToastContainer, toast } from "react-toastify"
import { FaPhoneAlt } from 'react-icons/fa';


function EditHebergement() {

    localStorage.setItem('url', JSON.stringify('/Dashboard/Publications/Hebergement/Voir_des_hebergements'));

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
    });

    const navigate = useNavigate();

    const [records, setRecords] = useState([]);
    const [tmprecords, setTmpecords] = useState([]);
    const [changed, setChanged] = useState();

    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({});

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [address, setAddress] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChanged(true);
        setFormData({ ...formData, [name]: value });
    };

    const location = useLocation();
    const id = location.state

    const handleCountryChange = (event) => {
        const { name, value } = event.target;
        setChanged(true);
        setFormData({ ...formData, [name]: value });

        //province
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/province/list/${value}`)
     .then(response => {setProvinces(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    const handleProvinceChange = (event) => {
        const { name, value } = event.target;
        setChanged(true);
        setFormData({ ...formData, [name]: value });

        //city
     axios.get(`${process.env.REACT_APP_BASE_URL}/api/city/list/${value}`)
     .then(response => {setCities(response.data.data)})
     //.then(response => console.log(response.data.data))
     .catch(err => console.log(err))

    };

    useEffect(() => {

        if (!data) {
            navigate("/Connexion");
        }
        
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/hebergement/show/${id}`)
     .then(response => {
        setRecords(response.data.data)
        setTmpecords(response.data.data)
    })
     //.then(response => console.log(response))
     .catch(err => console.log(err))

      //country
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/country/list`)
      .then(response => {setCountries(response.data.data)})
      //.then(response => console.log(response.data.data))
      .catch(err => console.log(err))

      //adresse
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/annonce/info/${id}`)
      .then(response => {
        setAddress(response.data.data)
    })
      .catch(err => console.log(err))

        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        //console.log(formData);

        let fd = new FormData();

        fd.append("id", id);
        fd.append("nom", formData.name_h ?? records.title_announce);
        fd.append("type", formData.type ?? records.realstate_type);
        fd.append("methode", formData.methode ?? records.payment_method);
        fd.append("date", formData.date ?? records.realstate_move_in_date);
        fd.append("size", formData.perimetre ?? records.realstate_size);
        fd.append("lit", formData.bed_type ?? records.realstate_bedtype);
        fd.append("ascenseur", formData.ascenseur ?? records.realstate_elevator);
        fd.append("balcon", formData.balcon ?? records.realstate_balcony);
        fd.append("concierge", formData.concierge ?? records.realstate_concierge);
        fd.append("camera", formData.camera ?? records.realstate_security);
        fd.append("clim", formData.climatiseur ?? records.realstate_air_conditioner);
        fd.append("meuble", formData.meuble ?? records.realstate_furnished);
        fd.append("desc", formData.msg ?? records.description_announce);
        fd.append("chambre", formData.nbre ?? records.realstate_number_pieces);
        fd.append("note", formData.note ?? records.note);
        fd.append("parking", formData.parking ?? records.realstate_parking);
        fd.append("piscine", formData.piscine ?? records.realstate_swimming_pool);
        fd.append("prix", formData.prix ?? records.price_announce);
        fd.append("wifi", formData.wifi ?? records.realstate_wifi);

        fd.append("adresse", formData.lieu ?? records.contact_address);
        fd.append("mail", formData.mail ?? records.contact_email);
        fd.append("tel", formData.phone ?? records.contact_phone);
        fd.append("pays", formData.country ?? records.contact_country);
        fd.append("ville", formData.ville ?? records.contact_city);
        fd.append("province", formData.province ?? records.contact_province);

        fd.append("date_debut", formData.date_debut ?? records.date_debut);
        fd.append("date_fin", formData.date_fin ?? records.date_fin);
        for(let i = 0; i < document.getElementById("photo")?.files.length; i++){
        fd.append("photo[]", document.getElementById("photo")?.files[i]);
    }
    console.log(fd);
        setLoading(true)
        Heber.submitForm(fd)
            .then((res) => {
                if (res.data.status === 201) {
                    toast.success(res.data.message);
                    console.log(res);
                } else {
                    toast.error(res.data.message);
                    console.log(res);
                }
                navigate("/Dashboard/Publications/Hebergement/Voir_des_hebergements");
            })
            .catch((err) => {
                toast.error('Erreur merci de reessayer !');
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    {/* sidebar left */}

                    <div className='w-screen h-screen barre_scroll'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements" className="opacity-60 md:text-base s:text-xs">
                                    Hebergements
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Modifier
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <div>
                <ToastContainer />
            </div>

                        <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précedente</p>
                            </div>
                        </Link>
                        {/* container formulaire */}
                        <div className='flex justify-center py-20 '>
                        <form onSubmit={handleSubmit} className='flex flex-col items-center space-y-3 '>

<div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
    {/* nom d'apartement */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
            <label>Nom</label>
        </div>
        <input defaultValue={tmprecords.title_announce} onChange={handleInputChange} required type='text' name='name_h' placeholder='Ex : Villa la joie' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
    </div>
    {/* Ajout des images */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
            <label> Ajouter des images</label>
        </div>
        <input onChange={handleInputChange} type="file" accept="image/*" multiple id="photo" name="photo" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 '/>
    </div>
    {/* Type de paiement */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <MdOutlinePayment className='text-xl text-[#fe7f2d]' />
            <label>Méthode de paiement</label>
        </div>
        <select onChange={handleInputChange} name="methode" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4' required>
        <option value=""></option>
        {tmprecords.payment_method === 1 
        ? <option value="1"  selected>Paiement en ligne et sur place</option>
        : <option value="1" >Paiement en ligne et sur place</option>
        }

{tmprecords.payment_method === 2 
        ? <option value="2" selected>Paiement en ligne</option>
        : <option value="2">Paiement en ligne</option>
        }

{tmprecords.payment_method === 3 
        ? <option value="3" selected>Paiement sur place</option>
        : <option value="3">Paiement sur place</option>
        }
        
        </select>
    </div>
    {/* Type */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
            <label>Type</label>
        </div>
        <select onChange={handleInputChange} name="type" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4' required>
        <option value=""></option>
        {tmprecords.realstate_type === "other" 
        ? <option value="other" selected>Autre</option>
        : <option value="other">Autre</option>
        }
        {tmprecords.realstate_type === "house" 
        ? <option value="house" selected>Maison</option>
        : <option value="house">Maison</option>
        }
        {tmprecords.realstate_type === "hotel" 
        ? <option value="hotel" selected>Hotel</option>
        : <option value="hotel">Hotel</option>
        }
        {tmprecords.realstate_type === "appartment"
        ? <option value="appartment" selected>Appartement</option>
        : <option value="appartment">Appartement</option>
        }
        
        </select>
    </div>
    {/* nombre de la chambre */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <BiHotel className='text-xl text-[#fe7f2d]' />
            <label>Nombres de pièces</label>
        </div>
        <input defaultValue={tmprecords.realstate_number_pieces} onChange={handleInputChange} required type='number' name='nbre' placeholder='Ex : 2 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
    </div>
    {/* perimetre chambre */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <RxDimensions className='text-xl text-[#fe7f2d]' />
            <label>Périmetre(m²)</label>
        </div>
        <input defaultValue={tmprecords.realstate_size} onChange={handleInputChange} type='number' name='perimetre' placeholder='Ex : 40 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
    </div>
    {/* Type de lit */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <IoBedOutline className='text-xl text-[#fe7f2d]' />
            <label>Type de lit</label>
        </div>
        <select onChange={handleInputChange} name="bed_type" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
        <option value=""></option>
        {tmprecords.realstate_bedtype === "Lit simple"
        ? <option value="Lit simple" selected>Lit simple</option>
        : <option value="Lit simple">Lit simple</option>
        }
        {tmprecords.realstate_bedtype === "Lit double"
        ? <option value="Lit double" selected>Lit double</option>
        : <option value="Lit double">Lit double</option>
        }
        {tmprecords.realstate_bedtype === "Lit triple"
        ? <option value="Lit triple" selected>Lit triple</option>
        : <option value="Lit triple">Lit triple</option>
        }      
        
        </select>
    </div>
</div>

<div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                    {/* Tel */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <FaPhoneAlt className=' text-[#fe7f2d]' />
                            <label>Telephone</label>
                        </div>
                        <input type='tel' defaultValue={tmprecords.contact_phone} name='phone' onChange={handleInputChange} placeholder="Ex : +225 xxx xxx xxxx " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Mail */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoIosMail className=' text-[#fe7f2d]' />
                            <label>E-mail</label>
                        </div>
                        <input type='email' defaultValue={tmprecords.contact_email} name='mail' onChange={handleInputChange} placeholder="Ex : info@monbonsejour.com " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                    {/* Country */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Pays</label>
                        </div>
                        <select onChange={handleCountryChange} required name='country' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code}>{address.fr_Name}</option>
                                            {countries.map((option, index) => (
          <option key={index} value={option.code} >{option.fr_Name}</option>
        
        ))}
                                            
                                        </select>
                    </div>
                    {/* Province */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Province</label>
                        </div>
                        <select onChange={handleProvinceChange} required name='province' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_province}>{address.pname}</option>
                                            {provinces.map((option, index) => (
          <option key={index} value={option.code_province}>{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Ville */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Ville</label>
                        </div>
                        <select onChange={handleInputChange} required name='ville' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option value={address.code_city}>{address.cname}</option>
                                            {cities.map((option, index) => (
          <option key={index} value={option.code_city} selected={option.code_city === tmprecords.contact_city ? true : false}  >{option.fr_Name}</option>
        ))}
                                            
                                        </select>
                    </div>
                    {/* Lieu */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Lieu</label>
                        </div>
                        <input type='text' defaultValue={tmprecords.contact_address} name='lieu' onChange={handleInputChange} placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                </div>

<div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-3 '>
    {/* caractéristique de la chambre */}
    <p className='text-lg font-semibold'>Dans votre appartement ou chambre</p>
    <div className='grid s:grid-cols-2 sm:grid-cols-3 xs:grid-cols-3 gap-y-8'>
        {/* piscine */}
        <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <MdOutlinePool className='text-xl text-[#fe7f2d]' />
                <label>Piscine</label>
            </div>
               
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_swimming_pool === 1
        ? <input onChange={handleInputChange} type='radio' name='piscine' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='piscine' value="1" />
        }
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_swimming_pool === 0
        ? <input onChange={handleInputChange} type='radio' name='piscine' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='piscine' value="0" />
        }
                
                <label>Non</label>
            </div>
        </div>
        {/* Wifi  */}
        <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <MdOutlineWifi className='text-xl text-[#fe7f2d]' />
                <label>Wifi </label>
            </div>
             
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_wifi === 1
        ? <input onChange={handleInputChange} type='radio' name='wifi' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='wifi' value="1" />
        } 
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_wifi === 0
        ? <input onChange={handleInputChange} type='radio' name='wifi' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='wifi' value="0" />
        } 
                
                <label>Non</label>
            </div>
        </div>
        {/* TV */}
        <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <GiCctvCamera className='text-xl text-[#fe7f2d]' />
                <label>Cameras</label>
            </div>
              
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_security === 1
        ? <input onChange={handleInputChange} type='radio' name='camera' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='camera' value="1" />
        } 
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_security === 0
        ? <input onChange={handleInputChange} type='radio' name='camera' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='camera' value="0"  />
        } 
                
                <label>Non</label>
            </div>
        </div>
        {/* Pétit déjeuner */}
        <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <MdBalcony className='text-xl text-[#fe7f2d]' />
                <label>Balcon</label>
            </div>
              
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_balcony === 1
        ? <input onChange={handleInputChange} type='radio' name='balcon' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='balcon' value="1" />
        } 
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_balcony === 0
        ? <input onChange={handleInputChange} type='radio' name='balcon' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='balcon' value="0" />
        } 
                
                <label>Non</label>
            </div>
        </div>
        {/* Micro onde */}
        <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <MdElevator className='text-xl text-[#fe7f2d]' />
                <label>Ascenseur</label>
            </div>  
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_elevator === 1
        ? <input onChange={handleInputChange} type='radio' name='ascenseur' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='ascenseur' value="1"  />
        }
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_elevator === 0
        ? <input onChange={handleInputChange} type='radio' name='ascenseur' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='ascenseur' value="0" />
        }
                
                <label>Non</label>
            </div>
        </div>
        {/* Baignoire */}
        <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <FaBellConcierge className='text-xl text-[#fe7f2d]' />
                <label>Conciegre</label>
            </div>
               
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_concierge === 1
        ? <input onChange={handleInputChange} type='radio' name='concierge' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='concierge' value="1" />
        }
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_concierge === 0
        ? <input onChange={handleInputChange} type='radio' name='concierge' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='concierge' value="0" />
        }
                
                <label>Non</label>
            </div>
        </div>
        {/* Ustensile de cuisine */}
        <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <RiParkingBoxFill className='text-xl text-[#fe7f2d]' />
                <label>Parking</label>
            </div>
               
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_parking === 1
        ? <input onChange={handleInputChange} type='radio' name='parking' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='parking' value="1" />
        }
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_parking === 0
        ? <input onChange={handleInputChange} type='radio' name='parking' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='parking' value="0" />
        }
                
                <label>Non</label>
            </div>
        </div>
        {/* frigo */}
        <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <MdOutlineSevereCold className='text-xl text-[#fe7f2d]' />
                <label>Climatiseur</label>
            </div>
               
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_air_conditioner === 1
        ? <input onChange={handleInputChange} type='radio' name='climatiseur' value="1" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='climatiseur' value="1"  />
        }
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_air_conditioner === 0
        ? <input onChange={handleInputChange} type='radio' name='climatiseur' value="0" defaultChecked={true} />
        : <input onChange={handleInputChange} type='radio' name='climatiseur' value="0" />
        }
                
                <label>Non</label>
            </div>
        </div>
         {/* frigo */}
         <div className='flex flex-col space-y-2 '>
            <div className='flex flex-row items-center space-x-2'>
                <LuArmchair className='text-xl text-[#fe7f2d]' />
                <label>Meublé</label>
            </div>
             
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_furnished === 1
        ? <input onChange={handleInputChange} type='radio' defaultChecked={true} name='meuble' value="1" />
        : <input onChange={handleInputChange} type='radio' name='meuble' value="1" />
        }  
                
                <label>Oui</label>
            </div>
            <div className='flex flex-row items-center space-x-2'>
            {tmprecords.realstate_furnished === 0
        ? <input onChange={handleInputChange} defaultChecked={true} type='radio' name='meuble' value="0" />
        : <input onChange={handleInputChange} type='radio' name='meuble' value="0" />
        }  
                
                <label>Non</label>
            </div>
        </div>
    </div>
</div>

<div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
    {/* nombre de personne */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de disponibilité</label>
        </div>
        <input defaultValue={tmprecords.realstate_move_in_date} name='date' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>
    {/* équipement */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <MdOutlineRoomPreferences className=' text-[#fe7f2d]' />
            <label>Équipements</label>
        </div>
        <input defaultValue={tmprecords.note} type='text' onChange={handleInputChange} name='note' placeholder='Ex : Bureau, Machine à café, Minibar, Fer à rapasser, Netflix, Canal+ ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
    </div>
    {/* Tarif */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
            <label>Tarif</label>
        </div>
        <input defaultValue={tmprecords.price_announce} onChange={handleInputChange} type='number' name='prix' required placeholder='Ex : 70000 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
        <small>Le tarif doit être par nuitée</small>
    </div>
    {/* Description */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
            <label>Description de la chambre</label>
        </div>
        <textarea defaultValue={tmprecords.description_announce} onChange={handleInputChange} name="msg" className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
    </div>
</div>

<div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
    {/* date debut */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de debut affichage</label>
        </div>
        <input defaultValue={tmprecords.date_debut} name='date_debut' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>

    {/* date fin */}
    <div className='flex flex-col space-y-3'>
        <div className='flex flex-row items-center space-x-2'>
            <CiCalendarDate className=' text-[#fe7f2d]' />
            <label>Date de fin affichage</label>
        </div>
        <input defaultValue={tmprecords.date_fin} name='date_fin' onChange={handleInputChange} type='date' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' required />
    </div>
    
</div>

<button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Modifier</button>
</form>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default EditHebergement